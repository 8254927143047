import { InventoryState } from '../../state'
import { IProviderInventory } from '../../../../classes/Inventory/interfaces/IProviderInventory'
import { IPurchaseOrder } from '../../../../classes/Inventory/interfaces/IPurchaseOrder'

export default {
  SET_PURCHASE_ORDER (state: InventoryState, entities: IPurchaseOrder[]): void {
    state.purchase_order = entities
  },
  PUSH_PURCHASE_ORDER (state: InventoryState, entitie: IPurchaseOrder): void {
    state.purchase_order.push(entitie)
  },
  UPDATE_PURCHASE_ORDER (state: InventoryState, entitie: IPurchaseOrder): void {
    const index = state.purchase_order.findIndex(p => p.id === entitie.id)
    if (index !== -1) {
      state.purchase_order.splice(index, 1, entitie)
    }
  },
  DELETE_PURCHASE_ORDER (state: InventoryState, entitie: IPurchaseOrder): void {
    const index = state.purchase_order.findIndex(p => p.id === entitie.id)
    if (index !== -1) {
      state.purchase_order.splice(index, 1)
    }
  }
}

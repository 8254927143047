import { State } from '../../state'
import { ActionContext } from 'vuex'
import { IProviderInventory } from '../../../../classes/Inventory/interfaces/IProviderInventory'
const actions = {
  async loadProviderInventory ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/provider/get/all',
        method: 'GET'
      },
      { root: true }
    )
    commit('SET_PROVIDER_INVENTORY', data)
    return data
  },
  async createProviderInventory ({ commit, dispatch }: ActionContext<State, any>, provider_inventory: IProviderInventory) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/provider/create',
        method: 'POST',
        data: provider_inventory
      },
      { root: true }
    )
    commit('PUSH_PROVIDER_INVENTORY', data)
    return data
  },
  async updateProviderInventory ({ commit, dispatch }: ActionContext<State, any>, provider_inventory: IProviderInventory) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/provider/update/${provider_inventory.id}`,
        method: 'PUT',
        data: provider_inventory
      },
      { root: true }
    )
    commit('UPDATE_PROVIDER_INVENTORY', data)
    return data
  },
  async deleteProviderInventory ({ commit, dispatch }: ActionContext<State, any>, provider_inventory: IProviderInventory) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/provider/delete/${provider_inventory.id}`,
        method: 'DELETE'
      },
      { root: true }
    )
    commit('DELETE_PROVIDER_INVENTORY', provider_inventory)
    return data
  }
}
export default actions

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex justify-center align-center"
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.message) + " ")]), _c('span', [_c('v-btn', {
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.btnAction.apply(null, arguments);
      }
    }
  }, [_vm._v(_vm._s(_vm.btnText))])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }
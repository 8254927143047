import { State } from '../../state'
import { ActionContext } from 'vuex'
import { IBatteryCapacity } from '../../../../classes/Battery/interfaces/IBatteryCapacity'
const actions = {
  async loadBatteryCapacity ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/battery_capacity/get/all',
        method: 'GET'
      },
      { root: true }
    )
    console.log('loadBatteryCapacity action received data:', data)
    commit('SET_BATTERY_CAPACITY', data)
    return data
  },
  async createBatteryCapacity ({ commit, dispatch }: ActionContext<State, any>, entity: IBatteryCapacity) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/battery_capacity/create',
        method: 'POST',
        data: entity
      },
      { root: true }
    )
    commit('PUSH_BATTERY_CAPACITY', data)
    return data
  },
  async updateBatteryCapacity ({ commit, dispatch }: ActionContext<State, any>, entity: IBatteryCapacity) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/battery_capacity/update/${entity.id}`,
        method: 'PUT',
        data: entity
      },
      { root: true }
    )
    commit('UPDATE_BATTERY_CAPACITY', data)
    return data
  },
  async deleteBatteryCapacity  ({ commit, dispatch }: ActionContext<State, any>, entity: IBatteryCapacity) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/battery_capacity/delete/${entity.id}`,
        method: 'DELETE'
      },
      { root: true }
    )
    commit('DELETE_BATTERY_CAPACITY', entity)
    return data
  }
}
export default actions

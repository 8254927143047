import state from './state'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'
import { change_store } from './modules/change_store'
import { requisition } from './modules/requisition/index'

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
  modules: {
    change_store,
    requisition
  }
}

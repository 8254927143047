/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { WIALON_SENSORS_TYPES } from '@/store/catalogs/WIALON_SENSORS_TYPES'
import ReportItemData from './ReportItemData'
import { UnitConsole } from '@/store/interfaces/ReportModules'
import { WSensor } from '@/store/interfaces/WSensor'
import getRouteData from '@/utils/wialonSDK/getRouteData'
import { trycatchAsync } from '@/utils/trycatch'
import { PLATAFORM } from '@/store/resources/RESOURCES'

export default class RIWialon
  extends ReportItemData<any, any>
  implements UnitConsole {
    public bateryFlag = 'V';
    public keyUnitMessage = ''
    public pType = PLATAFORM.WIALON

    async getDistanceByTime ({ from, to }: { from: number; to: number }): Promise<number> {
      const data = await trycatchAsync(() => getRouteData({ unit_id: this.unit_resource_id, from, to }), 0)
      if (data === 0) return 0
      return Math.round(data.mileage / 1000)
    }

    public getWialonUnitSensorData (wst: WIALON_SENSORS_TYPES): any {
      if (!this.unit_item) return null
      const sensors: WSensor[] = Object.values(this.unit_item.getSensors())
      if (!sensors) return null
      const sensor = sensors.find((sess) => sess.t === wst)
      if (!sensor) return null
      const value = this.unit_item.calculateSensorValue(
        sensor,
        this.last_message
      )
      return value
    }

    async getIgnitionState (): Promise<number> {
      const state = this.getWialonUnitSensorData(
        WIALON_SENSORS_TYPES.ENGINE_IGNITION_SENSOR
      )
      return state
    }

    unsusbscribeToUnitMessages (): void {
      if (this.keyUnitMessage) {
        this.unit_item.removeListenerById(this.keyUnitMessage)
      }
    }

    speed () {
      const lastMessage = this.unit_item.getLastMessage()
      // @ts-ignore
      return lastMessage?.pos?.s
    }

    susbscribeToUnitMessages () {
      let keyUnitMessage = ''
      try {
        const lastMessage = this.unit_item?.getLastMessage()
        if (lastMessage) {
          this.last_message = lastMessage
        }

        if (!this.unit_item?.getLastMessage) {
          console.log(`Unidad ${this.id} sin acceso a los ultimos mensaje`)
          this.unit_item.getLastMessage = function () {
            return new Date().getMilliseconds()
          }
        }

        keyUnitMessage = this.unit_item?.addListener(
          'changeLastMessage',
          (event: { getData: () => any }) => {
            const messageData = event.getData()
            // @ts-ignore
            this.unit_km_current = event._target.$$user_mileageCounter
            // @ts-ignore
            this.unit_hour_engine_current = parseInt(event._target.$$user_engineHoursCounter?.toFixed(1))
            this.last_message = messageData
          // this.onUnitDataChanged();
          }
        )
      } catch (error) {
        console.error(error)
      }

      setTimeout(() => {
        this.keyUnitMessage = keyUnitMessage
      }, 0)
    }

    getName (): string {
      return this.unit_item ? this.unit_item.$$user_name : `${this.unit_name} NO ESTA EN EL TOKEN`
    }

    getIcon (): string {
      return this.unit_item ? this.unit_item.getIconUrl() : '/images/truck.png'
    }
}

// import { GeofencesState, ICGeofence } from './state'
import { TypeState } from '../../state'
import TypeFuel from '../../../../classes/Types/Typefuel'

export default {
  SET_TYPEFUEL (state: TypeState, typefuels: TypeFuel[]): void {
    state.typefuels = typefuels
  },
  PUSH_TYPEFUEL (state: TypeState, typefuel: TypeFuel): void {
    state.typefuels.push(new TypeFuel(typefuel))
  },
  UPDATE_TYPEFUEL (state: TypeState, typefuel: TypeFuel): void {
    const index = state.typefuels.findIndex(p => p.id === typefuel.id)
    if (index !== -1) {
      state.typefuels.splice(index, 1, new TypeFuel(typefuel))
    }
  },
  DELETE_TYPEFUEL (state: TypeState, typefuel: TypeFuel) {
    state.typefuels = state.typefuels.filter(item => item.id !== typefuel.id)
  }
}


export interface CustomerState {
    customers: any[]
}

const state = (): CustomerState => ({
  customers: []
})
export type State = ReturnType<typeof state>;

export default state

import StreamaxJSSdk from '..'
import { RequestDefault, RequestQueryData } from '../interfaces/RequestsWrapper.interface'
import BaseApi from './BaseApi.base'

export default abstract class ResourceApi<IDataType, createDTO, updateDTO, listDTO, deleteDTO> extends BaseApi {
    /** Part of the url of the api of the services that will be called */
    protected abstract apiPlaceUrl: string

    public abstract create(params: createDTO): Promise<any>
    public abstract update(id: string, params: updateDTO): Promise<any>
    public abstract delete(params: deleteDTO): Promise<any>
    public abstract list(params?: listDTO): Promise<any>

    protected async createUtil (params: createDTO) {
      const { data } = await StreamaxJSSdk.axios<RequestDefault<{}>>({
        url: this.apiPlaceUrl,
        method: 'POST',
        data: params
      })
      return data
    }

    /**
     * Update the specified resource.
     *
     * @param {string} id - The ID of the resource to update
     */
    protected async updateUtil (id: string, params: updateDTO) {
      const { data } = await StreamaxJSSdk.axios<RequestDefault<{}>>({
        url: `${this.apiPlaceUrl}/${id}`,
        method: 'PATCH',
        data: params
      })
      return data
    }

    /**
     * Delete a resource using the provided parameters.
     */
    protected async deleteUtil (params: deleteDTO) {
      const { data } = await StreamaxJSSdk.axios<RequestDefault<{}>>({
        url: this.apiPlaceUrl,
        method: 'DELETE',
        data: params
      })
      return data
    }

    /**
     * Retrieves a list of items from the API.
     */
    protected async listUtil (params?: listDTO) {
      const { data } = await StreamaxJSSdk.axios<RequestQueryData<{}, IDataType[]>>({
        url: this.apiPlaceUrl,
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        params
      })
      return { ...data, data: this.assingToItems<typeof this.itemClass>(data.data, this.itemClass) }
    }
}

/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */

import { ActionContext } from 'vuex/types/index'
import {
  RESOURCES,
  PLATAFORM,
  PLATAFORM_RESOURCES
} from '../../resources/RESOURCES'
import { State } from '../state'
const URL = '/apis/inventoty_type/'
const actions = {
  async createInventoryType (
    { dispatch, commit }: ActionContext<State, any>,
    payload: {
      name: string;
    }
  ) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL + 'create',
        method: 'POST',
        data: payload
      },
      { root: true }
    )

    commit(
      'resources/PUSH_RESOURCE',
      {
        item: data,
        rType: RESOURCES.TRAVELS_INV
      },
      { root: true }
    )
  },
  async updateInventoryType (
    { dispatch, commit, rootState }: ActionContext<State, any>,
    payload: any) {
    await dispatch(
      'app/axios',
      {
        url: URL + 'update/' + payload.id,
        method: 'PUT',
        data: payload
      },
      { root: true }
    )

    const index = rootState.resources[
      RESOURCES.TRAVELS_INV
      // @ts-ignore
    ].findIndex((obj) => obj.id === payload.id)

    commit(
      'resources/UPDATE_RESOURCE',
      {
        index,
        item: { name: payload.name },
        rType: RESOURCES.TRAVELS_INV,
        force: false
      },
      { root: true }
    )
  },
  async loadInventoryTypes ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL + 'get/all',
        method: 'GET'
      },
      { root: true }
    )

    commit(
      'resources/SET_RESOURCES',
      {
        resources: data,
        rType: RESOURCES.TRAVELS_INV
      },
      { root: true }
    )
  }
}
export default actions

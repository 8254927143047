const noteSaleRoutes = {
  meta: {
    title: 'Notas de venta'
  },
  path: 'note-sale',
  component: () => import('@/components/Facturacion/modules/note_sale/NoteSale.vue'),
  children: [
    {
      meta: {
        title: 'Notas de venta'
      },
      path: '',
      name: 'note-sale',
      component: () => import('@/components/Facturacion/modules/note_sale/NoteSaleList.vue')
    },
    {
      path: 'nueva-note-sale',
      name: 'nueva-note-sale',
      meta: {
        title: 'Nueva nota de venta'
      },
      component: () => import('@/components/Facturacion/modules/note_sale/NoteSaleForm.vue')
    }
  ]
}

export default noteSaleRoutes

import Root from '../Root/Root'
export interface ITypeFuel {
    id: number,
    tf_name: string
}
export default class TypeFuel extends Root implements ITypeFuel {
  id!: number;
  tf_name!: string;

  constructor (typefuel: ITypeFuel) {
    super()
    Object.assign(this, typefuel)
  }

  getName () {
    return this.tf_name
  }
}

export default function getRouteData ({ unit_id, from, to }: { unit_id: number | string; from: number; to: number }) {
  return new Promise<any>((resolve, reject) => {
    // @ts-ignore
    const sess = wialon.core.Session.getInstance()
    const renderer = sess.getRenderer()

    const params = {
      layerName: 'route_unit_' + unit_id, // layer name
      itemId: unit_id, // ID of unit which messages will be requested
      timeFrom: from, // interval beginning
      timeTo: to, // interval end
      tripDetector: 0, // use trip detector: 0 - no, 1 - yes
      trackColor: '#fff', // track color in ARGB format (A - alpha channel or transparency level)
      trackWidth: 5, // track line width in pixels
      arrows: 0, // show course of movement arrows: 0 - no, 1 - yes
      points: 0, // show points at places where messages were received: 0 - no, 1 - yes
      pointColor: '#fff', // points color
      annotations: 0 // show annotations for points: 0 - no, 1 - yes
    }

    renderer.createMessagesLayer(params, function (code: number, layer: any) {
      // @ts-ignore
      if (code) { reject(wialon.core.Errors.getErrorText(code)) } // exit if error code
      resolve(layer._data.units.at(0))
    })
  })
}

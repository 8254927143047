// import { InventoryState } from '../../state'

import { FacturaState } from './state'

// import { ProductState } from './state'

export default {
  getNoteSales (state: FacturaState) {
    return state.facturas
  }
}

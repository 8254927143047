
export interface TechnicalState {
    technicals: any[]
}

const state = (): TechnicalState => ({
  technicals: []
})
export type State = ReturnType<typeof state>;

export default state

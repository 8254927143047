import mutations from './mutations'
import actions from './actions'
import state from './state'
import getters from './getters'
import { toll } from './modules/toll'
import { route_destination } from './modules/route_destination'

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
  modules: {
    toll,
    route_destination
  }
}

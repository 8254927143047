
export interface PagoState {
  pagos: any[]
}

const state = (): PagoState => ({
  pagos: []
})
export type State = ReturnType<typeof state>;

export default state

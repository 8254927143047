import sdkConfigs, { ISDKParams } from './sdkConfigs'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { ISendInstructParams } from './interfaces/SendInstructCommands';
import Api from './api';
import { COMMAND as CMD } from './interfaces/SendInstructCommands';
import objToURLSerachParams from './utils/objToURLSerachParams';
import logMessage from './utils/logMessage';
import Result from './utils/Result.class';
import { IDataCommandResult } from './interfaces/SendInstructCommands/IResponce'
import BaseEvents from './events';
import DVR from './dvr';
import IAPIResponse from './interfaces/IAPIResponse.interface';

/**
 * Class that provides a wrapper around the IoT Hub API.
 * 
 * It provides a way to send instructions to devices, as well as listen to events.
 * 
 * @example
 * const sdk = new JimiIothubSDK({
 *   iothubServerIp: '172.16.0.128',
 *   iothubCustomerServerInterfaceHost: '113.108.62.202:8888',
 *   useHttps: false,
 *   debug: true
 * })
 * 
 * @example
 * sdk.sendInstruct(JimiIothubSDK.COMMAND.CAMERA_SHOOTS_IMMEDIATELY('862811042001001', {
 *   channel: 1,
 *   photoCmd: 0,
 *   timeInterval: 0,
 *   saveFlag: 0,
 *   resolution: 0,
 *   quality: 0,
 *   light: 0,
 *   contrast: 0,
 *   saturability: 0,
 *   chroma: 0
 * }))
 * 
 * Or
 * 
 * sdk.sendInstruct(JimiIothubSDK.COMMAND.CAMERA_SHOOTS_IMMEDIATELY('862811042001001', {
 *   channel: 1,
 *   photoCmd: 0,
 *   timeInterval: 0,
 *   saveFlag: 0,
 *   resolution: 0,
 *   quality: 0,
 *   light: 0,
 *   contrast: 0,
 *   saturability: 0,
 *   chroma: 0
 * }, { requestId: '6', cmdType: 'normallns' }))
 * 
 */
export default class JimiIothubSDK {
  private initialConfig: ISDKParams;
  /**
  * 
  ** Clase que proporciona una interfaz para interactuar con la API de dispositivo de Jimi IoT Hub.
  * 
  * La clase utiliza el patron de proxy para llamar los metodos de la API correspondiente.
  * 
  * Luego, se puede llamar los m todos de la API utilizando el nombre del m todo y los
  * par metros necesarios.
  * 
  * Por ejemplo:
  * @example
  * // https://docs.jimicloud.com/api/query#31-querying-v2-for-jc450-series-devices
  * // GET /api/v2/alarm/getAlarm
  * const result = await sdk.api.get_api_v2_alarm_getAlarm({
  *   deviceImei: '860222070000574',
  *   startTime: '2024-09-11 00:00:00',
  *   endTime: '2024-09-12 00:00:00'
  * })
  */
  public api: Api
  public static COMMAND: typeof CMD = CMD
  public events: BaseEvents
  /**
  * Clase que proporciona una interfaz para interactuar con la API de DVR de Jimi IoT Hub.
  * 
  * La clase utiliza el patron de proxy para llamar los metodos de la API correspondiente.
  * 
  * Luego, se puede llamar los metodos de la API utilizando el nombre del m todo y los
  * par metros necesarios.
  * 
  * @example
  * const result = await sdk.drv.get_dvr_upload_jc450_assets(["860222070000574"],{}, { headers: { 'Authorization': 'Basic dXNlcjpwYXNz' } })
  * console.log(result.data)
  */
  public dvr: DVR

  constructor(config: ISDKParams) {
    this.initialConfig = sdkConfigs(config);
    this.api = new Api(this);
    this.events = new BaseEvents(this);
    this.dvr = new DVR(this);
  }

  public set config(config: ISDKParams) {
    this.initialConfig = config
  }

  public get config(): Required<ISDKParams> {
    // @ts-ignore
    return this.initialConfig;
  }

  /** The host of the API service for device instructions in Send Device Instruction Command Interface , for example http://172.16.0.128:10088 */
  public get API_DEVICE_INSTRUCTION_HOST(): string {
    return `${this.config.useHttps ? 'https' : 'http'}://${this.config.iothubServerIp}${this.config.usePortsAsUrl ? '/' : ':'}${this.config.apiDeviceInstruccionPort}`
  }

  /** The host of the API service for device DVR in Device Management Interface , for example http://172.16.0.128:9080 */
  public get API_DEVICE_DVR_HOST(): string {
    return `${this.config.useHttps ? 'https' : 'http'}://${this.config.iothubServerIp}${this.config.usePortsAsUrl ? '/' : ':'}${this.config.apiDeviceDVRPort}`
  }

  public async axios<D = any>(reqConfig: AxiosRequestConfig = {}) {
    const instance = axios.create(reqConfig)
    return await instance(reqConfig) as AxiosResponse<D>
  }

  /** Whether the SDK is in debug mode. */
  public get IS_DEV() {
    return this.config.debug
  }

  /**
   * Send instructions to devices.
   * 
   * @param imei The IMEI of the device to send the instruction to.
   * @param cmdParams The instruction data.
   * @param proNo The protocol number of the instruction.
   * @param additionalCommandParameters Additional parameters for the instruction.
   * @returns The response of the API.
   * 
   * @example
   * const sdk = new JimiIothubSDK({
   *   iothubServerIp: '172.16.0.128',
   *   iothubCustomerServerInterfaceHost: '113.108.62.202:8888',
   *   useHttps: false,
   *   debug: true
   * })
   * 
   * const instruction = JimiIothubSDK.COMMAND.CAMERA_SHOOTS_IMMEDIATELY('862811042001001', {
   *   channel: 1,
   *   photoCmd: 0,
   *   timeInterval: 0,
   *   saveFlag: 0,
   *   resolution: 0,
   *   quality: 0,
   *   light: 0,
   *   contrast: 0,
   *   saturability: 0,
   *   chroma: 0
   * })
   * 
   * const data = await sdk.sendInstruct(instruction)
   * if (data.isOk()) {
   *   console.log('Instruction sent successfully', data.value)
   * } else {
   *   console.error('Error sending instruction', data.error)
   * }
   */
  public async sendInstruct({ imei, cmdParams, proNo, additionalCommandParameters }: ISendInstructParams): Promise<Result<IDataCommandResult, AxiosResponse<IAPIResponse<IDataCommandResult>>>> {
    const payload = {
      ...additionalCommandParameters,
      imei,
      deviceImei: imei,
      cmdContent: JSON.stringify(cmdParams),
      proNo
    }
    const params = objToURLSerachParams(payload)

    const urlSendInstruct = `${this.API_DEVICE_INSTRUCTION_HOST}/api/device/sendInstruct`

    if (this.IS_DEV) logMessage("Send instruction payload: ", params)

    const config = {
      method: 'post',
      url: urlSendInstruct,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: params
    };

    if (this.IS_DEV) logMessage("Send instruction config: ", config)

    const data: AxiosResponse<IAPIResponse<IDataCommandResult>, typeof config> = await axios(config)

    if (data?.data?.code === 0) {
      return Result.Ok(data.data.data)
    } else {
      return Result.Err(data)
    }
  }
}


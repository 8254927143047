/* eslint-disable @typescript-eslint/ban-types */
import { ActionContext } from 'vuex'
import axios, { AxiosRequestConfig } from 'axios'
import { ToastID, ToastOptions } from 'vue-toastification/dist/types/src/types'
import { State } from './state'
import { IMessageEvent, w3cwebsocket as WebSocketClient } from 'websocket'
import { serializeError } from 'serialize-error'
import '@mdi/font/css/materialdesignicons.min.css'
import { SocketEvents } from '@/interfaces/SocketEvents.interface'

// import { onMessage } from 'firebase/messaging'
// import { requestPermission, fetchToken, messaging } from '@/firebase'
import { notifyToast } from '@/utils/notifications'
import Vue from 'vue'
import { ICeleryTaskResult } from '@/interfaces/Celery.interface'

export default {
  init ({ commit }: ActionContext<State, any>) {
    window.onerror = function (msg, url, lineNo, columnNo, error) {
      commit('ADD_LOG', {
        title: 'APP_ERROR',
        color: 'error',
        message: msg,
        payload: JSON.stringify({ url, lineNo, columnNo, error })
      })
      return false
    }
  },
  initSocket (
    { commit, rootGetters, rootState, dispatch }: ActionContext<State, any>,
    callback: (message: IMessageEvent) => void
  ) {
    const isHttps = window.location.protocol === 'https:'
    const protocol = isHttps ? 'wss:' : 'ws:'
    const urlConection = rootGetters['auth/isMonitorist']
      ? `${protocol}//${
          process.env.VUE_APP_HOST || window.location.host
        }/socket/travel/monitorista/`
      : `${protocol}//${
          process.env.VUE_APP_HOST || window.location.host
        }/socket/travel/${rootState.auth.user_date.id_user}/`
    commit('CLEAR_SOCKET_TRAVEL')
    if (urlConection) {
      const client = new WebSocketClient(urlConection)

      client.onopen = function () {
        console.log('WebSocket Client Connected')
      }

      client.onerror = function (error) {
        commit('ADD_LOG', {
          title: 'SOCKET_ERROR',
          color: 'error',
          message: error,
          payload: serializeError(error)
        })
        console.log(error)
      }

      client.onclose = async function (event) {
        commit('ADD_LOG', {
          title: 'SOCKET_CLOSED',
          color: 'info',
          message: event
        })
        await dispatch('retryConection')
      }

      client.onmessage = callback

      commit('SET_SOCKET_TRAVEL', client)
    }
  },
  initSocketByUser (
    { commit, rootGetters, rootState, dispatch }: ActionContext<State, any>,
    callback: (message: IMessageEvent) => void
  ) {
    const isHttps = window.location.protocol === 'https:'
    const protocol = isHttps ? 'wss:' : 'ws:'
    const urlConection = rootGetters['auth/isMonitorist']
      ? `${protocol}//${
          process.env.VUE_APP_HOST || window.location.host
        }/socket/travel/monitorista/`
      : `${protocol}//${
          process.env.VUE_APP_HOST || window.location.host
        }/socket/travel/${rootState.auth.user_date.id}/`
    commit('CLEAR_SOCKET_SYSTEM')

    if (urlConection) {
      const client = new WebSocketClient(urlConection)

      client.onopen = function () {
        console.log('WebSocket Client Connected')
      }

      client.onerror = function (error) {
        commit('ADD_LOG', {
          title: 'SOCKET_ERROR',
          color: 'error',
          message: error,
          payload: serializeError(error)
        })
        console.log(error)
      }

      client.onclose = async function (event) {
        commit('ADD_LOG', {
          title: 'SOCKET_CLOSED',
          color: 'info',
          message: event
        })
        await dispatch('retryConectionSystem')
      }

      client.onmessage = callback

      commit('SET_SOCKET_SYSTEM', client)
    }
  },
  sendMessageThroughSocket (
    { commit, rootGetters, rootState, dispatch }: ActionContext<State, any>,
    message: any
  ) {
    if (rootState.app.socket_system) {
      try {
        console.log('enviando data')
        // rootState.app.socket_system.send(JSON.stringify(message))
        // commit('ADD_LOG', { title: 'SOCKET_MESSAGE_SENT', color: 'success', message: 'Message sent successfully' })
      } catch (error) {
        // commit('ADD_LOG', { title: 'SOCKET_MESSAGE_ERROR', color: 'error', message: error, payload: serializeError(error) })
      }
    } else {
      // commit('ADD_LOG', { title: 'SOCKET_NOT_CONNECTED', color: 'error', message: 'WebSocket is not connected' })
    }
  },
  async socketEvent (
    { rootState, dispatch, commit, rootGetters }: ActionContext<State, any>,
    event: SocketEvents
  ) {
    console.log(event)
    switch (event.event) {
      case 'ticket':
        rootState.app.notifications.notifications.unshift(event.message)
        notifyToast({
          msj: event.message.notification.message,
          type: 'info',
          title: event.message.notification.title,
          icon: 'mdi-message-text'
        })
        await dispatch('playSound', { type: 'notify1' })
        break

      case 'alert':
        notifyToast({
          msj: event.message.message,
          type: 'warning',
          title: event.message.title,
          icon: 'mdi-update',
          duration: 20000
        })
        await dispatch('playSound', { type: 'error' })
        break
      case 'unit_assigned': {
        notifyToast({
          msj: `Conductor ${event.message.driver_data.username} asignado a la unidad ${event.message.unit_name}`,
          type: 'success',
          title: 'Conductor Actualizado',
          icon: 'mdi-card-account-details-outline',
          duration: 20000
        })
        // @ts-ignore
        const unitConsoleData: UnitConsoleData<unknown, unknown> = rootState.travel.unitsRegisters.find(obj => obj.id === event.message.unit)
        // @ts-ignore
        const previousUnitWithDriver = rootState.travel.unitsRegisters.find(obj => obj.driver_profile?.id === event.message.driver_data.id)
        if (unitConsoleData) {
          if (unitConsoleData.driver_profile) {
            notifyToast({
              msj: `Conductor ${unitConsoleData.driver_profile.username} desvinculado de la unidad ${unitConsoleData.unit_name}`,
              type: 'warning',
              title: 'Conductor Reemplazado',
              icon: 'mdi-car-switch',
              duration: 20000

            })
          }
          if (previousUnitWithDriver) {
            notifyToast({
              msj: `Conductor ${previousUnitWithDriver.driver_profile.username} desvinculado de la unidad ${previousUnitWithDriver.unit_name}`,
              type: 'info',
              title: 'Conductor Desvinculado',
              icon: 'mdi-card-account-details-outline',
              duration: 20000
            })
            previousUnitWithDriver.driver_profile = null
          }
          unitConsoleData.setDriver(event.message.driver_data)
        }
        await dispatch('playSound', { type: 'zumbido' })
        break
      }
      default:
    }
  },
  async initNotifyFirebase ({
    commit,
    dispatch,
    rootState
  }: ActionContext<State, any>) {
    try {
      // const token = await fetchToken()
      // console.log('------------')
      // console.log('------------')
      // console.log(token)
      // console.log('------------')
      // console.log('------------')
      // await dispatch('auth/setToken', token, { root: true })
      // console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa')
      // onMessage(messaging, (payload) => {
      //   console.log('Message received. ', payload)
      // })
    } catch (error) {
      console.error(error)
    }
  },
  async retryConection ({ dispatch }: ActionContext<State, any>) {
    setTimeout(async () => {
      await dispatch(
        'app/initSocket',
        (message: IMessageEvent) => {
          const data = JSON.parse(message.data.toString())
          dispatch('travel/events', data, { root: true })
        },
        { root: true }
      )
    }, 5000)
  },
  async retryConectionSystem ({ dispatch }: ActionContext<State, any>) {
    setTimeout(async () => {
      await dispatch(
        'app/initSocketByUser',
        (message: IMessageEvent) => {
          const data = JSON.parse(message.data.toString())
          dispatch('app/socketEvent', data, { root: true })
        },
        { root: true }
      )
    }, 5000)
  },
  async axios ({ dispatch, rootState, commit }: ActionContext<State, any>, options: AxiosRequestConfig): Promise<any> {
    try {
      // @ts-ignore
      const instance = axios.create({
        baseURL: process.env.VUE_APP_BASE_URL || '/',
        ...(rootState.auth.auth_token && {
          headers: {
            Authorization: `Token ${rootState.auth.auth_token}`
          }
        })
      })

      // @ts-ignore
      const data = await instance(options)

      return data
    } catch (error) {
      console.error(error)
      // @ts-ignore
      if (error?.response?.status === 403) {
        await dispatch('notification', {
          content: 'Sesion agotada, por favor introduzca sus credenciales nuevamente'
        })
        await dispatch('auth/logout', {}, { root: true })
      }

      commit('ADD_LOG', { title: 'REQUEST_ERROR', color: 'error', message: error })

      throw error
    }
  },
  async makeRequestWithNotifications (
    { dispatch }: ActionContext<State, any>,
    func: Function
  ) {
    const toastID: ToastID = await dispatch('notification', {
      content: 'Realizando Solicitud',
      timeout: false,
      info: 'info'
    })
    try {
      await func()
      await dispatch('notification', {
        content: 'Exito',
        type: 'success',
        timeout: 10000
      })
    } catch (error) {
      await dispatch('notifyToast', { msj: 'Ha ocurrido un error al realizar la solicitud', type: 'error' })
      throw error
    } finally {
      dispatch('notificationDismiss', toastID)
    }
  },
  async makeRequestWithNotificationsErrorMessage (
    { dispatch }: ActionContext<State, any>,
    func: Function
  ) {
    // const toastID: ToastID = await dispatch('notification', { content: 'Realizando Solicitud', timeout: false, info: 'info' })
    try {
      await func()
      await dispatch('notifyToast', { msj: 'Exito!', type: 'success' })
    } catch (error) {
      await dispatch('notifyToast', {
        // @ts-ignore
        msj: error.response.data.data.message,
        type: 'error'
      })
      // await dispatch('notification', { content: error.response.data.data.message, type: 'error', timeout: false })
      throw error
    } finally {
      dispatch('notificationDismiss')
    }
  },
  notification (
    _: ActionContext<State, any>,
    options: ToastOptions & { content: string }
  ): ToastID {
    // @ts-ignore
    const toast = this._vm.$toast
    // @ts-ignore
    return toast(options.content, options)
  },
  cleanNotifications () {
    // @ts-ignore
    this._vm.$toast.clear()
  },
  notificationToasted (
    _: ActionContext<State, any>,
    options: ToastOptions & { content: string }
  ) {
    // @ts-ignore
    const toast = this._vm.$toasted.show(options.content, {
      action: {
        icon: 'close',
        // @ts-ignore
        onClick: (e, toastObject) => {
          toastObject.goAway(0)
        },
        class: 'toasted-action-custom'
      },
      ...options,
      // @ts-ignore
      className: 'custom-toast ' + options.className
    })
    // @ts-ignore
    if (options.clear) {
      setTimeout(() => {
        toast.goAway(0)
      }, 4000)
    }
  },
  notifyToast (_: ActionContext<State, any>, options: { msj: string, type: string }) {
    Vue.notify({
      group: 'app',
      type: options.type,
      title: 'Notificación de operación',
      text: options.msj,
      data: {
        icon: 'mdi-bell-cog'
      }
    })
  },
  notificationDismiss (_: ActionContext<State, any>, id: ToastID): void {
    // @ts-ignore
    const toast = this._vm.$toast
    toast.dismiss(id)
  },
  async playSound (
    _: ActionContext<State, string>,
    audioPayload: {
      url?: string;
      type:
        | 'done'
        | 'alert'
        | 'notification'
        | 'camera_alert'
        | 'notify1'
        | 'notify2'
        | 'notify3'
        | 'zumbido'
        | 'message'
        | 'message-low'
        | 'error';
    }
  ): Promise<HTMLAudioElement | void> {
    try {
      if (audioPayload) {
        if (audioPayload.url) {
          const audio = new Audio(audioPayload.url)
          await audio.play()
          return audio
        } else if (audioPayload.type) {
          switch (audioPayload.type) {
            case 'alert':
              await new Audio('/audio/alert.mp3').play()
              break
            case 'done':
              await new Audio('/audio/done.mp3').play()
              break
            case 'notification':
              await new Audio('/audio/notification.mp3').play()
              break
            case 'camera_alert':
              await new Audio('/audio/notification.mp3').play()
              break
            case 'notify1':
              await new Audio('/audio/notify1.wav').play()
              break
            case 'notify2':
              await new Audio('/audio/notify2.wav').play()
              break
            case 'notify3':
              await new Audio('/audio/notify3.wav').play()
              break
            case 'zumbido':
              await new Audio('/audio/zumbido.wav').play()
              break
            case 'message':
              await new Audio('/audio/message.mp3').play()
              break
            case 'message-low':
              await new Audio('/audio/message-low.mp3').play()
              break
            case 'error':
              await new Audio('/audio/error.mp3').play()
              break
            default:
              await new Audio('/audio/camera_alert.wav').play()
              break
          }
        } else {
          await new Audio('/audio/notify.wav').play()
        }
      } else {
        await new Audio('/audio/notify.wav').play()
      }
    } catch (error) {
      await new Audio('/audio/notify.wav').play()
      console.error(error)
    }
  },
  async copyMessage (
    { dispatch, commit }: ActionContext<State, string>,
    msPayload: { message: string }
  ): Promise<void> {
    const toastID = await dispatch('notification', {
      content: 'Compiando el contenido',
      type: 'warning',
      timeout: -1
    })
    try {
      await navigator.clipboard.writeText(msPayload.message)
      dispatch('notificationDismiss', toastID)
      await dispatch('notification', {
        content: 'Copiado!!',
        type: 'info',
        timeout: 10000
      })
    } catch (error) {
      console.error(error)
      dispatch('notificationDismiss', toastID)
      commit('ADD_LOG', {
        title: 'NAVIGATOR_CLIPBOARD_ERROR',
        color: 'error',
        message: error
      })
      await dispatch('notification', {
        content: 'Error al copiar el contenido',
        type: 'error',
        timeout: 10000
      })
    }
  },
  addLogWithError (
    context: ActionContext<State, string>,
    {
      title,
      color,
      error,
      message
    }: { error: any; title: string; color: string; message: string }
  ) {
    context.commit('ADD_LOG', {
      title,
      color,
      message,
      payload: serializeError(error)
    })
  },
  async getCeleryTaskResult ({ dispatch }: ActionContext<State, string>, id: string): Promise<ICeleryTaskResult> {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: `apis/tasks/result/${id}`,
          method: 'GET'
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('app/addLogWithError', { title: 'GET_CELERY_TASK_RESULT', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async getCeleryTaskStatus ({ dispatch }: ActionContext<State, string>, id: string): Promise<ICeleryTaskResult['status']> {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: `apis/tasks/status/${id}`,
          method: 'GET'
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('app/addLogWithError', { title: 'GET_CELERY_TASK_STATUS', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  checkCeleryTaskStatus ({ dispatch }: ActionContext<State, string>, { id, tries = 18, intervalTime = 10000 }: { id: string; tries: number; intervalTime: number }): Promise<ICeleryTaskResult['status']> {
    return new Promise<ICeleryTaskResult['status']>((resolve, reject) => {
      let triesCount = 0
      const interval = setInterval(async () => {
        const status: ICeleryTaskResult['status'] = await dispatch('getCeleryTaskStatus', id)
        switch (status) {
          case 'PENDING':
          case 'STARTED':
            break
          case 'RETRY':
          case 'FAILURE':
            reject(status)
            break
          case 'SUCCESS':
            clearInterval(interval)
            resolve(status)
            break
        }
      }, intervalTime)
      triesCount++
      if (triesCount === tries) {
        clearInterval(interval)
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('TIMEOUT')
      }
    })
  }
}

import JimiIothubSDK from ".."
import DataPushEvents from "./DataPushEvents"
import { ADDITIONAL_ALARM_TYPES } from "../alarms/ADDITIONAL_ALARM_TYPES"
import { IPushEvent } from "./PushEvent.interface"
import IQueryTheListOfAudioAndVideoResources from "@/interfaces/SendInstructCommands/IQueryTheListOfAudioAndVideoResources.interface"
import { IAdditionalCommandParameters } from "@/interfaces/SendInstructCommands"
import IPushResourceList from "@/interfaces/Events/PushResourceList.interface"

export default class BaseEvents {
    public sdk: JimiIothubSDK
    public dataPushEvents: DataPushEvents

    constructor(sdk: JimiIothubSDK) {
        this.sdk = sdk
        this.dataPushEvents = new DataPushEvents(this)
    }

    public getAdditionalAlertType(alertType: string): string | null {
        // @ts-ignore
        return ADDITIONAL_ALARM_TYPES[alertType] ?? null
    }

    public getWSInstance(imeiConnections: string[]) {
        const connection = this.sdk.config.useHttps ? 'wss' : 'ws'
        const connection_url = `${connection}://${this.sdk.config.iothubCustomerServerInterfaceHost}/ws/dvr_events?imeis_rooms=${imeiConnections.join(',')}`
        if (this.sdk.IS_DEV) console.log('connection_url', connection_url)
        return new WebSocket(connection_url);
    }


    /**
     * Consulta la lista de recursos de audio y video para un dispositivo especifico.
     * 
     * @param imei El IMEI del dispositivo.
     * @param params Los parametros de la consulta.
     * @param additionalCommandParameters Parametros adicionales del comando.
     * @returns Un objeto IPushEvent<IPushResourceList> que contiene la lista de recursos.
     * 
     * @example
     * const result = await sdk.events.getQueryListOfAudioAndVideoResources('862811042001001', {
     *   channel: 1,
     *   beginTime: 1643723400,
     *   endTime: 1643723800,
     *   alarmFlag: 0,
     *   resourceType: 0,
     *   codeType: 0,
     *   storageType: 0,
     *   instructionID: 0
     * })
     * console.log('Resources list:', result)
     */
    public async getQueryListOfAudioAndVideoResources(
        imei: string, 
        params: IQueryTheListOfAudioAndVideoResources, 
        additionalCommandParameters: IAdditionalCommandParameters = {},
        { timeout = 60000 }: { timeout?: number } = {}
    ) {
        const result = await this.sdk.sendInstruct(JimiIothubSDK.COMMAND.QUERY_THE_LIST_OF_AUDIO_AND_VIDEO_RESOURCES(imei, params, additionalCommandParameters))
        if (!result.isOk()) throw new Error(result.getError() ? JSON.stringify(result.getError()) : 'Unknown error')

        return new Promise<IPushEvent<IPushResourceList>>((resolve, reject) => {
            const ws = this.getWSInstance([imei])

            // Evento que se ejecuta cuando se recibe un mensaje
            ws.onmessage = (event: MessageEvent<string>) => {
                if (!event.data) return
                const data: IPushEvent = JSON.parse(event.data)

                if (this.sdk.IS_DEV) console.log(`Message received ${data.type}: `, data)
                if (data.type === 'pushresourcelist' && data.imei === imei) {
                    resolve(data)
                    ws.close()
                }
            };

            ws.onclose = () => {
                reject('Connection closed')
            };

            ws.onerror = (error) => {
                reject(error)
            };

            setTimeout(() => {
                reject('Timeout')
                ws.close()
            }, timeout)
        })
    }
}
export enum PURCHASE_ORDER_STATUS {
    PENDIENTE = 1,
    PROCESS = 2,
    CONFIRMED = 3,
    RECHAZADA = 4
}

export const PURCHASE_ORDER_STATUS_ARRAY = [
  {
    label: 'Pendiente',
    color: '#EF6C00',
    id: PURCHASE_ORDER_STATUS.PENDIENTE
  },
  {
    label: 'En proceso',
    color: 'info',
    id: PURCHASE_ORDER_STATUS.PROCESS
  },
  {
    label: 'Confirmado',
    color: 'green',
    id: PURCHASE_ORDER_STATUS.CONFIRMED
  },
  {
    label: 'Rechazada',
    color: 'error',
    id: PURCHASE_ORDER_STATUS.RECHAZADA
  }
]

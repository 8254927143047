import { State } from '../../state'
import { ActionContext } from 'vuex'
import { IEntryType } from '../../../../classes/Inventory/interfaces/IEntryType'
const actions = {
  async loadEntryType ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/entry_type/get/all',
        method: 'GET'
      },
      { root: true }
    )
    commit('SET_ENTRY_TYPE', data)
    return data
  },
  async createEntryType ({ commit, dispatch }: ActionContext<State, any>, entity: IEntryType) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/entry_type/create',
        method: 'POST',
        data: entity
      },
      { root: true }
    )
    commit('PUSH_ENTRY_TYPE', data)
    return data
  },
  async updateEntryType ({ commit, dispatch }: ActionContext<State, any>, entity: IEntryType) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/entry_type/update/${entity.id}`,
        method: 'PUT',
        data: entity
      },
      { root: true }
    )
    commit('UPDATE_ENTRY_TYPE', data)
    return data
  },
  async deleteEntryType ({ commit, dispatch }: ActionContext<State, any>, entity: IEntryType) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/entry_type/delete/${entity.id}`,
        method: 'DELETE'
      },
      { root: true }
    )
    commit('DELETE_ENTRY_TYPE', entity)
    return data
  }
}
export default actions

/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */

import { ActionContext } from 'vuex/types/index'
import {
  RESOURCES,
  PLATAFORM,
  PLATAFORM_RESOURCES
} from '../../resources/RESOURCES'
import { State } from '../state'
const actions = {
  async createEventType (
    { commit, dispatch }: ActionContext<State, any>,
    payload: { name: string; description: string }
  ) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/create_travel_type/',
        method: 'POST',
        data: payload
      },
      { root: true }
    )

    commit(
      'resources/PUSH_RESOURCE',
      {
        item: data,
        rType: RESOURCES.TRAVEL_TYPES
      },
      { root: true }
    )
  },
  async createTravelEvent (
    { dispatch }: ActionContext<State, any>,
    payload: any
  ) {
    await dispatch(
      'app/axios',
      {
        url: '/apis/create_event_travel/',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
  }
}
export default actions

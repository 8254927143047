// plugins/GlobalFunctions.ts
import { VueConstructor } from 'vue'
import store from '@/store' // Importa tu store Vuex aquí
import { DateTime } from 'luxon'
import { getDateLocalDDMMYYYY, getDateLocalDDMMYYYYhhmmss, getLocalISOTime } from '@/utils/datetimeToDate'

export default {
  install (Vue: VueConstructor) {
    Vue.prototype.$formatNumberWithCommas = (number: number): string => {
      // parseFloat(number.toFixed(2))
      return parseFloat(number.toFixed(2)).toLocaleString()
    }
    Vue.prototype.$getTimezoneOffsetHours = (): number => {
      const now = DateTime.local()
      return now.offset / 60
    }
    Vue.prototype.$getDateLocalDDMMYYYY = (label:string): string => {
      return getDateLocalDDMMYYYY(label)
    }
    Vue.prototype.$getDateLocalDDMMYYYYhhmmss = (label:string): string => {
      return getDateLocalDDMMYYYYhhmmss(label)
    }
    Vue.prototype.$getLocalISOTime = (): string => {
      return getLocalISOTime()
    }
    Vue.prototype.$toCapitalizeLower = (label:string): string => {
      const label_ = label.toLowerCase()
      return label_.charAt(0).toUpperCase() + label_.slice(1)
    }
    Vue.prototype.$hasPermission = (permissionId: number): boolean => {
      // Obtiene los permisos del usuario del store Vuex
      // @ts-ignore
      const permissions = store.state.auth.user_date.service.flatMap((service: { settings: { permissions: any } }) => service.settings.permissions)
      console.log(permissions)
      // Verifica si el permiso con el ID dado está presente en los permisos del usuario
      return permissions.includes(permissionId)
    }
    Vue.prototype.$copyMessage = async (msPayload: string): Promise<void> => {
      // Obtiene los permisos del usuario del store Vuex
      await navigator.clipboard.writeText(msPayload)
      await store.dispatch('app/notifyToast', { msj: 'Copiado!', type: 'success' })
      // console.log(permissions)
      // Verifica si el permiso con el ID dado está presente en los permisos del usuario
    }
  }
}


export interface DriverState {
  units: any[]
}

const state = (): DriverState => ({
  units: []
})
export type State = ReturnType<typeof state>;

export default state

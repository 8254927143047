/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export default (wialon_id: number, aditionalFlags = 0, mode = 0): Promise<any> => {
  return new Promise((resolve, reject) => {
    // @ts-ignore
    const sess = wialon.core.Session.getInstance()
    // @ts-ignore
    const flags = wialon.item.Item.dataFlag.base | wialon.item.Resource.dataFlag.base | wialon.item.Item.dataFlag.messages | wialon.item.Resource.dataFlag.notifications | (aditionalFlags)
    sess.updateDataFlags(
      [{ type: 'type', data: 'avl_resource', flags: flags, mode }],
      (code: string) => {
        if (code) {
          // @ts-ignore
          reject(wialon.core.Errors.getErrorText(code))
        } else {
          // @ts-ignore
          const item = sess.getItem(wialon_id)
          resolve(item)
        }
      })
  })
}

import Notification from '@/classes/Notification/Notification'
import { ISheduledReport } from '@/classes/Unit/SheduledReport'

export interface CamerasState {
    sheduled_reports: Array<ISheduledReport>
    notifications_active: object,
    hvDisableNotifications: boolean,
    hvDisableSoundNotifications: boolean,
    notifications: [Notification] | [],
    streamax: {
      disabledNotification: boolean,
      disabledSoundNotification: boolean,
      notifications_active: object,
      allowAll: boolean
    }
}

const state = ():CamerasState => ({
  sheduled_reports: [],
  notifications_active: {},
  hvDisableNotifications: false,
  hvDisableSoundNotifications: false,
  streamax: {
    disabledNotification: false,
    disabledSoundNotification: false,
    notifications_active: {},
    allowAll: false
  },
  notifications: []
})

export type State = ReturnType<typeof state>;

export default state

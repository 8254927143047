import { IAssetMaintenance } from '@/classes/Inventory/interfaces/IAsset_Maintenance'

export interface AssetMaintenanceState {
  assetMaintenances: Array<IAssetMaintenance>
  assetMaintenanceParts: any
}

const state = (): AssetMaintenanceState => ({
  assetMaintenances: [],
  assetMaintenanceParts: null
})

export type State = ReturnType<typeof state>

export default state

export interface ChecklistSectionState {
  checklistModules: any[]
}

const state = (): ChecklistSectionState => ({
  checklistModules: []
})
export type State = ReturnType<typeof state>

export default state

import { State } from '../../state'
import { ActionContext } from 'vuex'
import { IUnitType } from '../../../../classes/Unit/IUnitType'

const actions = {
  async loadUnitType ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/unit/unit_type/get/all',
        method: 'GET'
      },
      { root: true }
    )
    commit('SET_UNIT_TYPE', data)
    return data
  },
  async createUnitType ({ commit, dispatch }: ActionContext<State, any>, entity: IUnitType) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/unit/unit_type/create',
        method: 'POST',
        data: entity
      },
      { root: true }
    )
    commit('PUSH_UNIT_TYPE', data)
    return data
  },
  async updateUnitType ({ commit, dispatch }: ActionContext<State, any>, entity: IUnitType) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/unit/unit_type/update/${entity.id}`,
        method: 'PUT',
        data: entity
      },
      { root: true }
    )
    commit('UPDATE_UNIT_TYPE', data)
    return data
  },
  async deleteUnitType ({ commit, dispatch }: ActionContext<State, any>, entity: IUnitType) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/unit/unit_type/delete/${entity.id}`,
        method: 'DELETE'
      },
      { root: true }
    )
    commit('DELETE_UNIT_TYPE', entity)
    return data
  }
}
export default actions

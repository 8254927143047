// import StreamaxJSSdk from '@/submodules/streamax'
import StreamaxJSSdk from '@/submodules/streamax/dist'
import RIStreamax from '../packages/ReportItem/RIStreamax'

export interface IMapon {
  view: number;
  sdk?: StreamaxJSSdk | undefined
  units: RIStreamax []
}
const state = ():IMapon => ({
  view: 0,
  sdk: undefined,
  units: []
})

export type State = ReturnType<typeof state>;

export default state

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
export default (sess: any, data = 'avl_unit'): Promise<void> => {
  return new Promise((resolve, reject) => {
    // @ts-ignore
    const flagsUnit = 1 | 4 | 32 | 1024 | 4096 | wialon.item.Unit.dataFlag.commands | wialon.item.Unit.dataFlag.commandAliases
    // const flagsUnit = wialon.item.Unit.dataFlag.base | wialon.item.Item.dataFlag.messages | wialon.item.User.dataFlag.notifications | wialon.item.Unit.dataFlag.sensors | wialon.item.Unit.dataFlag.maintenance | wialon.item.Resource.dataFlag.notifications | 4 | 256
    sess.updateDataFlags( // load items to current session
      [{ type: 'type', data: data, flags: flagsUnit, mode: 1 }],
      (code: string) => {
        if (code) {
          // @ts-ignore
          reject(wialon.core.Errors.getErrorText(code))
        } else {
          resolve()
        }
      })
  })
}

// import { InventoryState } from '../../state'

import { NoteSaleState } from './state'

// import { ProductState } from './state'

export default {
  getNoteSales (state: NoteSaleState) {
    return state.note_sales
  }
}

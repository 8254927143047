import { CRMState } from './state'

export default {
  getSales (state: CRMState) {
    return state.sales
  },
  getConfig (state: CRMState) {
    return state.config
  }
}

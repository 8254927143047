import { Module } from 'vuex'
import { sales } from './modules/sales'
import { companies } from './modules/companies'
import { tags } from './modules/tags'

export const CRM: Module<any, any> = {
  namespaced: true,
  modules: {
    sales,
    companies,
    tags
  }
}

// import { TypeState } from './state'
import getWheelType from './modules/wheel_type/getters'
import getWheelSize from './modules/wheel_size/getters'
import actionsWheelMaker from './modules/wheel_maker/getters'
import actionsWheelModel from './modules/wheel_model/getters'
import getUnitType from './modules/unit_type/getters'
export default {
  ...getWheelType,
  ...getWheelSize,
  ...actionsWheelMaker,
  ...actionsWheelModel,
  ...getUnitType
}

export enum SERVICES {
  VIAJE = 1,
  MANTENIMIENTO = 2,
  LLANTA = 3,
  BATERIA = 4,
  CAMARAS = 5,
  MAPAS = 6,
  COMBUSTIBLE = 7,
  CHECKLIST = 8,
  INVENTARIO = 9
}

export enum SERVICES_ARR {
  VIAJE = 0,
  MANTENIMIENTO = 1,
  LLANTA = 2,
  BATERIA = 3,
  CAMARAS = 4,
  MAPAS = 5,
  COMBUSTIBLE = 6,
  CHECKLIST = 7,
  INVENTARIO = 8
}

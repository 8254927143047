
export interface RequisitionState {
    id: number
}

const state = (): RequisitionState => ({
  id: 0
})
export type State = ReturnType<typeof state>;

export default state

// import { GeofencesState, ICGeofence } from './state'
import { InventoryState } from '../../state'
import { IStore } from '../../../../classes/Inventory/interfaces/IStore'
import { IMeasurement } from '@/classes/Inventory/interfaces/IMeasurement'

export default {
  SET_MEASUREMENTS (state: InventoryState, measurements: IMeasurement[]): void {
    state.measurements = measurements
  },
  PUSH_MEASUREMENT (state: InventoryState, measurement: IMeasurement): void {
    state.measurements.push(measurement)
  },
  UPDATE_MEASUREMENT (state: InventoryState, measurement: IMeasurement): void {
    const index = state.measurements.findIndex(p => p.id === measurement.id)
    if (index !== -1) {
      state.measurements.splice(index, 1, measurement)
    }
  },
  DELETE_MEASUREMENT (state: InventoryState, measurement: IMeasurement): void {
    const index = state.measurements.findIndex(p => p.id === measurement.id)
    if (index !== -1) {
      state.measurements.splice(index, 1)
    }
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Socket } from 'socket.io-client'
import { State } from './state'

export default {
  UPDATE_LOADER (state: State, payload: {
        loader: boolean;
        message: string;
    }) {
    state.loader = payload
  },
  UPDATE_OVERLAY (state: State, status:boolean) {
    state.overlay = status
  },
  SET_SOCKET_TRAVEL (state: State, socket: Socket) {
    // @ts-ignore
    state.socket_travel = socket
  },
  SET_SOCKET_SYSTEM (state: State, socket: Socket) {
    // @ts-ignore
    state.socket_system = socket
  },
  CLEAR_SOCKET_TRAVEL (state:State) {
    if (state.socket_travel) {
      // @ts-ignore
      state.socket_travel.close()
    }
    state.socket_travel = null
  },
  CLEAR_SOCKET_SYSTEM (state:State) {
    if (state.socket_system) {
      // @ts-ignore
      state.socket_system.close()
    }
    state.socket_system = null
  },
  ADD_LOG (state: State, log: { title: string; color: string; message: string; payload: any }) {
    if (state.logs.length > 50) {
      state.logs.pop()
    }
    // @ts-ignore
    state.logs.unshift({ ...log, date: new Date().toUTCString() })
  }
}

import ChecklistModule from '@/classes/Checklist/ChecklistModules'
import { ChecklistSectionState } from './state'

export default {
  // Mutadores para Checklists
  SET_ALL (state: ChecklistSectionState, modules: ChecklistModule[]) {
    state.checklistModules = modules
  },
  PUSH (state: ChecklistSectionState, module: ChecklistModule) {
    state.checklistModules.push(module)
  },
  UPDATE (state: ChecklistSectionState, item: any) {
    const module = state.checklistModules.find(module => module.id === item.id)
    // @ts-ignore
    Object.assign(module, { ...item })
  },
  DELETE (state: ChecklistSectionState, item: any) {
    state.checklistModules = state.checklistModules.filter(checklist => checklist.id !== item)
  }
}

import { InventoryState } from '../../state'
import { IProviderInventory } from '../../../../classes/Inventory/interfaces/IProviderInventory'

export default {
  SET_PROVIDER_INVENTORY (state: InventoryState, providers_inventory: IProviderInventory[]): void {
    state.provider_inventory = providers_inventory
  },
  PUSH_PROVIDER_INVENTORY (state: InventoryState, provider_inventory: IProviderInventory): void {
    state.provider_inventory.push(provider_inventory)
  },
  UPDATE_PROVIDER_INVENTORY (state: InventoryState, provider_inventory: IProviderInventory): void {
    const index = state.provider_inventory.findIndex(p => p.id === provider_inventory.id)
    if (index !== -1) {
      state.provider_inventory.splice(index, 1, provider_inventory)
    }
  },
  DELETE_PROVIDER_INVENTORY (state: InventoryState, provider_inventory: IProviderInventory): void {
    const index = state.provider_inventory.findIndex(p => p.id === provider_inventory.id)
    if (index !== -1) {
      state.provider_inventory.splice(index, 1)
    }
  }
}


import { ActionContext } from 'vuex'
import { State } from './state'
import { IProducto } from '@/classes/Facturacion/IProduct'
const URL_ACTION = '/apis/product/'
const actions = {
  async loadAll ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION,
        method: 'GET'
      },
      { root: true }
    )
    commit('SET_ALL', data)
    return data
  },
  async loadCatProductService ({ commit, dispatch }: ActionContext<State, any>, params = { search: '', page: 1 }) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: 'apis/sat/producto_servicio',
        method: 'GET',
        params
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async loadMaterialPeligroso ({ commit, dispatch }: ActionContext<State, any>, payload = { search: '', code: '', page: 1 }) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: 'apis/sat/material_peligroso',
        method: 'GET',
        params: {
          ...payload
        }
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async loadTipoEmbalaje ({ commit, dispatch }: ActionContext<State, any>, payload = { search: '', code: '', page: 1 }) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: 'apis/sat/tipo_embalaje',
        method: 'GET',
        params: {
          ...payload
        }
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async loadCatUnidadMedida ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: 'apis/sat/unidad_medida',
        method: 'GET'
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async createProduct ({ commit, dispatch }: ActionContext<State, any>, payload: IProducto) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION,
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    commit('PUSH', data)
  },
  async updateProduct ({ commit, dispatch }: ActionContext<State, any>, payload: IProducto) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${payload.id}`,
        method: 'PUT',
        data: payload
      },
      { root: true }
    )
    commit('UPDATE', data)
  }
}
export default actions

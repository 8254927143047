import { EventEmitter } from 'events'
import BaseEvents from '.';
import { IPushEvent, PushEventTypes } from './PushEvent.interface';
import debounce from 'lodash.debounce';
import getBitsPositions from '../utils/getBitsPositions';
import { IStandarAlarmFieldValue, STANDAR_ALARM_FIELDS_MAP } from '../alarms/STANDAR_ALARM_FIELDS';

export default class DataPushEvents {
    private event: EventEmitter
    private baseEvents: BaseEvents
    private socketConnection: WebSocket | null
    private currSubscribedEventImeis: { [key: string]: { eventType: PushEventTypes; imeis: Set<string>; callback: (event: IPushEvent) => void; } }
    private reconnectWS: boolean

    constructor(baseEvents: BaseEvents) {
        this.event = new EventEmitter()
        this.baseEvents = baseEvents
        this.socketConnection = null
        this.currSubscribedEventImeis = {}
        this.reconnectWS = true
        this.initDVRSocketEventsInstance = debounce(this.initDVRSocketEventsInstance.bind(this), 3000, { maxWait: 5000 })
    }

    private initDVRSocketEventsInstance() {
        if (this.baseEvents.sdk.IS_DEV) console.log('Init socket connection')

        const imeiConnections = Array.from(this.getSubscribedImeis())
        this.socketConnection = this.baseEvents.getWSInstance(imeiConnections)

        // Evento que se ejecuta cuando se recibe un mensaje
        this.socketConnection.onmessage = (event: MessageEvent<string>) => {
            if (!event.data) return
            const data: IPushEvent = JSON.parse(event.data)

            if (this.baseEvents.sdk.IS_DEV) console.log(`Message received ${data.type}: `, data)
            this.event.emit(data.type, data)
        };

        // Evento que se ejecuta cuando se cierra la conexión
        this.socketConnection.onclose = (event) => {
            if (this.reconnectWS) {
                this.initDVRSocketEventsInstance()
            }
        };
    }

    public subscribeToPushEvents(imeis: string[], pushEventType: PushEventTypes, callback: (event: IPushEvent) => void) {
        if (imeis.length === 0) throw new Error('No imeis provided')

        const areNewImeis = !this.isImeisSubscribed(imeis)
        const subscribeId = String(Date.now()) + Math.random().toString(36).slice(2)
        this.currSubscribedEventImeis[subscribeId] = { eventType: pushEventType, imeis: new Set(imeis), callback }
        this.event.on(pushEventType, callback)

        if (areNewImeis) {
            this.initDVRSocketEventsInstance()
            this.reconnectWS = true
        }

        return subscribeId
    }

    public unsubscribeToPushEvents(subscribeId: string) {
        if (!this.currSubscribedEventImeis[subscribeId]) {
            throw new Error('Subscription not found')
        }

        const { eventType, callback } = this.currSubscribedEventImeis[subscribeId]

        this.event.off(eventType, callback)

        delete this.currSubscribedEventImeis[subscribeId]

        if (!this.areSubscriptionsActive() && this.socketConnection) {
            this.reconnectWS = false
            this.socketConnection.close()
        }
    }

    /**
     * Checks if the given IMEIs are already subscribed.
     *
     * @param imeis The IMEIs to check.
     * @returns True if the IMEIs are already subscribed, false otherwise.
     */
    public isImeisSubscribed(imeis: string[]) {
        const incommingImeis = new Set(imeis)
        const currImeis = this.getSubscribedImeis()
        return currImeis.intersection(incommingImeis).size > 0
    }

    /**
     * Returns a Set of all the IMEIs that are currently subscribed to any event.
     * @returns A Set of IMEIs.
     */
    public getSubscribedImeis() {
        const imeis = Object.values(this.currSubscribedEventImeis).reduce((acc, { imeis }) => {
            return new Set([...acc, ...imeis])
        }, new Set<string>())

        return imeis
    }

    public areSubscriptionsActive() {
        return Object.keys(this.currSubscribedEventImeis).length > 0
    }

    public getSubscriptionsCount() {
        return Object.keys(this.currSubscribedEventImeis).length
    }

    /**
     * Returns an array of bit positions that are set in the given number `n`.
     *
     * @param n The number to get the bit positions from.
     * @returns An array of numbers with the bit positions that are set in `n`.
     * @example
     * const result = getStandarAlertFields(13)
     * // result: [0, 2, 3]
     */
    public getStandarAlertFieldsBitsPositions (n: number): number[] {
        return getBitsPositions(n)
    }

    /**
     * Returns an object with two properties: `positions` and `alerts`.
     *
     * `positions` is an array of numbers with the bit positions that are set in `n`.
     * `alerts` is an array of `IStandarAlarmFieldValue` objects, each one representing a standard alert field.
     *
     * This method is useful to get the standard alerts fields that are set in a given number `n`.
     *
     * @param n The number to get the standard alerts fields from.
     * @returns An object with the `positions` and `alerts` properties.
     * @example
     * const result = getStandarAlertsByValue(13)
     * // result: { positions: [0, 2, 3], alerts: [ {name: 'EMERGENCY_ALARM', ...}, {name: 'OVERSPEED_ALARM', ...}, {name: 'FATIGUE_DRIVING', ...} ] }
     */
    public getStandarAlertsByValue(n: number) {
        const positions = this.getStandarAlertFieldsBitsPositions(n)
        const alerts = positions.reduce((acc, pos) => {
          // @ts-ignore
          const alert = STANDAR_ALARM_FIELDS_MAP[pos] ?? null
          if (!alert) return acc
          acc.push(alert)
          return acc
        }, [] as IStandarAlarmFieldValue[])

        return {
            positions,
            alerts
        }
    }
}

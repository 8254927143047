import { IChecklistTask } from '@/classes/Checklist/Tasks'

export interface TaskState {
  tasks: Array<IChecklistTask>
  assignedTasks: Array<IChecklistTask>
  templates:any
}

const state = (): TaskState => ({
  tasks: [],
  assignedTasks: [],
  templates: []

})
export type State = ReturnType<typeof state>

export default state

import { State } from '../../state'
import { ActionContext } from 'vuex'
import { IWheelType } from '../../../../classes/Wheel/interfaces/IWheelType'
const actions = {
  async loadWheelType ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/wheel_type/get/all',
        method: 'GET'
      },
      { root: true }
    )
    commit('SET_WHEEL_TYPE', data)
    return data
  },
  async createWheelType ({ commit, dispatch }: ActionContext<State, any>, entity: IWheelType) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/wheel_type/create',
        method: 'POST',
        data: entity
      },
      { root: true }
    )
    commit('PUSH_WHEEL_TYPE', data)
    return data
  },
  async updateWheelType ({ commit, dispatch }: ActionContext<State, any>, entity: IWheelType) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/wheel_type/update/${entity.id}`,
        method: 'PUT',
        data: entity
      },
      { root: true }
    )
    commit('UPDATE_WHEEL_TYPE', data)
    return data
  },
  async deleteWheelType ({ commit, dispatch }: ActionContext<State, any>, entity: IWheelType) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/wheel_type/delete/${entity.id}`,
        method: 'DELETE'
      },
      { root: true }
    )
    commit('DELETE_WHEEL_TYPE', entity)
    return data
  }
}
export default actions

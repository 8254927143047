import { State } from './state'

export default {
  SET_TALLERES (state: State, items: []) {
    state.talleres = items
  },
  SET_MANTTOS (state: State, manttos: any): void {
    state.manttos = manttos
  },
  PUSH_TALLERES (state: State, item:any) {
    state.talleres.push(item)
  },
  UPDATE_TALLERES (state: State, item:any) {
    const taller = state.talleres.find(taller => taller.id === item.id)
    Object.assign(taller, { ...item })
  },
  DELETE_TALLERES (state: State, id:number) {
    const index = state.talleres.findIndex(taller => taller.id === id)
    if (index > -1) {
      state.talleres.splice(index, 1)
    }
  },
  SET_CONCEPTOS (state: State, items: []) {
    state.conceptos = items
  },
  PUSH_CONCEPTOS (state: State, item:any) {
    // const iw = { a: 'a' }
    state.conceptos.push(item)
  },
  UPDATE_CONCEPTOS (state: State, item:any) {
    const concepto = state.conceptos.find(concepto => concepto.id === item.id)
    Object.assign(concepto, { ...item })
  },
  DELETE_CONCEPTOS (state: State, id:number) {
    const index = state.conceptos.findIndex(concepto => concepto.id === id)
    if (index > -1) {
      state.conceptos.splice(index, 1)
    }
  },
  // VERSION PARA CAT, LUEGO ACTUALIZAR LOS DE ARRIBA
  SET_CAT_TIRE (state: State, options :{ data: [], cat_type:string}) {
    if (options.cat_type === 'SIZE') {
      state.tires.sizes = options.data
    }
    if (options.cat_type === 'BRAND') {
      state.tires.brands = options.data
    }
    if (options.cat_type === 'BRANDBATTERY') {
      state.batteries.brands = options.data
    }
    if (options.cat_type === 'MODELBATTERY') {
      state.batteries.models = options.data
    }
  },
  PUSH_ITEM_CAT_TIRE (state: State, { cat_type, item }: { item:any, cat_type:string }) {
    // const iw = { a: 'a' }
    if (cat_type === 'SIZE') {
      state.tires.sizes.push(item)
    }
    if (cat_type === 'BRAND') {
      state.tires.brands.push(item)
    }
    if (cat_type === 'BRANDBATTERY') {
      state.batteries.brands.push(item)
    }
    if (cat_type === 'MODELBATTERY') {
      state.batteries.models.push(item)
    }
  },
  UPDATE_ITEM_CAT_TIRE (state: State, { item, cat_type }:{item:any, cat_type:string}) {
    if (cat_type === 'SIZE') {
      const size = state.tires.sizes.find(size => size.id === item.id)
      Object.assign(size, { ...item })
      // state.tires.sizes.push(item)
    }
    if (cat_type === 'BRAND') {
      const brand = state.tires.brands.find(brand => brand.id === item.id)
      Object.assign(brand, { ...item })
    }
    if (cat_type === 'BRANDBATTERY') {
      const brand = state.batteries.brands.find(batterie => batterie.id === item.id)
      Object.assign(brand, { ...item })
      // state.tires.sizes.push(item)
    }
    if (cat_type === 'MODELBATTERY') {
      const model = state.batteries.models.find(model => model.id === item.id)
      Object.assign(model, { ...item })
    }
  },
  DELETE_ITEM_CAT_TIRE (state: State, { id, cat_type } :{id:number, cat_type:string}) {
    if (cat_type === 'SIZE') {
      const index = state.tires.sizes.findIndex(size => size.id === id)
      if (index > -1) {
        state.tires.sizes.splice(index, 1)
      }
    }
    if (cat_type === 'BRAND') {
      const index = state.tires.brands.findIndex(brand => brand.id === id)
      if (index > -1) {
        state.tires.brands.splice(index, 1)
      }
    }
  }
}

import { State } from './state'

export default {
  getAssetMaintenances (state: State) {
    return state.assetMaintenances
  },
  getLoadedAssetMaintenanceParts (state: State) {
    return state.assetMaintenanceParts
  }
}

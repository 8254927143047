import { TaskState } from './state'
import ChecklistTask from '@/classes/Checklist/Tasks'

export default {
  SET_ASSIGNED_TASKS (state: TaskState, tasks: ChecklistTask[]) {
    state.assignedTasks = tasks
  },
  SET_TEMPLATE (state: TaskState, template: any[]) {
    state.templates = template
  },
  SET_ALL (state: TaskState, taks: ChecklistTask[]) {
    state.tasks = taks
  },
  PUSH (state: TaskState, task: ChecklistTask) {
    state.tasks.push(task)
  },
  UPDATE (state: TaskState, updatedTask: ChecklistTask) {
    const index = state.tasks.findIndex(task => task.id === updatedTask.id)
    if (index !== -1) {
      state.tasks.splice(index, 1, updatedTask)
    }
  },
  DELETE (state: TaskState, taskId: number) {
    state.tasks = state.tasks.filter(task => task.id !== taskId)
  }
}

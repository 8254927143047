// import { GeofencesState, ICGeofence } from './state'
import { BatteryState } from '../../state'
import { IBatteryCapacity } from '@/classes/Battery/interfaces/IBatteryCapacity'

export default {
  SET_BATTERY_CAPACITY (state: BatteryState, entities: IBatteryCapacity[]): void {
    state.battery_capacity = entities
  },
  PUSH_BATTERY_CAPACITY (state: BatteryState, entity: IBatteryCapacity): void {
    state.battery_capacity.push(entity)
  },
  UPDATE_BATTERY_CAPACITY (state: BatteryState, entity: IBatteryCapacity): void {
    const index = state.battery_capacity.findIndex(p => p.id === entity.id)
    if (index !== -1) {
      state.battery_capacity.splice(index, 1, entity)
    }
  },
  DELETE_BATTERY_CAPACITY (state: BatteryState, entity: IBatteryCapacity): void {
    const index = state.battery_capacity.findIndex(p => p.id === entity.id)
    if (index !== -1) {
      state.battery_capacity.splice(index, 1)
    }
  }
}

import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
// @ts-ignore
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi' // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        // Colores de apliacacion
        main: '#fff',
        primary: '#117dd4',
        secondary: '#87C0B6',
        tertiary: '#BEC8B3',
        quaternary: '#EBC9C9',
        quinary: '#2E363C',
        // Colores fundamentales
        error: '#ff7575',
        info: '#3b7aff',
        success: '#65c969',
        warning: '#f0b811',
        orange: '#FFAA5A',
        plum: '#AB47BC',
        hotPink: '#FF69B4',
        royalBlue: '#4169E1',
        text: '#332e2e',
        bgColor: '#f2f2f2'
      },
      dark: {
        // Colores de apliacacion
        main: '#207F94',
        primary: '#348186',
        secondary: '#448075',
        tertiary: '#72835F',
        quaternary: '#BC4949',
        quinary: '#911BFF',
        // Colores fundamentales
        error: '#de1d1d',
        info: '#117dd4',
        success: '#2c9931',
        warning: '#c99906',
        orange: '#E36E01',
        plum: '#8a008a',
        hotPink: '#e32785',
        royalBlue: '#1441c7',
        text: '#ffffff',
        bgColor: '#3d3a3a'
      }
    }
  }
})

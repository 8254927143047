var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.$store.state.app.overlay ? _c('div', {
    staticClass: "d-flex justify-center align-center loaderDialog"
  }, [_c('div', {
    staticClass: "loaderContainer"
  }, [_c('div', [_c('div', {
    staticClass: "loader"
  })])])]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }
import Root from '../Root/Root'
import { Store } from 'vuex'
export interface ISheduledReport {
  id: number
  shr_name: string
  shr_emails: [string]
  shr_each_execute: number
  shr_events: [string]
  shr_active: boolean
}

export default class SheduledReport extends Root implements ISheduledReport {
  id!: number;
  shr_name!: string
  shr_emails!: [string]
  shr_each_execute!: number
  shr_events!: [string]
  shr_active!: boolean
  constructor (shedule_report: ISheduledReport) {
    super()
    Object.assign(this, shedule_report)
  }

  async active () {
    console.log(this.shr_active)
  }
}

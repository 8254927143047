/**
 * Converts an object into URLSearchParams format.
 * 
 * @param obj - The object to be converted.
 * @returns URLSearchParams object containing key-value pairs from the input object.
 */
export default function (obj: any): URLSearchParams {
    const data = new URLSearchParams();

    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const element = obj[key];
            data.append(key, element);
        }
    }

    return data
}
import IDevice from '../interfaces/Device.interface'
import BaseItem from './BaseItem'

export default class DeviceItem extends BaseItem<IDevice> implements IDevice {
    channels!: string;
    deviceType!: string;
    dormantState!: 'AWAKE' | 'DORMANT' | 'UNKNOWN';
    fleetId!: string;
    lanWan!: 'INTRANET_NETWORK' | 'PUBLIC_NETWORK';
    onlineState!: 'OFFLINE' | 'ONLINE';
    uniqueId!: string;
}

import { State } from './state'

export default {
  SET_ALL (state: State, items: []) {
    state.sales = items
  },
  UPDATE (state: State, item: any) {
    const salesIndex = state.sales.findIndex(sale => sale.id === item.id)
    if (salesIndex !== -1) {
      Object.assign(state.sales[salesIndex], { ...item })
      const updatedSale = state.sales.splice(salesIndex, 1)[0]
      state.sales.push(updatedSale)
    }
  },
  PUSH (state: State, item: any) {
    state.sales.push(item)
  },
  DELETE (state: State, item: any) {
    state.sales = state.sales.filter(sale => sale.id !== item.id)
  },
  SET_CONFIG (state: State, config: any) {
    state.config = config
  }
}

import { State } from '../../state'
import { ActionContext } from 'vuex'
import { IPurchaseOrder } from '../../../../classes/Inventory/interfaces/IPurchaseOrder'
const actions = {
  async loadPurchaseOrder ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/purchase_order/get/all',
        method: 'GET'
      },
      { root: true }
    )
    commit('SET_PURCHASE_ORDER', data)
    return data
  },
  async createPurchaseOrder ({ commit, dispatch }: ActionContext<State, any>, payload: IPurchaseOrder) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/purchase_order/create',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    commit('PUSH_PURCHASE_ORDER', data)
    return data
  },
  async updatePurchaseOrder ({ commit, dispatch }: ActionContext<State, any>, payload: IPurchaseOrder) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/purchase_order/update/${payload.id}`,
        method: 'PUT',
        data: payload
      },
      { root: true }
    )
    commit('UPDATE_PURCHASE_ORDER', data)
    return data
  },
  async deletePurchaseOrder ({ commit, dispatch }: ActionContext<State, any>, payload: IPurchaseOrder) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/purchase_order/delete/${payload.id}`,
        method: 'DELETE'
      },
      { root: true }
    )
    commit('DELETE_PURCHASE_ORDER', payload)
    return data
  },
  async loadPurchaseOrderByUserAuthorized ({ commit, dispatch }: ActionContext<State, any>, id_user: number) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/purchase_order/get/all',
        method: 'GET'
      },
      { root: true }
    )
    commit('SET_PURCHASE_ORDER', data)
    return data
  },
  async authorizedPurchaseOrder ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/purchase_order/authorized',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    commit('UPDATE_PURCHASE_ORDER', data)
    return data
  },
  async denniedPurchaseOrder ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/purchase_order/dennied',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    commit('UPDATE_PURCHASE_ORDER', data)
    return data
  },
  async getPDFPurchaseOrder ({ commit, dispatch }: ActionContext<State, any>, id_purchase_order: number) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/purchase_order/get/pdf/${id_purchase_order}`,
        method: 'GET'
      },
      { root: true }
    )
    // commit('SET_PURCHASE_ORDER', data)
    return data
  },
  async loadPurchaseOrderMovementLastest ({ commit, dispatch }: ActionContext<State, any>, id_requisition:number) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/purchase_order/movements/lastest/${id_requisition}`,
        method: 'GET'
      },
      { root: true }
    )
    return data
  }
}
export default actions


/**
 * Converts a string of comma-separated values into an array of numbers.
 * Handles individual numbers and ranges specified with a hyphen.
 * Throws errors for invalid ranges or numbers.
 *
 * @param cadena - The input string to be converted
 * @returns An array of numbers extracted from the input string
 */
export default function convertirCanales (cadena: string) {
  const resultado = []
  const elementos = cadena.split(',')

  for (const elemento of elementos) {
    if (elemento.includes('-')) {
      const [inicio, fin] = elemento.split('-').map(Number)

      if (isNaN(inicio) || isNaN(fin)) {
        throw new Error(`Rango inválido: '${elemento}'`)
      }
      if (inicio > fin) {
        throw new Error(`El inicio del rango es mayor que el final: '${elemento}'`)
      }

      for (let i = inicio; i <= fin; i++) {
        resultado.push(i)
      }
    } else {
      const numero = Number(elemento)

      if (isNaN(numero)) {
        throw new Error(`Número inválido: '${elemento}'`)
      }

      resultado.push(numero)
    }
  }

  return resultado
}

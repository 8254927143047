import { State } from './state'
import { SERVICES } from '../catalogs/SERVICES'
export default {
  getTalleres (state: State) {
    return state.talleres
  },
  getConceptos (state: State) {
    return state.conceptos
  },
  getManttos (state: State) {
    return state.manttos
  }
}

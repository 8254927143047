import { ActionContext } from 'vuex'
import { State } from '../state'
import ChatItem from '@/store/packages/Chat/ChatItem'
import { CHAT_TYPE } from '@/store/catalogs/CHAT_TYPE'
import { IChat, IChatMessage, IChatSchemaSimple, IChatUser, UserSchemaSimple } from '@/store/interfaces/Chat'
import ChatMessage from '@/store/packages/Chat/ChatMessage'

export default {
  async susbscribeToChatEventsById ({ dispatch }: ActionContext<State, any>, id_chat: number) {
    try {
      await dispatch(
        'axios',
        {
          url: 'chat/item/rooms/subscribe',
          method: 'POST',
          data: [id_chat]
        }
      )
    } catch (error) {
      console.error(error)
      dispatch('app/addLogWithError', { title: 'SUBSCRIBE_CHAT_ITEM_EVENTS', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async getAvaliableAccountUsersToChat ({ dispatch }: ActionContext<State, any>) {
    try {
      const { data }: { data: UserSchemaSimple[] } = await dispatch(
        'axios',
        {
          url: 'chat/item/avaliable_account_users',
          method: 'GET'
        }
      )
      return data
    } catch (error) {
      console.error(error)
      dispatch('app/addLogWithError', { title: 'GET_AVALIABLE_ACCOUNT_USERS', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  getOneToOneChatItemByUserId (context: ActionContext<State, any>, id_user: number) {
    return (context.getters.chatsArr as ChatItem[]).find(obj => obj.id_chat_type === CHAT_TYPE.ONE_TO_ONE && obj.chat_users.some(cu => cu.id_user === id_user))
  },
  setEmptyOneToOneChatItem (context: ActionContext<State, string>, payload: { id: number; username: string; }) {
    const emptyChatItem = ChatItem.createEmptyChatItem(payload)
    context.commit('SET_CURR_CHAT', emptyChatItem)
  },
  async bindGroupToChat ({ dispatch }: ActionContext<State, any>, { id_chat, id_group }: { id_chat: number; id_group: number }) {
    try {
      const { data }: { data: IChat } = await dispatch(
        'axios',
        {
          url: `chat/item/group/bind/${id_chat}/${id_group}`,
          method: 'PUT'
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('app/addLogWithError', { title: 'BIND_GROUP_TO_CHAT', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async unbindGroupToChat ({ dispatch }: ActionContext<State, any>, { id_chat, id_group }: { id_chat: number; id_group: number }) {
    try {
      const { data }: { data: IChat } = await dispatch(
        'axios',
        {
          url: `chat/item/group/unbind/${id_chat}/${id_group}`,
          method: 'PUT'
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('app/addLogWithError', { title: 'UNBIND_GROUP_TO_CHAT', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async createChatItemByChatMessage ({ dispatch }: ActionContext<State, any>, payload: FormData) {
    try {
      const { data }: { data: IChat } = await dispatch(
        'axios',
        {
          url: 'chat/item/create_by_message',
          method: 'POST',
          data: payload
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('app/addLogWithError', { title: 'CREATE_CHAT_ITEM_BY_CHAT_MESSAGE', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async createChatItemGroup ({ dispatch }: ActionContext<State, any>, payload: FormData) {
    try {
      const { data }: { data: IChat } = await dispatch(
        'axios',
        {
          url: 'chat/item/create_group',
          method: 'POST',
          data: payload
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('app/addLogWithError', { title: 'CREATE_CHAT_ITEM_GROUP', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async toggleEnableChatBots ({ dispatch }: ActionContext<State, any>, toggle: boolean) {
    try {
      const { data }: { data: { ids_chats: number[]; cht_bot_active: boolean; } } = await dispatch(
        'axios',
        {
          url: `chat/item/update/toggle_enable_bots/${toggle}`,
          method: 'PUT'
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('app/addLogWithError', { title: 'TOGGLE_ENABLE_BOTS', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async updateChatItem ({ dispatch, state }: ActionContext<State, any>, { payload, updateItem }: { payload: Partial<IChat> & { id_chat: number }, updateItem: boolean }) {
    try {
      const { data }: { data: IChat } = await dispatch(
        'axios',
        {
          url: `chat/item/update/${payload.id_chat}`,
          method: 'PUT',
          data: payload
        }
      )

      if (updateItem) {
        Object.assign(state.chats[payload.id_chat], payload)
      }

      return data
    } catch (error) {
      console.error(error)
      dispatch('app/addLogWithError', { title: 'UPDATE_CHAT_ITEM', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async deleteChat ({ dispatch }: ActionContext<State, any>, id_chat: number) {
    try {
      const { data }: { data: IChat } = await dispatch(
        'axios',
        {
          url: `chat/item/delete/${id_chat}`,
          method: 'delete'
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('app/addLogWithError', { title: 'DELETE_CHAT', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async updateThumnail ({ dispatch }: ActionContext<State, any>, { formdata, id_chat }: { formdata: FormData, id_chat: number }) {
    try {
      const { data }: { data: IChatSchemaSimple } = await dispatch(
        'axios',
        {
          url: `chat/item/change_thumbnail/${id_chat}`,
          method: 'PUT',
          data: formdata
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('app/addLogWithError', { title: 'THUBMNAIL_UPDATED', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  isEmptyChat ({ state }: ActionContext<State, any>) {
    if (!state.currChat) return false
    return state.currChat.id_chat === -1
  },
  getChatBotChat ({ state }: ActionContext<State, any>) {
    return Object.values(state.chats).find(chat => chat.isChatBotChat())
  },
  userHaveUnreadedMessages ({ state }: ActionContext<State, any>) {
    return Object.values(state.chats).some(chat => chat.unreadedChatMessages > 0)
  },
  socket_ChatCreated ({ commit, dispatch }: ActionContext<State, any>, data: IChat) {
    const chatItem = new ChatItem({ data })
    commit('ADD_CHAT_ITEM', chatItem)
    dispatch('app/playSound', { type: 'message' }, { root: true })
  },
  socket_ChatDeleted ({ commit, dispatch, state }: ActionContext<State, any>, id_chat: number) {
    const chat = state.chats[id_chat]
    if (!chat) return

    dispatch('app/notification', {
      content: `El chat ${chat.chatName} a sido eliminado`,
      type: 'warning',
      timeout: 5000
    }, { root: true })

    commit('REMOVE_CHAT', id_chat)
    commit('SET_CURR_CHAT', null)
  },
  socket_ChatUpdated ({ state }: ActionContext<State, any>, data: IChatSchemaSimple) {
    const chat = state.chats[data.id_chat]
    if (!chat) return

    chat.rehidratateDataPartial(data)
  },
  socket_ChatUserAdded ({ state }: ActionContext<State, any>, { id_chat, chat_users, message }: { id_chat: number; chat_users: IChatUser[]; message: IChatMessage | null }) {
    const chat = state.chats[id_chat]
    if (!chat) return

    chat.addChatUsersByIChatUsers(chat_users)

    if (message) {
      chat.addChatMessage(new ChatMessage({ data: message, chatItem: state.chats[message.id_chat_item] }))
    }
  },
  socket_ChatUsersUpdated ({ state }: ActionContext<State, any>, { id_chat, chat_users }: { id_chat: number; chat_users: IChatUser[] }) {
    const chat = state.chats[id_chat]
    if (!chat) return

    chat.updateChatUsersByIChatUsers(chat_users)
  },
  socket_ChatThumbnailUpdated ({ state }: ActionContext<State, any>, { chat, message }: { chat: IChatSchemaSimple; message: IChatMessage }) {
    const chatItem = state.chats[chat.id_chat]
    if (!chatItem) return

    chatItem.rehidratateDataPartial(chat)
    chatItem.addChatMessage(new ChatMessage({ data: message, chatItem }))
  },
  socket_ChatUsersRemoved ({ state }: ActionContext<State, any>, { id_chat, chat_users, message }: { id_chat: number; chat_users: IChatUser[]; message: IChatMessage | null }) {
    const chat = state.chats[id_chat]
    if (!chat) return

    chat.updateChatUsersByIChatUsers(chat_users)
    if (message) {
      chat.addChatMessage(new ChatMessage({ data: message, chatItem: state.chats[message.id_chat_item] }))
    }
  },
  socket_chatsToggleEnableBot ({ state }: ActionContext<State, any>, { cht_bot_active, ids_chats }: { ids_chats: number[]; cht_bot_active: boolean; }) {
    ids_chats.forEach(id_chat => {
      const chat = state.chats[id_chat]
      if (!chat) return
      chat.updateProperty('cht_bot_active', cht_bot_active)
    })
  }

}


export interface ChecklistState {
  checklists: any[]
}

const state = (): ChecklistState => ({
  checklists: []
})
export type State = ReturnType<typeof state>

export default state

import { ActionContext } from 'vuex'
import { State } from './state'
import actionsBatteryCapacity from './modules/battery_capacity/actions'
import actionsBatteryMaker from './modules/battery_maker/actions'
import actionsBatteryModel from './modules/battery_model/actions'

export default {
  async init ({ commit, dispatch }: ActionContext<State, any>) {
    console.log('battery')
  },
  ...actionsBatteryCapacity,
  ...actionsBatteryMaker,
  ...actionsBatteryModel
}

/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */

import { ActionContext } from 'vuex/types/index'
import {
  RESOURCES,
  PLATAFORM,
  PLATAFORM_RESOURCES
} from '../../resources/RESOURCES'
import { State } from '../state'
const actions = {
  async loadColors ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/get_color_all/',
        method: 'GET'
      },
      { root: true }
    )

    commit(
      'resources/SET_RESOURCES',
      {
        resources: data,
        rType: RESOURCES.COLORS
      },
      { root: true }
    )
  },
  async createColor (
    { commit, dispatch }: ActionContext<State, any>,
    payload: any
  ) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: '/apis/create_color/',
          method: 'POST',
          data: payload
        },
        { root: true }
      )

      commit(
        'resources/PUSH_RESOURCE',
        {
          item: data,
          rType: RESOURCES.COLORS
        },
        { root: true }
      )
    } catch (error) {
      // @ts-ignore
      if (error?.response?.status === 400) {
        await dispatch(
          'app/notification',
          {
            content: 'El color ya existe en otra notificacion',
            type: 'error'
          },
          { root: true }
        )
      }
      throw error
    }
  }
}
export default actions

import { IChatUser, UserSchemaSimple } from '@/store/interfaces/Chat'
import BaseClass from '../BaseClass'
import capitalizeText from '@/utils/capitalize'

export default class ChatUser extends BaseClass<IChatUser> implements IChatUser {
  chu_is_hidden!: boolean;
  chu_createdAt!: string;
  chu_is_admin!: boolean;
  id_chat_item!: number;
  id_chat_user!: number;
  chu_silent_chat!: boolean;
  chu_last_seen_message_id!: number | null
  /** SI el usuario esta eliminado o esta devinculado, user sera nulo */
  user!: UserSchemaSimple | null;
  chu_user_saved_data!: UserSchemaSimple;

  public onInitInstance (): void {
    // ---
  }

  public get curr_id_user () {
    return this.id_user || this.chu_user_saved_data.id
  }

  public get id_user (): number {
    return this.user?.id || this.chu_user_saved_data.id
  }

  public set id_user (value: number) {
    if (this.user) {
      this.user.id = value
    } else {
      if (!this.chu_user_saved_data) return
      this.chu_user_saved_data.id = value
    }
  }

  public get id () {
    return this.curr_id_user
  }

  public get username () {
    return this.us_name
  }

  public get us_name () {
    return this.user?.username || this.chu_user_saved_data.username
  }

  public get us_description () {
    return ''
  }

  public get last_time_conected () {
    return ''
  }

  public get ut_name () {
    return ''
  }

  public get userThumbnail () {
    return ''
  }

  public get isUserConected () {
    return false
  }

  public get isAvaliable () {
    return Boolean(this.id_user)
  }

  public get naturalLastTimeConected () {
    return ''
  }

  public get userStringMention () {
    return `@${capitalizeText(this.us_name).replaceAll(' ', '_')}`
  }

  public static usNameToMention (name: string) {
    return `@${capitalizeText(name).replaceAll(' ', '_')}`
  }
}

import { ActionContext } from 'vuex'
import { State } from './state'

const URL_ACTION = '/apis/crm_tags/'

export default {
  async load ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION + 'get/all',
        method: 'GET'
      },
      { root: true }
    )
    return data
  },

  async create ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION + 'create',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    return data
  },

  async update ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION + 'update/' + payload.id,
        method: 'PUT',
        data: payload
      },
      { root: true }
    )
    return data
  },
  async delete ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION + 'delete/' + payload.id,
        method: 'DELETE',
        data: payload
      },
      { root: true }
    )
    return data
  }
}

import { IChat, UserSchemaSimple } from '@/store/interfaces/Chat'
import { State } from './state'
import ChatItem from '@/store/packages/Chat/ChatItem'
import Vue from 'vue'
import { CombinedVueInstance } from 'vue/types/vue'

export default {
  SET_STATE (state: State, payload: typeof state) {
    Object.assign(state, payload)
  },
  SET_CURR_CHAT_POPUP_STATE (state: State, payload: typeof state.currPopupChatItem) {
    Object.assign(state.currPopupChatItem, payload)
  },
  SET_CHATS (state: State, payload: IChat[]) {
    const chats: Record<string, ChatItem> = Object.fromEntries(payload.map(data => [data.id_chat, new ChatItem({ data })]))
    Object.assign(state, { chats })
  },
  SET_CURR_CHAT (state: State, payload: ChatItem | null) {
    state.currChat = payload
  },
  SAVE_CURR_CHAT (state: State) {
    if (!state.currChat || state.currChat?.id_chat === -1) return
    Vue.set(state.chats, state.currChat.id_chat, state.currChat)
  },
  ADD_CHAT_ITEM (state: State, payload: ChatItem) {
    Vue.set(state.chats, payload.id_chat, payload)
  },
  REMOVE_CHAT (state: State, id_chat: number) {
    Vue.delete(state.chats, id_chat)
  },
  REMOVE_USER_CONECTED (state: State, index: number) {
    state.chatUsersConected.splice(index, 1)
  },
  CURR_COMPONENT (state: State, component: CombinedVueInstance<any, any, any, any, any, any>) {
    state.currChatComponent = component
  },
  ADD_USER_CONECTED (state: State, data: UserSchemaSimple) {
    state.chatUsersConected.push(data)
  }
}

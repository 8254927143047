
export interface CompanyState {
  sales: any[]
}

const state = (): CompanyState => ({
  sales: []
})

export type State = ReturnType<typeof state>

export default state

// import { GeofencesState, ICGeofence } from './state'
import { WheelState } from '../../state'
import { IWheelSize } from '@/classes/Wheel/interfaces/IWheelSize'

export default {
  SET_WHEEL_SIZE (state: WheelState, entities: IWheelSize[]): void {
    state.wheel_size = entities
  },
  PUSH_WHEEL_SIZE (state: WheelState, entity: IWheelSize): void {
    state.wheel_size.push(entity)
  },
  UPDATE_WHEEL_SIZE (state: WheelState, entity: IWheelSize): void {
    const index = state.wheel_size.findIndex(p => p.id === entity.id)
    if (index !== -1) {
      state.wheel_size.splice(index, 1, entity)
    }
  },
  DELETE_WHEEL_SIZE (state: WheelState, entity: IWheelSize): void {
    const index = state.wheel_size.findIndex(p => p.id === entity.id)
    if (index !== -1) {
      state.wheel_size.splice(index, 1)
    }
  }
}

import { InventoryState } from '../../state'

export default {
  SET_TIRE_INVENTORY (state: InventoryState, entities: any[]): void {
    state.tire_inventory = entities
  },
  PUSH_TIRE_INVENTORY (state: InventoryState, entitie: any): void {
    state.tire_inventory.unshift(entitie)
  },
  UNMOUNTED_TIRE (state: InventoryState, entity: any): void {
    const tire = state.tire_inventory.find(it => it.id === entity.id_tire)
    if (tire) {
      tire.unit = null
      tire.axle = null
      tire.position = null
    }
  },
  MOUNTED_TIRE (state: InventoryState, entity: any): void {
    const tire = state.tire_inventory.find(it => it.id === entity.id_tire)
    if (tire) {
      tire.unit = entity.unit
      tire.axle = entity.axle
      tire.position = entity.position
    }
  },
  UPDATE_TIRE_INVENTORY (state: InventoryState, entity: any): void {
    const index = state.tire_inventory.findIndex(tire => tire.id === entity.id)
    if (index !== -1) {
      state.tire_inventory.splice(index, 1, (entity))
    }
  }
  // DELETE_PROVIDER (state: TypeState, provider: Provider): void {
  //   const index = state.providers.findIndex(p => p.id === provider.id)
  //   if (index !== -1) {
  //     state.providers.splice(index, 1)
  //   }
  // }
}

import { InventoryState } from '../../state'

export default {
  getDepartments (state: InventoryState) {
    return state.deparment
  }
  // getPurchaseOrderConfirmed (state: InventoryState) {
  //   // @ts-ignore
  //   return state.purchase_order.filter(purch => purch.por_added_inventory === false)
  // }
}

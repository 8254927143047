// import { GeofencesState, ICGeofence } from './state'
import { WheelState } from '../../state'
import { IWheelType } from '@/classes/Wheel/interfaces/IWheelType'

export default {
  SET_WHEEL_TYPE (state: WheelState, entities: IWheelType[]): void {
    state.wheel_type = entities
  },
  PUSH_WHEEL_TYPE (state: WheelState, entity: IWheelType): void {
    state.wheel_type.push(entity)
  },
  UPDATE_WHEEL_TYPE (state: WheelState, entity: IWheelType): void {
    const index = state.wheel_type.findIndex(p => p.id === entity.id)
    if (index !== -1) {
      state.wheel_type.splice(index, 1, entity)
    }
  },
  DELETE_WHEEL_TYPE (state: WheelState, entity: IWheelType): void {
    const index = state.wheel_type.findIndex(p => p.id === entity.id)
    if (index !== -1) {
      state.wheel_type.splice(index, 1)
    }
  }
}

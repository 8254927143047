import StreamaxJSSdk from "..";
import BaseItem from "../item/BaseItem";

export default abstract class BaseApi {
    protected sdk: StreamaxJSSdk
    /** Item class for the api call response data */
    protected abstract itemClass: typeof BaseItem<any>

    constructor(sdk: StreamaxJSSdk) {
        this.sdk = sdk
    }

    public assingToItems<itemType>(data: any[], itemClass: itemType) {
        // @ts-ignore
        return data.map((item: any) => new itemClass({ data: item }))
    }
}
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import getSecondsDiffFromNow from '@/utils/getSecondsDiffFromNow'
import timeFormating from '@/utils/timeFormating'
import Timer from 'easytimer.js'
const C24HRS_IN_SECONDS = 86400
/**
 * Buils a object that manages a unit that the last message is more than 24hrs
 */
export default class ActualizerItem {
  unit_item: any
  unit_timer: Timer
  unit_time_report: string
  unit_time_report_seconds: number
  is_reporting = false
  /**
     * Create a point.
     * @param {any} unit_item - Wialon item unit, generated by the util metho.
     */
  constructor (unit_item: any) {
    this.unit_item = unit_item
    this.unit_timer = new Timer()
    this.unit_time_report = ''
    this.unit_time_report_seconds = getSecondsDiffFromNow(this.unit_item.getLastMessage() ? this.unit_item.getLastMessage().t : 0)
    this.isReporting()
    this.getUnitTimeReport()
    unit_item.addListener('changeLastMessage', this.onChangeLastMessage)
  }

  isReporting (): boolean {
    if (this.unit_time_report_seconds > C24HRS_IN_SECONDS) {
      this.is_reporting = false
    } else {
      this.is_reporting = true
    }
    return this.is_reporting
  }

  onChangeLastMessage = (event: { getData: () => any }): void => {
    const messageData = event.getData()
    this.unit_time_report_seconds = getSecondsDiffFromNow(messageData.t)
    this.isReporting()
  }

  getUnitTimeReport (): string {
    const unitStartAt = this.unit_time_report_seconds
    this.unit_timer.start({
      startValues: { seconds: unitStartAt }
    })
    this.unit_time_report = timeFormating(this.unit_timer)
    return this.unit_time_report
  }

  getUnitTimeReportSeconds (): number {
    return this.unit_time_report_seconds
  }

  /**
   * @returns The wialon id from wialon plataform
   */
  getWialonId (): number {
    return this.unit_item.getId()
  }

  /**
   * @returns Get the name of wialon unit
   */
  getName (): string {
    return this.unit_item.getName()
  }

  getResourceId (): number {
    return this.unit_item?.getAccountId() || 0
  }

  /**
   * @returns Get the icon or image of wialon unit
   */
  getIcon (): string {
    return this.unit_item.getIconUrl()
  }
}

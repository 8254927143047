// import { InventoryState } from '../../state'

import { ChangeStoreState } from './state'

// import { ProductState } from './state'

export default {
  getNoteSales (state: ChangeStoreState) {
    return state.change_store
  }
}

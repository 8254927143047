import { PARTS_MATERIAL_TYPE } from '@/store/catalogs/PARTS_MATERIALS_TYPE'
import { TYPE_SERIALIZED_PARTSMATERIALS } from '@/store/catalogs/TYPE_SERIALIZED_PARTSMATERIALS'
import { InventoryState } from '../../state'

export default {
  getPartsMaterials (state: InventoryState) {
    return state.parts_materials.filter((material) => material.active_status)
  },
  getPartsMaterialsConsumibleAndUnidades (state: InventoryState) {
    return state.parts_materials.filter((material) => material.active_status).filter((material) => material.pm_type !== PARTS_MATERIAL_TYPE.SERIALIZADA)
  },
  getOnlyPartsMaterials (state: InventoryState) {
    return state.parts_materials.filter((material) => material.active_status).filter((serial) => serial.pm_serialized_type !== TYPE_SERIALIZED_PARTSMATERIALS.NEUMATICO)
  },
  getSerializedPartsWheels (state: InventoryState) {
    const result = state.parts_materials.filter((material) => material.active_status).filter((serial) => serial.pm_serialized_type === TYPE_SERIALIZED_PARTSMATERIALS.NEUMATICO)
    console.log(result)
    return result
  },
  getSerializedPartsBatteries (state: InventoryState) {
    const result = state.parts_materials.filter((material) => material.active_status).filter((serial) => serial.pm_serialized_type === TYPE_SERIALIZED_PARTSMATERIALS.BATERIA)
    console.log(result)
    return result
  }
}

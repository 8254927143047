
export interface RouteDestinationState {
  route_destinations: any[]
}

const state = (): RouteDestinationState => ({
  route_destinations: []
})
export type State = ReturnType<typeof state>;

export default state

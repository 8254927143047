
import { Module } from 'vuex'
// @ts-ignore
import { RootState } from '@/store/types'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import state, { AccountState } from './state'

export const account: Module<AccountState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

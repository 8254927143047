
export default function (str: string) {
    // Encontrar el prefijo (primera parte antes del primer "_")
    const underscoreIndex = str.indexOf('_');

    if (underscoreIndex === -1) {
        throw new Error('No underscore found in string');
    }
    
    // Separar en prefijo y el resto del string
    const prefix = str.slice(0, underscoreIndex + 1); // Incluye el guion bajo
    const restOfString = str.slice(underscoreIndex + 1); // Todo lo demás después del guion bajo
    return [prefix, restOfString];
}
export default function (data: any) {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  // @ts-ignore
  const groupByMonth = data.loadfuels.reduce((acc, entry) => {
    // Convertir la fecha de registro a la zona horaria del cliente
    const dateInClientTimezone = new Date(entry.ldf_date_registration).toLocaleString('en-US', { timeZone: timezone })

    // Extraer el mes y año de la fecha convertida
    const date = new Date(dateInClientTimezone)
    const monthNames = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre']
    const yearMonth = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`
    const monthName = `${monthNames[date.getMonth()]} ${date.getFullYear()}`

    // Agrupar por mes y año, sumando los gastos
    if (!acc[yearMonth]) {
      acc[yearMonth] = {
        mes: monthName,
        gastos: 0
      }
    }
    acc[yearMonth].gastos += entry.ldf_total_cost

    return acc
  }, {})

  const monthly_costs = Object.values(groupByMonth)
  return monthly_costs
}

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('notifications', {
    staticStyle: {
      "margin-top": "20px",
      "margin-right": "2px"
    },
    attrs: {
      "group": "app",
      "position": "top center"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (props) {
        return [_c('div', {
          class: 'vue-notification ' + props.item.type
        }, [_c('div', {}, [_c('div', {
          staticClass: "notification-title"
        }, [props.item.data.icon ? _c('v-icon', {
          attrs: {
            "dark": "",
            "small": ""
          }
        }, [_vm._v(_vm._s(props.item.data.icon))]) : _vm._e(), _vm._v(" " + _vm._s(props.item.title))], 1), _c('div', {
          staticClass: "notification-content"
        }, [_vm._v(_vm._s(props.item.text))])])])];
      }
    }])
  }), _c('OverlayLoader'), _c('LoaderDialog'), _c('router-view')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
import { ActionContext } from 'vuex'
import { State } from './state'

export default {
  refreshMap (context: ActionContext<State, string>) {
    setTimeout(() => {
      context.state.$ref.invalidateSize()
    }, 100)
  },
  centerUnit (context: ActionContext<State, string>, coors: { lat?: number; lng?: number, id: number }) {
    if (!coors.lat || !coors.lng) return
    context.dispatch('center', { lat: coors.lat, lng: coors.lng })
    context.commit('SET_CURR_UNIT_CONSOLE_ID', coors.id)
  },
  center (context: ActionContext<State, string>, coors: { lat?: number; lng?: number }) {
    if (!coors.lat || !coors.lng) return
    context.state.$ref.center({ lat: coors.lat ?? 0, lng: coors.lng ?? 0 })
  },
  async requestGeolocationPermission (context: ActionContext<State, string>): Promise<void> {
    let timeoutId = null
    try {
      timeoutId = setTimeout(() => { throw new Error('Timeout requestGeolocationPermission') }, 7500)
      const result = await navigator.permissions.query({ name: 'geolocation' })
      clearTimeout(timeoutId)

      if (result.state === 'granted') {
        // context.dispatch('app/notification', { content: 'Coordenadas de mapa obtenidas', type: 'plum' }, { root: true })
        navigator.geolocation.getCurrentPosition(payload => {
          context.commit('SET_GEOLOCATION', payload)
        }, () => {
          // context.dispatch('app/notification', { content: 'Coordenadas de mapa no obtenidas', type: 'error' }, { root: true })
        })
      } else {
        // context.dispatch('app/notification', { content: 'Coordenadas de mapa no obtenidas', type: 'error' }, { root: true })
      }
    } catch (error) {
      context.dispatch('app/notification', { content: 'No se pudieron acceder a las corrdenadas en el navegador', type: 'error' }, { root: true })
      console.error(error)
    } finally {
      // @ts-ignore
      clearTimeout(timeoutId)
    }
  }
}

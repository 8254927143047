const pagoRoutes = {
  meta: {
    title: 'Pagos'
  },
  path: 'pago',
  component: () => import('@/components/Facturacion/modules/pagos/Pago.vue'),
  children: [
    {
      meta: {
        title: 'Pagos'
      },
      path: '',
      name: 'pago',
      component: () => import('@/components/Facturacion/modules/pagos/PagoTable.vue')
    },
    {
      path: 'nuevo-pago',
      name: 'nuevo-pago',
      meta: {
        title: 'Pago'
      },
      component: () => import('@/components/Facturacion/modules/pagos/PagoView.vue')
    }
  ]
}

export default pagoRoutes

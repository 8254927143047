import { State } from '../../state'
import { ActionContext } from 'vuex'
import TypeFuel, { ITypeFuel } from '../../../../classes/Types/Typefuel'
const actions = {
  async loadTypefuel ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/typefuel/',
        method: 'GET'
      },
      { root: true }
    )
    const typefuels = data.map((typefuel: ITypeFuel) => new TypeFuel(typefuel))
    commit('SET_TYPEFUEL', typefuels)
  },
  async createTypefuel ({ commit, dispatch }: ActionContext<State, any>, payload:ITypeFuel) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/typefuel/',
        method: 'POST',
        data: { ...payload }
      },
      { root: true }
    )
    commit('PUSH_TYPEFUEL', data)
  },
  async updateTypefuel ({ commit, dispatch }: ActionContext<State, any>, payload:ITypeFuel) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/typefuel/${payload.id}/`,
        method: 'PUT',
        data: { ...payload }
      },
      { root: true }
    )
    commit('UPDATE_TYPEFUEL', data)
  },
  async deleteTypefuel ({ commit, dispatch }: ActionContext<State, any>, payload:any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/typefuel/${payload.id}/`,
        method: 'DELETE'
      },
      { root: true }
    )
    commit('DELETE_TYPEFUEL', payload)
  }
}
export default actions

// import { TypeState } from './state'
import gettersStore from './modules/store/getters'
import gettersStoreParts from './modules/store_parts/getters'
import gettersPartsMaterials from './modules/parts_materials/getters'
import gettersMeasurement from './modules/measurement/getters'
import gettersProviderInventory from './modules/provider_inventory/getters'
import gettersEntryConcept from './modules/entry_concept/getters'
import gettersEntryType from './modules/entry_type/getters'
import gettersPurchaseOrder from './modules/purchase_order/getters'
import gettersTireInventory from './modules/tire_inventory/getters'
import entryEntryStore from './modules/entry_store/getters'
import gettersRequisition from './modules/requisition/getters'
import gettersDepartment from './modules/department/getters'
import gettersBatteryInventory from './modules/battery_inventory/getters'

export default {
  ...gettersStore,
  ...gettersStoreParts,
  ...gettersPartsMaterials,
  ...gettersMeasurement,
  ...gettersProviderInventory,
  ...gettersEntryConcept,
  ...gettersEntryType,
  ...gettersPurchaseOrder,
  ...gettersTireInventory,
  ...entryEntryStore,
  ...gettersRequisition,
  ...gettersDepartment,
  ...gettersBatteryInventory
  // ...gettersFuelType
}

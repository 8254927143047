<template>
  <transition name="fade">
    <div class="loaderDialog" v-show="$store.state.app.loader.loader">
      <div class="loaderContainer">
        <v-img :src="getUrlLoader" width="450"></v-img>
        <!-- <SUDLogoLoader class="sudLogoLoader"></SUDLogoLoader> -->
        <div class="loaderInfoText ">
          <div style="margin-top: 20px;" class="loader"></div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Vue from 'vue'
// import SUDLogoLoader from '@/components/SUDLogoLoader.vue'

export default Vue.extend({
  name: 'LoaderDialog',
  components: {
    // SUDLogoLoader
  },
  computed: {
    getUrlLoader () {
      // if (this.$store.state.auth.user_date.loader_image) {
      //   return `${process.env.VUE_APP_BASE_URL || ''}/media/${this.$store.state.auth.user_date.loader_image}`
      // }
      return (require('../../public/ANANTA_HORIZONTAL.png'))
      // return (path) => {
      //   // eslint-disable-next-line @typescript-eslint/no-var-requires
      //   console.log(require('../../.env.files/logo_l.png'))
      //   if (!path) return require('../../.env.files/logo_l.png')
      //   return `${process.env.VUE_APP_BASE_URL || ''}/${path}?time${Date.now()}`
      // }
    }
  }
})
</script>

<style lang="sass" scoped>

.loaderDialog
  position: fixed
  width: 100%
  height: 100%
  display: flex
  justify-content: center
  align-items: center
  background-color: rgba(255,250,250, 1)
  z-index: 1000
  overflow: hidden

  .loaderContainer
    width: 75%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

  .d-flex
    width: 100%
    display: flex
    justify-content: center
    align-items: center

  .sudLogoLoader
    width: 100%

  .loaderInfoText
    text-align: center
    color: black
    font-size: 25px
    font-weight: 100

  .loader
    width: 50px
    aspect-ratio: 1
    border-radius: 50%
    border: 8px solid
    border-color: #000 #0000
    animation: l1 1s infinite

  @keyframes l1
    to
      transform: rotate(.5turn)

</style>

import StreamaxJSSdk from '..'
import IVehicle, { ApiAddVehicleReqDTO, ApiDeleteVehicleReqDTO, ApiEditVehicleReqDTO, QueryVehicleReqDTO } from '@/interfaces/Vehicle.interface'
import VehicleItem from '../item/Vehicle.item'
import ResourceApi from './ResourceApi.base'

export default class VehicleApi extends ResourceApi<IVehicle, ApiAddVehicleReqDTO, ApiEditVehicleReqDTO, QueryVehicleReqDTO, ApiDeleteVehicleReqDTO> {
    apiPlaceUrl: string;
    itemClass: typeof VehicleItem;

    constructor (sdk: StreamaxJSSdk) {
      super(sdk)
      this.apiPlaceUrl = '/vehicles'
      this.itemClass = VehicleItem
    }

    /**
     * This API is used to add vehicles. Batch add is not currently supported.
     * In the returned results, the ID of successfully added vehicle and error information for those that failed to be added are included.
     * The N9M protocol is used for added vehicles by default, and the linked device channel numbers are subject to the ones reported by devices.
     * If required parameters are left empty, API debugging will fail.
     */
    public async create (params: ApiAddVehicleReqDTO): Promise<any> {
      return await this.createUtil(params)
    }

    /**
     * This API is used to modify the vehicle information. Only the fields you pass in will be modified.
     * Default request frequency limit for the interface: 5 requests/second
     */
    public async update (id: string, params: ApiEditVehicleReqDTO): Promise<any> {
      return await this.updateUtil(id, params)
    }

    /**
     * This API is used to delete existing vehicles and linked devices.
     * If some vehicles of the given vehicleIds do not exist, the API will only delete vehicles that exist and ignore those that do not exist.
     * Default request frequency limit for the interface: 5 requests/second
     */
    public async delete (params: ApiDeleteVehicleReqDTO): Promise<any> {
      return await this.deleteUtil(params)
    }

    /**
     * This API is used to query the vehicle list information of subscribers and applications in pages.
     * When vehicleIds and fleetIds are input at the same time, the returned result takes the intersection of the two parameters.
     * Special note: Only the subscriber administrator can use this interface.
     */
    public async list (params?: QueryVehicleReqDTO): Promise<any> {
      return await this.listUtil(params)
    }
}

import { AxiosResponse } from "axios"
import JimiIothubSDK from ".."
import getMethodNameArgs from "../utils/getMethodNameArgs"
import IAPIResponse from "../interfaces/IAPIResponse.interface"
import Result from "../utils/Result.class"

/**
 * 
 ** Clase que proporciona una interfaz para interactuar con la API de dispositivo de Jimi IoT Hub.
 * 
 * La clase utiliza el patron de proxy para llamar los metodos de la API correspondiente.
 * 
 * Luego, se puede llamar los metodos de la API utilizando el nombre del metodo y los
 * par metros necesarios.
 * 
 * Por ejemplo:
 * @example
 * // https://docs.jimicloud.com/api/query#31-querying-v2-for-jc450-series-devices
 * // GET /api/v2/alarm/getAlarm
 * import Result from "@/utils/Result.class"
 * 
 * const result: Result = await sdk.api.get_api_v2_alarm_getAlarm({
 *   deviceImei: '860222070000574',
 *   startTime: '2024-09-11 00:00:00',
 *   endTime: '2024-09-12 00:00:00'
 * })
 */
export default class Api {
    private sdk: JimiIothubSDK

    constructor(sdk: JimiIothubSDK) {
        this.sdk = sdk
        return this.initHandler()
    }

    /**
     * Represents an API class that acts as a proxy for calling methods of the Jimi IoT Hub SDK.
     * The class dynamically handles method calls by forwarding them to the corresponding SDK methods.
     * It constructs the HTTP request based on the method name and arguments, then sends the request to the specified endpoint.
     * If in debug mode, it logs the method details before making the API call.
     * @constructor
     * @param {JimiIothubSDK} sdk - The instance of JimiIothubSDK to interact with.
     * @returns {Proxy} A proxy object that intercepts method calls and forwards them to the caller method for processing.
     */
    private initHandler() {
        const self = this;
        return new Proxy<typeof this>(this, {
            /**
             * Intercepta las llamadas a metodos de la clase `Api`.
             * 
             * Si el metodo no existe en la clase `Api`, se asume que es un metodo de la API de Jimi IoT Hub y se llama al metodo `caller` para manejarlo.
             * 
             * @param target - El objeto que se esta llamando (la clase `Api` en este caso).
             * @param prop - El nombre del metodo que se esta llamando.
             * @returns El resultado de la llamada al metodo.
             */
            get(target: any, prop: string) {
                if (prop in target) {
                    return (target as any)[prop];
                }
                return function (...args: any[]) {
                    /**
                     * Llamada al metodo `caller` para manejar la llamada al metodo de la API de Jimi IoT Hub.
                     * 
                     * @param prop - El nombre del metodo de la API que se esta llamando.
                     * @param args - Los argumentos que se pasan al metodo de la API.
                     * @returns El resultado de la llamada al metodo.
                     */
                    return self.caller(prop, args.length > 0 ? args[0] : {});
                };
            }
        });
    }

    private async caller(method: string, args: any): Promise<any> {
        const [httpMethodUnderScore, methodPathUnderScore] = getMethodNameArgs(method)
        const httpMethod = httpMethodUnderScore.replace(/_/g, '')
        const methodPath = methodPathUnderScore.replace(/_/g, '/')
        const isGet = httpMethod === 'get'
        const url = `${this.sdk.API_DEVICE_DVR_HOST}/${methodPath}`

        if (this.sdk.IS_DEV) {
            console.log("Method name: ", method)
            console.log("HttpMethod: ", httpMethod)
            console.log("Path: ", methodPath)
            console.log("Args: ", args)
            console.log('URL: ', url)
        }

        const response: AxiosResponse<IAPIResponse> = await this.sdk.axios({
            url,
            method: httpMethod,
            data: isGet ? undefined : args,
            params: isGet ? args : {},
            headers: {
                ...(isGet ? {} : { 'Content-Type': 'application/x-www-form-urlencoded' }),
            }
        })

        if (response?.data?.code === 0) {
            return Result.Ok(response.data.data)
        } else {
            return Result.Err(response)
        }
    }
}
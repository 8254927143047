/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ActionContext } from 'vuex'
import { State } from './state'
import { CAMERA_TYPE } from '../catalogs/CAMERA_TYPE'
import UnitConsoleData from '../packages/ReportItem/ReportItemData'
import actionsSheduledReport from './sheduled_report/actions'
import { trycatchAsync, trycatchSync } from '@/utils/trycatch'
import { RNRESOURCES_TYPES, RNResource } from '@/classes/Notification/Notification'
import { downloadByBlob } from '@/utils/download'
import { DateTime } from 'luxon'
import { PLAN_CAMERA_TYPE } from '../catalogs/PLAN_CAMERA_TYPE'
import utcOffset from '@/utils/utcOffset'
import RIHikvision from '../packages/ReportItem/RIHikvision'
import { HVEventsLastData } from '@/interfaces/HVEventsLastData.interface'
import { CAMERA_PLATAFORM } from '../catalogs/CAMERA_PLATAFORM'
import StreamaxJSSdk from '@/submodules/streamax/dist'
import RIStreamax from '../packages/ReportItem/RIStreamax'
import { addAmPmToDateTime } from '@/utils/datetimeToDate'
import { IJimiIotDVRLastStatus } from '@/interfaces/JimiIot.interfaces'
import { ICeleryTaskResult } from '@/interfaces/Celery.interface'

export default {
  async init ({ dispatch }: ActionContext<State, string>) {
    // await dispatch('getCamerasOnline')
    // await dispatch('getSheduledReports')
    // await dispatch('getHikEventsLastData')
  },
  async createCamerasByHIKSDK ({ dispatch, rootState }: ActionContext<State, string>, payload: any) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: '/apis/camera/encoding/create/device',
          method: 'POST',
          data: payload
        },
        { root: true }
      )

      // @ts-ignore
      const unitConsoleData: UnitConsoleData<unknown, unknown> = rootState.travel.unitsRegisters.find(obj => obj.id === payload.camera.unitconsole_id)
      if (unitConsoleData) unitConsoleData.addCamera(data)

      return data
    } catch (error) {
      console.error(error)
      await dispatch('app/notification', { content: 'No se a podido añadir la camara, consulte al equipo tecnico', type: 'error' }, { root: true })
      dispatch('app/addLogWithError', { title: 'HIK_ADD_DEVICE', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async updateCamera ({ dispatch, rootState }: ActionContext<State, string>, payload: any) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: `/apis/camera/update/${payload.camera.id}`,
          method: 'PUT',
          data: payload
        },
        { root: true }
      )
      return data
    } catch (error) {
      console.error(error)
      // await dispatch('app/notification', { content: 'N', type: 'error' }, { root: true })
      dispatch('app/addLogWithError', { title: 'HIK_ADD_DEVICE', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async deleteCamera ({ dispatch, rootState }: ActionContext<State, string>, payload: any) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: `/apis/camera/delete/${payload.id}`,
          method: 'DELETE',
          data: payload
        },
        { root: true }
      )
      return data
    } catch (error) {
      console.error(error)
      // await dispatch('app/notification', { content: 'N', type: 'error' }, { root: true })
      dispatch('app/addLogWithError', { title: 'HIK_ADD_DEVICE', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async getDeviceChannelsByHIKSDK ({ dispatch, rootState }: ActionContext<State, string>, payload: any) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: '/apis/camera/encoding/channels/get/device',
          method: 'POST',
          data: payload
        },
        { root: true }
      )

      if (data.device.Device.devStatus === 'offline') {
        await dispatch('app/notifyToast', { msj: 'Cámara fuera de linea', type: 'gray' }, { root: true })
        throw Error('Error')
        // dispatch('app/notification', {
        //   content: `El dispositivo ${data.device.Device.devName} se encuentra actualmente apagado`,
        //   type: 'warning',
        //   timeout: 10000
        // }, { root: true })
      }

      const cameras = data.urls.map((cam: any) => ({
        id_camera: `${cam.Channel.id}-${cam.Channel.name}-${payload.EhomeID}`,
        cam_name: cam.Channel.name,
        cam_type: CAMERA_TYPE.HV_RTSP,
        cam_url: cam.url,
        cam_edit: false,
        cam_delete: false,
        cam_data: cam,
        device: data.device.Device,
        fk_camera_properties: 0
      }))

      // @ts-ignore
      const unitConsoleData: UnitConsoleData<unknown, unknown> = rootState.travel.unitsRegisters.find(obj => obj.id === payload.id)
      if (unitConsoleData) unitConsoleData.setCameraChannels(cameras)

      return cameras
    } catch (error) {
      console.error(error)
      dispatch('app/addLogWithError', { title: 'HIK_GET_DEVICE_CHANNELS', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async deleteCamerasByHIKSDK ({ dispatch, rootState }: ActionContext<State, string>, payload: any) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: '/apis/camera/encoding/create/device',
          method: 'DELETE',
          data: payload
        },
        { root: true }
      )

      // @ts-ignore
      const unitConsoleData: UnitConsoleData<unknown, unknown> = rootState.travel.unitsRegisters.find(obj => obj.id === payload.unitconsole_id)
      if (unitConsoleData) unitConsoleData.deleteCameraById()

      return data
    } catch (error) {
      console.error(error)
      dispatch('app/addLogWithError', { title: 'HIK_DELETE_DEVICE', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async getVideoPlayback ({ dispatch }: ActionContext<State, string>, payload: any) {
    try {
      const data = await dispatch(
        'app/axios',
        {
          url: '/apis/camera/video/playback/get',
          method: 'POST',
          data: payload
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('app/addLogWithError', { title: 'GET_VIDEO_PLAYBACK', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async donwloadVideoPlaybackGet ({ dispatch }: ActionContext<State, string>, payload: { playbackURI: string; devIndex: string; startTime: string; endTime: string; channel: number }): Promise<ICeleryTaskResult> {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: '/apis/camera/video/download/task/get',
          method: 'POST',
          data: {
            ...payload
          }
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('app/addLogWithError', { title: 'GET_VIDEO_PLAYBACK_DOWNLOAD_TASK_GET', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async donwloadVideoPlaybackResult ({ dispatch }: ActionContext<State, string>, id: string) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: `/apis/camera/video/download/task/result/${id}`,
          method: 'get',
          responseType: 'blob'
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('app/addLogWithError', { title: 'GET_VIDEO_PLAYBACK_DOWNLOAD_TASK_RESULT', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async getPDFEvents ({ dispatch, rootState }: ActionContext<State, string>, payload: { startTime: string; endTime: string; timezone: string; events: string[]; unitDevices: { id: number; unit_name: string; EhomeID: number }[] }) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: '/apis/camera/hikvision/events/pdf',
          method: 'POST',
          data: {
            ...payload,
            // @ts-ignore
            url_root: `${rootState.app.BASE_URL}/`
          }
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('app/addLogWithError', { title: 'GET_PDF_EVENTS', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async getCamerasOnline ({ dispatch, rootGetters }: ActionContext<State, string>) {
    try {
      const units_cameras = rootGetters['travel/getUnitsWithCameras'] as UnitConsoleData<any, any>[]

      const hikvision_cameras = units_cameras
        .filter((unitcam) => unitcam.cameras && unitcam.cameras.cpf_type === CAMERA_PLATAFORM.HIKVISION)
        .map((unitcam) => ({
          id_unit: unitcam.id,
          cameras: {
            id: unitcam.cameras?.id!,
            EhomeID: unitcam.cameras?.cpt_properties.EhomeID ?? 0
          }
        }))

      const streamax_units = units_cameras
        .filter((unitcam) => unitcam.cameras && unitcam.cameras.cpf_type === CAMERA_PLATAFORM.STREAMAX)
        .map((unitcam) => ({
          id_unit: unitcam.id,
          cameras: {
            id: unitcam.cameras?.id!,
            uniqueId: unitcam.cameras?.cpt_properties.uniqueId!,
            status: false
          }
        }))

      const streamaxUniqueIdsStr = streamax_units
        .filter((device) => device !== null && device.cameras && device.cameras?.uniqueId)
        .map((unit) => unit.cameras.uniqueId!)
        .join(',')

      const streamax_cameras = streamaxUniqueIdsStr === '' ? [] : await trycatchAsync<any[]>(() => dispatch('streamax/loadUnitsStreamax', streamaxUniqueIdsStr, { root: true }), [])

      streamax_units.forEach((unidad) => {
        if (unidad.cameras) {
          const dispositivoEncontrado = streamax_cameras.find((dispositivo: { uniqueId: any }) => dispositivo.uniqueId === unidad.cameras?.uniqueId)
          unidad.cameras.status = dispositivoEncontrado ? dispositivoEncontrado.onlineState !== 'OFFLINE' : false
        }
      })

      const jimiiot_units = units_cameras.filter((unitcam) => unitcam.cameras && unitcam.cameras.cpf_type === CAMERA_PLATAFORM.JIMIIOT)
        .map((unitcam) => ({
          id_unit: unitcam.id,
          cameras: {
            id: unitcam.cameras?.id!,
            imei: unitcam.cameras?.cpt_properties.imei!,
            status: false
          }
        }))

      const jimiot_cameras_status = await trycatchAsync<IJimiIotDVRLastStatus>(() => dispatch('jimiiot/getDevicesStatusByIds', jimiiot_units.map((cam) => cam.cameras.imei), { root: true }), {} as IJimiIotDVRLastStatus)

      jimiiot_units.forEach((cam) => {
        const status = jimiot_cameras_status[cam.cameras.imei]
        if (status) cam.cameras.status = status.online
      })

      const { data: hikvision_units } = await trycatchAsync<{ data: { id_unit: number; cameras: { id: number; ehomeid: number; status: boolean } }[] }>(() => dispatch(
        'app/axios',
        {
          url: '/apis/camera/hikvision/searchdevice/all',
          method: 'POST',
          data: hikvision_cameras
        },
        { root: true }
      ), { data: [] })

      const estatus_cameras = [...hikvision_units, ...streamax_units, ...jimiiot_units]

      let cameras_online = 0
      for (const unit of estatus_cameras) {
        const unitc = units_cameras.find((u: { id: number }) => u.id === unit.id_unit)
        if (!unitc?.cameras) continue

        const camera = unit.cameras
        if (!camera) continue

        if (unitc.cameras.id !== camera.id) continue

        unitc.cameras.status = camera.status
        if (camera.status) cameras_online++
      }

      return cameras_online
    } catch (error) {
      console.error('Error in getCamerasOnline:', error)
      await dispatch('app/notification', { content: 'Error al consultar información', type: 'error' }, { root: true })
      dispatch('app/addLogWithError', { title: 'GET_CAMERAS_ONLINE', color: 'error', message: '', error }, { root: true })
      // throw error
    }
  },
  async getHikResources (_: ActionContext<State, string>, { event, objname, unit }: { objname: string; event: { message: any }; unit: UnitConsoleData<unknown, unknown> }) {
    let resources = []
    // @ts-ignore
    const resourcesHVPictures = await trycatchSync(() => event?.message?.data.EventNotificationAlert[objname].ImageInfo.map((obj: any) => {
      const url = new URL(obj.Image.resourcesContent)
      const id = url.search.substring(1) // Quitamos el primer caracter que es el signo de interrogación
      const data = { name: `${unit.unit_name.replaceAll(' ', '_')}-${objname}-${event?.message?.data.EventNotificationAlert.channelName}.jpeg`, type: RNRESOURCES_TYPES.HV_PICTURE_ID, url: id, icon: 'mdi-camera', tooltip: 'Obtener imagen' } as RNResource
      return data
    }), [])
    resources = [...resourcesHVPictures]

    const resourcesHVVideo = await trycatchSync(() => {
      const obj = event?.message?.data.EventNotificationAlert[objname].VideoInfo
      const url = new URL(obj.Video.resourcesContent)
      const id = url.search.substring(1) // Quitamos el primer caracter que es el signo de interrogación
      return { name: `${unit.unit_name.replaceAll(' ', '_')}-${objname}-${event?.message?.data?.EventNotificationAlert.channelName}.mp4`, type: RNRESOURCES_TYPES.HV_VIDEO_ID, url: id, icon: 'mdi-cctv', tooltip: 'Obtener video' } as RNResource
    }, null)

    if (resourcesHVVideo) resources.push(resourcesHVVideo)
    return resources
  },
  async getPictureById ({ dispatch, state }: ActionContext<State, string>, payload: { picid: string; name: string }) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: `/apis/camera/hikvision/picture/download/get/${payload.picid}`,
          method: 'GET',
          data: payload,
          responseType: 'blob'
        },
        { root: true }
      )
      return data
    } catch (error) {
      console.error(error)
      await dispatch('app/notification', { content: 'Error al traer la imagen', type: 'error' }, { root: true })
      dispatch('app/addLogWithError', { title: 'GET_PDF_EVENTS', color: 'error', message: '', error }, { root: true })
    }
  },
  async getVideoById ({ dispatch }: ActionContext<State, string>, payload: { picid: string; name: string }) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: `apis/camera/hikvision/video/download/get/${payload.picid}`,
          method: 'GET',
          data: payload,
          responseType: 'blob'
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      await dispatch('app/notification', { content: 'Error al traer el video', type: 'error' }, { root: true })
      dispatch('app/addLogWithError', { title: 'GET_VIDEO_BY_ID', color: 'error', message: '', error }, { root: true })
      // throw error
    }
  },
  async createHVUnit ({ dispatch }: ActionContext<State, string>, payload: any) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: '/apis/unit/create/hikvision',
          method: 'POST',
          data: payload
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('app/addLogWithError', { title: 'CREATE_HV_UNIT', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async getHikEventsAnalitics ({ dispatch }: ActionContext<State, string>, payload: { startTime: string; endTime: string; timezone: string; events: string[]; unitDevices: { id: number; unit_name: string; EhomeID: number }[] }) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: '/apis/camera/hikvision/events/analitics',
          method: 'POST',
          data: payload
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('app/addLogWithError', { title: 'GET_HIK_ANALITICS', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async getStreamaxLastData ({ dispatch, rootState }: ActionContext<State, string>) {
    // @ts-ignore
    const units: RIStreamax[] = rootState.travel.unitsRegisters.filter(unit => {
      const hasStreamaxCamera = unit.cameras?.cpf_type === CAMERA_PLATAFORM.STREAMAX
      return hasStreamaxCamera
    })
    const stUniqueIds = [
      ...new Set(
        units.flatMap(unit =>
          unit.cameras
            ? [unit.cameras.cpt_properties?.uniqueId]
            : []
        )
      )
    ]
    let streamaxCam: any = []
    if (stUniqueIds.length > 0) {
      streamaxCam = await StreamaxJSSdk.actions.AUnit.list({ uniqueIds: stUniqueIds.join(',') })
    }
    units.forEach(unit => {
      const camera = unit.haveUniqueIdCamera(stUniqueIds)
      if (!camera) return
      const uniqueId = camera.cpt_properties.uniqueId
      const event = streamaxCam.find((evt: { uniqueId: string }) => evt.uniqueId === uniqueId)
      if (!event) return
      camera.last_conection = new Date(event.time).toLocaleString()
    })
  },
  async getHikEventsLastData ({ dispatch, rootState }: ActionContext<State, string>) {
    try {
      // @ts-ignore
      const units: RIHikvision[] = rootState.travel.unitsRegisters.filter(unit => {
        const hasHikvisionCamera = unit.cameras?.cpf_type === CAMERA_PLATAFORM.HIKVISION
        return hasHikvisionCamera
      })
      const hvEhomeIds = [...new Set(units.map(unit => unit.getHVEHomeIds()).flat())]

      const { data }: { data: HVEventsLastData } = await dispatch(
        'app/axios',
        {
          url: '/apis/camera/hikvision/events/last_data',
          method: 'POST',
          data: {
            count_last_rows: 1,
            hikvision_devices: hvEhomeIds,
            timeoffset: utcOffset()
          }
        },
        { root: true }
      )

      const hvEventsIds = Object.keys(data)
      units.forEach(unit => {
        const camera = unit.haveHVEHomeIDCamera(hvEventsIds)
        if (!camera) {
          return
        }

        const EhomeID = camera.cpt_properties.EhomeID
        const event = data[EhomeID].at(0)
        if (!event) {
          return
        }

        camera.last_conection = addAmPmToDateTime(event.coordinates.date)
        unit.setUnitData({
          last_message: {
            pos: {
              x: event.coordinates.longitude,
              y: event.coordinates.latitude,
              c: event.direction
            }
          },
          currSpeed: event.speed
        })
      })

      return data
    } catch (error) {
      console.error('Error fetching Hikvision last data:', error)
      dispatch('app/addLogWithError', { title: 'GET_HIK_LAST_DATA', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async getResourceItemNotification ({ dispatch }: ActionContext<State, string>, res: RNResource) {
    switch (res.type) {
      case RNRESOURCES_TYPES.HV_PICTURE_ID: {
        const img = await dispatch('cameras/getPictureById', { picid: res.url, name: res.name }, { root: true })
        downloadByBlob(img, `${res.name}-${DateTime.now().toLocal().toString()}.jpeg`)
        break
      }
      case RNRESOURCES_TYPES.HV_VIDEO_ID: {
        const video = await dispatch('cameras/getVideoById', { picid: res.url, name: res.name }, { root: true })
        downloadByBlob(video, `${res.name}-${DateTime.now().toLocal().toString()}.mp4`)
        break
      }
    }
  },
  async setConsumeData ({ dispatch }: ActionContext<State, string>, payload: { id_camera: number, seconds: number, plan_camera: number }) {
    try {
      const plan_camera_find = PLAN_CAMERA_TYPE.find(plan_cam => plan_cam.id === payload.plan_camera)
      const { data } = await dispatch(
        'app/axios',
        {
          url: `apis/camera/consume_data/${payload.id_camera}`,
          method: 'POST',
          data: { ...payload, seconds_plan_camera: plan_camera_find?.seconds }
        },
        { root: true }
      )
      return data
    } catch (error) {
      console.error(error)
      await dispatch('app/notification', { content: 'Error al guardar tiempo usado', type: 'error' }, { root: true })
      dispatch('app/addLogWithError', { title: 'GET_VIDEO_BY_ID', color: 'error', message: '', error }, { root: true })
      return false
      // throw error
    }
  },
  async getDashboard ({ dispatch }: ActionContext<State, string>, payload: any) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: 'apis/camera/dashboard',
          method: 'POST',
          data: payload
        },
        { root: true }
      )
      return data
    } catch (error) {
      console.error(error)
      await dispatch('app/notification', { content: 'Error al consultar datos camara', type: 'error' }, { root: true })
      dispatch('app/addLogWithError', { title: 'GET_DASHBOARD_CAMERA', color: 'error', message: '', error }, { root: true })
      // throw error
    }
  },
  async getCamerasByUser ({ dispatch }: ActionContext<State, string>, id_user: number) {
    try {
      const { data } = await dispatch('app/axios', { url: `apis/camera/get/all?user_id=${id_user}`, method: 'GET' }, { root: true })
      return data
    } catch (error) {
      console.error(error)
      await dispatch('app/notification', { content: 'Error al camaras', type: 'error' }, { root: true })
      dispatch('app/addLogWithError', { title: 'GET_DASHBOARD_CAMERA', color: 'error', message: '', error }, { root: true })
      // throw error
    }
  },
  async getCamerasByAccount ({ dispatch }: ActionContext<State, string>, id_user: number) {
    try {
      const { data } = await dispatch('app/axios', { url: `apis/camera/get/all/account-by?account=${id_user}`, method: 'GET' }, { root: true })
      return data
    } catch (error) {
      console.error(error)
      await dispatch('app/notification', { content: 'Error al camaras', type: 'error' }, { root: true })
      dispatch('app/addLogWithError', { title: 'GET_DASHBOARD_CAMERA', color: 'error', message: '', error }, { root: true })
      // throw error
    }
  },
  async getCamerasEventsPDF ({ dispatch }: ActionContext<State, string>, payload: any) {
    try {
      const { data } = await dispatch('app/axios', {
        url: 'apis/camera/reports/events/pdf',
        method: 'POST',
        data: { events: payload },
        responseType: 'blob'
      },
      { root: true }
      )
      return data
    } catch (error) {
      console.error(error)
      dispatch('app/addLogWithError', { title: 'GET_CAMERA_EVENTS_PDF', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  ...actionsSheduledReport
}

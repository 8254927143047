
import { ActionContext } from 'vuex'
import { State } from './state'
const URL_ACTION = '/apis/facturacion/dashboard/'
const actions = {
  async getDashboard ({ commit, dispatch }: ActionContext<State, any>, payload:any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION,
        method: 'POST',
        data: { ...payload }
      },
      { root: true }
    )
    return data
  }
}
export default actions

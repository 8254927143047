/**
 * Returns an array of positions where the bits are set to 1 in the binary representation of the given number.
 * 
 * @param n The number to analyze its binary representation.
 * @returns An array of positions where the bits are set to 1.
 */
export default function (n: number): number[] {
    const positions = [];
    for (let i = 31; i >= 0; i--) {
      if ((n & (1 << i)) !== 0) {
        positions.push(i);
      }
    }
    return positions;
  }
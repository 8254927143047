import { State } from '../../state'
import { ActionContext } from 'vuex'
import { IProvider } from '../../../../classes/Types/Provider'
const actions = {
  async loadProvider ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/provider/',
        method: 'GET'
      },
      { root: true }
    )
    commit('types/SET_PROVIDERS', data, { root: true })
  },
  async createProvider ({ commit, dispatch }: ActionContext<State, any>, payload:IProvider) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/provider/',
        method: 'POST',
        data: { ...payload }
      },
      { root: true }
    )
    commit('types/PUSH_PROVIDERS', data, { root: true })
  },
  async updateProvider ({ commit, dispatch }: ActionContext<State, any>, payload:IProvider) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/provider/${payload.id}/`,
        method: 'PUT',
        data: { ...payload }
      },
      { root: true }
    )
    commit('types/UPDATE_PROVIDER', data, { root: true })
  },
  async deleteProvider ({ commit, dispatch }: ActionContext<State, any>, payload:any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/provider/${payload.id}/`,
        method: 'DELETE'
      },
      { root: true }
    )
    commit('DELETE_PROVIDER', payload)
  }
}
export default actions

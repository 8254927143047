
import { ActionContext } from 'vuex'
import { State } from './state'
// import { ICustomer } from '@/classes/Facturacion/ICustomer'
const URL_ACTION = '/apis/email/'
const actions = {
  async getHistorySended ({ commit, dispatch, state }: ActionContext<State, any>, payload: { entity_type: '', entity_id: ''}) {
    console.log(payload)
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}history_sended/`,
        method: 'GET',
        params: { ...payload }
      },
      { root: true }
    )
    return data
  }
}
export default actions

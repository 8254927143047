import { ActionConstructor, IAction } from '../interfaces/actions.interface'
// import Base from '../Base'
import { EventEmitter } from 'events'

export default abstract class Action<AType> implements IAction {
    public event: EventEmitter
    public prevData: AType | null
    public lastData: AType | null
    protected CHANGE_EVENT!: string

    constructor (config: ActionConstructor) {
      this.prevData = null
      this.lastData = null
      this.event = new EventEmitter()
      this.setData({ data: config.data })
      this.initActionEvents()
    }

    public reassingData () {
      Object.assign(this, this.lastData)
    }

    public static getChangeEventName (aId: string) {
      return `ACTION_CHANGE_${aId}`
    }

    protected initActionEvents () {
      this.CHANGE_EVENT = `ACTION_CHANGE_${this.getIdStr()}`
    }

    public getIdStr () {
      return ''
    }

    // protected static async AList<ReturnData> (params: any, config: IListConfig): Promise<{ data: ReturnData }> {
    // // @ts-ignore
    //   const { data } = await MaponSDK.config.axios?.get<ReturnData>(`${MaponSDK.config.baseUrl}${config.action}/list.json`, { params: { ...params, key: MaponSDK.config.token } })
    //   return data
    // }

    // protected static async AEdit<ReturnData> (params: any, config: IListConfig): Promise<{ data: ReturnData }> {
    //   // @ts-ignore
    //   const { data } = await MaponSDK.config.axios?.post<ReturnData>(`${MaponSDK.config.baseUrl}${config.action}/edit.json?key=${MaponSDK.config.token}`, serialize(params), {
    //     headers: {
    //       'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
    //       accept: '*/*'
    //     }
    //   })
    //   return data
    // }

    abstract isUpdated(data: any): boolean;

    public setData ({ data }: { data: any }) {
      this.onDataUpdated(this.lastData, data)
      const isUpdated = this.isUpdated(data)
      Object.assign(this, data)
      this.prevData = this.lastData
      this.lastData = data

      return true
    }

    protected onDataUpdated (prevData: AType | null, lastData: AType) {
    // --
    }
}

const cartaPorteRoutes = {
  meta: {
    title: 'Carta porte'
  },
  path: 'carta-porte',
  component: () => import('@/components/Facturacion/modules/carta_porte/CartaPorte.vue'),
  children: [
    {
      meta: {
        title: 'Carta porte'
      },
      path: '',
      name: 'carta-porte',
      component: () => import('@/components/Facturacion/modules/carta_porte/CartaPorteList.vue')
    },
    {
      path: 'nuevo-traslado',
      name: 'nuevo-traslado',
      meta: {
        title: 'Nuevo traslado'
      },
      component: () => import('@/components/Facturacion/modules/carta_porte/CartaPorteForm.vue')
    }
  ]
}

export default cartaPorteRoutes

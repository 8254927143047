/* eslint-disable @typescript-eslint/no-explicit-any */
import { RESOURCES, PLATAFORM, PLATAFORM_RESOURCES } from './RESOURCES'
import { State } from './state'

export default {
  getResources (state: State) {
    return (rType: RESOURCES): any[] => {
      return state[rType]
    }
  },
  getPlataformResources (state: State) {
    return (pType: PLATAFORM, prType: PLATAFORM_RESOURCES): any[] => {
      return state.PLATAFORM[pType][prType]
    }
  }
}

import Fuel from '@/classes/Fuel/Fuel'
import Provider from '@/classes/Types/Provider'
import TypeFuel from '@/classes/Types/Typefuel'

export interface TypeState {
    fuels: Fuel[]
    providers: Provider[]
    typefuels: TypeFuel[]
}

const state = (): TypeState => ({
  fuels: [],
  providers: [],
  typefuels: []
})
export type State = ReturnType<typeof state>;

export default state

export enum CAMERA_PLATAFORM {
  HIKVISION = 1,
  STREAMAX = 2,
  JIMIIOT = 3
}

export const CAMERA_PLATAFORM_ARR = [
  {
    id: CAMERA_PLATAFORM.HIKVISION,
    name: 'HikVision',
    icon: '/images/hikvision_logo.png'
  },
  {
    id: CAMERA_PLATAFORM.STREAMAX,
    name: 'Streamax',
    icon: '/images/streamax.png'
  },
  {
    id: CAMERA_PLATAFORM.JIMIIOT,
    name: 'JimiIot',
    icon: '/images/jimiiot-icon.ico'
  }
]

/* eslint-disable @typescript-eslint/no-empty-function */
import datetimeToDate from '@/utils/datetimeToDate'

export enum TYPE_NOTIFY {
  ADAS = 1,
  DPA = 2
}

export class Event {
  // eslint-disable-next-line no-useless-constructor
  constructor (
    public type: string,
    public name: string,
    public color: string,
    public icon?: string,
    public classification?: TYPE_NOTIFY
  ) {}

  getNotName (event: any): string {
    return ''
  }

  getNotMessage (event: any, report_item: any): string {
    return ''
  }
}
export class DriverEvent extends Event {
  getNotName (event: any): string {
    return `Alerta de ${this.name.toLowerCase()} en canal ${event.data?.EventNotificationAlert?.channelName}`
  }

  getNotMessage ({ event, report_item } : any): string {
    return `Se ha detectado una notificación Alerta de ${this.name.toLowerCase()} en canal ${event.data?.EventNotificationAlert?.channelName}`
  }
}
/* eslint-disable @typescript-eslint/no-unused-vars */
export enum EVENTS_TYPES {
  HIKVISION_DG_EVENTS = 1
}

export const ET_HIKVISION = {
  driverEyeClosed: new DriverEvent('driverEyeClosed', 'Somnolencia', '#f83b8d', 'mdi-sleep', TYPE_NOTIFY.ADAS),
  GPSUpload: new DriverEvent('GPSUpload', 'Evento del GPS', '#bfbfbf', 'mdi-crosshairs-gps'),
  driverInattention: new DriverEvent('driverInattention', 'Conductor distraido', '#3b87f7', 'mdi-account-voice', TYPE_NOTIFY.ADAS),
  driverSmoking: new DriverEvent('driverSmoking', 'Conductor fumando', '#f7df3b', 'mdi-smoking-off', TYPE_NOTIFY.ADAS),
  driverCalling: new DriverEvent('driverCalling', 'Conductor realizando llamada', '#f5942f', 'mdi-phone-lock', TYPE_NOTIFY.ADAS),
  driverBlockCamera: new DriverEvent('driverBlockCamera', 'Camara obstruida por conductor', '#BF360C', 'mdi-wifi-arrow-left-right', TYPE_NOTIFY.ADAS),
  // mobilePeopleCounting: new DriverEvent('mobilePeopleCounting', 'Movimiento de peatones', '#db4040', 'mdi-leak', TYPE_NOTIFY.DPA),
  // FCW: new DriverEvent('FCW', 'Colicion frontal', '#db4040', 'mdi-bus-alert', TYPE_NOTIFY.DPA),
  PCW: new DriverEvent('PCW', 'Eventos de manejo', '#4B77E7', 'mdi-steering', TYPE_NOTIFY.DPA),
  LDW: new DriverEvent('LDW', 'Alerta de advertencia de cambio de carril', '#87bb10', 'mdi-highway', TYPE_NOTIFY.DPA),
  // devStatusChanged: new DriverEvent('devStatusChanged', 'Cambio de estado del dispositivo', '#07d9ad', 'mdi-wifi-arrow-left-right', TYPE_NOTIFY.DPA),
  urgentAcceleration: new DriverEvent('urgentAcceleration', 'Aceleracion brusca', '#EAE727', 'mdi-wifi-arrow-left-right', TYPE_NOTIFY.DPA),
  urgentBrake: new DriverEvent('urgentBrake', 'Frenado brusco', '#AFAC0A', 'mdi-wifi-arrow-left-right', TYPE_NOTIFY.DPA),
  collision: new DriverEvent('collision', 'Posible colision', '#FF0000', 'mdi-wifi-arrow-left-right', TYPE_NOTIFY.DPA)
} as const

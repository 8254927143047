import { IQrCode } from '@/classes/Inventory/interfaces/IQrCode'

export interface QRCodeState {
  qrCodes: Array<IQrCode>;
}

const state = (): QRCodeState => ({
  qrCodes: []
})

export type State = ReturnType<typeof state>

export default state

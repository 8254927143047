import { ActionContext } from 'vuex'
import { State } from '../state'
import { IChatMessage } from '@/store/interfaces/Chat'
import ChatMessage from '@/store/packages/Chat/ChatMessage'
import { Pagination } from '@/store/interfaces/Pagination'
import ChatItem from '@/store/packages/Chat/ChatItem'
import debounce from '@/utils/debounce'

export default {
  showPopupIncomingChat ({ commit }: ActionContext<State, any>, chat: ChatItem) {
    commit('SET_CURR_CHAT_POPUP_STATE', { popup: true, chatItem: chat })
  },
  debounceClosePopupIncomingChat: debounce(function ({ commit }: ActionContext<State, any>) {
    commit('SET_CURR_CHAT_POPUP_STATE', { popup: false, chatItem: null })
  }, 8500, false),
  async createChatMessage ({ dispatch }: ActionContext<State, any>, payload: FormData) {
    try {
      const { data }: { data: IChatMessage } = await dispatch(
        'axios',
        {
          url: 'chat/message/add',
          method: 'POST',
          data: payload
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('app/addLogWithError', { title: 'CREATE_CHAT_MESSAGE', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async updateChatMessage ({ dispatch }: ActionContext<State, any>, payload: Partial<IChatMessage>) {
    try {
      const { data }: { data: IChatMessage } = await dispatch(
        'axios',
        {
          url: 'chat/message/update',
          method: 'PUT',
          data: payload
        }
      )
      return data
    } catch (error) {
      console.error(error)
      dispatch('app/addLogWithError', { title: 'UPDATE_CHAT_MESSAGE', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async deleteChatMessage ({ dispatch }: ActionContext<State, any>, { id_chat, id_chat_message }: { id_chat: number; id_chat_message: number }) {
    try {
      const { data }: { data: IChatMessage } = await dispatch(
        'axios',
        {
          url: `chat/message/delete/${id_chat}/${id_chat_message}`,
          method: 'DELETE'
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('app/addLogWithError', { title: 'DELETE_CHAT_MESSAGE', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async loadOldMessages ({ dispatch }: ActionContext<State, any>, { count, id_chat, id_chat_message }: { id_chat: number; id_chat_message: number; count: number }) {
    try {
      const { data }: { data: IChatMessage[] } = await dispatch(
        'axios',
        {
          url: `chat/message/load/down/${id_chat}/${id_chat_message}/${count}`,
          method: 'GET'
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('app/addLogWithError', { title: 'LOAD_OLD_MESSAGES', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async loadUpMessages ({ dispatch }: ActionContext<State, any>, { count, id_chat, id_chat_message }: { id_chat: number; id_chat_message: number; count: number }) {
    try {
      const { data }: { data: IChatMessage[] } = await dispatch(
        'axios',
        {
          url: `chat/message/load/up/${id_chat}/${id_chat_message}/${count}`,
          method: 'GET'
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('app/addLogWithError', { title: 'LOAD_UP_MESSAGES', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async getMultimediaMessagesDocumentsPaginate ({ dispatch }: ActionContext<State, any>, { id_chat_item, page, items }: { id_chat_item: number; page: number; items: number }): Promise<Pagination<IChatMessage>> {
    try {
      const { data }: { data: Promise<Pagination<IChatMessage>> } = await dispatch(
        'axios',
        {
          url: `chat/message/get/multimedia/documents/${id_chat_item}/${page}/${items}`,
          method: 'GET'
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('app/addLogWithError', { title: 'GET_MULTIMEDIA_MESSAGES_DOCUMENTS', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async getMultimediaMessagesAssetsPaginate ({ dispatch }: ActionContext<State, any>, { id_chat_item, page, items }: { id_chat_item: number; page: number; items: number }): Promise<Pagination<IChatMessage>> {
    try {
      const { data }: { data: Promise<Pagination<IChatMessage>> } = await dispatch(
        'axios',
        {
          url: `chat/message/get/multimedia/assets/${id_chat_item}/${page}/${items}`,
          method: 'GET'
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('app/addLogWithError', { title: 'GET_MULTIMEDIA_MESSAGES_ASSETS', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async getMultimediaMessagesLinksPaginate ({ dispatch }: ActionContext<State, any>, { id_chat_item, page, items }: { id_chat_item: number; page: number; items: number }): Promise<Pagination<IChatMessage>> {
    try {
      const { data }: { data: Promise<Pagination<IChatMessage>> } = await dispatch(
        'axios',
        {
          url: `chat/message/get/multimedia/links/${id_chat_item}/${page}/${items}`,
          method: 'GET'
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('app/addLogWithError', { title: 'GET_MULTIMEDIA_MESSAGES_LINKS', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async resendChatMessages ({ dispatch }: ActionContext<State, any>, payload: { id_chat_item: number; ids_chat_messages: number[] }) {
    try {
      const { data }: { data: { messages: IChatMessage[]; id_chat_item: number } } = await dispatch(
        'axios',
        {
          url: 'chat/message/resend',
          method: 'POST',
          data: payload
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('app/addLogWithError', { title: 'RESEND_CHAT_MESSAGES', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async getChatsMessagesByMessage ({ dispatch }: ActionContext<State, any>, payload: { id_chat_item: number; message: string; page: number; items: number }) {
    try {
      const { data }: { data: IChatMessage[] } = await dispatch(
        'axios',
        {
          url: 'chat/message/get/filter/text',
          method: 'POST',
          data: payload
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('app/addLogWithError', { title: 'GET_CHATS_MESSAGES_BY_MESSAGE', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async getChatsMessagesByIdChatMessage ({ dispatch }: ActionContext<State, any>, payload: { id_chat_item: number; id_chat_message: string; additional_count: number; }) {
    try {
      const { data }: { data: IChatMessage[] } = await dispatch(
        'axios',
        {
          url: `chat/message/load/by_id_chat_message/${payload.id_chat_item}/${payload.id_chat_message}/${payload.additional_count}`,
          method: 'GET',
          data: payload
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('app/addLogWithError', { title: 'GET_CHATS_MESSAGES_BY_ID_CHAT_MESSAGE', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },

  async socket_chatMessageUpdated ({ state }: ActionContext<State, any>, message: IChatMessage) {
    const chat = state.chats[message.id_chat_item]
    if (!chat) return

    const index = chat.chat_messages.findIndex((chatMessage) => chatMessage.id_chat_message === message.id_chat_message)
    if (index === -1) return

    chat.chat_messages[index].rehidratateData(message)
  },
  async onMessageIncoming ({ dispatch, rootGetters, state }: ActionContext<State, any>, { chat, id_chat_user }: { chat: ChatItem; id_chat_user: number }) {
    if (!id_chat_user) return
    // Si el chat esta abierto y hay ultimo mensaje del chat
    if (chat.getIsThisChatFocused() && chat.lastChatMessage) {
      if (!chat.ownChatUser?.chu_silent_chat) {
        dispatch('app/playSound', { type: 'message-low' }, { root: true })
      }
      await dispatch('updateLastUserMessageSeen', {
        id_chat: chat.id_chat,
        id_chat_message: chat.lastChatMessage.id_chat_message,
        id_chat_user,
        id_user: rootGetters['auth/userId']
      })
      chat.updateToLastMessageSeenId()
    } else {
      if (chat.ownChatUser?.chu_silent_chat) return
      dispatch('app/playSound', { type: 'message' }, { root: true })

      if (state.drawer) return
      dispatch('showPopupIncomingChat', chat)
      dispatch('debounceClosePopupIncomingChat')
    }
  },
  async socket_chatMessageAdded ({ state, dispatch }: ActionContext<State, any>, message: IChatMessage) {
    const chat = state.chats[message.id_chat_item]
    if (!chat) return
    chat.addChatMessage(new ChatMessage({ data: message, chatItem: state.chats[message.id_chat_item] }))

    // SI el chat esta focusiado (esta abierto) y se puede obtener el id_chat_user del usuario autenticado, se procede a actualizar
    // El ultimo chat id_chat_message visto por este usuario
    const id_chat_user = chat.getOwnChatUserId()
    if (!id_chat_user) return
    dispatch('onMessageIncoming', { chat, id_chat_user })
  },
  async socket_chatMessagesAdded ({ state, dispatch }: ActionContext<State, any>, payload: { messages: IChatMessage[]; id_chat_item: number }) {
    const chat = state.chats[payload.id_chat_item]
    if (!chat) return
    chat.addChatMessagesByIChatMessages(payload.messages)

    // SI el chat esta focusiado (esta abierto) y se puede obtener el id_chat_user del usuario autenticado, se procede a actualizar
    // El ultimo chat id_chat_message visto por este usuario
    const id_chat_user = chat.getOwnChatUserId()
    if (!id_chat_user) return
    dispatch('onMessageIncoming', { chat, id_chat_user })
  },
  socket_chatMessageDeleted (context: ActionContext<State, any>, data: { id_chat: number; id_chat_message: number }) {
    const chat = context.state.chats[data.id_chat]
    if (!chat) return

    chat.deleteChatMessageById(data.id_chat_message)
  }
}

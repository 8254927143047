import { IMarker } from '../interfaces/Marker'
import { IRoute } from '../interfaces/Route'

const state = () => ({
  $ref: null as any,
  geolocation: null as GeolocationPosition | null,
  unitconsole_id: null as number | null,
  routes: [] as IRoute[],
  markers: [] as IMarker[]
})

export type State = ReturnType<typeof state>;

export default state

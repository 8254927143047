export default function (aditionalFlags?: number): Promise<void> {
  return new Promise((resolve, reject) => {
    // @ts-ignore
    const sess = wialon.core.Session.getInstance()
    // flags to specify what kind of data should be returned
    // @ts-ignore
    const flags = wialon.item.Item.dataFlag.base | (aditionalFlags)
    sess.updateDataFlags( // load items to current session
      [{ type: 'type', data: 'avl_resource', flags: flags, mode: 0 }],
      function (code: string) { // updateDataFlags callback
        if (code) {
          // @ts-ignore
          reject(wialon.core.Errors.getErrorText(code))
        } else {
          const res = sess.getItems('avl_resource')
          if (!res || !res.length) {
            reject(Error('No resources found'))
          } else {
            resolve(res)
          }
        }
      })
  })
}


export interface UserState {
    users: any[]
}

const state = (): UserState => ({
  users: []
})
export type State = ReturnType<typeof state>;

export default state

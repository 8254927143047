import { State } from '../../state'
import { ActionContext } from 'vuex'

const actions = {
  async loadStoreParts ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/store_parts/get/all',
        method: 'GET'
      },
      { root: true }
    )
    commit('SET_STORE_PARTS', data)
    return data
  },
  async loadStoreParts2 ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/store_parts/get/infoPart',
        method: 'GET'
      },
      { root: true }
    )
    commit('SET_STORE_PARTS', data)
    return data
  },
  async importStoreParts ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/store_parts/many',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    return data
  }
}
export default actions

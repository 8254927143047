import { InventoryState } from '../../state'

export default {
  getBatteryInventory (state: InventoryState) {
    return state.battery_inventory.map((inventory) => ({ array: [{ battery: { ...inventory }, id: inventory.id }], ...inventory })).filter(battery => battery.sp_status === 1)
  },
  getBatteryInventoryAvailable (state: InventoryState) {
    return state.battery_inventory.map((inventory) => ({ array: [{ battery: { ...inventory }, id: inventory.id }], ...inventory })).filter((item) => !item.unit && item.sp_status === 1)
  },
  getBatteryInventoryUser (state: InventoryState) {
    return state.battery_inventory.map((inventory) => ({ array: [{ battery: { ...inventory }, id: inventory.id }], ...inventory })).filter((item) => item.unit && item.sp_status === 1)
  }
}

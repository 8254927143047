import { PLATAFORM } from './RESOURCES'

const state = () => ({
  1: [],
  2: [],
  3: [],
  4: [],
  5: [],
  6: [],
  7: [],
  8: [],
  9: [],
  10: [],
  11: [],
  12: [],
  13: [],
  PLATAFORM: {
    [PLATAFORM.WIALON]: {
      1: [],
      2: [],
      3: [],
      4: []
    },
    [PLATAFORM.MAPON]: {
      1: [],
      2: [],
      3: [],
      4: []
    },
    [PLATAFORM.HIKVISION]: {
      1: [],
      2: [],
      3: [],
      4: []
    },
    [PLATAFORM.STREAMAX]: {
      1: [],
      2: [],
      3: [],
      4: []
    }
  }
})

export type State = ReturnType<typeof state>

export default state

import { ICoordenadas } from '@/store/geofences/state'

export default function formatGeofenceMapon (wktPolygon: string): ICoordenadas[] {
  const coordinatesString = wktPolygon.slice(9, -2)
  // Dividir la cadena en una matriz de puntos
  const points = coordinatesString.split(',')
  // Convertir cada punto en una matriz de coordenadas numéricas
  const coordinates = points.map(point => {
    const [latitude, longitude] = point.trim().split(' ')
    return { lat: parseFloat(latitude), lng: parseFloat(longitude) }
  })
  return coordinates
}

export interface DefaultParams {
    /** Streamax sign */
    _sign: string
    /** Streamax tenanid */
    _tenantid: string
    /** Turn off the interval of events */
    disableInterval: boolean
    /** The base url of the streamax api */
    baseURL: string
    /** Time of the interval of events, default 10 seconds in ms */
    intervalTime: number
    /** The debug mode of the sdk */
    debug: boolean
    /** The version of the api */
    apiVersion: string
    /** Especifica si el sdk se consulta desde un proxi || SE RESUELVE PROBLEMAS CON LOS CORS DEL LADO DEL CLIENTE */
    proxyUrl: string | null
}

const defaultParams = (config: Partial<DefaultParams> = {}) => {
  const params: DefaultParams = {
    _sign: '',
    _tenantid: '',
    disableInterval: false,
    baseURL: 'https://america-ftcloud.ifleetvision.com:20501',
    intervalTime: 10000,
    debug: false,
    apiVersion: 'v2',
    proxyUrl: null
  }
  Object.assign(params, config)
  // @ts-ignore
  return params
}

export type IConfig = ReturnType<typeof defaultParams>;

export default defaultParams

<template>
    <div class="d-flex justify-center align-center">
        <span>
            {{ message }}
        </span>
        <span>
            <v-btn @click.stop="btnAction">{{ btnText }}</v-btn>
        </span>
    </div>
</template>

<script>
/* eslint-disable @typescript-eslint/no-empty-function */
export default {
  props: {
    message: {
      type: String,
      default: ''
    },
    btnText: {
      type: String,
      default: ''
    },
    btnAction: {
      type: Function,
      default: () => {}
    }
  }
}
</script>

import { DateTime } from 'luxon'

export default function (date: string) {
  const formatDate = new Date(date)
  console.log(formatDate)
  // const stringDate = `${formatDate.getDay()}/${formatDate.getMonth()}/${formatDate.getFullYear()} - ${formatDate.getHours()}:${formatDate.getMinutes()}`
  const stringDate = `${formatDate.getDate() < 10 ? '0' : ''}${formatDate.getDate()}/${formatDate.getMonth() + 1 < 10 ? '0' : ''}${
    formatDate.getMonth() + 1
  }/${formatDate.getFullYear() < 10 ? '0' : ''}${formatDate.getFullYear()} - ${formatDate.getHours() < 10 ? '0' : ''}${formatDate.getHours()}:${
    formatDate.getMinutes() < 10 ? '0' : ''
  }${formatDate.getMinutes()} hrs`
  return stringDate
}

export function formatDateString (dateString:string) {
  // Parsear la fecha utilizando Luxon
  const date__ = removePlusMinus(dateString)
  console.log(date__)
  const date = DateTime.fromISO(date__, { setZone: true }).toLocal()
  // Formatear la fecha en el formato deseado
  const stringDate = date.toFormat("dd/MM/yyyy - HH:mm 'hrs'")

  return stringDate
}
function removePlusMinus (sequence:string) {
  // Regex para identificar la secuencia `+-`
  const plusMinusRegex = /\+-/
  return sequence.split(plusMinusRegex)[0]
}
export const getCurrentDate = function () {
  const formatDate = new Date()

  const stringDate = `${formatDate.getUTCFullYear() < 10 ? '0' : ''}${formatDate.getUTCFullYear()}-${formatDate.getUTCMonth() + 1 < 10 ? '0' : ''}${
    formatDate.getUTCMonth() + 1
  }-${formatDate.getUTCDate() < 10 ? '0' : ''}${formatDate.getUTCDate()}`

  return stringDate
}

export const convertDate = function (date: string) {
  // Primero, parseamos la fecha original con DateTime
  console.log(date)
  const originalDate = DateTime.fromFormat(date, 'dd/MM/yyyy - HH:mm')

  // Luego, formateamos la fecha original en el formato deseado
  const convertedDate = originalDate.toISO()
  console.log(convertedDate)
  return convertedDate
}

export const getDateLocal = function (date:string) {
  try {
    if (date) { return new Date(date).toLocaleString() }
    return null
  } catch (error) {
    return ''
  }
}

export const getDateLocalDDMMYYYY = function (date:string) {
  if (date) {
    return DateTime.fromISO(date).toFormat('dd.MM.yyyy')
  }
  return ''
}
export const getDateLocalDDMMYYYYhhmmss = function (date:string) {
  if (date) {
    return DateTime.fromISO(date).toFormat('dd.MM.yyyy HH:mm:ss')
  }
  return ''
}

export const getLocalISOTime = function () {
  const today = new Date()
  const offset = today.getTimezoneOffset() * 60000 // Offset en milisegundos
  // @ts-ignore
  const localISOTime = new Date(today - offset).toISOString().split('T')[0]
  return localISOTime
}

export const convertDateToString = function (date: Date) {
  // Primero, parseamos la fecha original con DateTime
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  // Asegúrate de que los valores del mes y el día tengan dos dígitos
  const monthFormatted = month.toString().padStart(2, '0')
  const dayFormatted = day.toString().padStart(2, '0')
  const formattedDate = `${year}-${monthFormatted}-${dayFormatted}`
  return formattedDate
}

export function addAmPmToDateTime (datetimeStr:string) {
  // Dividir la cadena para obtener la parte de la hora
  const [datePart, timePart] = datetimeStr.split(' - ')

  // Obtener la hora, minutos y segundos
  const [hours, minutes, seconds] = timePart.split(':').map(Number)

  // Determinar AM o PM
  const period = hours >= 12 ? 'pm' : 'am'

  // Convertir la hora a formato de 12 horas
  const adjustedHours = hours % 12 || 12 // Convertir 0 a 12 para el formato de 12 horas

  // Crear la nueva cadena con AM/PM
  const newTimePart = `${adjustedHours}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')} ${period}`

  // Combinar de nuevo con la fecha
  return `${datePart} - ${newTimePart}`
}

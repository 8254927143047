// import { GeofencesState, ICGeofence } from './state'
import { WheelState } from '../../state'
import { IWheelModel } from '@/classes/Wheel/interfaces/IWheelModel'

export default {
  SET_WHEEL_MODEL (state: WheelState, entities: IWheelModel[]): void {
    state.wheel_model = entities
  },
  PUSH_WHEEL_MODEL (state: WheelState, entity: IWheelModel): void {
    state.wheel_model.push(entity)
  },
  UPDATE_WHEEL_MODEL (state: WheelState, entity: IWheelModel): void {
    const index = state.wheel_model.findIndex(p => p.id === entity.id)
    if (index !== -1) {
      state.wheel_model.splice(index, 1, entity)
    }
  },
  DELETE_WHEEL_MODEL (state: WheelState, entity: IWheelModel): void {
    const index = state.wheel_model.findIndex(p => p.id === entity.id)
    if (index !== -1) {
      state.wheel_model.splice(index, 1)
    }
  }
}


export interface DriverState {
    drivers: any[]
}

const state = (): DriverState => ({
  drivers: []
})
export type State = ReturnType<typeof state>;

export default state

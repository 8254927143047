import { ActionContext } from 'vuex'
import { serializeError } from 'serialize-error'
import { State } from './state'
import actionsWheelType from './modules/wheel_type/actions'
import actionsWheelSize from './modules/wheel_size/actions'
import actionsWheelMaker from './modules/wheel_maker/actions'
import actionsWheelModel from './modules/wheel_model/actions'
import actionsUnitType from './modules/unit_type/actions'

export default {
  async init ({ commit, dispatch }: ActionContext<State, any>) {
    console.log('NADA')
  },
  ...actionsWheelType,
  ...actionsWheelSize,
  ...actionsWheelMaker,
  ...actionsWheelModel,
  ...actionsUnitType
}

import { State } from '../../state'
import { ActionContext } from 'vuex'
import { IMeasurement } from '@/classes/Inventory/interfaces/IMeasurement'

const actions = {
  async loadMeasurement ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/measurement/get/all',
        method: 'GET'
      },
      { root: true }
    )
    commit('SET_MEASUREMENTS', data)
    return data
  },
  async createMeasurement ({ commit, dispatch }: ActionContext<State, any>, measurement: IMeasurement) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/measurement/create',
        method: 'POST',
        data: measurement
      },
      { root: true }
    )
    commit('PUSH_MEASUREMENT', data)
    return data
  },
  async updateMeasurement ({ commit, dispatch }: ActionContext<State, any>, measurement: IMeasurement) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/measurement/update/${measurement.id}`,
        method: 'PUT',
        data: measurement
      },
      { root: true }
    )
    commit('UPDATE_MEASUREMENT', data)
    return data
  },
  async deleteMeasurement ({ commit, dispatch }: ActionContext<State, any>, measurement: IMeasurement) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/measurement/delete/${measurement.id}`,
        method: 'DELETE'
      },
      { root: true }
    )
    commit('DELETE_MEASUREMENT', measurement)
    return data
  }
}
export default actions

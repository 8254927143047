export enum ADDITIONAL_ALARM_TYPES_ENUM {
    ALERT_FLAG = "256",
    VIDEO_SIGNAL_LOST_ALARM = "257",
    VIDEO_SIGNAL_BLOCKING_ALARM = "258",
    STORAGE_UNIT_FAILURE_ALARM = "259",
    OTHER_VIDEO_EQUIPMENT_FAILURE_ALARM = "260",
    PASSENGER_OVERLOAD_WARNING = "261",
    ABNORMAL_DRIVING_BEHAVIOR_ALARM = "262",
    SPECIAL_ALARM_RECORDING = 263,
    ADAS_ALARM = "264",
    DMS_ALARM = "265",
    CAMERA_FAULT_ALARM = "15",
    SHARP_ACCELERATION = "1024",
    SHARP_DECELERATION = "1025",
    SHARP_TURN = "1026",
    OVERSPEED_ALARM = "1027",
    OVERTIME_FATIGUE_DRIVING_ALARM = "1028",
    DRIVING_COLLISION_ALARM = "1029",
    PARKING_VIBRATION_ALARM = "1030",
    DISPLACEMENT_ALARM = "1031",
    FENCE_ENTRY_ALARM = "1032",
    FENCE_EXIT_ALARM = "1033",
    SLEEP_MODE_EVENT = "1040",
    WORKING_MODE_EVENT = "1041",
    SOS_EMERGENCY_ALARM = "3073",
    EXTERNAL_LOW_POWER_ALARM = "3074",
    ACC_ON_ALARM = "3075",
    ACC_OFF_ALARM = "3076",
    ANTI_THEFT_ALARM = "3077",
    CALIBRATION_ABNORMAL_ALARM = "3078",
    IDENTIFICATION_ALARM = "3079",
    DOOR_TRIGGER_ALARM = "3080",
    ABNORMAL_OIL_ALARM = "3081",
    ABNORMAL_TEMPERATURE_AND_HUMIDITY_ALARM = "3082",
    DLT_CARD_LOGIN_ALARM = "3083",
    DLT_CARD_LOGOUT_ALARM = "3084",
    DLT_NON_REGISTERED_CARD_ALARM = "3085"
}

export const ADDITIONAL_ALARM_TYPES = {
    [ADDITIONAL_ALARM_TYPES_ENUM.ALERT_FLAG]: {
        name: 'ALERT_FLAG',
        type: ADDITIONAL_ALARM_TYPES_ENUM.ALERT_FLAG
    },
    [ADDITIONAL_ALARM_TYPES_ENUM.VIDEO_SIGNAL_LOST_ALARM]: {
        name: 'VIDEO_SIGNAL_LOST_ALARM',
        type: ADDITIONAL_ALARM_TYPES_ENUM.VIDEO_SIGNAL_LOST_ALARM
    },
    [ADDITIONAL_ALARM_TYPES_ENUM.VIDEO_SIGNAL_BLOCKING_ALARM]: {
        name: 'VIDEO_SIGNAL_BLOCKING_ALARM',
        type: ADDITIONAL_ALARM_TYPES_ENUM.VIDEO_SIGNAL_BLOCKING_ALARM
    },
    [ADDITIONAL_ALARM_TYPES_ENUM.STORAGE_UNIT_FAILURE_ALARM]: {
        name: 'STORAGE_UNIT_FAILURE_ALARM',
        type: ADDITIONAL_ALARM_TYPES_ENUM.STORAGE_UNIT_FAILURE_ALARM
    },
    [ADDITIONAL_ALARM_TYPES_ENUM.OTHER_VIDEO_EQUIPMENT_FAILURE_ALARM]: {
        name: 'OTHER_VIDEO_EQUIPMENT_FAILURE_ALARM',
        type: ADDITIONAL_ALARM_TYPES_ENUM.OTHER_VIDEO_EQUIPMENT_FAILURE_ALARM
    },
    [ADDITIONAL_ALARM_TYPES_ENUM.PASSENGER_OVERLOAD_WARNING]: {
        name: 'PASSENGER_OVERLOAD_WARNING',
        type: ADDITIONAL_ALARM_TYPES_ENUM.PASSENGER_OVERLOAD_WARNING
    },
    [ADDITIONAL_ALARM_TYPES_ENUM.ABNORMAL_DRIVING_BEHAVIOR_ALARM]: {
        name: 'ABNORMAL_DRIVING_BEHAVIOR_ALARM',
        type: ADDITIONAL_ALARM_TYPES_ENUM.ABNORMAL_DRIVING_BEHAVIOR_ALARM
    },
    [ADDITIONAL_ALARM_TYPES_ENUM.SPECIAL_ALARM_RECORDING]: {
        name: 'SPECIAL_ALARM_RECORDING',
        type: ADDITIONAL_ALARM_TYPES_ENUM.SPECIAL_ALARM_RECORDING
    },
    [ADDITIONAL_ALARM_TYPES_ENUM.ADAS_ALARM]: {
        name: 'ADAS_ALARM',
        type: ADDITIONAL_ALARM_TYPES_ENUM.ADAS_ALARM
    },
    [ADDITIONAL_ALARM_TYPES_ENUM.DMS_ALARM]: {
        name: 'DMS_ALARM',
        type: ADDITIONAL_ALARM_TYPES_ENUM.DMS_ALARM
    },
    [ADDITIONAL_ALARM_TYPES_ENUM.CAMERA_FAULT_ALARM]: {
        name: 'CAMERA_FAULT_ALARM',
        type: ADDITIONAL_ALARM_TYPES_ENUM.CAMERA_FAULT_ALARM
    },
    [ADDITIONAL_ALARM_TYPES_ENUM.SHARP_ACCELERATION]: {
        name: 'SHARP_ACCELERATION',
        type: ADDITIONAL_ALARM_TYPES_ENUM.SHARP_ACCELERATION
    },
    [ADDITIONAL_ALARM_TYPES_ENUM.SHARP_DECELERATION]: {
        name: 'SHARP_DECELERATION',
        type: ADDITIONAL_ALARM_TYPES_ENUM.SHARP_DECELERATION
    },
    [ADDITIONAL_ALARM_TYPES_ENUM.SHARP_TURN]: {
        name: 'SHARP_TURN',
        type: ADDITIONAL_ALARM_TYPES_ENUM.SHARP_TURN
    },
    [ADDITIONAL_ALARM_TYPES_ENUM.OVERSPEED_ALARM]: {
        name: 'OVERSPEED_ALARM',
        type: ADDITIONAL_ALARM_TYPES_ENUM.OVERSPEED_ALARM
    },
    [ADDITIONAL_ALARM_TYPES_ENUM.OVERTIME_FATIGUE_DRIVING_ALARM]: {
        name: 'OVERTIME_FATIGUE_DRIVING_ALARM',
        type: ADDITIONAL_ALARM_TYPES_ENUM.OVERTIME_FATIGUE_DRIVING_ALARM
    },
    [ADDITIONAL_ALARM_TYPES_ENUM.DRIVING_COLLISION_ALARM]: {
        name: 'DRIVING_COLLISION_ALARM',
        type: ADDITIONAL_ALARM_TYPES_ENUM.DRIVING_COLLISION_ALARM
    },
    [ADDITIONAL_ALARM_TYPES_ENUM.PARKING_VIBRATION_ALARM]: {
        name: 'PARKING_VIBRATION_ALARM',
        type: ADDITIONAL_ALARM_TYPES_ENUM.PARKING_VIBRATION_ALARM
    },
    [ADDITIONAL_ALARM_TYPES_ENUM.DISPLACEMENT_ALARM]: {
        name: 'DISPLACEMENT_ALARM',
        type: ADDITIONAL_ALARM_TYPES_ENUM.DISPLACEMENT_ALARM
    },
    [ADDITIONAL_ALARM_TYPES_ENUM.FENCE_ENTRY_ALARM]: {
        name: 'FENCE_ENTRY_ALARM',
        type: ADDITIONAL_ALARM_TYPES_ENUM.FENCE_ENTRY_ALARM
    },
    [ADDITIONAL_ALARM_TYPES_ENUM.FENCE_EXIT_ALARM]: {
        name: 'FENCE_EXIT_ALARM',
        type: ADDITIONAL_ALARM_TYPES_ENUM.FENCE_EXIT_ALARM
    },
    [ADDITIONAL_ALARM_TYPES_ENUM.SLEEP_MODE_EVENT]: {
        name: 'SLEEP_MODE_EVENT',
        type: ADDITIONAL_ALARM_TYPES_ENUM.SLEEP_MODE_EVENT
    },
    [ADDITIONAL_ALARM_TYPES_ENUM.WORKING_MODE_EVENT]: {
        name: 'WORKING_MODE_EVENT',
        type: ADDITIONAL_ALARM_TYPES_ENUM.WORKING_MODE_EVENT
    },
    [ADDITIONAL_ALARM_TYPES_ENUM.SOS_EMERGENCY_ALARM]: {
        name: 'SOS_EMERGENCY_ALARM',
        type: ADDITIONAL_ALARM_TYPES_ENUM.SOS_EMERGENCY_ALARM
    },
    [ADDITIONAL_ALARM_TYPES_ENUM.EXTERNAL_LOW_POWER_ALARM]: {
        name: 'EXTERNAL_LOW_POWER_ALARM',
        type: ADDITIONAL_ALARM_TYPES_ENUM.EXTERNAL_LOW_POWER_ALARM
    },
    [ADDITIONAL_ALARM_TYPES_ENUM.ACC_ON_ALARM]: {
        name: 'ACC_ON_ALARM',
        type: ADDITIONAL_ALARM_TYPES_ENUM.ACC_ON_ALARM
    },
    [ADDITIONAL_ALARM_TYPES_ENUM.ACC_OFF_ALARM]: {
        name: 'ACC_OFF_ALARM',
        type: ADDITIONAL_ALARM_TYPES_ENUM.ACC_OFF_ALARM
    },
    [ADDITIONAL_ALARM_TYPES_ENUM.ANTI_THEFT_ALARM]: {
        name: 'ANTI_THEFT_ALARM',
        type: ADDITIONAL_ALARM_TYPES_ENUM.ANTI_THEFT_ALARM
    },
    [ADDITIONAL_ALARM_TYPES_ENUM.CALIBRATION_ABNORMAL_ALARM]: {
        name: 'CALIBRATION_ABNORMAL_ALARM',
        type: ADDITIONAL_ALARM_TYPES_ENUM.CALIBRATION_ABNORMAL_ALARM
    },
    [ADDITIONAL_ALARM_TYPES_ENUM.IDENTIFICATION_ALARM]: {
        name: 'IDENTIFICATION_ALARM',
        type: ADDITIONAL_ALARM_TYPES_ENUM.IDENTIFICATION_ALARM
    },
    [ADDITIONAL_ALARM_TYPES_ENUM.DOOR_TRIGGER_ALARM]: {
        name: 'DOOR_TRIGGER_ALARM',
        type: ADDITIONAL_ALARM_TYPES_ENUM.DOOR_TRIGGER_ALARM
    },
    [ADDITIONAL_ALARM_TYPES_ENUM.ABNORMAL_OIL_ALARM]: {
        name: 'ABNORMAL_OIL_ALARM',
        type: ADDITIONAL_ALARM_TYPES_ENUM.ABNORMAL_OIL_ALARM
    },
    [ADDITIONAL_ALARM_TYPES_ENUM.ABNORMAL_TEMPERATURE_AND_HUMIDITY_ALARM]: {
        name: 'ABNORMAL_TEMPERATURE_AND_HUMIDITY_ALARM',
        type: ADDITIONAL_ALARM_TYPES_ENUM.ABNORMAL_TEMPERATURE_AND_HUMIDITY_ALARM
    },
    [ADDITIONAL_ALARM_TYPES_ENUM.DLT_CARD_LOGIN_ALARM]: {
        name: 'DLT_CARD_LOGIN_ALARM',
        type: ADDITIONAL_ALARM_TYPES_ENUM.DLT_CARD_LOGIN_ALARM
    },
    [ADDITIONAL_ALARM_TYPES_ENUM.DLT_CARD_LOGOUT_ALARM]: {
        name: 'DLT_CARD_LOGOUT_ALARM',
        type: ADDITIONAL_ALARM_TYPES_ENUM.DLT_CARD_LOGOUT_ALARM
    },
    [ADDITIONAL_ALARM_TYPES_ENUM.DLT_NON_REGISTERED_CARD_ALARM]: {
        name: 'DLT_NON_REGISTERED_CARD_ALARM',
        type: ADDITIONAL_ALARM_TYPES_ENUM.DLT_NON_REGISTERED_CARD_ALARM
    }
};

import StreamaxJSSdk from '@/submodules/streamax'
// import MaponSDK from '@/submodules/maponjssdk/dist'
import { State } from './state'

export default {
  SET_SDK (state:State, sdk:StreamaxJSSdk) {
    state.sdk = sdk
  },
  SET_UNITS (state:State, units: []) {
    state.units = units
  }
}

import AClient from '@/store/travel/actions/AClient'
import AColor from '@/store/travel/actions/AColor'
import AEvent from '@/store/travel/actions/AEvent'
import AInventory from '@/store/travel/actions/AInventory'
import ATravel from '@/store/travel/actions/ATravel'
import AUnit from '@/store/travel/actions/AUnit'
import AWialon from '@/store/travel/actions/AWialon'
import ADriver from '@/store/travel/actions/ADriver'

const actions = {
  ...AClient,
  ...AColor,
  ...AEvent,
  ...AInventory,
  ...ATravel,
  ...AUnit,
  ...AWialon,
  ...ADriver
}
export default actions

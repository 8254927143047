export enum PARTS_MATERIAL_TYPE {
    SERIALIZADA = 1,
    UNIDAD = 2,
    CONSUMIBLE = 3
  }

export const PARTS_MATERIAL_TYPE_ARRAY = [
  // {
  //   id: 1,
  //   label: 'Serializada (Posee control de cada componente)',
  //   icon: 'mdi-qrcode'
  // },
  {
    id: 2,
    label: 'Unidad (Por cantidad de piezas)',
    icon: 'mdi-cube'
  },
  {
    id: 3,
    label: 'Consumible (Posee unidad de medición)',
    icon: 'mdi-ethernet-cable'
  }
]

import Root from '../Root/Root'

export interface ITicket {
  id: number;
  assignments: any[];
  comments: any[]; // Aquí puedes ajustar el tipo si conoces la estructura de los comentarios
  description: string;
  issue: number;
  area: number;
  assignment_by: number;
  assignment_by_name: string;
  created_by_name: string;
  status: number;
  updated_at: string;
  issue_name: string;
  area_name: string;
  latest_unassigned_assignment: any;
  folio: string;
  created_at: string;
  created_by: number;
}
export default class Ticket extends Root implements ITicket {
  id!: number;
  assignments!: any[];
  comments!: any[];
  description!: string;
  issue!: number;
  area!: number;
  assignment_by!: number;
  assignment_by_name!: string;
  created_by_name!: string;
  status!: number;
  updated_at!: string;
  issue_name!: string;
  area_name!: string;
  latest_unassigned_assignment!: any;
  folio!: string;
  created_at!: string;
  created_by!: number;

  constructor (ticket: ITicket) {
    super()
    Object.assign(this, ticket)
  }
}

/* eslint-disable no-use-before-define */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import store from '@/store'
import ReportItemData from './ReportItemData'
import { UnitConsole } from '@/store/interfaces/ReportModules'
import { trycatch } from '@/utils/trycatch'
import utcOffset from '@/utils/utcOffset'
import { DateTime } from 'luxon'
import { PLATAFORM } from '@/store/resources/RESOURCES'

export default class RIHikvision
  extends ReportItemData<any, any>
  implements UnitConsole {
    public bateryFlag = 'V';
    public keyUnitMessage = ''
    public pType = PLATAFORM.HIKVISION
    public currSpeed = 0

    async getDistanceByTime ({ from, to }: { from: number; to: number }): Promise<number> {
      return await trycatch(async () => {
        const analiticsData = await store.dispatch('cameras/getHikEventsAnalitics', {
          events: [],
          unitDevices: [
            {
              id: this.id,
              unit_name: this.unit_name,
              EhomeID: this.unit_resource_id
            }
          ],
          timezone: DateTime.local().zoneName,
          startTime: DateTime.fromMillis(from).toUTC().toISO(),
          endTime: DateTime.fromMillis(to).toUTC().toISO(),
          timeoffset: utcOffset()
        })

        if (!analiticsData[this.unit_resource_id]) return 0

        return analiticsData[this.unit_resource_id].distance
      }, 0)
    }

    async getIgnitionState (): Promise<number> {
      return 1
    }

    unsusbscribeToUnitMessages (): void {
      //
    }

    speed () {
      return this.currSpeed
    }

    susbscribeToUnitMessages () {
      //
    }

    getName (): string {
      return this.unit_name
    }

    getIcon () {
      return '/images/truck.png'
    }
}

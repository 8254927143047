
export interface FacturaState {
  facturas: any[]
}

const state = (): FacturaState => ({
  facturas: []
})
export type State = ReturnType<typeof state>;

export default state

// import { GeofencesState, ICGeofence } from './state'
import mutationsStore from './modules/store/mutations'
import mutationsStoreParts from './modules/store_parts/mutations'
import mutationsPartsMaterials from './modules/parts_materials/mutations'
import mutationsMeasurement from './modules/measurement/mutations'
import mutationsProviderInventory from './modules/provider_inventory/mutations'
import mutationsEntryConcept from './modules/entry_concept/mutations'
import mutationsEntryType from './modules/entry_type/mutations'
import mutationsPurchaseOrder from './modules/purchase_order/mutations'
import mutationsTireInventory from './modules/tire_inventory/mutations'
import mutationsEntryStore from './modules/entry_store/mutations'
import mutationsRequisition from './modules/requisition/mutations'
import mutationsDepartment from './modules/department/mutations'
import mutationsBatteryInventory from './modules/battery_inventory/mutations'

export default {
  ...mutationsStore,
  ...mutationsStoreParts,
  ...mutationsPartsMaterials,
  ...mutationsMeasurement,
  ...mutationsProviderInventory,
  ...mutationsEntryConcept,
  ...mutationsEntryType,
  ...mutationsPurchaseOrder,
  ...mutationsTireInventory,
  ...mutationsEntryStore,
  ...mutationsRequisition,
  ...mutationsDepartment,
  ...mutationsBatteryInventory
}


export enum ESTATUS_TRAVEL {
    SIN_INICIAR = 1,
    EN_VIAJE = 2,
    CARGANDO_EN = 3,
    DESCARGANDO_EN = 4
}

export const ESTATUS_TRAVEL_ITEMS = [
  {
    id: 1,
    label: 'Sin iniciar',
    icon: 'mdi-truck-remove-outline'
  },
  {
    id: 2,
    label: 'En viaje',
    icon: 'mdi-truck-delivery-outline'
  },
  {
    id: 3,
    label: 'Cargando',
    icon: 'mdi-truck-plus-outline'
  },
  {
    id: 4,
    label: 'Descargando',
    icon: 'mdi-dolly'
  }
]

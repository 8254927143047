import { ActionContext } from 'vuex'
import { serializeError } from 'serialize-error'
import { Socket } from 'socket.io-client'
import state, { GeofencesState, ICGeofence } from './state'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}

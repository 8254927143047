export interface IViewModule {
  viewModule: number;
  viewActive: boolean
}
export interface IMantto {
  view: number;
  talleres: any[];
  conceptos: any[];
  tires: {
    brands: any[],
    sizes: any[]
  }
  batteries: {
    brands: any[],
    models: any[]
  }
  viewActive: boolean,
  id_unit_console_tire_view: number | null,
  viewTires: number,
  viewBattery: IViewModule
  viewFuel: IViewModule
  viewGeofence: IViewModule
  manttos: any[]
}

const state = ():IMantto => ({
  view: 0,
  talleres: [],
  conceptos: [],
  tires: {
    brands: [],
    sizes: []
  },
  batteries: {
    brands: [],
    models: []
  },
  viewActive: true,
  id_unit_console_tire_view: null,
  viewTires: 0,
  viewBattery: { viewModule: 0, viewActive: true },
  viewFuel: { viewModule: 0, viewActive: true },
  viewGeofence: { viewModule: 0, viewActive: true },
  manttos: []
})

export type State = ReturnType<typeof state>;

export default state

import { ActionContext } from 'vuex'
import { serializeError } from 'serialize-error'
import { State } from './state'
import actionsProvider from './modules/provider/actions'
import actionsTypefuel from './modules/typefuel/actions'

export default {
  async init ({ commit, dispatch }: ActionContext<State, any>) {
    dispatch('loadProvider')
    dispatch('loadTypefuel')
  },
  ...actionsProvider,
  ...actionsTypefuel
}

import { IUnitType } from '@/classes/Unit/IUnitType'
import { IWheelMaker } from '@/classes/Wheel/interfaces/IWheelMaker'
import { IWheelModel } from '@/classes/Wheel/interfaces/IWheelModel'
import { IWheelSize } from '@/classes/Wheel/interfaces/IWheelSize'
import { IWheelType } from '@/classes/Wheel/interfaces/IWheelType'

export interface WheelState {
    wheel_type: IWheelType[],
    wheel_size: IWheelSize[],
    wheel_maker: IWheelMaker[],
    wheel_model: IWheelModel[],
    unit_type: IUnitType[]
  }
const state = (): WheelState => ({
  wheel_type: [],
  wheel_size: [],
  wheel_maker: [],
  wheel_model: [],
  unit_type: []
})
export type State = ReturnType<typeof state>;

export default state

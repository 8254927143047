import JimiIothubSDK from '@/submodules/iothubsudsdk/dist/index'
import { ET_JIMIIOT_ADAS, ET_JIMIIOT_DMS, TYPE_NOTIFY, ET_JIMIIOT_STANDARD } from '../catalogs/EVENT_TYPES_JIMIIOT'

const state = {
  sdk: null as null | JimiIothubSDK,
  disabledNotification: true,
  disabledSoundNotification: true,
  allowAll: true,
  notifications_active: {
    [TYPE_NOTIFY.ADAS]: Object.keys(ET_JIMIIOT_ADAS).reduce((acc, key) => {
      acc[key] = true
      return acc
    }, {} as Record<string, boolean>),
    [TYPE_NOTIFY.DMS]: Object.keys(ET_JIMIIOT_DMS).reduce((acc, key) => {
      acc[key] = true
      return acc
    }, {} as Record<string, boolean>),
    [TYPE_NOTIFY.STANDARD]: Object.keys(ET_JIMIIOT_STANDARD).reduce((acc, key) => {
      acc[key] = true
      return acc
    }, {} as Record<string, boolean>)
  } as Record<string, Record<string, boolean>>,
  lastDevicesStandarValues: {} as Record<string, number>,
  currSubscribedId: ''
}

export type State = typeof state

export default state

// import { InventoryState } from '../../state'

import { SatState } from './state'

// import { ProductState } from './state'

export default {
  getEstados (state: SatState) {
    return state.ubication.estados
  },
  getRemolques (state: SatState) {
    return state.transport.remolque
  }
}


// import { InventoryState } from '../../state'
import { AccountState } from './state'

export default {
  SET_ALL (state: AccountState, entities: any[]): void {
    state.accounts = entities
  },
  PUSH (state: AccountState, entitie: any): void {
    state.accounts.unshift(entitie)
  },
  UPDATE (state: AccountState, entitie: any): void {
    const index = state.accounts.findIndex(p => p.id === entitie.id)
    if (index !== -1) {
      state.accounts.splice(index, 1, entitie)
    }
  }
}

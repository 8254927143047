import { IBatteryInventory } from '@/classes/Inventory/interfaces/IBatteryInventory'
import { IDepartment } from '@/classes/Inventory/interfaces/IDepartment'
import { IEntryConcept } from '@/classes/Inventory/interfaces/IEntryConcept'
import { IEntryStore } from '@/classes/Inventory/interfaces/IEntryStore'
import { IEntryType } from '@/classes/Inventory/interfaces/IEntryType'
import { IMeasurement } from '@/classes/Inventory/interfaces/IMeasurement'
import { IPartsMaterials } from '@/classes/Inventory/interfaces/IPartsMaterials'
import { IProviderInventory } from '@/classes/Inventory/interfaces/IProviderInventory'
import { IPurchaseOrder } from '@/classes/Inventory/interfaces/IPurchaseOrder'
import { IRequisition } from '@/classes/Inventory/interfaces/IRequisition'
import { IStore } from '@/classes/Inventory/interfaces/IStore'
import { IStoreParts } from '@/classes/Inventory/interfaces/IStoreParts'
import { ITireInventory } from '@/classes/Inventory/interfaces/ITireInventory'

export interface InventoryState {
    stores: IStore[]
    store_parts: IStoreParts[]
    parts_materials: IPartsMaterials[],
    measurements: IMeasurement[],
    provider_inventory: IProviderInventory[],
    entry_concept: IEntryConcept[],
    entry_type: IEntryType[]
    purchase_order: IPurchaseOrder[],
    requisition: IRequisition[],
    deparment: IDepartment[],
    entry_store: IEntryStore[]
    tire_inventory: ITireInventory[]
    battery_inventory: IBatteryInventory[]
    inventories: any[]
    total:any[]
  }

const state = (): InventoryState => ({
  stores: [],
  store_parts: [],
  parts_materials: [],
  measurements: [],
  provider_inventory: [],
  entry_concept: [],
  entry_type: [],
  deparment: [],
  purchase_order: [],
  requisition: [],
  entry_store: [],
  tire_inventory: [],
  battery_inventory: [],
  inventories: [],
  total: []
})
export type State = ReturnType<typeof state>;

export default state

import Root from '../../Root/Root'

export interface IQrCode {
  id: number;
  code: string;
  in_use: boolean;
}
export default class QRCode extends Root implements IQrCode {
  id!: number;
  code!: string;
  in_use!: boolean;

  constructor (qrCode: IQrCode) {
    super()
    Object.assign(this, qrCode)
  }
}

import MaponJSSDK from '@/submodules/maponjssdk/dist'

export interface IMapon {
  view: number;
  sdk?: MaponJSSDK | undefined
}
const state = ():IMapon => ({
  view: 0,
  sdk: undefined
})

export type State = ReturnType<typeof state>;

export default state

import { Canceler, CancelToken } from 'axios'
import { IAuth } from './auth.interface'

export interface IService {
  id: number;
  name: string;
  permissions: []
  group_order: number
  group: string
  parent_service: number
}
export interface IUserEdit {
  cancel: Canceler | null ;
  units: []
  loaded: boolean
}
export interface IAdministration {
  view: number
  users: [];
  services: IService[];
  userEdit: IUserEdit;
}

const state = ():IAdministration => ({
  view: 0,
  users: [],
  services: [],
  userEdit: { cancel: null, units: [], loaded: false }
})

export type State = ReturnType<typeof state>

export default state

import { ActionContext } from 'vuex'
import { State } from './state'
import AssetMaintenance, { IAssetMaintenance } from '@/classes/Inventory/interfaces/IAsset_Maintenance'

export default {
  async init ({ dispatch }: ActionContext<State, string>) {
    dispatch('loadAssetMaintenance')
    dispatch('loadAssetMaintenanceParts')
  },
  async loadAssetMaintenanceParts ({ commit, dispatch }: ActionContext<State, any>) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: '/apis/asset_maintenance/get/serialized/maintenances',
          method: 'GET'
        },
        { root: true }
      )
      commit('SET_ASSET_MAINTENANCE_PARTS', data)
      return data
    } catch (error) {
      console.error('Error loading asset maintenances parts:', error)
      dispatch('app/addLogWithError', {
        title: 'Error loading asset maintenances parts',
        color: 'error',
        message: '',
        error
      }, { root: true })
      throw error
    }
  },
  async loadAssetMaintenance ({ commit, dispatch }: ActionContext<State, any>) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: '/apis/asset_maintenance/get/all',
          method: 'GET'
        },
        { root: true }
      )
      const assetMaintenances = data.map((maintenance: IAssetMaintenance) => new AssetMaintenance(maintenance))
      commit('SET_ASSET_MAINTENANCES', assetMaintenances)
      return assetMaintenances
    } catch (error) {
      console.error('Error loading asset maintenances:', error)
      dispatch('app/addLogWithError', {
        title: 'Error loading asset maintenances',
        color: 'error',
        message: '',
        error
      }, { root: true })
      throw error
    }
  },
  async createAssetMaintenance ({ commit, dispatch }: ActionContext<State, any>, assetMaintenance: IAssetMaintenance) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/asset_maintenance/create',
        method: 'POST',
        data: assetMaintenance
      },
      { root: true }
    )
    commit('PUSH_ASSET_MAINTENANCES', data)
    return data
  },
  async updateAssetMaintenance ({ commit, dispatch }: ActionContext<State, any>, assetMaintenance: IAssetMaintenance) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/asset_maintenance/update/${assetMaintenance.id}`,
        method: 'PUT',
        data: assetMaintenance
      },
      { root: true }
    )
    commit('UPDATE_ASSET_MAINTENANCE', data)
    return data
  },
  async deleteAssetMaintenance ({ commit, dispatch }: ActionContext<State, any>, assetMaintenance: IAssetMaintenance) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/asset_maintenance/delete/${assetMaintenance.id}`,
        method: 'DELETE'
      },
      { root: true }
    )
    commit('DELETE_ASSET_MAINTENANCE', assetMaintenance.id)
    return data
  },
  async loadStoreParts2 ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/asset_maintenance/get/serializedQr',
        method: 'GET'
      },
      { root: true }
    )
    return data
  }
}

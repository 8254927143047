
// import { InventoryState } from '../../state'
import { TollState } from './state'

export default {
  SET_ALL (state: TollState, entities: any[]): void {
    state.tolls = entities
  },
  PUSH (state: TollState, entitie: any): void {
    state.tolls.unshift(entitie)
  },
  PUSH_ITEMS (state: TollState, entitie: []): void {
    state.tolls.unshift(...entitie)
  },
  UPDATE (state: TollState, entitie: any): void {
    const index = state.tolls.findIndex(p => p.id === entitie.id)
    if (index !== -1) {
      state.tolls.splice(index, 1, entitie)
    }
  }
}

// import { GeofencesState, ICGeofence } from './state'
import { TypeState } from '../../state'
import Provider from '../../../../classes/Types/Provider'

export default {
  SET_PROVIDERS (state: TypeState, providers: Provider[]): void {
    state.providers = providers.map(provider => new Provider(provider))
  },
  PUSH_PROVIDERS (state: TypeState, provider: Provider): void {
    state.providers.push(new Provider(provider))
  },
  UPDATE_PROVIDER (state: TypeState, provider: Provider): void {
    const index = state.providers.findIndex(p => p.id === provider.id)
    if (index !== -1) {
      state.providers.splice(index, 1, new Provider(provider))
    }
  },
  DELETE_PROVIDER (state: TypeState, provider: Provider) {
    state.providers = state.providers.filter(item => item.id !== provider.id)
  }
}

/* eslint-disable @typescript-eslint/no-empty-function */
import datetimeToDate from '@/utils/datetimeToDate'

export enum TYPE_NOTIFY {
  ADAS = 1,
  DPA = 2
}

export class Event {
  // eslint-disable-next-line no-useless-constructor
  constructor (
    public type: string,
    public name: string,
    public color: string,
    public code: number,
    public icon?: string
    // public classification?: TYPE_NOTIFY
  ) {
  }

  getNotName (event: any): string {
    return ''
  }

  getNotMessage (event: any, report_item: any): string {
    return ''
  }
}
export class DriverEvent extends Event {
  getNotName (): string {
    return `Alerta de ${this.name.toLowerCase()}`
  }

  getNotMessage (): string {
    return `Se ha detectado una notificación Alerta de ${this.name.toLowerCase()}`
  }
}
/* eslint-disable @typescript-eslint/no-unused-vars */
export enum EVENTS_TYPES {
  HIKVISION_DG_EVENTS = 1
}

export const ET_STREAMAX = {
  videoLossAlarm: new DriverEvent('videoLossAlarm', 'Alarma de pérdida de video', '#color', 0, 'mdi-video-off-outline'),
  videoBlockAlarm: new DriverEvent('videoBlockAlarm', 'Alarma de bloqueo de video', '#color', 1, 'mdi-cctv-off'),
  motionDetectionAlarm: new DriverEvent('motionDetectionAlarm', 'Alarma de detección de movimiento', '#color', 2, 'mdi-leak'),
  memoryAbnormalityAlarm: new DriverEvent('memoryAbnormalityAlarm', 'Alarma de anormalidad de memoria', '#color', 3, 'mdi-alert-circle-outline'),
  emergencyAlarm: new DriverEvent('emergencyAlarm', 'Alarma de emergencia', '#color', 7, 'mdi-lightbulb-alert'),
  speedingAlarm: new DriverEvent('speedingAlarm', 'Alarma de exceso de velocidad', '#color', 8, 'mdi-truck-fast'),
  lowVoltageAlarm: new DriverEvent('lowVoltageAlarm', 'Alarma de bajo voltaje', '#color', 9, 'mdi-battery-off-outline'),
  peripheralOffline: new DriverEvent('peripheralOffline', 'Periférico desconectado', '#color', 19, 'mdi-car-off'),
  illegalShutdownAlarm: new DriverEvent('illegalShutdownAlarm', 'Alarma de apagado ilegal', '#color', 38, 'mdi-car-off'),
  forgottenAlarm: new DriverEvent('forgottenAlarm', 'Alarma de olvido', '#color', 44, 'mdi-alarm-light'),
  antiForgettingInfraredMovementDetectionAlarm: new DriverEvent('antiForgettingInfraredMovementDetectionAlarm', 'Alarma de detección de movimiento por infrarrojos antiolvido', '#color', 70, 'mdi-alarm-light-outline'),
  gpsLossAlarm: new DriverEvent('gpsLossAlarm', 'Alarma de pérdida de GPS', '#color', 105, 'mdi-signal-off'),
  radarAlarm: new DriverEvent('radarAlarm', 'Alarma de radar', '#color', 170, 'mdi-broadcast'),
  frontCloseBlindSpotAlarm: new DriverEvent('frontCloseBlindSpotAlarm', 'Alarma de punto ciego frontal cercano', '#color', 6005, 'mdi-eye-off'),
  rapidAcceleration: new DriverEvent('rapidAcceleration', 'Aceleración rápida', '#color', 18006, 'mdi-truck-fast'),
  rapidDeceleration: new DriverEvent('rapidDeceleration', 'Desaceleración rápida', '#color', 18007, 'mdi-car-clock'),
  sharpLeftTurn: new DriverEvent('sharpLeftTurn', 'Giro brusco a la izquierda', '#color', 18010, 'mdi-arrow-left-top'),
  sharpRightTurn: new DriverEvent('sharpRightTurn', 'Giro brusco a la derecha', '#color', 18011, 'mdi-arrow-right-top'),
  shock: new DriverEvent('shock', 'Impacto', '#color', 18015, 'mdi-car-off'),
  io1Alarm: new DriverEvent('io1Alarm', 'Alarma IO1', '#color', 40001, 'mdi-alarm-light-outline'),
  io2Alarm: new DriverEvent('io2Alarm', 'Alarma IO2', '#color', 40002, 'mdi-alarm-light-outline'),
  io3Alarm: new DriverEvent('io3Alarm', 'Alarma IO3', '#color', 40003, 'mdi-alarm-light-outline'),
  io4Alarm: new DriverEvent('io4Alarm', 'Alarma IO4', '#color', 40004, 'mdi-alarm-light-outline'),
  io5Alarm: new DriverEvent('io5Alarm', 'Alarma IO5', '#color', 40005, 'mdi-alarm-light-outline'),
  io6Alarm: new DriverEvent('io6Alarm', 'Alarma IO6', '#color', 40006, 'mdi-alarm-light-outline'),
  io7Alarm: new DriverEvent('io7Alarm', 'Alarma IO7', '#color', 40007, 'mdi-alarm-light-outline'),
  io8Alarm: new DriverEvent('io8Alarm', 'Alarma IO8', '#color', 40008, 'mdi-alarm-light-outline'),
  driverFatigue: new DriverEvent('driverFatigue', 'Fatiga del conductor', '#color', 56000, 'mdi-emoticon-confused'),
  noDriver: new DriverEvent('noDriver', 'Sin conductor', '#color', 56001, 'mdi-emoticon-dead'),
  driverMakingPhoneCalls: new DriverEvent('driverMakingPhoneCalls', 'Conductor realizando llamadas', '#color', 56002, 'mdi-phone-lock'),
  driverSmoking: new DriverEvent('driverSmoking', 'Conductor fumando', '#color', 56003, 'mdi-smoking-off'),
  driverDistraction: new DriverEvent('driverDistraction', 'Distracción del conductor', '#color', 56004, 'mdi-account-voice'),
  laneDeparture: new DriverEvent('laneDeparture', 'Salida de carril', '#color', 56005, 'mdi-highway'),
  frontCollision: new DriverEvent('frontCollision', 'Colisión frontal', '#color', 56006, 'mdi-car-arrow-left'),
  speedLimitSignAlarm: new DriverEvent('speedLimitSignAlarm', 'Alarma de señal de límite de velocidad', '#color', 56007, 'mdi-car-speed-limiter'),
  tailgating: new DriverEvent('tailgating', 'Conducción agresiva por seguimiento cercano', '#color', 56009, 'mdi-car-multiple'),
  yawning: new DriverEvent('yawning', 'Bostezo', '#color', 56010, 'mdi-sleep'),
  pedestrianCollision: new DriverEvent('pedestrianCollision', 'Colisión con peatón', '#color', 56011, 'mdi-emoticon-dead'),
  unfastenedSeatBelt: new DriverEvent('unfastenedSeatBelt', 'Cinturón de seguridad desabrochado', '#color', 56016, 'mdi-seatbelt'),
  intersectionSpeedLimitAlarm: new DriverEvent('intersectionSpeedLimitAlarm', 'Alarma de límite de velocidad en intersección', '#color', 56025, 'mdi-alert-circle-outline'),
  stopSignAlarm: new DriverEvent('stopSignAlarm', 'Alarma de señal de STOP', '#color', 56031, 'mdi-alert-octagon'),
  blindSpotDetectionRight: new DriverEvent('blindSpotDetectionRight', 'Detección de punto ciego derecho', '#color', 56018, 'mdi-rotate-right-variant'),
  infraredBlockingGlass: new DriverEvent('infraredBlockingGlass', 'Vidrio bloqueador de infrarrojos', '#color', 56022, 'mdi-safety-goggles'),
  blindSpotDetectionLeft: new DriverEvent('blindSpotDetectionLeft', 'Detección de punto ciego izquierdo', '#color', 56023, 'mdi-rotate-left-variant'),
  abnormalDrivingAlarm: new DriverEvent('abnormalDrivingAlarm', 'Alarma de conducción anormal', '#color', 210000, 'mdi-bell-alert'),
  unknownDriverAlarm: new DriverEvent('unknownDriverAlarm', 'Alarma de conductor desconocido', '#color', 210001, 'mdi-head-alert')

} as const

import { InventoryState } from '../../state'

export default {
  getTireInventory (state: InventoryState) {
    return state.tire_inventory.map((inventory) => ({ array: [{ wheel: { ...inventory }, id: inventory.id }], ...inventory })).filter(tire => tire.sp_status === 1)
  },
  getTireInventoryAvailable (state: InventoryState) {
    return state.tire_inventory.map((inventory) => ({ array: [{ wheel: { ...inventory }, id: inventory.id }], ...inventory })).filter((item) => !item.unit && item.sp_status === 1)
  },
  getTireInventoryUser (state: InventoryState) {
    return state.tire_inventory.map((inventory) => ({ array: [{ wheel: { ...inventory }, id: inventory.id }], ...inventory })).filter((item) => item.unit && item.sp_status === 1)
  }
}

import { ActionContext } from 'vuex'
import { State } from '../state'
import SheduledReport, { ISheduledReport } from '../../../classes/Unit/SheduledReport'

export default {
  async createSheduledReport ({ dispatch, rootGetters, commit }: ActionContext<State, string>, sheduled_report: ISheduledReport) {
    try {
    //   const units_cameras = rootGetters['travel/getUnitsWithCameras']
    //   const ehomes_id = units_cameras.map((unitcam: { id: any; cameras: any[] }) => ({ id_unit: unitcam.id, cameras: unitcam.cameras.map((cam: { id: any; cpt_properties: { EhomeID: any } }) => ({ id: cam.id, EhomeID: cam.cpt_properties.EhomeID })) }))
      const { data } = await dispatch(
        'app/axios',
        {
          url: '/apis/camera/sheduled_report/create',
          method: 'POST',
          data: sheduled_report
        },
        { root: true }
      )
      commit('PUSH_SHEDULED_REPORT', new SheduledReport(data))
      console.log(data)
    } catch (error) {
      console.error(error)
      await dispatch('app/notification', { content: 'Error al guardar', type: 'error' }, { root: true })
      dispatch('app/addLogWithError', { title: 'POST_SHEDULE_REPORT', color: 'error', message: '', error }, { root: true })
      // throw error
    }
  },
  async getSheduledReports ({ dispatch, rootGetters, commit }: ActionContext<State, string>) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: '/apis/camera/sheduled_report/get/all',
          method: 'GET'
        },
        { root: true }
      )
      const shedule_reports = data.map((shedule_report: ISheduledReport) => new SheduledReport(shedule_report))
      commit('SET_SHEDULED_REPORTS', shedule_reports)
      console.log(data)
    } catch (error) {
      console.error(error)
      await dispatch('app/notification', { content: 'Error al consultar tareas programadas', type: 'error' }, { root: true })
      dispatch('app/addLogWithError', { title: 'POST_SHEDULE_REPORT', color: 'error', message: '', error }, { root: true })
    }
  },
  async deleteSheduledReport ({ dispatch, rootGetters, commit }: ActionContext<State, string>, sheduled_report: ISheduledReport) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: `/apis/camera/sheduled_report/delete/${sheduled_report.id}`,
          method: 'DELETE'
        },
        { root: true }
      )
      commit('DELETE_SHEDULED_REPORT', sheduled_report.id)
      console.log(data)
    } catch (error) {
      console.error(error)
      await dispatch('app/notification', { content: 'Error al eliminar', type: 'error' }, { root: true })
      dispatch('app/addLogWithError', { title: 'POST_SHEDULE_REPORT', color: 'error', message: '', error }, { root: true })
      // throw error
    }
  },
  async updateSheduledReport ({ dispatch, rootGetters, commit }: ActionContext<State, string>, sheduled_report: ISheduledReport) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: `/apis/camera/sheduled_report/update/${sheduled_report.id}`,
          method: 'PUT',
          data: sheduled_report
        },
        { root: true }
      )
      commit('UPDATE_SHEDULED_REPORT', sheduled_report)
      console.log(data)
    } catch (error) {
      console.error(error)
      await dispatch('app/notification', { content: 'Error al actualizar', type: 'error' }, { root: true })
      dispatch('app/addLogWithError', { title: 'POST_SHEDULE_REPORT', color: 'error', message: '', error }, { root: true })
      // throw error
    }
  }
}

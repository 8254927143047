// import { GeofencesState, ICGeofence } from './state'
import mutationsWheelType from './modules/wheel_type/mutations'
import mutationsWheelSize from './modules/wheel_size/mutations'
import mutationsWheelMaker from './modules/wheel_maker/mutations'
import mutationsWheelModel from './modules/wheel_model/mutations'
import mutationsUnitType from './modules/unit_type/mutations'

export default {
  ...mutationsWheelType,
  ...mutationsWheelSize,
  ...mutationsWheelMaker,
  ...mutationsWheelModel,
  ...mutationsUnitType
}

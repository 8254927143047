import { State } from '../../state'
import { ActionContext } from 'vuex'
import { IRequisition } from '../../../../classes/Inventory/interfaces/IRequisition'
import { IDepartment } from '@/classes/Inventory/interfaces/IDepartment'
const actions = {
  async loadDepartment ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/department/get/all',
        method: 'GET'
      },
      { root: true }
    )
    commit('SET_DEPARTMENT', data)
    return data
  },
  async createDepartment ({ commit, dispatch }: ActionContext<State, any>, payload: IDepartment) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/department/create',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    commit('PUSH_DEPARTMENT', data)
    return data
  },
  async updateDepartment ({ commit, dispatch }: ActionContext<State, any>, payload: IDepartment) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/department/update/${payload.id}`,
        method: 'PUT',
        data: payload
      },
      { root: true }
    )
    commit('UPDATE_DEPARTMENT', data)
    return data
  },
  async deletePurchaseOrder ({ commit, dispatch }: ActionContext<State, any>, payload: IRequisition) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/purchase_order/delete/${payload.id}`,
        method: 'DELETE'
      },
      { root: true }
    )
    commit('DELETE_PURCHASE_ORDER', payload)
    return data
  },
  async getPDFPurchaseOrder ({ commit, dispatch }: ActionContext<State, any>, id_purchase_order: number) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/purchase_order/get/pdf/${id_purchase_order}`,
        method: 'GET'
      },
      { root: true }
    )
    // commit('SET_PURCHASE_ORDER', data)
    return data
  }
}
export default actions

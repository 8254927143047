<template>
  <div class="custom-notification">
    <div class="icon">
      <i class="fas fa-check-circle"></i> <!-- You can replace this with a different icon if needed -->
    </div>
    <div class="content">
      <div class="title">{{ title }}</div>
      <div class="message">{{ message }}</div>
      <div class="date">{{ date }}</div>
    </div>
    <button @click="close" class="close-btn">&times;</button>
  </div>
</template>

<script>
export default {
  props: ['title', 'message', 'date', 'close']
}
</script>

<style scoped>
.custom-notification {
  display: flex;
  align-items: center;
  background-color: #e6f4ea;
  border: 1px solid #c1e2d0;
  padding: 10px;
  border-radius: 5px;
  font-family: 'YourPreferredFont', sans-serif; /* Replace with your preferred font */
}

.icon {
  color: #00a86b;
  margin-right: 10px;
  font-size: 1.5em;
}

.content {
  flex-grow: 1;
}

.title {
  font-weight: bold;
}

.message {
  margin-top: 5px;
  font-size: 0.9em;
}

.date {
  font-size: 0.8em;
  color: #666;
}

.close-btn {
  background: transparent;
  border: none;
  font-size: 1.2em;
  cursor: pointer;
  color: #666;
}
</style>

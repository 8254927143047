export enum STANDAR_ALARM_FIELDS_ENUM {
    EMERGENCY_ALARM = 0, 
    OVERSPEED_ALARM = 1,
    FATIGUE_DRIVING = 2,
    DANGER_WARNING = 3,
    GNSS_MODULE_FAILURE = 4,
    GNSS_ANTENNA_NOT_CONNECTED = 5,
    GNSS_ANTENNA_SHORT_CIRCUIT = 6,
    EXTERNAL_POWER_LOW = 7,
    EXTERNAL_POWER_REMOVED = 8,
    TERMINAL_LCD_FAILURE = 9,
    TTS_MODULE_FAILURE = 10,
    CAMERA_FAILURE = 11,
    IC_CARD_MODULE_FAULTY = 12,
    OVERSPEED_WARNING = 13,
    FATIGUE_DRIVING_WARNING = 14,
    CUMULATIVE_DRIVING_TIMEOUT = 18,
    OVERTIME_PARKING = 19,
    IN_AND_OUT_OF_AREA = 20,
    IN_AND_OUT_OF_ROUTE = 21,
    TOO_LONG_DRIVING_TIME = 22,
    ROUTE_DEVIATION_ALARM = 23,
    VEHICLE_VSS_FAILURE = 24,
    VEHICLE_OIL_LEVEL_ABNORMAL = 25,
    VEHICLE_STOLEN = 26,
    ILLEGAL_IGNITION = 27,
    ILLEGAL_DISPLACEMENT = 28,
    COLLISION_WARNING = 29,
    ROLLOVER_WARNING = 30,
    ILLEGAL_DOOR_OPENING_ALARM = 31,
}

export const STANDAR_ALARM_FIELDS_MAP = {
    [STANDAR_ALARM_FIELDS_ENUM.EMERGENCY_ALARM]: {
        name: 'EMERGENCY_ALARM',
        description: 'Cleared after receiving response',
        bit_value: STANDAR_ALARM_FIELDS_ENUM.EMERGENCY_ALARM
    },
    [STANDAR_ALARM_FIELDS_ENUM.OVERSPEED_ALARM]: {
        name: 'OVERSPEED_ALARM',
        description: 'Maintain the flag until the alarm condition is cleared',
        bit_value: STANDAR_ALARM_FIELDS_ENUM.OVERSPEED_ALARM
    },
    [STANDAR_ALARM_FIELDS_ENUM.FATIGUE_DRIVING]: {
        name: 'FATIGUE_DRIVING',
        description: 'Maintain the flag until the alarm condition is cleared',
        bit_value: STANDAR_ALARM_FIELDS_ENUM.FATIGUE_DRIVING
    },
    [STANDAR_ALARM_FIELDS_ENUM.DANGER_WARNING]: {
        name: 'DANGER_WARNING',
        description: 'Cleared after receiving response',
        bit_value: STANDAR_ALARM_FIELDS_ENUM.DANGER_WARNING
    },
    [STANDAR_ALARM_FIELDS_ENUM.GNSS_MODULE_FAILURE]: {
        name: 'GNSS_MODULE_FAILURE',
        description: 'Maintain the flag until the alarm condition is cleared',
        bit_value: STANDAR_ALARM_FIELDS_ENUM.GNSS_MODULE_FAILURE
    },
    [STANDAR_ALARM_FIELDS_ENUM.GNSS_ANTENNA_NOT_CONNECTED]: {
        name: 'GNSS_ANTENNA_NOT_CONNECTED',
        description: 'Maintain the flag until the alarm condition is cleared',
        bit_value: STANDAR_ALARM_FIELDS_ENUM.GNSS_ANTENNA_NOT_CONNECTED
    },
    [STANDAR_ALARM_FIELDS_ENUM.GNSS_ANTENNA_SHORT_CIRCUIT]: {
        name: 'GNSS_ANTENNA_SHORT_CIRCUIT',
        description: 'Maintain the flag until the alarm condition is cleared',
        bit_value: STANDAR_ALARM_FIELDS_ENUM.GNSS_ANTENNA_SHORT_CIRCUIT
    },
    [STANDAR_ALARM_FIELDS_ENUM.EXTERNAL_POWER_LOW]: {
        name: 'EXTERNAL_POWER_LOW',
        description: 'Maintain the flag until the alarm condition is cleared',
        bit_value: STANDAR_ALARM_FIELDS_ENUM.EXTERNAL_POWER_LOW
    },
    [STANDAR_ALARM_FIELDS_ENUM.EXTERNAL_POWER_REMOVED]: {
        name: 'EXTERNAL_POWER_REMOVED',
        description: 'Maintain the flag until the alarm condition is cleared',
        bit_value: STANDAR_ALARM_FIELDS_ENUM.EXTERNAL_POWER_REMOVED
    },
    [STANDAR_ALARM_FIELDS_ENUM.TERMINAL_LCD_FAILURE]: {
        name: 'TERMINAL_LCD_FAILURE',
        description: 'Maintain the flag until the alarm condition is cleared',
        bit_value: STANDAR_ALARM_FIELDS_ENUM.TERMINAL_LCD_FAILURE
    },
    [STANDAR_ALARM_FIELDS_ENUM.TTS_MODULE_FAILURE]: {
        name: 'TTS_MODULE_FAILURE',
        description: 'Maintain the flag until the alarm condition is cleared',
        bit_value: STANDAR_ALARM_FIELDS_ENUM.TTS_MODULE_FAILURE
    },
    [STANDAR_ALARM_FIELDS_ENUM.CAMERA_FAILURE]: {
        name: 'CAMERA_FAILURE',
        description: 'Maintain the flag until the alarm condition is cleared',
        bit_value: STANDAR_ALARM_FIELDS_ENUM.CAMERA_FAILURE
    },
    [STANDAR_ALARM_FIELDS_ENUM.IC_CARD_MODULE_FAULTY]: {
        name: 'IC_CARD_MODULE_FAULTY',
        description: 'Maintain the flag until the alarm condition is cleared',
        bit_value: STANDAR_ALARM_FIELDS_ENUM.IC_CARD_MODULE_FAULTY
    },
    [STANDAR_ALARM_FIELDS_ENUM.OVERSPEED_WARNING]: {
        name: 'OVERSPEED_WARNING',
        description: 'Maintain the flag until the alarm condition is cleared',
        bit_value: STANDAR_ALARM_FIELDS_ENUM.OVERSPEED_WARNING
    },
    [STANDAR_ALARM_FIELDS_ENUM.FATIGUE_DRIVING_WARNING]: {
        name: 'FATIGUE_DRIVING_WARNING',
        description: 'Maintain the flag until the alarm condition is cleared',
        bit_value: STANDAR_ALARM_FIELDS_ENUM.FATIGUE_DRIVING_WARNING
    },
    [STANDAR_ALARM_FIELDS_ENUM.CUMULATIVE_DRIVING_TIMEOUT]: {
        name: 'CUMULATIVE_DRIVING_TIMEOUT',
        description: 'Maintain the flag until the alarm condition is cleared',
        bit_value: STANDAR_ALARM_FIELDS_ENUM.CUMULATIVE_DRIVING_TIMEOUT
    },
    [STANDAR_ALARM_FIELDS_ENUM.OVERTIME_PARKING]: {
        name: 'OVERTIME_PARKING',
        description: 'Maintain the flag until the alarm condition is cleared',
        bit_value: STANDAR_ALARM_FIELDS_ENUM.OVERTIME_PARKING
    },
    [STANDAR_ALARM_FIELDS_ENUM.IN_AND_OUT_OF_AREA]: {
        name: 'IN_AND_OUT_OF_AREA',
        description: 'Cleared after receiving response',
        bit_value: STANDAR_ALARM_FIELDS_ENUM.IN_AND_OUT_OF_AREA
    },
    [STANDAR_ALARM_FIELDS_ENUM.IN_AND_OUT_OF_ROUTE]: {
        name: 'IN_AND_OUT_OF_ROUTE',
        description: 'Cleared after receiving response',
        bit_value: STANDAR_ALARM_FIELDS_ENUM.IN_AND_OUT_OF_ROUTE
    },
    [STANDAR_ALARM_FIELDS_ENUM.TOO_LONG_DRIVING_TIME]: {
        name: 'TOO_LONG_DRIVING_TIME',
        description: 'Cleared after receiving response',
        bit_value: STANDAR_ALARM_FIELDS_ENUM.TOO_LONG_DRIVING_TIME
    },
    [STANDAR_ALARM_FIELDS_ENUM.ROUTE_DEVIATION_ALARM]: {
        name: 'ROUTE_DEVIATION_ALARM',
        description: 'Maintain the flag until the alarm condition is cleared',
        bit_value: STANDAR_ALARM_FIELDS_ENUM.ROUTE_DEVIATION_ALARM
    },
    [STANDAR_ALARM_FIELDS_ENUM.VEHICLE_VSS_FAILURE]: {
        name: 'VEHICLE_VSS_FAILURE',
        description: 'Maintain the flag until the alarm condition is cleared',
        bit_value: STANDAR_ALARM_FIELDS_ENUM.VEHICLE_VSS_FAILURE
    },
    [STANDAR_ALARM_FIELDS_ENUM.VEHICLE_OIL_LEVEL_ABNORMAL]: {
        name: 'VEHICLE_OIL_LEVEL_ABNORMAL',
        description: 'Maintain the flag until the alarm condition is cleared',
        bit_value: STANDAR_ALARM_FIELDS_ENUM.VEHICLE_OIL_LEVEL_ABNORMAL
    },
    [STANDAR_ALARM_FIELDS_ENUM.VEHICLE_STOLEN]: {
        name: 'VEHICLE_STOLEN',
        description: 'Maintain the flag until the alarm condition is cleared',
        bit_value: STANDAR_ALARM_FIELDS_ENUM.VEHICLE_STOLEN
    },
    [STANDAR_ALARM_FIELDS_ENUM.ILLEGAL_IGNITION]: {
        name: 'ILLEGAL_IGNITION',
        description: 'Cleared after receiving response',
        bit_value: STANDAR_ALARM_FIELDS_ENUM.ILLEGAL_IGNITION
    },
    [STANDAR_ALARM_FIELDS_ENUM.ILLEGAL_DISPLACEMENT]: {
        name: 'ILLEGAL_DISPLACEMENT',
        description: 'Cleared after receiving response',
        bit_value: STANDAR_ALARM_FIELDS_ENUM.ILLEGAL_DISPLACEMENT
    },
    [STANDAR_ALARM_FIELDS_ENUM.COLLISION_WARNING]: {
        name: 'COLLISION_WARNING',
        description: 'Maintain the flag until the alarm condition is cleared',
        bit_value: STANDAR_ALARM_FIELDS_ENUM.COLLISION_WARNING
    },
    [STANDAR_ALARM_FIELDS_ENUM.ROLLOVER_WARNING]: { 
        name: 'ROLLOVER_WARNING',
        description: 'Maintain the flag until the alarm condition is cleared',
        bit_value: STANDAR_ALARM_FIELDS_ENUM.ROLLOVER_WARNING
    },
    [STANDAR_ALARM_FIELDS_ENUM.ILLEGAL_DOOR_OPENING_ALARM]: {
        name: 'ILLEGAL_DOOR_OPENING_ALARM',
        description: 'Cleared after receiving response',
        bit_value: STANDAR_ALARM_FIELDS_ENUM.ILLEGAL_DOOR_OPENING_ALARM
    }
}

export type IStandarAlarmFieldValue = typeof STANDAR_ALARM_FIELDS_MAP[keyof typeof STANDAR_ALARM_FIELDS_MAP]
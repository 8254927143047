
import { Module } from 'vuex'
// @ts-ignore
import { RootState } from '@/store/types'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import state, { EmailState } from './state'

export const email: Module<EmailState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

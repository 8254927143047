import { ActionContext } from 'vuex'
import { State } from './state'
import ChecklistModule from '@/classes/Checklist/ChecklistModules'

const URL_ACTION = '/apis/checklist_modules/'
const actions = {
  async loadAll ({ dispatch, rootState, commit }: ActionContext<State, string>) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: `${URL_ACTION}get/too`,
          method: 'GET'
        },
        { root: true }
      )
      const modules = data.map((module: any) => new ChecklistModule(module))
      commit('SET_ALL', modules)
    } catch (error) {
      console.error('Error al obtener los módulos de lista de verificación:', error)
    }
  },
  async create ({ dispatch, rootState, commit }: ActionContext<State, string>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}create`,
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    commit('PUSH', data)
    return data
  },
  async update ({ dispatch, rootState, commit }: ActionContext<State, string>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}update/${payload.id}`,
        method: 'PUT',
        data: payload
      },
      { root: true }
    )
    commit('UPDATE', data)
    return data
  },
  async delete ({ commit, dispatch }: ActionContext<State, string>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}delete/${payload}`,
        method: 'DELETE'
      },
      { root: true }
    )
    commit('DELETE', payload)
  },
  async loadModulesByUser ({ commit, dispatch }: ActionContext<State, any>, id_user: number) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION + 'access/',
        method: 'POST',
        data: { id_user }
      },
      { root: true }
    )
    return data
  },
  async saveModuleByUser ({ commit, dispatch }: ActionContext<State, any>, payload: { id_user:number, ch_modules_selected: []}) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION + 'access/',
        method: 'PUT',
        data: payload
      },
      { root: true }
    )
    return data
  },
  async loadChecklistByModule ({ commit, dispatch }: ActionContext<State, any>, payload: number) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION + 'get/checklist/' + payload,
        method: 'GET'
      },
      { root: true }
    )
    return data
  }
}
export default actions

import { State } from '../../state'
import { ActionContext } from 'vuex'
import { IBatteryMaker } from '../../../../classes/Battery/interfaces/IBatteryMaker'
const actions = {
  async loadBatteryMaker ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/battery_maker/get/all',
        method: 'GET'
      },
      { root: true }
    )
    commit('SET_BATTERY_MAKER', data)
    return data
  },
  async createBatteryMaker  ({ commit, dispatch }: ActionContext<State, any>, entity: IBatteryMaker) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/battery_maker/create',
        method: 'POST',
        data: entity
      },
      { root: true }
    )
    commit('PUSH_BATTERY_MAKER', data)
    return data
  },
  async updateBatteryMaker  ({ commit, dispatch }: ActionContext<State, any>, entity: IBatteryMaker) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/battery_maker/update/${entity.id}`,
        method: 'PUT',
        data: entity
      },
      { root: true }
    )
    commit('UPDATE_BATTERY_MAKER', data)
    return data
  },
  async deleteBatteryMaker  ({ commit, dispatch }: ActionContext<State, any>, entity: IBatteryMaker) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/battery_maker/delete/${entity.id}`,
        method: 'DELETE'
      },
      { root: true }
    )
    commit('DELETE_BATTERY_MAKER', entity)
    return data
  }
}
export default actions

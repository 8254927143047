import { ActionContext } from 'vuex'
import { State } from './state'

const URL_ACTION = '/apis/ticket_kpi/'
export default {
  async load ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION + 'userKPI',
        method: 'GET'
      },
      { root: true }
    )
    return data
  },
  async loadByUser ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION + 'userKPI/' + payload,
        method: 'GET'
      },
      { root: true }
    )
    return data
  },
  async loadByUserIssue ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION + 'userKPI_Issue/' + payload,
        method: 'GET'
      },
      { root: true }
    )
    return data
  }
}

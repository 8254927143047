import { State } from './state'
import { IRoute } from '../interfaces/Route'
import { IMarker } from '../interfaces/Marker'

export default {
  SET_STATE (state: State, payload: typeof state) {
    Object.assign(state, payload)
  },
  SET_MAP_REF (state: State, $ref: any) {
    state.$ref = $ref
  },
  SET_GEOLOCATION (state: State, payload: GeolocationPosition | null) {
    // @ts-ignore
    state.geolocation = payload
  },
  SET_CURR_UNIT_CONSOLE_ID (state: State, id: number) {
    state.unitconsole_id = id
  },
  SET_ROUTES (state: State, routes: IRoute[]) {
    state.routes = routes
  },
  PUSH_ROUTES (state: State, route: IRoute) {
    console.log(route)
    state.routes.push(route)
  },
  DELETE_ROUTE (state: State, index: number) {
    state.routes.splice(index, 1)
  },
  SET_MARKERS (state: State, markers: IMarker[]) {
    state.markers = markers
  },
  PUSH_MARKER (state: State, marker: IMarker) {
    state.markers.push(marker)
  },
  DELETE_MARKER (state: State, index: number) {
    state.markers.splice(index, 1)
  }
}

import { ActionContext } from 'vuex'
import { State } from './state'
import Issue, { IIssue } from '@/classes/Issue/IIssue'

const URL_ACTION = '/apis/issue/'

export default {
  async init ({ dispatch }: ActionContext<State, string>) {
    dispatch('load')
  },
  async load ({ commit, dispatch }: ActionContext<State, any>) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: `${URL_ACTION}get/all`,
          method: 'GET'
        },
        { root: true }
      )
      const issues = data.map((issue: IIssue) => new Issue(issue))
      commit('SET_ISSUES', issues)
      return issues
    } catch (error) {
      dispatch('app/addLogWithError', {
        title: 'Error al cargar los Asuntos',
        color: 'error',
        message: '',
        error
      }, { root: true })
      throw error
    }
  },
  async create ({ commit, dispatch }: ActionContext<State, any>, issue: IIssue) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}create`,
        method: 'POST',
        data: issue
      },
      { root: true }
    )
    commit('PUSH_ISSUES', data)
    return data
  },
  async update ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}update/${payload.id}`,
        method: 'PUT',
        data: payload
      },
      { root: true }
    )
    commit('UPDATE_ISSUE', data)
    return data
  },
  async delete ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}delete/${payload}`,
        method: 'DELETE'
      },
      { root: true }
    )
    commit('DELETE_ISSUE', payload)
    return data
  }
}

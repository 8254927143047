/* eslint-disable import/named */
import { isObject, isArray } from 'lodash'

export default function (obj: any) {
  const formData = new FormData()
  for (const key in obj) {
    if (obj[key] instanceof File) {
      formData.append(key, obj[key])
    } else if (isArray(obj[key]) || isObject(obj[key]) || obj[key] === '{}') {
      formData.append(key, JSON.stringify(obj[key]))
    } else {
      formData.append(key, obj[key])
    }
  }
  return formData
}

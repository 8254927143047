import Action from './Action'
import { DateTime } from 'luxon'
import { Alert } from '@/interfaces/Alert.interface'
import StreamaxJSSdk from '..'
import { RequestData } from '@/interfaces/RequestsWrapper.interface'
import { QueryHistoryAlarmDTO } from '@/interfaces/Gps.interface'


export default class AAlert extends Action<Alert> implements Alert {
  alarmId!: string
  alarmType!: number
  gps!: { angle: number; lat: number; lng: number; speed: number; time: string }
  startTime!: string
  uniqueId!: string
  vehicleNumber!: string
  vehicleId!: string

  public static isSubscribed = false
  public static queueMessages: Set<Alert> = new Set();

  public static async observeMessages () {
    if (!AAlert.isSubscribed) return
    const messages = await AAlert.list({
      startTime: DateTime.now().toUTC().plus({ hours: -2 }).toFormat("yyyy-LL-dd'T'HH:mm:ss'Z'"),
      endTime: DateTime.now().toUTC().toFormat("yyyy-LL-dd'T'HH:mm:ss'Z'"),
      uniqueIds: StreamaxJSSdk.actions.AUnit.unit_ids.join(','),
      alarmTypes: ''
    }, { raw: true })
    if (this.queueMessages.size === 0) {
      this.queueMessages = new Set(messages)
    } else {
      const newEvents = messages.filter(event => ![...this.queueMessages].some(alert => alert.alarmId === event.alarmId));
      // Añadir los nuevos eventos a queueMessages
      newEvents.forEach(event => this.queueMessages.add(event));
      const threeHoursAgo = DateTime.utc().minus({ hours: 3 });

      this.queueMessages.forEach(event => {
        const startTime = DateTime.fromFormat(event.startTime, "yyyy-LL-dd'T'HH:mm:ss'Z'")

        // Si el evento es menor a 3 horas, se elimina de queueMessages
        if (startTime < threeHoursAgo) this.queueMessages.delete(event);
      });
      if (newEvents.length > 0) StreamaxJSSdk.event.emit('ALERT_MESSAGE_CHANGED', newEvents)
    }
  }

  public static addEventListener (type: 'ALERT_MESSAGE_CHANGED', callback: (alerts: Alert[]) => void) {
    switch (type) {
      case 'ALERT_MESSAGE_CHANGED':
        AAlert.isSubscribed = true
        StreamaxJSSdk.event.addListener(type, callback)
        break
    }
  }

  public static removeEventListener (type: 'ALERT_MESSAGE_CHANGED', callback: (alert: Alert) => void) {
    switch (type) {
      case 'ALERT_MESSAGE_CHANGED':
        AAlert.isSubscribed = false
        StreamaxJSSdk.event.removeListener(type, callback)
        break
    }
  }

  public static async list (params: QueryHistoryAlarmDTO, { raw } = { raw: false }): Promise<AAlert[]> {
    const { data } = await StreamaxJSSdk.axios<RequestData<QueryHistoryAlarmDTO>>({
      url: '/alarms',
      method: 'GET',
      params,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    // return data
    if (!raw) {
    // @ts-ignore
      const aAlerts = data.data.map((obj) => new AAlert({ data: obj }))
      return aAlerts
    } else {
      // @ts-ignore
      return data.data
    }
  }

  isUpdated (data: any): boolean {
    return false
  }
}

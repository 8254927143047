// import { InventoryState } from '../../state'

import { CustomerState } from './state'

// import { ProductState } from './state'

export default {
  getCustomers (state: CustomerState) {
    return state.customers
  }
}

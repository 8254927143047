import Root from '../Root/Root'

export interface IArea {
  area: string;
  created_by: number;
}
export default class Area extends Root implements IArea {
  area!: string;
  created_by!: number;

  constructor (area: IArea) {
    super()
    Object.assign(this, area)
  }
}

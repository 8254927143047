import { ICGeofence, ICoordenadas } from '@/store/geofences/state'
import Root from '../Root/Root'
import { GEOFENCES_TYPE } from '@/store/resources/GEOFENCE'
import getPointCentral from '@/utils/getPointCentral'
export default class Geofence extends Root implements ICGeofence {
  id!: number;
  geo_name!: string;
  geo_description!: string;
  geo_color!: string;
  geo_payload!: { type: number; radius?: number | undefined; coordinates: ICoordenadas | ICoordenadas[]; };
  b!: { cen_x: number; cen_y: number; };
  n!: string;
  w!: number;
  direction: object | any;

  constructor (geogence: ICGeofence) {
    super()
    Object.assign(this, geogence)
  }

  getCentralPoint () {
    if (this.geo_payload.type === GEOFENCES_TYPE.POLYGON) {
      // @ts-ignore
      return getPointCentral(this.geo_payload.coordinates)
    } else {
      return { ...this.geo_payload.coordinates }
    }
  }
}

export function getCentralPoint (geofence:any) {
  if (geofence.geo_payload.type === GEOFENCES_TYPE.POLYGON) {
    // @ts-ignore
    return getPointCentral(geofence.geo_payload.coordinates)
  } else {
    return { ...geofence.geo_payload.coordinates }
  }
}

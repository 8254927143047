/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */

import { ActionContext } from 'vuex/types/index'
import {
  RESOURCES,
  PLATAFORM,
  PLATAFORM_RESOURCES
} from '../../resources/RESOURCES'
import { State } from '../state'
const actions = {
  async loadClients ({ dispatch, commit }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/get_all_user_client/',
        method: 'GET'
      },
      { root: true }
    )

    commit(
      'resources/SET_RESOURCES',
      {
        resources: data,
        rType: RESOURCES.CLIENTS
      },
      { root: true }
    )
  },
  async createClient ({ dispatch }: ActionContext<State, any>, payload: any) {
    await dispatch(
      'app/axios',
      {
        url: '/apis/create_user_client/',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    await dispatch('loadClients')
  },
  async deleteClient (
    { dispatch, commit, rootState }: ActionContext<State, any>,
    id: any
  ) {
    await dispatch(
      'app/axios',
      {
        url: '/apis/delete_user_client/',
        method: 'POST',
        data: { id }
      },
      { root: true }
    )
    // @ts-ignore
    const index = rootState.resources[RESOURCES.CLIENTS].findIndex(
      // @ts-ignore
      (r) => r.userID === id
    )

    commit(
      'resources/REMOVE_RESOURCE',
      { index, rType: RESOURCES.CLIENTS },
      { root: true }
    )
  },
  async updateClient (
    { dispatch, commit }: ActionContext<State, any>,
    payload: any
  ) {
    await dispatch(
      'app/axios',
      {
        url: '/apis/update_user_client/',
        method: 'POST',
        data: payload
      },
      { root: true }
    )

    // @ts-ignore
    const index = rootState.resources[RESOURCES.CLIENTS].findIndex(
      // @ts-ignore
      (clt) => clt.id === payload.id
    )
    // @ts-ignore
    const client = rootState.resources[RESOURCES.CLIENTS][index]
    delete client.password

    commit(
      'resources/UPDATE_RESOURCE',
      {
        index,
        item: client,
        rType: RESOURCES.CLIENTS,
        force: false
      },
      { root: true }
    )
  }
}
export default actions

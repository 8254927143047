
import { ActionContext } from 'vuex'
import { State } from './state'
import { serializeError } from 'serialize-error'
import { isArray } from 'lodash'
// import { ICustomer } from '@/classes/Facturacion/ICustomer'
// const URL_ACTION = '/apis/note_sale/'
const actions = {
  async getDriverHistory ({ commit, dispatch }: ActionContext<State, any>, payload:any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/unit/driver/history/',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async assignDriver ({ commit, dispatch, rootState }: ActionContext<State, any>, payload:any) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: '/apis/unit/driver/assignment/',
          method: 'POST',
          data: payload
        },
        { root: true }
      )
      // @ts-ignore
      // const unitConsoleData: UnitConsoleData<unknown, unknown> = rootState.travel.unitsRegisters.find(obj => obj.id === payload.unitconsole_id)
      // if (unitConsoleData) {
      //   console.log(unitConsoleData)
      //   unitConsoleData.setDriver(data)
      //   // AUN FALTA REMOVAR AL OTRO DRIVER SI ESTA EN OTRA UNIDAD
      // }
    } catch (error) {
      commit('sys/ADD_LOG', { title: 'DRIVER - ASSIGNMENT', color: 'error', message: error, payload: serializeError(error) }, { root: true })
      await dispatch('app/notifyToast', { msj: 'Ha ocurrido un error', type: 'error' }, { root: true })
      // await dispatch('app/notification', { content: 'Ha ocurrido un error', type: 'error' }, { root: true })
    }
  },
  async removeDriver ({ commit, dispatch, rootState }: ActionContext<State, any>, payload:any) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: '/apis/unit/driver/assignment/',
          method: 'DELETE',
          data: payload
        },
        { root: true }
      )
      // @ts-ignore
      const unitConsoleData: UnitConsoleData<unknown, unknown> = rootState.travel.unitsRegisters.find(obj => obj.id === payload.unitconsole_id)
      if (unitConsoleData) {
        console.log('aaaaaaaaaaaaaa')
        unitConsoleData.removeDriver(payload.userprofile_id)
      }
    } catch (error) {
      commit('sys/ADD_LOG', { title: 'DRIVER - ASSIGNMENT', color: 'error', message: error, payload: serializeError(error) }, { root: true })
      await dispatch('app/notifyToast', { msj: 'Ha ocurrido un error', type: 'error' }, { root: true })
    }
  }
  // async create ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
  //   const { data } = await dispatch(
  //     'app/axios',
  //     {
  //       url: URL_ACTION,
  //       method: 'POST',
  //       data: payload
  //     },
  //     { root: true }
  //   )
  //   commit('PUSH', data)
  //   return data
  // },
  // async update ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
  //   const { data } = await dispatch(
  //     'app/axios',
  //     {
  //       url: `${URL_ACTION}${payload.id}`,
  //       method: 'PUT',
  //       data: payload
  //     },
  //     { root: true }
  //   )
  //   commit('UPDATE', data)
  //   return data
  // }
}
export default actions


// import { InventoryState } from '../../state'
import { EmailState } from './state'

export default {
  SET_ALL (state: EmailState, entities: any[]): void {
    state.emails = entities
  },
  PUSH (state: EmailState, entitie: any): void {
    state.emails.unshift(entitie)
  },
  UPDATE (state: EmailState, entitie: any): void {
    const index = state.emails.findIndex(p => p.id === entitie.id)
    if (index !== -1) {
      state.emails.splice(index, 1, entitie)
    }
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { IDriver, State } from './state'
// import { Tire, UnitConsole } from '../interfaces/ReportModules';
import UnitConsoleData from '../packages/ReportItem/ReportItemData'
import { PLATAFORM } from '../resources/RESOURCES'
import { ESTATUS_TRAVEL } from '../catalogs/ESTATUS_TRAVEL'

export default {
  getUnitsWithCameras (state: State) {
    return state.unitsRegisters.filter(obj => obj.cameras != null)
  },
  getUnitsWithCamerasOnly (state: State) {
    const result = (state.unitsRegisters as UnitConsoleData<unknown, unknown>[])
      .filter(obj => obj.cameras != null)
      .map(obj => ({
        camera: obj.cameras,
        unitconsole: obj,
        currChannels: []
      }))
    return result
  },
  getUnitByDeviceID (state: State) {
    return (ehome_id: any) => {
      return state.unitsRegisters.filter(obj =>
        obj.cameras?.cpt_properties?.EhomeID === ehome_id
      )
    }
  },
  getUnitsRegisters (state: State) {
    return state.unitsRegisters
  },
  getUnitsRegistersIds (state: State) {
    return state.unitsRegisters.map(unit => unit.id)
  },
  getUnit (state: State) {
    return (id_unit_console: number): UnitConsoleData<any, any> => {
      const unitC = state.unitsRegisters.find(unit => unit.id === id_unit_console)
      // console.log(unitC)
      // @ts-ignore
      return unitC
    }
  },
  getUserDriversAssigned (state:State) {
    const assignedDriverIds: number[] = state.unitsRegisters.reduce((driverIds: number[], unit) => {
      // Obtener los IDs de los conductores de la unidad actual
      const driverIdsOfUnit: number[] = unit.drivers.map((driver: IDriver) => driver.id)
      // Agregar los IDs de los conductores de la unidad actual a la lista general
      return driverIds.concat(driverIdsOfUnit)
    }, [])
    return assignedDriverIds
  },
  getUnitsSelect (state: State) {
    return (plataform: PLATAFORM): UnitConsoleData<any, any>[] => {
      const unitC = state.unitsRegisters.find(unit => unit.id === 1)
      // @ts-ignore
      return unitC
    }
  },
  getDrivers (state: State) {
    // @ts-ignore
    return state.driversRegisters.map(driver => ({ id: driver.id, name: driver.tdr_plataform_name, phone: driver.tdr_plataform_phone, register: true }))
  },
  getNameOrigin () {
    // @ts-ignore
    return (travel) => {
      // @ts-ignore
      const destination_current = travel.tvl_destinations.find(destination => destination.pk === travel.tra_destination_current)
      return destination_current?.tvd_plataform_route_origin.name
    }
  },
  getNameDestiny () {
    // @ts-ignore
    return (travel) => {
      // @ts-ignore
      const destination_current = travel.tvl_destinations.find(destination => destination.pk === travel.tra_destination_current)
      return destination_current?.tvd_plataform_route_destination.name
    }
  },
  getStatusTravelString () {
    // @ts-ignore
    return (status: ESTATUS_TRAVEL) => {
      // @ts-ignore
      switch (status) {
        case ESTATUS_TRAVEL.CARGANDO_EN:
          return 'Cargando'
        case ESTATUS_TRAVEL.EN_VIAJE:
          return 'En viaje'
        case ESTATUS_TRAVEL.DESCARGANDO_EN:
          return 'Descargando'
        case ESTATUS_TRAVEL.SIN_INICIAR:
          return 'Sin iniciar'
        default:
          break
      }
    }
  }
}

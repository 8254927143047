
import { Module } from 'vuex'
// @ts-ignore
import { RootState } from '@/store/types'
import actions from './actions'
import state, { TagsState } from './state'

export const tags: Module<TagsState, RootState> = {
  namespaced: true,
  state,
  actions
}

import { State } from './state'
import QRCode from '@/classes/Inventory/interfaces/IQrCode'

export default {
  SET_QR_CODES (state: State, qrCodes: QRCode[]) {
    state.qrCodes = qrCodes
  },
  PUSH_QR_CODES (state: State, qrCodes: QRCode[]) {
    state.qrCodes.unshift(...qrCodes)
  },
  UPDATE_QR_CODE (state: State, updatedQrCode: QRCode) {
    const index = state.qrCodes.findIndex(qrCode => qrCode.id === updatedQrCode.id)
    if (index !== -1) {
      state.qrCodes.splice(index, 1, updatedQrCode)
    }
  },
  DELETE_QR_CODE (state: State, qrCodeId: number) {
    state.qrCodes = state.qrCodes.filter(qrCode => qrCode.id !== qrCodeId)
  }
}

import { State } from '../../state'
import { ActionContext } from 'vuex'
import { IEntryStore } from '../../../../classes/Inventory/interfaces/IEntryStore'
const actions = {
  async loadEntryStore ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/entry_store/get/all',
        method: 'GET'
      },
      { root: true }
    )
    commit('SET_ENTRY_STORE', data)
    return data
  },
  async createEntryStore ({ commit, dispatch }: ActionContext<State, any>, entity: IEntryStore) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/entry_store/create',
        method: 'POST',
        data: entity
      },
      { root: true }
    )
    commit('PUSH_ENTRY_STORE', data)
    return data
  },
  async createEntryDetail ({ commit, dispatch }: ActionContext<State, any>, entity: IEntryStore) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/entry_detail/add/new',
        method: 'POST',
        data: entity
      },
      { root: true }
    )
    commit('PUSH_ENTRY_STORE', data)
    return data
  },
  async updateEntryStore ({ commit, dispatch }: ActionContext<State, any>, entity: IEntryStore) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/entry_store/update/${entity.id}`,
        method: 'PUT',
        data: entity
      },
      { root: true }
    )
    commit('UPDATE_ENTRY_STORE', data)
    return data
  },
  async deleteEntryStore ({ commit, dispatch }: ActionContext<State, any>, entity: IEntryStore) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/entry_store/delete/${entity.id}`,
        method: 'DELETE'
      },
      { root: true }
    )
    commit('DELETE_ENTRY_STORE', entity)
    return data
  }
}
export default actions

import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Console from './console'
import Login from './login'
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [...Login, Console]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  // @ts-ignore
  return originalPush.call(this, location).catch((err: { name: string }) => {
    if (err.name !== 'NavigationDuplicated') {
      // Solo lanza el error si no es NavigationDuplicated
      throw err
    }
  })
}

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (location) {
  // @ts-ignore
  return originalReplace.call(this, location).catch((err: { name: string }) => {
    if (err.name !== 'NavigationDuplicated') {
      // Solo lanza el error si no es NavigationDuplicated
      throw err
    }
  })
}

export default router

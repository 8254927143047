import Root from '../../Root/Root'

export interface IAssetMaintenance {
  id: number
  serialized_part: number
  date: string | null
  scheduled_date: string
  creation_date: string
  cost: number | null
  technician: string | null
  notes: string | null
  provider: number | null
  is_complete: boolean
  priority: number | null
  details: string
}

export default class AssetMaintenance extends Root implements IAssetMaintenance {
  id!: number
  serialized_part!: number
  date!: string | null
  scheduled_date!: string
  creation_date!: string
  cost!: number | null
  technician!: string | null
  notes!: string | null
  provider!: number | null
  is_complete!: boolean
  priority!: number | null
  details!: string

  constructor (maintenance: IAssetMaintenance) {
    super()
    Object.assign(this, maintenance)
  }
}

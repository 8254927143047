/* eslint-disable @typescript-eslint/no-explicit-any */
import { decode } from '@googlemaps/polyline-codec'
interface Point {
  lat: number;
  lon: number
}

const flags = 2

export default (initCoors: Point, finalCoors: Point): Promise<any> => {
  return new Promise((resolve, reject) => {
    // @ts-ignore
    wialon.util.Gis.getRouteViaWaypoints(initCoors, finalCoors, [], function (code, d2) {
      if (code) {
      // @ts-ignore
        reject(wialon.core.Errors.getErrorText(code))
      } else {
        const route = d2.routes.at(0)
        if (route) {
          const points = decode(route.points, 5)
          resolve({
            ...route,
            points
          })
        } else {
          resolve(null)
        }
      }
    }, flags)
  })
}

<template>
  <transition name="fade">
    <div class=" d-flex justify-center align-center loaderDialog" v-if="$store.state.app.overlay">
      <div class="loaderContainer">
          <div>
            <div class="loader"></div>
          </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Vue from 'vue'
// import SUDLogoLoader from '@/components/SUDLogoLoader.vue'

export default Vue.extend({
  name: 'OverlayLoader',
  components: {
    // SUDLogoLoader
  },
  computed: {
    getUrlLoader () {
      // if (this.$store.state.auth.user_date.loader_image) {
      //   return `${process.env.VUE_APP_BASE_URL || ''}/media/${this.$store.state.auth.user_date.loader_image}`
      // }
      return (require('../../public/ANANTA_HORIZONTAL.png'))
      // return (path) => {
      //   // eslint-disable-next-line @typescript-eslint/no-var-requires
      //   console.log(require('../../.env.files/logo_l.png'))
      //   if (!path) return require('../../.env.files/logo_l.png')
      //   return `${process.env.VUE_APP_BASE_URL || ''}/${path}?time${Date.now()}`
      // }
    }
  }
})
</script>
<style lang="sass" scoped>

.loader
  width: 85px
  height: 50px
  --g1: conic-gradient(from 90deg at left 3px top 3px, #0000 90deg, #fff 0)
  --g2: conic-gradient(from -90deg at bottom 3px right 3px, #0000 90deg, #fff 0)
  background: var(--g1), var(--g1), var(--g1), var(--g2), var(--g2), var(--g2)
  background-position: left, center, right
  background-repeat: no-repeat
  animation: l10 1s infinite alternate

@keyframes l10
  0%,
  2%
    background-size: 25px 50%, 25px 50%, 25px 50%
  20%
    background-size: 25px 25%, 25px 50%, 25px 50%
  40%
    background-size: 25px 100%, 25px 25%, 25px 50%
  60%
    background-size: 25px 50%, 25px 100%, 25px 25%
  80%
    background-size: 25px 50%, 25px 50%, 25px 100%
  98%,
  100%
    background-size: 25px 50%, 25px 50%, 25px 50%

.loaderDialog
  position: fixed
  width: 100%
  height: 100%
  display: flex
  justify-content: center
  align-items: center
  background-color: rgba(0, 0, 0, 0.70)
  z-index: 1000
  overflow: hidden

.fade-enter-active,
.fade-leave-active
  transition: opacity 0.5s

.fade-enter,
.fade-leave-to /* .fade-leave-active en versiones anteriores a 2.1.8 */
  opacity: 0
</style>

import { IIssue } from '@/classes/Issue/IIssue'

export interface IssueState {
  issues: Array<IIssue>;
}

const state = (): IssueState => ({
  issues: []
})

export type State = ReturnType<typeof state>

export default state

import { State } from '../../state'
import { ActionContext } from 'vuex'
import { IWheelModel } from '../../../../classes/Wheel/interfaces/IWheelModel'
const actions = {
  async loadWheelModel ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/wheel_model/get/all',
        method: 'GET'
      },
      { root: true }
    )
    commit('SET_WHEEL_MODEL', data)
    return data
  },
  async createWheelModel ({ commit, dispatch }: ActionContext<State, any>, entity: IWheelModel) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/wheel_model/create',
        method: 'POST',
        data: entity
      },
      { root: true }
    )
    commit('PUSH_WHEEL_MODEL', data)
    return data
  },
  async updateWheelModel ({ commit, dispatch }: ActionContext<State, any>, entity: IWheelModel) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/wheel_model/update/${entity.id}`,
        method: 'PUT',
        data: entity
      },
      { root: true }
    )
    commit('UPDATE_WHEEL_MODEL', data)
    return data
  },
  async deleteWheelModel ({ commit, dispatch }: ActionContext<State, any>, entity: IWheelModel) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/wheel_model/delete/${entity.id}`,
        method: 'DELETE'
      },
      { root: true }
    )
    commit('DELETE_WHEEL_MODEL', entity)
    return data
  }
}
export default actions

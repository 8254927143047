import LoadFuel from '@/classes/Fuel/LoadFuel'
import UnitConsoleData from '../packages/ReportItem/ReportItemData'

export interface IDriver {
  id: number
  name: string
  phone: string
}

export interface TravelState {
  view: string
  key: number
  unitsRegisters: UnitConsoleData<any, any>[]
  unitsCombustibleSelected: [],
  driversRegisters: IDriver[]
  loadFuels : LoadFuel[]
}
const state = (): TravelState => ({
  view: '',
  key: 0,
  unitsRegisters: [],
  unitsCombustibleSelected: [],
  driversRegisters: [],
  loadFuels: []
})

export type State = ReturnType<typeof state>;

export default state

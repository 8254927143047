import { State } from './state'

export const mutations = {
  SET_USERS (state: State, users: []) {
    state.users = users
  },
  PUSH_USER (state: State, user:any) {
    // @ts-ignore
    state.users.push(user)
  },
  SET_SERVICES (state: State, services: []) {
    state.services = services
  },
  UPDATE_USER (state: State, user: any) {
    // @ts-ignore
    const user_find = state.users.find((us) => us.userID === user.userID)
    if (user_find) {
      Object.assign(user_find, { ...user })
    }
    // @ts-ignore
  },
  DELETE_USER (state: State, userID: number) {
    // @ts-ignore
    state.users = state.users.filter((user) => user.userID !== userID)
  }
  // CLEAN_LOGIN_DATA (state: State) {
  //   const cleanState: IAuth = {
  //     is_auth: false,
  //     auth_token: '',
  //     plataform_data: [],
  //     user_date: {
  //       id_user: 0,
  //       service: [],
  //       us_name: '',
  //       data_userType: []
  //     }
  //   }
  //   Object.assign(state, cleanState)
  // }
}

export default mutations

import { MaponSDKConfig } from './interfaces/index.inferface'
import defaultConfig from './defaultConfig'
import { EventEmitter } from 'events'
import actions, { IActions } from './classes/actions/index'
export default class MaponSDK {
  public static config: MaponSDKConfig;
  public static actions:IActions = actions
  public static event: EventEmitter = new EventEmitter()

  constructor (config: MaponSDKConfig) {
    const initConfig = { ...defaultConfig, ...config }
    MaponSDK.config = initConfig
    this.loopFetchData()
  }

  private loopFetchData () {
    setInterval(async () => {
      if (!MaponSDK.config.disableInterval) {
        try {
          await MaponSDK.actions.AAlert.observeMessages()
          await MaponSDK.actions.AUnit.observeMessages()
        } catch (error) {
          console.error(error)
        }
      }
    }, MaponSDK.config.intervalTime)
  }
}

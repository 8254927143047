import { State } from './state'

export default {
  getQRCodes (state: State) {
    return state.qrCodes
  },
  getQRCodesAvailable (state: State) {
    return state.qrCodes.filter(qrCode => qrCode.in_use === false)
  }
}

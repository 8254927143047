
import { ActionContext } from 'vuex'
import { State } from './state'
// import { ICustomer } from '@/classes/Facturacion/ICustomer'
const URL_ACTION = '/apis/notification/'
const actions = {
  async markerRead ({ dispatch, state }: ActionContext<State, any>, payload:any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION + payload.id + '/read',
        method: 'POST'
      },
      { root: true }
    )
    const notify = state.notifications.find(notify => notify.id === payload.id)
    if (notify) {
      notify.read = true
    }
    // commit('SET_ALL', data)
    return data
  },
  async markerDeleted ({ commit, dispatch, state }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION + payload.id + '/delete',
        method: 'POST'
      },
      { root: true }
    )

    const notify = state.notifications.find(notify => notify.id === payload.id)
    if (notify) {
      notify.deleted_at = new Date().toISOString()
    }

    return data
  },
  async loadFirstNotifications ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION,
        method: 'GET'
      },
      { root: true }
    )
    commit('SET_ALL', data)
    return data
  },
  async markedGruopRead ({ commit, dispatch, state }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION + 'group/read',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    payload.forEach((id: number) => {
      const notify = state.notifications.find(notify => notify.id === id)
      if (notify) {
        notify.read = true
      }
    })
    return data
  },
  async markedGruopDeleted ({ commit, dispatch, state }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION + 'group/delete',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    payload.forEach((id: number) => {
      const notify = state.notifications.find(notify => notify.id === id)
      if (notify) {
        notify.deleted_at = new Date().toISOString()
      }
    })
    return data
  }
  // async create ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
  //   const { data } = await dispatch(
  //     'app/axios',
  //     {
  //       url: URL_ACTION,
  //       method: 'POST',
  //       data: payload
  //     },
  //     { root: true }
  //   )
  //   commit('PUSH', data)
  // },
  // async update ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
  //   const { data } = await dispatch(
  //     'app/axios',
  //     {
  //       url: `${URL_ACTION}${payload.id}`,
  //       method: 'PUT',
  //       data: payload
  //     },
  //     { root: true }
  //   )
  //   commit('UPDATE', data)
  // }
}
export default actions

import Checklist, { IChecklist } from '@/classes/Checklist/Checklist'
import { ReportTaskState } from './state'

export default {
  // Mutadores para Checklists
  SET_ALL (state: ReportTaskState, checklit: Checklist[]) {
    state.report_templates = checklit
  },
  PUSH (state: ReportTaskState, checklit: Checklist) {
    state.report_templates.push(checklit)
  },
  UPDATE (state: ReportTaskState, item: any) {
    const checklist = state.report_templates.find(checklist => checklist.id === item.id)
    // @ts-ignore
    Object.assign(checklist, { ...item })
  },
  DELETE (state: ReportTaskState, item: IChecklist) {
    state.report_templates = state.report_templates.filter(checklist => checklist.id !== item.id)
  }
}

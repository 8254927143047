/* eslint-disable @typescript-eslint/no-unused-vars */
import { IObject } from '../../interfaces/Object.interface'
import { IParameters } from '../../interfaces/parameters.interface'
import { Optional } from '../../interfaces/utils.interface'
import AssingData from '../../utils/AssingData'
import Action from './Action'
import { Alert } from '../../interfaces/Alert.interface'
import { DateTime } from 'luxon'
import MaponSDK from '../..'

export type IListUnitParams = Optional<
  Pick<
    IParameters,
    | 'key'
    | 'from'
    | 'till'
    | 'unit_id'
    | 'alert_type'
    | 'driver'
    | 'include'
    | 'limit'
    | 'page'
  >,
  'key' | 'unit_id' | 'alert_type' | 'driver' | 'include' | 'limit' | 'page'
>;

@AssingData()
export default class AAlert extends Action<Alert> implements Alert {
  unit_id!: number;
  time!: string;
  alert_type!: string;
  alert_val!: string;
  msg!: string;
  id!: number;
  location!: string;
  address!: string;

  public static isSubscribed = false
  public static queueMessages: AAlert[] = [];
  // public static queueMessages: Map<string, Alert> = new Map();
  public static iterationCallCount = 0

  public static async observeMessages() {
    if (!AAlert.isSubscribed) return

    const messages = await AAlert.list({
      from: DateTime.utc().plus({ hours: -2 }).toFormat("yyyy-LL-dd'T'HH:mm:ss'Z'"),
      till: DateTime.utc().toFormat("yyyy-LL-dd'T'HH:mm:ss'Z'")
    })

    if (AAlert.iterationCallCount === 0) {
      this.queueMessages = messages
      AAlert.iterationCallCount++
      return
    }

    const newEvents = messages.filter(event => !this.queueMessages.some(alert => alert.custom_unitque_id === event.custom_unitque_id));
    // Añadir los nuevos eventos a queueMessages
    this.queueMessages.push(...newEvents);

    // Se eliminan los eventos menores a 3 horas
    const threeHoursAgo = DateTime.utc().minus({ hours: 3 });
    this.queueMessages.forEach(event => {
      const startTime = DateTime.fromFormat(event.time, "yyyy-LL-dd'T'HH:mm:ss'Z'")
      if (startTime < threeHoursAgo) {
        this.queueMessages = this.queueMessages.filter(alert => alert.custom_unitque_id !== event.custom_unitque_id);
      }
    });

    if (newEvents.length > 0) {
      newEvents.forEach(event => MaponSDK.event.emit('ALERT_MESSAGE_CHANGED', event));
    }

    AAlert.iterationCallCount++
  }

  public static addEventListener(type: 'ALERT_MESSAGE_CHANGED', callback: (alert: Alert) => void) {
    switch (type) {
      case 'ALERT_MESSAGE_CHANGED':
        AAlert.isSubscribed = true
        MaponSDK.event.addListener(type, callback)
        break
    }
  }

  public static removeEventListener(type: 'ALERT_MESSAGE_CHANGED', callback: (alert: Alert) => void) {
    switch (type) {
      case 'ALERT_MESSAGE_CHANGED':
        AAlert.isSubscribed = false
        MaponSDK.event.removeListener(type, callback)
        break
    }
  }

  public static async list(
    params: IListUnitParams,
    { raw } = { raw: false }
  ): Promise<AAlert[]> {
    const res = await super.AList<Alert[]>(params, {
      action: 'alert'
    })
    if (!raw) {
      const aAlerts = res.data.map((obj) => new AAlert({ data: obj }))
      return aAlerts
    } else {
      // @ts-ignore
      return res.data
    }
  }

  isUpdated(data: any): boolean {
    return false
  }

  /**
   * Returns a custom unique identifier for the alert, based on the unit ID, time, alert type, and alert value.
   *
   * @return {string} The custom unique identifier for the alert.
   */

  public get custom_unitque_id (): string {
    return `${this.unit_id}-${this.time}-${this.alert_type}-${this.alert_val}`
  }
}

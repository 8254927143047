import ICommand from './ICommand.interface'
import IRealTimeAudioAndVideoTransmissionRequest from './IRealTimeAudioAndVideoTransmissionRequest.interface'
import IAudioAndVideoRealtimeTransmissionControl from './IAudioAndVideoRealtimeTransmissionControl.interface'
import IQueryTheListOfAudioAndVideoResources from './IQueryTheListOfAudioAndVideoResources.interface'
import IHistoryVideoPlaybackRequest from './IHistoryVideoPlaybackRequest.interface'
import IHistoryVideoPlaybackControl from './IHistoryVideoPlaybackControl.interface'
import IFileUploadCommandByFTP from './IFIleUploadCommandByFTP.interface'
import IFTPFileUploadControl from './IFTPFileUploadControl.interface'
import ICameraShootsImmediately from './ICameraShootsImmediately.interface'
import IMultimediaDataRetrieval from './IMultimediaDataRetrieval.interface'
import IMultimediaDataUpload from './IMultimediaDataUpload.interface'
import IDataDownlinkTransparentTransmission from './IDataDownlinkTransparentTransmission.interface'
import IManualAcknowledmentOfAlarmMessages from './IManualAcknowledmentOfAlarmMessages.interface'
import ITerminalControl from './ITerminalControl.interface'
import ITextMessageDistribution from './ITextMessageDistribution.interface'
import IAlarmAttachementUpload from './IAlarmAttachementUpload.interface'
import mergeOptions from '../../utils/mergeOptions'

export interface IAdditionalCommandParameters {
    /**
     * Server flag ID
     */
    serverFlagId?: string;
    /**
     * Pro No
     */
    proNo?: string;
    /**
     * Platform
     */
    platform?: string;
    /**
     * Request ID
     */
    requestId?: string;
    /**
     * Command type
     */
    cmdType?: string;
    /**
     * Token
     */
    token?: string;
}

export const additionalOptionalCommandParameters = {
    serverFlagId: "1",
    platform: "web",
    requestId: "6",
    cmdType: "normallns",
    token: "123"
}

export interface ISendInstructParams {
    imei: string
    proNo: number
    cmdParams: any
    additionalCommandParameters: IAdditionalCommandParameters
}


const COMMAND = {
    COMMAND: (imei: string, cmdParams: ICommand, additionalCommandParametersOptions: IAdditionalCommandParameters = {}) => {

        return { imei, cmdParams, proNo: 128, additionalCommandParameters: mergeOptions(additionalOptionalCommandParameters, additionalCommandParametersOptions) }
    },
    REALTIME_AUDIO_AND_VIDEO_TRANSMISSION: (imei: string, cmdParams: IRealTimeAudioAndVideoTransmissionRequest, additionalCommandParametersOptions: IAdditionalCommandParameters = {}) => {

        return { imei, cmdParams, proNo: 37121, additionalCommandParameters: mergeOptions(additionalOptionalCommandParameters, additionalCommandParametersOptions) }
    },
    AUDIO_AND_VIDEO_REALTIME_TRANSMISSION_CONTROL: (imei: string, cmdParams: IAudioAndVideoRealtimeTransmissionControl, additionalCommandParametersOptions: IAdditionalCommandParameters = {}) => {

        return { imei, cmdParams, proNo: 37122, additionalCommandParameters: mergeOptions(additionalOptionalCommandParameters, additionalCommandParametersOptions) }
    },
    QUERY_THE_LIST_OF_AUDIO_AND_VIDEO_RESOURCES: (imei: string, cmdParams: IQueryTheListOfAudioAndVideoResources, additionalCommandParametersOptions: IAdditionalCommandParameters = {}) => {

        return { imei, cmdParams, proNo: 37381, additionalCommandParameters: mergeOptions(additionalOptionalCommandParameters, additionalCommandParametersOptions) }
    },
    HISTORY_VIDEO_PLAYBACK_REQUEST: (imei: string, cmdParams: IHistoryVideoPlaybackRequest, additionalCommandParametersOptions: IAdditionalCommandParameters = {}) => {

        return { imei, cmdParams, proNo: 37377, additionalCommandParameters: mergeOptions(additionalOptionalCommandParameters, additionalCommandParametersOptions) }
    },
    HISTORY_VIDEO_PLAYBACK_CONTROL: (imei: string, cmdParams: IHistoryVideoPlaybackControl, additionalCommandParametersOptions: IAdditionalCommandParameters = {}) => {

        return { imei, cmdParams, proNo: 37378, additionalCommandParameters: mergeOptions(additionalOptionalCommandParameters, additionalCommandParametersOptions) }
    },
    FILE_UPLOAD_COMMAND_BY_FTP: (imei: string, cmdParams: IFileUploadCommandByFTP, additionalCommandParametersOptions: IAdditionalCommandParameters = {}) => {

        return { imei, cmdParams, proNo: 37382, additionalCommandParameters: mergeOptions(additionalOptionalCommandParameters, additionalCommandParametersOptions) }
    },
    FTP_FILE_UPLOAD_CONTROL: (imei: string, cmdParams: IFTPFileUploadControl, additionalCommandParametersOptions: IAdditionalCommandParameters = {}) => {

        return { imei, cmdParams, proNo: 37383, additionalCommandParameters: mergeOptions(additionalOptionalCommandParameters, additionalCommandParametersOptions) }
    },
    CAMERA_SHOOTS_IMMEDIATELY: (imei: string, cmdParams: ICameraShootsImmediately, additionalCommandParametersOptions: IAdditionalCommandParameters = {}) => {

        return { imei, cmdParams, proNo: 34817, additionalCommandParameters: mergeOptions(additionalOptionalCommandParameters, additionalCommandParametersOptions) }
    },
    MULTIMEDIA_DATA_RETRIEVAL: (imei: string, cmdParams: IMultimediaDataRetrieval, additionalCommandParametersOptions: IAdditionalCommandParameters = {}) => {

        return { imei, cmdParams, proNo: 34818, additionalCommandParameters: mergeOptions(additionalOptionalCommandParameters, additionalCommandParametersOptions) }
    },
    MULTIMEDIA_DATA_UPLOAD: (imei: string, cmdParams: IMultimediaDataUpload, additionalCommandParametersOptions: IAdditionalCommandParameters = {}) => {

        return { imei, cmdParams, proNo: 34819, additionalCommandParameters: mergeOptions(additionalOptionalCommandParameters, additionalCommandParametersOptions) }
    },
    DATA_DOWNLINK_TRANSPARENT_TRANSMISSION: (imei: string, cmdParams: IDataDownlinkTransparentTransmission, additionalCommandParametersOptions: IAdditionalCommandParameters = {}) => {

        return { imei, cmdParams, proNo: 35072, additionalCommandParameters: mergeOptions(additionalOptionalCommandParameters, additionalCommandParametersOptions) }
    },
    MANUAL_ACKNOWLEDMENT_OF_ALARM_MESSAGES: (imei: string, cmdParams: IManualAcknowledmentOfAlarmMessages, additionalCommandParametersOptions: IAdditionalCommandParameters = {}) => {

        return { imei, cmdParams, proNo: 33283, additionalCommandParameters: mergeOptions(additionalOptionalCommandParameters, additionalCommandParametersOptions) }
    },
    TERMINAL_CONTROL: (imei: string, cmdParams: ITerminalControl, additionalCommandParametersOptions: IAdditionalCommandParameters = {}) => {

        return { imei, cmdParams, proNo: 33029, additionalCommandParameters: mergeOptions(additionalOptionalCommandParameters, additionalCommandParametersOptions) }
    },
    TEXT_MESSAGE_DISTRIBUTION: (imei: string, cmdParams: ITextMessageDistribution, additionalCommandParametersOptions: IAdditionalCommandParameters = {}) => {

        return { imei, cmdParams, proNo: 33536, additionalCommandParameters: mergeOptions(additionalOptionalCommandParameters, additionalCommandParametersOptions) }
    },
    ALARM_ATTACHEMENT_UPLOAD: (imei: string, cmdParams: IAlarmAttachementUpload, additionalCommandParametersOptions: IAdditionalCommandParameters = {}) => {

        return { imei, cmdParams, proNo: 37384, additionalCommandParameters: mergeOptions(additionalOptionalCommandParameters, additionalCommandParametersOptions) }
    }
}

export type SendInstructCommands = typeof COMMAND

export { COMMAND }

const facturaRoutes = {
  meta: {
    title: 'Facturas'
  },
  path: 'factura',
  component: () => import('@/components/Facturacion/modules/factura/Factura.vue'),
  children: [
    {
      meta: {
        title: 'Facturas'
      },
      path: '',
      name: 'factura',
      component: () => import('@/components/Facturacion/modules/factura/FacturaList.vue')
    },
    {
      path: 'nueva-factura',
      name: 'nueva-factura',
      meta: {
        title: 'Nueva fatura'
      },
      component: () => import('@/components/Facturacion/modules/factura/FacturaForm.vue')
    }
  ]
}

export default facturaRoutes

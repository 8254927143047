import ChatItem from '@/store/packages/Chat/ChatItem'
import { CombinedVueInstance } from 'vue/types/vue'
import { UserSchemaSimple } from '../interfaces/Chat'

const state = {
  /** Indica si se estan cargando los chats del servidor */
  loadingChats: false,
  /** Abre o cierra el drawer de los chat en la consola */
  drawer: false,
  /** Chats listados en el drawer de la consola */
  chats: {} as {[key: number]: ChatItem},
  /** El chat que actuamente se renderiza */
  currChat: null as null | ChatItem,
  /** Indica si se esta mostrando la informacion del chat */
  showCurrChatInfo: false,
  /** Indica si el bot esta omitido para las respuesta del usuario actual */
  omitBot: true,
  /** Indica el id del mensaje que se tiene que enfocar */
  currMessageIdFocus: 0,
  /** El componente actualmente abierto */
  currChatComponent: null as CombinedVueInstance<any, any, any, any, any, any>,
  /** Popup que indica el ultimo recivido en la interfaz del chat */
  currPopupChatItem: {
    popup: false,
    chatItem: null as null | ChatItem
  },
  /** Lista de usuarios conectados al socket del chat (Y que pertenecen a la misma compañia) */
  chatUsersConected: [] as UserSchemaSimple[],
  /** Id de la conexion al socket */
  id_connection: null as null | number
}

export type State = typeof state

export default state

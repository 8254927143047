const reporteRoutes = {
  meta: {
    title: 'Reportes'
  },
  path: 'reports',
  component: () => import('@/components/Facturacion/modules/reports/Report.vue'),
  children: [
    {
      meta: {
        title: 'Reportes'
      },
      path: '',
      name: 'reports',
      component: () => import('@/components/Facturacion/modules/reports/ReportView.vue')
    }
    // {
    //   path: 'nueva-cotizacion',
    //   name: 'nueva-cotizacion',
    //   meta: {
    //     title: 'Cotización'
    //   },
    //   component: () => import('@/components/Facturacion/modules/quotation/QuotationForm.vue')
    // }
  ]
}

export default reporteRoutes

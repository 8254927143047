
export interface TollState {
  tolls: any[]
}

const state = (): TollState => ({
  tolls: []
})
export type State = ReturnType<typeof state>;

export default state

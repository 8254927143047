import { DateTime } from 'luxon'
import { IFile } from '@/interfaces/IFIle.interface'
import { IChat, IChatMessage, IChatType, IChatUser, UserSchemaSimple } from '@/store/interfaces/Chat'
import store from '@/store'
import { CHAT_TYPE } from '@/store/catalogs/CHAT_TYPE'
import BaseClass from '../BaseClass'
import ChatMessage from './ChatMessage'
import { CHAT_MESSAGE_STATE } from '@/store/catalogs/CHAT_MESSAGE_STATE'
import ChatUser from './ChatUser'
import { AtLeastOne } from '@/store/interfaces/AtLeastPropertiesInterface'
import { CHAT_BOT_CONSTANTS } from '@/store/catalogs/CHAT_BOT'

export default class ChatItem extends BaseClass<IChat> implements IChat {
  id_chat!: number;
  cht_name!: string;
  cht_user_created_name!: string | null;
  cht_description!: string;
  chat_thumbnail_file!: IFile | null;
  chat_messages!: ChatMessage[];
  chat_users!: ChatUser[];
  cht_bot_context_message!: string
  id_chat_type!: CHAT_TYPE;
  chat_type!: IChatType;
  cht_createdAt!: string;
  cht_bot_allowed!: boolean;
  cht_bot_active!: boolean;
  user_created!: UserSchemaSimple;
  cht_additional_config!: { [key: string]: any; };
  id_account!: number
  cht_bot_temperature!: number
  /** Omita los mensajes de los bots al preguntar en el chat */
  omitBot!: boolean
  /** Ayuda a saber si se an obtenido todos lo mensajes de este chat al scrollear hacia arriba */
  isAllMessagesLoaded!: boolean
  isChatChatbot: boolean
  /**
   * Returns the number of unread chat messages.
  *
  * @returns {number} The number of unread chat messages.
  */
 private _unreadedChatMessages: number;
 private _unreadedChatMessagesItems: ChatMessage[]
 private _id_attached_filed_selected!: number | null;

 constructor (params: { data: IChat }) {
   super(params)
   this.isAllMessagesLoaded = false
   this._unreadedChatMessages = 0
   this._unreadedChatMessagesItems = []
   // De preferenia actualizar el status de los mensajes primero antes que los no leidos
   this.updateUserMessagesStatus()
   this.updateUnreadedChatMessages()
   this.isChatChatbot = this.isChatBotChat()
 }

 public onInitInstance (): void {
   // ---
 }

 /**
   * Checks if the chat is a one to one with the chatbot.
   * @returns {boolean} True if the chat is a one to one with the chatbot, false otherwise.
   */
 public isChatBotChat (): boolean {
   return this.chat_users.some(chatUser => chatUser.id === CHAT_BOT_CONSTANTS.ID) && this.isType(CHAT_TYPE.ONE_TO_ONE)
 }

 public rehidratateData ({ chat_messages, chat_users, ...data }: IChat): void {
   // Rehidratando parcialmente el objeto para evitar que actualizar datos que no quiero (como los chat_messages y chat_users)
   super.rehidratateData({
     chat_messages: this.chat_messages ?? chat_messages,
     chat_users: this.chat_users ?? chat_users,
     ...data
   })

   // Creando objetos de tipo ChatMessage de los IChatMessage del chat
   this.rehidratateChatMessages(chat_messages)
   this.rehidratateChatUsers(chat_users)

   // Actualizando los datos del ultimo mensaje del chat
   this.setLastData({ ...data, chat_messages, chat_users })

   // De preferenia actualizar el status de los mensajes primero antes que los no leidos
   this.updateUserMessagesStatus()
   this.updateUnreadedChatMessages()
 }

 public rehidratateChatMessages (data: IChatMessage[]) {
   // En caso de que el array no exista (porque es la primera vez que se carga el chat) se crea un array vacio
   if (!this.chat_messages) {
     this.chat_messages = []
   }

   // Creo un mapa con los id_chat_message como clave y el index como valor
   const currMapMesagesIndex = this.chat_messages.reduce((prev, curr, index) => {
     prev[curr.id_chat_message] = index
     return prev
   }, {} as { [key: number]: number })

   // Si hay nuevo chat message, se guardan para ser añadidos posteriormente
   const newChatMessages: IChatMessage[] = []

   for (const mess of data) {
     const chatMessageIndex = currMapMesagesIndex[mess.id_chat_message]
     if (chatMessageIndex === undefined) {
       // Si el mensaje es undefined, se añade al array para añadirlo posteriormente como un ChatMessage
       newChatMessages.push(mess)
     } else if (chatMessageIndex !== undefined && !(this.chat_messages[chatMessageIndex] instanceof ChatMessage)) {
       // Si el mensaje existe, pero no es una instancia de ChatMessage, se crea una nueva instancia de ChatMessage y se sustituye el antiguo
       this.chat_messages[chatMessageIndex] = new ChatMessage({ data: mess, chatItem: this })
     } else {
       // Si el chat message ya existe, se actualiza
       this.chat_messages[chatMessageIndex].rehidratateData(mess)
     }
   }

   this.addChatMessagesByIChatMessages(newChatMessages)
 }

 public rehidratateChatUsers (data: IChatUser[]) {
   // En caso de que el array no exista (porque es la primera vez que se carga el chat) se crea un array vacio
   if (!this.chat_users) {
     this.chat_users = []
   }
   // Creo un mapa con los id_chat_user como clave y el index como valor
   const currMapChatUsersIndex = this.chat_users.reduce((prev, curr, index) => {
     prev[curr.id_chat_user] = index
     return prev
   }, {} as { [key: number]: number })

   // Si hay nuevo chat users, se guardan para ser añadidos posteriormente
   const newChatUsers: IChatUser[] = []
   for (const chatuser of data) {
     const chatUserIndex = currMapChatUsersIndex[chatuser.id_chat_user]
     if (chatUserIndex === undefined) {
       // Si el chat user es undefined, se añade al array para añadirlo posteriormente como un ChatUser
       newChatUsers.push(chatuser)
     } else if (chatUserIndex !== undefined && !(this.chat_users[chatUserIndex] instanceof ChatUser)) {
       // Si el chat user existe, pero no es una instancia de ChatUser, se crea una nueva instancia de ChatUser y se sustituye el antiguo
       this.chat_users[chatUserIndex] = new ChatUser({ data: chatuser })
     } else {
       // Si el chat user ya existe, se actualiza
       this.chat_users[chatUserIndex].rehidratateData(chatuser)
     }
   }

   this.addChatUsersByIChatUsers(newChatUsers)
 }

 /**
 * Adds a new chat message to the chat item.
 *
 * @param payload - The payload containing the message content, optional message files, and user information.
 * @returns void
 */
 public addMessage (payload: {
    chm_message: string;
    raw_message_files?: File[],
    raw_chat_message_audio?: File | null
    id_chat_message_to_reply?: number | null
    chat_message_to_reply?: ChatMessage | null
    omit_bot_message: boolean
  }) {
   const chatMessage = ChatMessage.createEmptyChatMessage({
     chatItem: this,
     ...payload
   })
   this.chat_messages.push(chatMessage)
   chatMessage.rehidratateChatMessage()
 }

 public addChatMessagesByIChatMessages (payload: IChatMessage[]) {
   const chatMessages = payload.map(data => new ChatMessage({ data, chatItem: this }))
   this.chat_messages.push(...chatMessages)
   // Obtengo el chat user del usuario actual
   const ownChatUser = this.getOwnChatUser()
   const isChatFocused = Boolean(this.getIsThisChatFocused() && ownChatUser)
   // SI el chat esta en foco y no esta vacio, se actualiza el ultimo chu_last_seen_message_id
   // Y se actualizan los mensajes no leidos
   if (isChatFocused && ownChatUser && this.lastChatMessage) {
     ownChatUser.updateProperty('chu_last_seen_message_id', this.lastChatMessage.id_chat_message)
   }
   this.updateUnreadedChatMessages()
 }

 public addChatMessage (payload: ChatMessage) {
   this.chat_messages.push(payload)
   // Obtengo el chat user del usuario actual
   const ownChatUser = this.getOwnChatUser()
   const isChatFocused = Boolean(this.getIsThisChatFocused() && ownChatUser)
   // SI el chat esta en foco y no esta vacio, se actualiza el ultimo chu_last_seen_message_id
   // Y se actualizan los mensajes no leidos
   if (isChatFocused && ownChatUser) {
     ownChatUser.updateProperty('chu_last_seen_message_id', payload.id_chat_message)
   }
   this.updateUnreadedChatMessages()
 }

 /**
   * Updates the last seen message ID for the current user in the chat.
   *
   * This method updates the 'chu_last_seen_message_id' property of the current user's chat user object
   * with the ID of the last chat message in the chat. This indicates that the user has seen all messages
   * up to and including the last message.
   *
   * If the current user is not a member of the chat or there are no chat messages in the chat,
   * the method does nothing.
   */
 public updateToLastMessageSeenId () {
   const ownChatUser = this.getOwnChatUser()
   if (!ownChatUser || !this.lastChatMessage) return
   ownChatUser.updateProperty('chu_last_seen_message_id', this.lastChatMessage.id_chat_message)
 }

 public removeUserByID (id_user: number) {
   const index = this.chat_users.findIndex(user => user.id_user === id_user)
   if (index === -1) return
   this.chat_users.splice(index, 1)
 }

 public updateChatUsersById (chatUsersData: IChatUser[]) {
   chatUsersData.forEach(chatUserData => {
     const currChatUser = this.chatUsersIndexed.get(chatUserData.chu_user_saved_data.id)
     if (!currChatUser) return
     currChatUser.rehidratateData(chatUserData)
   })
 }

 public addChatUsersByIChatUsers (data: IChatUser[]) {
   this.chat_users.push(...data.map(ichatuser => new ChatUser({ data: ichatuser })))
 }

 public getOwnChatUserId (): number | undefined {
   return this.chatUsersIndexed.get(store.getters['auth/userId'])?.id_chat_user
 }

 public get ownChatUser (): ChatUser | undefined {
   return this.getOwnChatUser()
 }

 public updateUserMessagesStatus () {
   for (const mess of this.chat_messages) {
     // Si el mensaje tiene los siguientes estados o si el mensaje no pertene al usuario, continuamos al siguientes mensaje
     if (
       mess.isState(CHAT_MESSAGE_STATE.VIEWED, CHAT_MESSAGE_STATE.ERROR, CHAT_MESSAGE_STATE.SENDING) ||
        mess.id_user !== store.getters['auth/userId']
     ) continue

     // Si todos los usuarios ya vieron el mensaje, se le cambia el estado y se continua al siguiente al siguiente mensaje
     if (mess.isAllChatUsersSeeThisMessage()) {
       mess.setMessageState(CHAT_MESSAGE_STATE.VIEWED)
       continue
     }

     // Si todos los usuarios ya recivieron el mensaje, se le cambia el estado y se continua al siguiente mensaje
     if (mess.isAllChatUsersGetThisMessage()) {
       mess.setMessageState(CHAT_MESSAGE_STATE.RECEIVED)
       continue
     }
   }
 }

 /**
   * Returns the chat user object for the authenticated user.
   *
   * @returns {ChatUser | undefined} The chat user object for the authenticated user, or undefined if the user is not found.
   */
 public getIsThisChatFocused () {
   // @ts-ignore
   const currChatItem: ChatItem | null = store.state.chat.currChat
   return Boolean(currChatItem?.id_chat === this.id_chat && !this.isEmpty)
 }

 /**
   * Retrieves the chat user object for the authenticated user.
   *
   * @returns {ChatUser | undefined} The chat user object for the authenticated user, or undefined if not found.
   */
 public getOwnChatUser () {
   const chatUser = this.chatUsersIndexed.get(store.getters['auth/userId'])
   return chatUser
 }

 /**
   * Updates the count of unread chat messages for the authenticated user.
   *
   * This method calculates the number of unread chat messages by comparing the `id_chat_item` of each message in the `chat_messages` array with the `chu_last_seen_message_id` of the authenticated user. If the `id_chat_item` is greater than `chu_last_seen_message_id`, it means the message is unread.
   *
   * @returns {void}
   */
 public updateUnreadedChatMessages () {
   const authChatUser = this.getOwnChatUser()
   if (!authChatUser) {
     this._unreadedChatMessages = 0
     return
   }
   // Si el usuario nunca a entrado al chat (por ende chu_last_seen_message_id estaria null) devuelvo todos los mensajes como no leidos
   if (!authChatUser?.chu_last_seen_message_id) {
     this._unreadedChatMessages = this.chat_messages.length
     return
   }
   const unreadedChatMessagesItems = this.chat_messages.filter(mess => {
     // @ts-ignore
     return !mess.isState(CHAT_MESSAGE_STATE.VIEWED) && mess.id_chat_message > authChatUser.chu_last_seen_message_id
   })

   this._unreadedChatMessages = unreadedChatMessagesItems.length
   this._unreadedChatMessagesItems = unreadedChatMessagesItems
 }

 /**
   * Fetches more messages for the chat.
   *
   * This method retrieves older messages from the server and adds them to the chat.
   *
   * @returns {Promise<void>} - A promise that resolves once the messages are fetched and added to the chat.
   */
 public async fetchMoreMessages () {
   // Si ya se establecio que no hay mas mensajes, se retorna directamenete
   if (this.isAllMessagesLoaded) return
   const firstMessage = this.chat_messages.at(0)
   if (!firstMessage) return
   const oldMessages: IChatMessage[] = await store.dispatch('chat/loadOldMessages', {
     count: 30,
     id_chat: this.id_chat,
     id_chat_message: firstMessage.id_chat_message
   })
   // Si no hay mas mensajes disponibles en el servidor, returnamos y cambiamos la variable isAllMessagesLoaded a true
   if (oldMessages.length < 1) {
     this.isAllMessagesLoaded = true
     return
   }
   const chatMessages = oldMessages.map(data => new ChatMessage({ data, chatItem: this, state: CHAT_MESSAGE_STATE.SENDED }))
   this.chat_messages.unshift(...chatMessages)
 }

 public updateChatUsersByIChatUsers (ichatUsers: AtLeastOne<IChatUser>[]) {
   ichatUsers.forEach(data => {
     const curr_id_user = data.id_user
     if (!curr_id_user) return

     // @ts-ignore
     const chatUser = this.chatUsersIndexed.get(data.id_user)
     if (!chatUser) return

     // Antes de actulizar me cersioro de que el chu_last_seen_message_id sea un numero para evitar errores
     const parsedData = { ...data, ...(data.chu_last_seen_message_id ? { chu_last_seen_message_id: Number(data.chu_last_seen_message_id) } : {}) }
     // @ts-ignore
     chatUser.rehidratateData(parsedData)
   })
 }

 public isType (...types: CHAT_TYPE[]) {
   return new Set(types).has(this.id_chat_type)
 }

 public updateChatUserByChatUserId (chatUserId: number, data: AtLeastOne<IChatUser>) {
   // @ts-ignore
   const chatUserIndex = this.chat_users.findIndex(chatUser => chatUser.id_chat_user === chatUserId)
   if (chatUserIndex === -1) return
   // @ts-ignore
   this.chat_users[chatUserIndex].rehidratateData(data)
 }

 public getComunicationsUsers (): any {
   if (this.id_chat_type !== CHAT_TYPE.ONE_TO_ONE) return undefined
   if (!this.userToChat) return undefined

   // @ts-ignore
   return (store.state.chat.chatUsersConected as UserSchemaSimple[]).find(user => user.id === this.userToChat?.chu_user_saved_data.id)
 }

 public get isChatSilent () {
   return this.ownChatUser?.chu_silent_chat ?? false
 }

 public get isUserMentionInChat () {
   const chatUser: ChatUser | undefined = this.getOwnChatUser()
   if (!chatUser) return false
   const mentionRegex = new RegExp(ChatUser.usNameToMention(chatUser.us_name), 'i')
   return this.unreadedChatMessagesItems.some(mess => {
     return mentionRegex.test(mess.chm_message)
   })
 }

 // =======================================================================================================================================================
 // Getters
 // =======================================================================================================================================================

 /**
   * Returns the available chat users.
   *
   * This method filters the `chat_users` array and returns only the chat users that are not hidden and have a valid user object.
   *
   * @returns {ChatUser[]} An array of available chat users.
   */
 public get avaliableChatUsers () {
   return this.chat_users.filter(obj => !obj.chu_is_hidden && obj.user)
 }

 public get lastMessageTimeMillis () {
   if (!this.lastChatMessage) return null
   return DateTime.fromISO(this.lastChatMessage?.chm_createdAt).toMillis()
 }

 public get filesResourcesMessages () {
   return this.chat_messages.flatMap(mess => mess.message_files)
 }

 public get isAuthUserAdmin () {
   return this.chatUsersIndexed.get(store.getters['auth/userId'])?.chu_is_admin
 }

 public get is_chatbot_avaliable () {
   return this.cht_bot_active && this.cht_bot_allowed
 }

 public deleteChatMessageById (id_chat_message: number) {
   const index = this.chat_messages.findIndex(mess => mess.id_chat_message === id_chat_message)
   if (index === -1) return

   const message = this.chat_messages[index]
   if (message.isEmpty) {
     this.chat_messages.splice(index, 1)
   } else {
     message.updateProperty('chm_is_deleted', true)
   }
 }

 /**
   * Es una version del array de IChatUser pero con los datos indexados por el id_user del usuario del chat
   */
 public get chatUsersIndexed () {
   const chatUsersMap = new Map<number, ChatUser>()
   for (const user of this.chat_users) {
     chatUsersMap.set(user.curr_id_user, user)
   }
   return chatUsersMap
 }

 public get isEmpty () {
   return this.id_chat === -1
 }

 public get lastChatMessage (): ChatMessage | undefined {
   if (!this.chat_messages.length) return undefined
   return this.chat_messages.at(-1)
 }

 public get thumbnailUrl () {
   if (this.id_chat_type === CHAT_TYPE.ONE_TO_ONE) {
     if (!this.userToChat || !this.userToChat.user?.thumbnail_file?.fl_url) return ''
     // @ts-ignore
     return `${store.state.app.BASE_URL}${this.userToChat.user?.thumbnail_file?.fl_url}`
   } else if (this.id_chat_type === CHAT_TYPE.GROUP) {
     if (!this.chat_thumbnail_file || !this.chat_thumbnail_file?.fl_url) return ''
     // @ts-ignore
     return `${store.state.app.BASE_URL}${this.chat_thumbnail_file?.fl_url}`
   }
   return ''
 }

 public get lastChatMessageText () {
   if (!this.lastChatMessage) return ''
   return this.lastChatMessage.chm_message
 }

 public get lastChatMessageUserName () {
   if (!this.lastChatMessage) return ''
   return this.lastChatMessage.user?.username || this.lastChatMessage.chm_user_name
 }

 public get lastMessageTime () {
   if (!this.lastChatMessage) return ''
   const isLastMessageTimeToday = this.getIsLastMessageTimeToday()
   const isLastMessageTimeYesterday = this.getIsLastMessageTimeYesterday()
   const isLastMessageTimeInSixDays = this.getIsLastMessageTimeInSixDays()

   if (isLastMessageTimeToday) {
     return this.getLastMessageTimeFormated()
   } else if (isLastMessageTimeYesterday) {
     return 'Ayer'
   } else if (isLastMessageTimeInSixDays) {
     return this.getLastMessageDayName()
   } else {
     return this.getLastMessageDateFormated()
   }
 }

 public get chatName () {
   if (this.id_chat_type === CHAT_TYPE.GROUP) return this.cht_name
   return this.userToChat?.us_name || this.cht_name
 }

 public get chatUserNames () {
   return (this.avaliableChatUsers.map(obj => obj.user?.username) ?? [])
 }

 public get userToChat () {
   // Si es esta en un chat grupal, no hay un usuario con el cual chatear, es para chats uno a uno
   if (this.id_chat_type !== CHAT_TYPE.ONE_TO_ONE) return undefined

   const id_user = store.getters['auth/userId']
   const userJoined = this.chat_users.find(obj => obj.id_user !== id_user)
   return userJoined
 }

 public get isUserToChatConected () {
   if (this.id_chat_type !== CHAT_TYPE.ONE_TO_ONE) return false
   if (!this.userToChat) return false
   return (store.getters['chat/objSetUsersConectedIds'] as Set<number>).has(this.userToChat.curr_id_user)
 }

 public get userToChatLastTimeConected () {
   if (this.id_chat_type !== CHAT_TYPE.ONE_TO_ONE) return ''
   if (!this.userToChat) return ''
   if (!this.userToChat?.user?.last_time_conected) return ''

   // @ts-ignore
   return this.getNaturalFormatedDate({ offset: store.state.app.timeoffset, isoDate: this.userToChat?.user?.last_time_conected })
 }

 public get naturalCreatedDate () {
   // @ts-ignore
   return this.getNaturalFormatedDate({ isoDate: this.cht_createdAt, offset: store.state.app.timeoffset })
 }

 /**
  * Returns the number of unread chat messages.
  *
  * @returns {number} The number of unread chat messages.
  */
 public get unreadedChatMessages (): number {
   return this._unreadedChatMessages
 }

 public get unreadedChatMessagesItems (): ChatMessage[] {
   return this._unreadedChatMessagesItems
 }

 public get id_attached_filed_selected (): number | null {
   return this._id_attached_filed_selected
 }

 public set id_attached_filed_selected (value: number | null) {
   this._id_attached_filed_selected = value
 }

 // =======================================================================================================================================================
 // Manejo de fechas del ultimo mensaje
 // =======================================================================================================================================================

 public getLastMessageOffsetDateTime () {
   if (!this.lastChatMessage) return null
   // @ts-ignore
   const dateTimeOffset = DateTime.fromISO(this.lastChatMessage.chm_createdAt, { zone: 'utc' }).setLocale('es').plus({ hours: store.state.app.timeoffset })
   return dateTimeOffset
 }

 public getIsLastMessageTimeToday () {
   const lastMessageDateTime = this.getLastMessageOffsetDateTime()
   if (!lastMessageDateTime) return false
   // @ts-ignore
   const today = this.getUTCDateTimeOffset({ offset: store.state.app.timeoffset })

   // Compara si la fecha dada es igual al día de hoy (ignorando la hora)
   const isToday = lastMessageDateTime.hasSame(today, 'day')
   return isToday
 }

 public getIsLastMessageTimeYesterday () {
   const lastMessageDateTime = this.getLastMessageOffsetDateTime()
   if (!lastMessageDateTime) return false
   // Obtiene la fecha de ayer
   // @ts-ignore
   const yesterday = this.getUTCDateTimeOffset({ offset: store.state.app.timeoffset }).minus({ days: 1 })

   // Compara si la fecha dada es igual al día de ayer (ignorando la hora)
   const isYesterday = lastMessageDateTime.hasSame(yesterday, 'day')
   return isYesterday
 }

 public getIsLastMessageTimeInSixDays () {
   const lastMessageDateTime = this.getLastMessageOffsetDateTime()
   if (!lastMessageDateTime) return false
   // Obtener la fecha actual y restarle 6 días para obtener el inicio del rango
   // @ts-ignore
   const initRange = this.getUTCDateTimeOffset({ offset: store.state.app.timeoffset }).minus({ days: 6 }).startOf('day')

   // Comprobar si la fecha está dentro de los últimos 6 días
   const inRange = lastMessageDateTime >= initRange && lastMessageDateTime < DateTime.utc().setLocale('es').startOf('day')
   return inRange
 }

 public getLastMessageDayName () {
   const lastMessageDateTime = this.getLastMessageOffsetDateTime()
   if (!lastMessageDateTime) return ''
   return lastMessageDateTime.toFormat('cccc')
 }

 public getLastMessageTimeFormated () {
   const lastMessageDateTime = this.getLastMessageOffsetDateTime()
   if (!lastMessageDateTime) return ''
   return lastMessageDateTime.toFormat('HH:mm')
 }

 public getLastMessageDateFormated () {
   const lastMessageDateTime = this.getLastMessageOffsetDateTime()
   if (!lastMessageDateTime) return ''
   return lastMessageDateTime.toFormat('dd/MM/yyyy')
 }

 // =======================================================================================================================================================
 // Static methods
 // =======================================================================================================================================================

  public static chatUserAllMentionsREGEX = /(?<![\p{L}\d_.])@\p{Lu}[\p{L}\d_.]*/gu;

  /**
   * Creates an empty chat item with the given payload.
   *
   * @param payload - The payload containing the user ID and name.
   * @returns A new ChatItem instance.
   */
  public static createEmptyChatItem (payload: { id: number; username: string; }) {
    return new ChatItem({
      data: {
        id_chat: -1,
        chat_messages: [],
        chat_thumbnail_file: null,
        chat_files_attached: [],
        chat_type: {
          id_chat_type: CHAT_TYPE.ONE_TO_ONE,
          ctp_name: 'ONE_TO_ONE'
        },
        chat_users: [
          // @ts-ignore
          {
            id_user: payload.id,
            // @ts-ignore
            user: payload
          },
          // @ts-ignore
          {
            id_user: store.getters['auth/userId'],
            // @ts-ignore
            user: {
              id_user: store.getters['auth/userId'],
              us_name: store.getters['auth/username']
            }
          }
        ],
        cht_createdAt: DateTime.utc().toISO(),
        cht_description: '',
        cht_name: payload.username,
        id_chat_type: CHAT_TYPE.ONE_TO_ONE,
        user_created: {
          first_name: '',
          // @ts-ignore
          id: null,
          last_name: '',
          username: ''
        },
        cht_user_created_name: ''
      }
    })
  }
}

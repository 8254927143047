export interface DefaultParams {
  /** Turn off the interval of events */
  disableInterval?: boolean
  /** Time of the interval of events, default 10 seconds in ms */
  intervalTime?: number
  /** The debug mode of the sdk */
  debug?: boolean,
  /** The port of the API service for device instructions in Send Device Instruction Command Interface , for example http://113.108.62.202:10088 */
  apiDeviceInstruccionPort?: number
  /** The address of the API service for DVRs in jimi-api, which is used for deliver commands, for example http://113.108.62.202:9080 */
  apiDeviceDVRPort?: number
  /** Whether to use https */
  useHttps?: boolean
  /** Whether to use ports as url on the server interface of jimiiot */
  usePortsAsUrl?: boolean
}

export interface JimiIothubSDKConfig {
  /** The iothub server url of your jimiiot */
  iothubServerIp: string
  /** 
   * The address of the service Interface for receiving device data in the docker-compose.yml file, see (3.2.3.2 Configuring pushURL)[https://docs.jimicloud.com/guide/installation#3232-Configuring-pushURL] for details. 
   * For example: 113.108.62.202:8888
   * */
  iothubCustomerServerInterfaceHost: string
}

const defaultParams = (config: Partial<DefaultParams> & JimiIothubSDKConfig) => {
  const params: DefaultParams & JimiIothubSDKConfig = {
    disableInterval: false,
    intervalTime: 10000,
    debug: false,
    apiDeviceInstruccionPort: 10088,
    apiDeviceDVRPort: 9080,
    useHttps: false,
    usePortsAsUrl: false,
    ...config
  }

  // @ts-ignore
  return params
}

export type ISDKParams = ReturnType<typeof defaultParams>;

export default defaultParams

import { State } from '../../state'
import { ActionContext } from 'vuex'
import { IPartsMaterials } from '../../../../classes/Inventory/interfaces/IPartsMaterials'
const actions = {
  async loadPartsMaterials ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/parts_materials/get/all',
        method: 'GET'
      },
      { root: true }
    )
    commit('SET_PARTS_MATERIALS', data)
    return data
  },
  async createPartsMaterials ({ commit, dispatch }: ActionContext<State, any>, payload: IPartsMaterials) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/parts_materials/create',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    commit('PUSH_PARTS_MATERIALS', data)
    return data
  },
  async updatePartsMaterials ({ commit, dispatch }: ActionContext<State, any>, payload: IPartsMaterials) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/parts_materials/update/${payload.id}`,
        method: 'PUT',
        data: payload
      },
      { root: true }
    )
    commit('UPDATE_PARTS_MATERIALS', data)
    return data
  },
  async deletePartsMaterials ({ commit, dispatch }: ActionContext<State, any>, payload: IPartsMaterials) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/parts_materials/delete/${payload.id}`,
        method: 'DELETE'
      },
      { root: true }
    )
    commit('DELETE_PARTS_MATERIALS', payload)
    return data
  }
}
export default actions

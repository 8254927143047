// import { GeofencesState, ICGeofence } from './state'
import { BatteryState } from '../../state'
import { IBatteryMaker } from '@/classes/Battery/interfaces/IBatteryMaker'

export default {
  SET_BATTERY_MAKER (state: BatteryState, entities: IBatteryMaker[]): void {
    state.battery_maker = entities
  },
  PUSH_BATTERY_MAKER (state: BatteryState, entity: IBatteryMaker): void {
    state.battery_maker.push(entity)
  },
  UPDATE_BATTERY_MAKER (state: BatteryState, entity: IBatteryMaker): void {
    const index = state.battery_maker.findIndex(p => p.id === entity.id)
    if (index !== -1) {
      state.battery_maker.splice(index, 1, entity)
    }
  },
  DELETE_BATTERY_MAKER (state: BatteryState, entity: IBatteryMaker): void {
    const index = state.battery_maker.findIndex(p => p.id === entity.id)
    if (index !== -1) {
      state.battery_maker.splice(index, 1)
    }
  }
}

interface SuccessResult<T> {
    success: true;
    value: T;
}

interface ErrorResult<E> {
    success: false;
    error: E;
}

/**
 * Represents a result that can be either successful or an error.
 * Provides methods to create successful or error results, check the result status,
 * unwrap the value if successful, handle errors, and apply transformations.
 */
export default class Result<T, E> {
    private constructor(
        private readonly success: boolean,
        private readonly value?: T,
        private readonly error?: E
    ) { }

    /**
     * Creates a successful result.
     *
     * @param value - The value for the successful result.
     * @returns A new successful result.
     */
    static Ok<T>(value: T): Result<T, any> {
        return new Result<T, any>(true, value);
    }


    /**
     * Creates an error result.
     *
     * @param error - The error value for the error result.
     * @returns A new error result.
     */
    static Err<E>(error: E): Result<any, E> {
        return new Result<any, E>(false, undefined, error);
    }

    /**
     * Checks if the result is successful.
     *
     * @returns True if the result is successful, false otherwise.
     */
    isOk(): this is SuccessResult<T> {
        return this.success;
    }

    /**
     * Returns true if the result is an error.
     *
     * @returns True if the result is an error, false otherwise.
     */
    isErr(): this is ErrorResult<E> {
        return !this.success;
    }

    /**
     * Unwraps the result and returns the value if it is successful.
     *
     * If the result is an error, throws an error with the message "Tried to unwrap an Err value: <error>".
     *
     * @returns The value of the result if it is successful.
     * @throws An error if the result is an Err.
     */
    unwrap(): T {
        if (this.isOk()) {
            return (this as SuccessResult<T>).value!;
        }
        throw new Error(`Tried to unwrap an Err value: ${this.error}`);
    }

    /**
     * Returns the value of the result if it is successful, otherwise returns the given default value.
     *
     * @param defaultValue - The value to return if this result is an error.
     * @returns The value of the result if it is successful, or the given default value if it is an error.
     */
    unwrapOr(defaultValue: T): T {
        return this.isOk() ? (this as SuccessResult<T>).value! : defaultValue;
    }

    /**
     * Returns the error value if this result is an error, otherwise undefined.
     * @returns The error value, or undefined if this result is successful.
     */
    getError(): E | undefined {
        return this.error;
    }

    /**
     * Applies a transformation function to the value of a successful result.
     *
     * @param fn - The transformation function to apply.
     * @returns A new successful result with the transformed value if this result is successful, otherwise the same error result.
     */
    map<U>(fn: (value: T) => U): Result<U, E> {
        if (this.isOk()) {
            return new Result<U, E>(true, fn((this as SuccessResult<T>).value!), undefined);
        } else {
            return Result.Err<E>(this.error!);
        }
    }

    /**
     * Maps the error value to a new value.
     *
     * If the result is a success, returns the original value. If the result is an error, applies the given function to the error value and returns the result.
     *
     * @param fn - The function to apply to the error value.
     * @returns A new result with the modified error value.
     */
    mapErr<U>(fn: (error: E) => U): Result<T, U> {
        if (this.isErr()) {
            return Result.Err(fn((this as ErrorResult<E>).error!));
        } else {
            return Result.Ok(this.value!);
        }
    }

    /**
     * Runs one of two functions depending on whether the result is a success or an error.
     *
     * @param onOk - The function to run if the result is a success.
     * @param onErr - The function to run if the result is an error.
     * @returns The result of running one of the two functions.
     */
    match<U>(onOk: (value: T) => U, onErr: (error: E) => U): U {
        return this.isOk() ? onOk((this as SuccessResult<T>).value!) : onErr(this.error!);
    }
}

// import { GeofencesState, ICGeofence } from './state'
import { BatteryState } from '../../state'
import { IBatteryModel } from '@/classes/Battery/interfaces/IBatteryModel'

export default {
  SET_BATTERY_MODEL (state: BatteryState, entities: IBatteryModel[]): void {
    state.battery_model = entities
  },
  PUSH_BATTERY_MODEL (state: BatteryState, entity: IBatteryModel): void {
    state.battery_model.push(entity)
  },
  UPDATE_BATTERY_MODEL (state: BatteryState, entity: IBatteryModel): void {
    const index = state.battery_model.findIndex(p => p.id === entity.id)
    if (index !== -1) {
      state.battery_model.splice(index, 1, entity)
    }
  },
  DELETE_BATTERY_MODEL (state: BatteryState, entity: IBatteryModel): void {
    const index = state.battery_model.findIndex(p => p.id === entity.id)
    if (index !== -1) {
      state.battery_model.splice(index, 1)
    }
  }
}

import IVehicle, { Fleet } from "../interfaces/Vehicle.interface";
import BaseItem from "./BaseItem";

export default class VehicleItem extends BaseItem<IVehicle> implements IVehicle {
    alarmState!: "NORMAL" | "ALARM";
    device!: { channels: string; uniqueId: string; };
    fleetList!: Fleet[];
    networkState!: "WIRED" | "WIFI" | "2G" | "3G" | "4G" | "5G" | null;
    onlineState!: "OFFLINE" | "ONLINE";
    vehicleCode!: string;
    vehicleId!: string;
    vehicleNumber!: string;
}
export enum REQUISITION_STATUS {
    SOLICITADA = 0,
    AUTORIZADA = 1,
    CANCELADA = 2,
    DENEGADA = 3
}

export const REQUISITION_STATUS_ARRAY = [
  {
    label: 'Solicitada',
    color: '#EF6C00',
    id: REQUISITION_STATUS.SOLICITADA
  },
  {
    label: 'Autorizada',
    color: '#43A047',
    id: REQUISITION_STATUS.AUTORIZADA
  },
  {
    label: 'Cancelada',
    color: '#E53935',
    id: REQUISITION_STATUS.CANCELADA
  },
  {
    label: 'Denegada',
    color: '#5E35B1',
    id: REQUISITION_STATUS.DENEGADA
  }
]

export function statusRequisition (status: REQUISITION_STATUS) {
  switch (status) {
    case 0:
      return 'Solicitada'
      break
    case 1:
      return 'Autorizada'
      break
    case 2:
      return 'Cancelada'
    case 3:
      return 'Denegada'
      break
    default:
      return 'No data'
      break
  }
}

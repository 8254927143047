
// import { InventoryState } from '../../state'
import { UserState } from './state'

export default {
  SET_ALL (state: UserState, entities: any[]): void {
    state.users = entities
  },
  PUSH (state: UserState, entitie: any): void {
    state.users.unshift(entitie)
  },
  UPDATE (state: UserState, entitie: any): void {
    const index = state.users.findIndex(p => p.id === entitie.id)
    if (index !== -1) {
      state.users.splice(index, 1, entitie)
    }
  }
}

import Root from '../Root/Root'

export interface IChecklistModule {
  id: number
  mod_name: string
  icon: string
}

export default class ChecklistModule extends Root implements IChecklistModule {
  id!: number
  mod_name!: string
  icon!: string

  constructor (checklistModule: IChecklistModule) {
    super()
    Object.assign(this, checklistModule)
  }
}

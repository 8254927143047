// import { GeofencesState, ICGeofence } from './state'
import mutationsBatteryCapacity from './modules/battery_capacity/mutations'
import mutationsBatteryMaker from './modules/battery_maker/mutations'
import mutationsBatteryModel from './modules/battery_model/mutations'

export default {
  ...mutationsBatteryCapacity,
  ...mutationsBatteryMaker,
  ...mutationsBatteryModel
}

/* eslint-disable @typescript-eslint/no-empty-function */

export enum TYPE_NOTIFY {
  ADAS = 264,
  DMS = 265,
  STANDARD = 256,
  ALERT_TYPE = -1
}

export class BaseEvent {
  // eslint-disable-next-line no-useless-constructor
  constructor (
    public type: string,
    public name: string,
    public color: string,
    public icon: string,
    public classification: TYPE_NOTIFY
  ) { }

  getNotName (event: any): string {
    return ''
  }

  getNotMessage (props: { event: any; report_item: any }): string {
    return ''
  }
}
export class JimiIotEvent extends BaseEvent {
  get key () {
    return this.getEventUniqueId()
  }

  getEventUniqueId () {
    return `${TYPE_NOTIFY[this.classification].toString()}-${this.type}`
  }

  getNotName (): string {
    return `Alerta de ${this.name.toLowerCase()}`
  }

  getNotMessage (): string {
    return `Se ha detectado una notificación Alerta de ${this.name.toLowerCase()}`
  }
}

export const ET_JIMIIOT_DMS: { [key: string]: JimiIotEvent } = {
  1: new JimiIotEvent('1', 'Somnolencia', '#f83b8d', 'mdi-sleep', TYPE_NOTIFY.DMS),
  2: new JimiIotEvent('2', 'Conductor realizando llamada', '#f5942f', 'mdi-phone-lock', TYPE_NOTIFY.DMS),
  3: new JimiIotEvent('3', 'Conductor fumando', '#f7df3b', 'mdi-smoking-off', TYPE_NOTIFY.DMS),
  4: new JimiIotEvent('4', 'Conductor distraido', '#3b87f7', 'mdi-account-voice', TYPE_NOTIFY.DMS),
  5: new JimiIotEvent('5', 'Sin conductor en el vehiculo', '#5f5f5f', 'mdi-comment-question-outline', TYPE_NOTIFY.DMS),
  10: new JimiIotEvent('10', 'Captura Automatica', '#f7df3b', 'mdi-fullscreen', TYPE_NOTIFY.DMS)
} as const

export const ET_JIMIIOT_ADAS: { [key: string]: JimiIotEvent } = {
  1: new JimiIotEvent(
    '1',
    'Alarma de colisión frontal',
    '#db4040',
    'mdi-bus-alert',
    TYPE_NOTIFY.ADAS
  ),
  2: new JimiIotEvent(
    '2',
    'Alarma de cambio de carril',
    '#87bb10',
    'mdi-leak',
    TYPE_NOTIFY.ADAS
  ),
  3: new JimiIotEvent(
    '3',
    'La distancia del vehículo es demasiado cercana a la alarma delantera del vehículo.',
    '#D6D300',
    'mdi-highway',
    TYPE_NOTIFY.ADAS
  ),
  4: new JimiIotEvent(
    '4',
    'Alarma de colisión de peatones',
    '#900000',
    'mdi-account-multiple',
    TYPE_NOTIFY.ADAS
  ),
  5: new JimiIotEvent(
    '5',
    'Alarma de cambio frecuente de carril',
    '#DB9340',
    'mdi-shuffle',
    TYPE_NOTIFY.ADAS
  ),
  6: new JimiIotEvent(
    '6',
    'Alarma de desbordamiento de señales de tráfico',
    '#0A8FB4',
    'mdi-sign-caution',
    TYPE_NOTIFY.ADAS
  ),
  7: new JimiIotEvent(
    '7',
    'Alarma de obstáculos',
    '#CC005F',
    'mdi-block-helper',
    TYPE_NOTIFY.ADAS
  ),
  10: new JimiIotEvent(
    '10',
    'Evento de reconocimiento de señales de tráfico',
    '#81CC00',
    'mdi-sign-text',
    TYPE_NOTIFY.ADAS
  ),
  11: new JimiIotEvent(
    '11',
    'Evento de captura activa',
    '#A700CC',
    'mdi-car',
    TYPE_NOTIFY.ADAS
  )
} as const

export const ET_JIMIIOT_STANDARD: { [key: string]: JimiIotEvent } = {
  0: new JimiIotEvent('0', 'Alarma de emergencia (boton de panico)', '#FF0000', 'mdi-alert', TYPE_NOTIFY.STANDARD)
}

export const ET_JIMIIOT_ALERT_TYPE: { [key: string]: JimiIotEvent } = {
  // 256: new JimiIotEvent('256', 'Bandera de alerta', '', '', TYPE_NOTIFY.ALERT_TYPE), // Standard alerts,
  // 264: new JimiIotEvent('264', 'Alarma ADAS', '', '', TYPE_NOTIFY.ALERT_TYPE), // ADAS alerts
  // 265: new JimiIotEvent('265', 'Alarma DMS', '', '', TYPE_NOTIFY.ALERT_TYPE), // DMS alerts

  257: new JimiIotEvent('257', 'Pérdida de señal de video', '', '', TYPE_NOTIFY.ALERT_TYPE),
  258: new JimiIotEvent('258', 'Bloqueo de señal de video', '', '', TYPE_NOTIFY.ALERT_TYPE),
  259: new JimiIotEvent('259', 'Fallo en la unidad de almacenamiento', '', '', TYPE_NOTIFY.ALERT_TYPE),
  260: new JimiIotEvent('260', 'Fallo en otros equipos de video', '', '', TYPE_NOTIFY.ALERT_TYPE),
  261: new JimiIotEvent('261', 'Advertencia de sobrecarga de pasajeros', '', '', TYPE_NOTIFY.ALERT_TYPE),
  262: new JimiIotEvent('262', 'Alarma de comportamiento de conducción anormal', '', '', TYPE_NOTIFY.ALERT_TYPE),
  263: new JimiIotEvent('263', 'Alarma especial alcanzó el umbral de almacenamiento', '', '', TYPE_NOTIFY.ALERT_TYPE),
  15: new JimiIotEvent('15', 'Fallo en la cámara', '', '', TYPE_NOTIFY.ALERT_TYPE),
  1024: new JimiIotEvent('1024', 'Aceleración brusca', '', '', TYPE_NOTIFY.ALERT_TYPE),
  1025: new JimiIotEvent('1025', 'Desaceleración brusca', '', '', TYPE_NOTIFY.ALERT_TYPE),
  1026: new JimiIotEvent('1026', 'Giro brusco', '', '', TYPE_NOTIFY.ALERT_TYPE),
  1027: new JimiIotEvent('1027', 'Exceso de velocidad', '', '', TYPE_NOTIFY.ALERT_TYPE),
  1028: new JimiIotEvent('1028', 'Conducción prolongada/Fatiga', '', '', TYPE_NOTIFY.ALERT_TYPE),
  1029: new JimiIotEvent('1029', 'Alarma de colisión de conducción', '', '', TYPE_NOTIFY.ALERT_TYPE),
  1030: new JimiIotEvent('1030', 'Alarma de vibración al estacionar', '', '', TYPE_NOTIFY.ALERT_TYPE),
  1031: new JimiIotEvent('1031', 'Alarma de desplazamiento', '', '', TYPE_NOTIFY.ALERT_TYPE),
  1032: new JimiIotEvent('1032', 'Alarma de entrada a cerca', '', '', TYPE_NOTIFY.ALERT_TYPE),
  1033: new JimiIotEvent('1033', 'Alarma de salida de cerca', '', '', TYPE_NOTIFY.ALERT_TYPE),
  1040: new JimiIotEvent('1040', 'Evento de modo de suspensión', '', '', TYPE_NOTIFY.ALERT_TYPE),
  1041: new JimiIotEvent('1041', 'Evento de modo de trabajo', '', '', TYPE_NOTIFY.ALERT_TYPE),
  3073: new JimiIotEvent('3073', 'Alarma de emergencia SOS', '', '', TYPE_NOTIFY.ALERT_TYPE),
  3074: new JimiIotEvent('3074', 'Alarma de baja energía externa', '', '', TYPE_NOTIFY.ALERT_TYPE),
  3075: new JimiIotEvent('3075', 'Alarma ACC ON', '', '', TYPE_NOTIFY.ALERT_TYPE),
  3076: new JimiIotEvent('3076', 'Alarma ACC OFF', '', '', TYPE_NOTIFY.ALERT_TYPE),
  3077: new JimiIotEvent('3077', 'Alarma antirrobo', '', '', TYPE_NOTIFY.ALERT_TYPE),
  3078: new JimiIotEvent('3078', 'Alarma de calibración anormal', '', '', TYPE_NOTIFY.ALERT_TYPE),
  3079: new JimiIotEvent('3079', 'Alarma de identificación', '', '', TYPE_NOTIFY.ALERT_TYPE),
  3080: new JimiIotEvent('3080', 'Alarma de puerta activada', '', '', TYPE_NOTIFY.ALERT_TYPE),
  3081: new JimiIotEvent('3081', 'Alarma de aceite anormal', '', '', TYPE_NOTIFY.ALERT_TYPE),
  3082: new JimiIotEvent('3082', 'Alarma de temperatura y humedad anormales', '', '', TYPE_NOTIFY.ALERT_TYPE),
  3083: new JimiIotEvent('3083', 'Alarma de inicio de tarjeta DLT', '', '', TYPE_NOTIFY.ALERT_TYPE),
  3084: new JimiIotEvent('3084', 'Alarma de cierre de sesión de tarjeta DLT', '', '', TYPE_NOTIFY.ALERT_TYPE),
  3085: new JimiIotEvent('3085', 'Alarma de tarjeta DLT no registrada', '', '', TYPE_NOTIFY.ALERT_TYPE)
} as const

export const EVENTS_TYPES_JIMIIOT = {
  [TYPE_NOTIFY.ADAS]: ET_JIMIIOT_ADAS,
  [TYPE_NOTIFY.DMS]: ET_JIMIIOT_DMS,
  [TYPE_NOTIFY.STANDARD]: ET_JIMIIOT_STANDARD,
  [TYPE_NOTIFY.ALERT_TYPE]: ET_JIMIIOT_ALERT_TYPE
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import ActualizerItem from '@/classes/Actualizer/ActualizerItem'

export default class ResourceItem {
  resource_item;
  resource_id;
  units: Array<ActualizerItem> = []
  units_not_reporting: Array<ActualizerItem> = []

  constructor (resource_item: any, resource_id: number) {
    this.resource_item = resource_item
    this.resource_id = resource_id
    this.loadUnitsNotReporting()
  }

  getName (): string {
    return this.resource_item?.getName() || '-- No se pudo obtener el nombre del recurso --'
  }

  getResourceId (): number {
    return this.resource_id
  }

  addUnit (unit:ActualizerItem): void {
    this.units.push(unit)
    this.loadUnitsNotReporting()
  }

  getUnitsLength (): number {
    return this.units.length
  }

  loadUnitsNotReporting (): void {
    const unitsNotReporting = this.units.filter(unit => !unit.isReporting()).sort(function (a, b) {
      return a.getUnitTimeReportSeconds() - b.getUnitTimeReportSeconds()
    })
    this.units_not_reporting = unitsNotReporting
  }

  getUnitsNotReporting () {
    return this.units_not_reporting
  }

  getUnits (): Array<ActualizerItem> {
    return this.units.sort(function (a, b) {
      return a.getUnitTimeReportSeconds() - b.getUnitTimeReportSeconds()
    })
  }
}

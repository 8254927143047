import { State } from '../../state'
import { ActionContext } from 'vuex'

const actions = {
  async loadTireInventory ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/tire_inventory/get/all',
        method: 'GET'
      },
      { root: true }
    )
    commit('SET_TIRE_INVENTORY', data)
    return data
  },
  async getTireByIdInventory ({ commit, dispatch }: ActionContext<State, any>, entity_id: number) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/tire_inventory/get/${entity_id}`,
        method: 'GET'
      },
      { root: true }
    )
    commit('UPDATE_TIRE_INVENTORY', data)
    return data
  },
  async createTireInventory ({ commit, dispatch }: ActionContext<State, any>, entity: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/tire_inventory/create',
        method: 'POST',
        data: entity
      },
      { root: true }
    )
    commit('PUSH_TIRE_INVENTORY', data)
    return data
  },
  async updateTireInventory ({ commit, dispatch }: ActionContext<State, any>, entity: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/tire_inventory/update/${entity.id}`,
        method: 'PUT',
        data: entity
      },
      { root: true }
    )
    commit('UPDATE_TIRE_INVENTORY', data)
    return data
  },
  async deleteTireInventory ({ commit, dispatch }: ActionContext<State, any>, entity: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/tire_inventory/delete/${entity.id}`,
        method: 'DELETE'
      },
      { root: true }
    )
    commit('DELETE_TIRE_INVENTORY', entity)
    return data
  },
  async mountedTireInventory ({ commit, dispatch, rootState }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/tire_inventory/mounted',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    commit('MOUNTED_TIRE', payload)
    commit('UPDATE_TIRE_INVENTORY', data)
    const unit = rootState.travel.unitsRegisters.find((unit:any) => unit.id === payload.unit)
    if (unit && !unit.wheels.includes(payload.id_tire)) unit.wheels.push(payload.id_tire)
    return data
  },
  async unmountedTireInventory ({ commit, dispatch, rootState }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/tire_inventory/unmounted',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    commit('UNMOUNTED_TIRE', payload)
    commit('UPDATE_TIRE_INVENTORY', data)
    // @ts-ignore
    const unit = rootState.travel.unitsRegisters.find((unit:any) => unit.id === payload.unit)
    if (unit) unit.wheels = unit.wheels.filter((id_tir : number) => id_tir !== payload.id_tire)
    return data
  }
}
export default actions


export interface AccountState {
    accounts: any[]
}

const state = (): AccountState => ({
  accounts: []
})
export type State = ReturnType<typeof state>;

export default state

export enum TYPE_SERIALIZED_PARTSMATERIALS {
    OTHER = 1,
    NEUMATICO = 2,
    BATERIA = 3
}

export const TYPE_SERIALIZED_PARTSMATERIALS_ARRAY = [
  {
    label: 'Otro',
    id: TYPE_SERIALIZED_PARTSMATERIALS.OTHER
  },
  {
    label: 'Neumatico',
    id: TYPE_SERIALIZED_PARTSMATERIALS.NEUMATICO
  },
  {
    label: 'Bateria',
    id: TYPE_SERIALIZED_PARTSMATERIALS.BATERIA
  }
]

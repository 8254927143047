/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */

import getAVLResourceItems from '@/utils/wialonSDK/getAVLResourceItems'
import { ActionContext } from 'vuex/types/index'
import {
  RESOURCES,
  PLATAFORM,
  PLATAFORM_RESOURCES
} from '../../resources/RESOURCES'
import { State } from '../state'
import { GEOFENCE_TYPE_RESOURCE } from '@/store/catalogs/GEOFENCE_TYPE_RESOURCE'
import { ICGeofence } from '@/store/geofences/state'
import gettingAVLUnitItems from '@/utils/wialonSDK/gettingAVLUnitItems'
const actions = {
  async loadUnits ({ commit }: ActionContext<State, any>) {
    const pUnits = await gettingAVLUnitItems(32 + 1024 + 8192)
    commit(
      'resources/SET_RESOURCES',
      {
        resources: pUnits,
        plataform: PLATAFORM.WIALON,
        rPlatafrom: PLATAFORM_RESOURCES.UNITS
      },
      { root: true }
    )
  },
  async loadResources ({ commit }: ActionContext<State, any>) {
    // @ts-ignore
    console.log(wialon.item.Resource.dataFlag.zones)
    // @ts-ignore
    const resources = await getAVLResourceItems(4096 | wialon.item.Resource.dataFlag.zones | wialon.item.Resource.dataFlag.drivers)
    commit(
      'resources/SET_RESOURCES',
      {
        resources: resources,
        plataform: PLATAFORM.WIALON,
        rPlatafrom: PLATAFORM_RESOURCES.RESOURCES
      },
      { root: true }
    )
    return resources
  },
  async getWialonResourcesIdsAndUnits ({
    rootState,
    commit
  }: ActionContext<State, any>) {
    const resourcesIds: number[] = []
    const unitsIds: number[] = []
    const travels = rootState.resources[RESOURCES.TRAVELS]
    const resources =
      rootState.resources.PLATAFORM[PLATAFORM.WIALON][
        PLATAFORM_RESOURCES.RESOURCES
      ]

    for (let i = 0; i < travels.length; i++) {
      const travel = travels[i]
      if (!unitsIds.includes(travel.tra_plataform_unit.id)) {
        unitsIds.push(travel.tra_plataform_unit.id)
      }

      if (!resourcesIds.includes(travel.tra_plataform_line_resource_id)) {
        resourcesIds.push(travel.tra_plataform_line_resource_id)
      }

      const res = resources.find(
        // @ts-ignore
        (r) => r._id === travel.tra_plataform_line_resource_id
      )
      if (res || travel.tra_plataform === PLATAFORM.MAPON) {
        for (let j = 0; j < travel.tvl_destinations.length; j++) {
          const des = travel.tvl_destinations[j]
          const tvd_routes = { tvd_plataform_route_origin_data: null, tvd_plataform_route_destination_data: null }
          if (des.tdv_geofence_type === GEOFENCE_TYPE_RESOURCE.WIALON) {
            tvd_routes.tvd_plataform_route_origin_data = res.getZone(
              des.tvd_plataform_route_origin.id
            )
            tvd_routes.tvd_plataform_route_destination_data = res.getZone(
              des.tvd_plataform_route_destination.id
            )
          } else if (des.tdv_geofence_type === GEOFENCE_TYPE_RESOURCE.CONSOLE) {
            tvd_routes.tvd_plataform_route_origin_data = rootState.geofences.geofences.find((geo: ICGeofence) => geo.id === des.tvd_plataform_route_origin.id_geofence)
            tvd_routes.tvd_plataform_route_destination_data = rootState.geofences.geofences.find((geo: ICGeofence) => geo.id === des.tvd_plataform_route_destination.id_geofence)
          } else if (des.tdv_geofence_type === GEOFENCE_TYPE_RESOURCE.COORDENADAS) {
            tvd_routes.tvd_plataform_route_origin_data = des.tvd_geofence_coordinates_origin
            tvd_routes.tvd_plataform_route_destination_data = des.tvd_geofence_coordinates_destination
          }
          travel.tvl_destinations[j].tvd_plataform_route_origin_data = tvd_routes.tvd_plataform_route_origin_data
          travel.tvl_destinations[j].tvd_plataform_route_destination_data = tvd_routes.tvd_plataform_route_destination_data
        }
      }
    }
    // throw Error('ERROR')

    commit(
      'resources/SET_RESOURCES',
      {
        resources: resourcesIds,
        rType: RESOURCES.TRAVEL_RESOURCES_IDS
      },
      { root: true }
    )

    commit(
      'resources/SET_RESOURCES',
      {
        resources: unitsIds,
        rType: RESOURCES.TRAVEL_UNITS_IDS
      },
      { root: true }
    )
  },
  async susbscribeAndVerify ({ dispatch, rootState, commit }: ActionContext<State, any>, travel: any) {
    const resourcesIds: any[] = rootState.resources[RESOURCES.TRAVEL_RESOURCES_IDS]
    const unitsIds: any[] = rootState.resources[RESOURCES.TRAVEL_UNITS_IDS]
    if (!resourcesIds.includes(travel.transport_line_resource_id)) {
      await dispatch('wialon/suscribeNotificationResource', travel.transport_line_resource_id, { root: true })
      commit(
        'resources/PUSH_RESOURCE',
        {
          item: travel.transport_line_resource_id,
          rType: RESOURCES.TRAVEL_RESOURCES_IDS
        },
        { root: true }
      )
    }

    if (!unitsIds.includes(travel.tra_plataform_unit.id)) {
      commit(
        'resources/PUSH_RESOURCE',
        {
          item: travel.tra_plataform_unit.id,
          rType: RESOURCES.TRAVEL_UNITS_IDS
        },
        { root: true }
      )
    }
  }
}
export default actions

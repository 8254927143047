// import { GeofencesState, ICGeofence } from './state'
import { InventoryState } from '../../state'
import { IStoreParts } from '../../../../classes/Inventory/interfaces/IStoreParts'

export default {
  SET_STORE_PARTS (state: InventoryState, store_parts: IStoreParts[]): void {
    state.store_parts = store_parts
  }
  // PUSH_PROVIDERS (state: TypeState, provider: Provider): void {
  //   state.providers.push(new Provider(provider))
  // },
  // UPDATE_PROVIDER (state: TypeState, provider: Provider): void {
  //   const index = state.providers.findIndex(p => p.id === provider.id)
  //   if (index !== -1) {
  //     state.providers.splice(index, 1, new Provider(provider))
  //   }
  // },
  // DELETE_PROVIDER (state: TypeState, provider: Provider): void {
  //   const index = state.providers.findIndex(p => p.id === provider.id)
  //   if (index !== -1) {
  //     state.providers.splice(index, 1)
  //   }
  // }
}

export default class BaseItem<rType = any> {
    protected data!: rType

    constructor({ data }: { data: rType }) {
        this.rehidratateData(data)
     }

    public rehidratateData(data: rType) {
        // @ts-ignore
        Object.assign(this, data)
    }
}
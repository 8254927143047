/* eslint-disable camelcase */
import router from '@/router'
import { ActionContext } from 'vuex'
import { State } from './state'
import { LOGIN_TYPE } from '../catalogs/LOGIN_TYPE'

export const actions = {
  async login ({ commit, dispatch }: ActionContext<State, any>, payload: { usr_username: string; usr_password: string, login_cms: boolean }): Promise<any> {
    try {
      const { data } = await dispatch('app/axios', { url: '/apis/login/', method: 'POST', data: payload }, { root: true })
      commit('SET_LOGIN_DATA', data)
      if (data.is_auth) {
        await dispatch('app/notifyToast', { msj: 'Bienvenido nuevamente!!', type: 'success' }, { root: true })
        // await dispatch(
        //   'app/notificationToasted',
        //   {
        //     // @ts-ignore
        //     content: 'Bienvenido',
        //     position: 'top-center',
        //     theme: 'bubble',
        //     duration: false,
        //     type: 'success',
        //     icon: 'check',
        //     clear: true
        //   },
        //   { root: true }
        // )
        // @ts-ignore
        await router.push({
          // @ts-ignore
          name: (payload.login_cms ? 'Console' : 'mapas')
        })
      }

      return data.auth
    } catch (error) {
      commit('app/ADD_LOG', { title: 'LOGIN', color: 'error', message: error }, { root: true })
      console.log(error)
      // @ts-ignore
      switch (error.response.status) {
        case 400:
          await dispatch(
            'app/notificationToasted',
            {
              // @ts-ignore
              content: `${error.response.data.non_field_errors[0]}`,
              position: 'top-center',
              theme: 'bubble',
              duration: false,
              type: 'error',
              icon: 'check',
              clear: true
            },
            { root: true }
          )
          break
        case 409:
          await dispatch(
            'app/notification',
            {
              content: 'Contraseña incorrecta'
            },
            { root: true }
          )
          break
        default:
          await dispatch(
            'app/notificationToasted',
            {
              content: 'Usuario no encontrado',
              position: 'top-center',
              theme: 'bubble',
              duration: false,
              type: 'error',
              icon: 'check',
              clear: true
            },
            { root: true }
          )
          break
      }
    }
  },
  async updateAuth ({ commit, dispatch, rootState }: ActionContext<State, any>, userBaseAuth:boolean) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/data_auth/',
        method: 'POST',
        data: { userBaseAuth }
      },
      { root: true }
    )
    commit('UPDATE_AUTH_DATA', data)
  },
  async setToken ({ commit, dispatch, rootState }: ActionContext<State, any>, token:string) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/save_token',
        method: 'POST',
        data: { token }
      },
      { root: true }
    )
  },
  async logout ({ commit }: ActionContext<State, any>) {
    commit('CLEAN_LOGIN_DATA')
    // @ts-ignore
    await router.replace({
      name: 'Login'
    })
    window.location.reload()
  },
  haveWialon ({ state }: ActionContext<State, any>) {
    const haveWialon = state.plataform_data.find(obj => obj.name === 'WIALON')
    return !!haveWialon
  }
}

export default actions

import BaseItem from '../item/BaseItem'
import EmptyApi from './EmptyApi.base'
import StreamaxJSSdk from '..'
import { RequestData, RequestDefault } from '../interfaces/RequestsWrapper.interface'
import { CloseVideoReqDTO, GetMediaLiveUrlRespDTO, QueryHistoryStreamingVideoLinkDTO, QueryStreamingVideoLinkDTO, SwitchStreamReqDTO } from '@/interfaces/Media.interface'
import { QueryHistoryAlarmDTO, QueryHistoryGPSDTO } from '@/interfaces/Gps.interface'

export default class GPSApi extends EmptyApi {
    protected itemClass: { new({ data }: { data: any; }): BaseItem<any>; prototype: BaseItem<any>; };

    constructor (sdk: StreamaxJSSdk) {
      super(sdk)
      this.itemClass = BaseItem
    }

    public async gpsHistory (params: QueryHistoryGPSDTO) {
      const { data } = await StreamaxJSSdk.axiosV3<RequestData<QueryHistoryGPSDTO>>({
        url: '/gps/history',
        method: 'GET',
        params,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      return data
    }
    public async alarmsHistory (params: QueryHistoryAlarmDTO) {
      const { data } = await StreamaxJSSdk.axios<RequestData<QueryHistoryAlarmDTO>>({
        url: '/alarms',
        method: 'GET',
        params,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      return data
    }

    /** This API is used to stop device live and playback.
     * The returned data after calling the [Querying Address of Historical Video Files] API includes the URL and session information of the video file.
     * This API is used to close the URL of the video in play according to the session information.
    */
    public async stopDevicePlayback (uniqueID: string, params: CloseVideoReqDTO) {
      const { data } = await StreamaxJSSdk.axios<RequestData<boolean>>({
        url: `/devices/${uniqueID}/session-close`,
        method: 'POST',
        data: params
      })
      return data
    }

    /** This API is used to switch stream type. When the link is not played, it is assumed that the link session does not exist. */
    public async switchStreamType (params: SwitchStreamReqDTO) {
      const { data } = await StreamaxJSSdk.axios<RequestDefault<{}>>({
        url: '/devices/session-switch',
        method: 'PUT',
        data: params
      })
      return data
    }

    /** This API is used to query the address of remote device playback media, and the playing address is valid for 20 seconds.If there is no video within the query time range, the playback media address will also be returned, and there will be no video when this address is played. The interface does not automatically close videos in play and the [Stopping Device Playback] API can be used to close videos. */
    public async queryStreamingVideoLink (uniqueID: string, params: QueryStreamingVideoLinkDTO) {
      const urlParams = new URLSearchParams()

      const { data } = await StreamaxJSSdk.axios<RequestData<GetMediaLiveUrlRespDTO[]>>({
        url: `/devices/${uniqueID}/live-links`,
        method: 'GET',
        params,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      return data
    }

    /** This API is used to obtain the device remote streaming video link through the device serial number.
     * The flv and hls formats are supported for videos. Remote streaming video links are used for once only.
     * Each streaming requires a new link.
    */
    public async queryHistoryStreamingVideoLink (uniqueID: string, params: QueryHistoryStreamingVideoLinkDTO) {
      const { data } = await StreamaxJSSdk.axios<RequestData<GetMediaLiveUrlRespDTO[]>>({
        url: `/devices/${uniqueID}/playback-links`,
        method: 'GET',
        params,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      return data
    }
}


import { Module } from 'vuex'
// @ts-ignore
import { RootState } from '@/store/types'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
// import state, { ChangeStoreState } from './state'
import state, { RequisitionState } from './state'

export const requisition: Module<RequisitionState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

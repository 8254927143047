
// import { InventoryState } from '../../state'
import { QuotationsState } from './state'

export default {
  SET_ALL (state: QuotationsState, entities: any[]): void {
    state.quotations = entities
  },
  PUSH (state: QuotationsState, entitie: any): void {
    state.quotations.unshift(entitie)
  },
  UPDATE (state: QuotationsState, entitie: any): void {
    const index = state.quotations.findIndex(p => p.id === entitie.id)
    if (index !== -1) {
      state.quotations.splice(index, 1, entitie)
    }
  }
}

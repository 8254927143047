import { ActionContext } from 'vuex'
import { State } from './state'
import QRCode, { IQrCode } from '@/classes/Inventory/interfaces/IQrCode'

export default {
  async init ({ dispatch }: ActionContext<State, string>) {
    dispatch('loadQrCodes')
  },
  async loadQrCodes ({ commit, dispatch }: ActionContext<State, any>) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: '/apis/qrcode/get/all',
          method: 'GET'
        },
        { root: true }
      )
      const qrCodes = data.map((qrCode: IQrCode) => new QRCode(qrCode))
      commit('SET_QR_CODES', qrCodes)
      return qrCodes
    } catch (error) {
      console.error('Error al cargar los códigos QR:', error)
      dispatch('app/addLogWithError', {
        title: 'Error al cargar los códigos QR',
        color: 'error',
        message: '',
        error
      }, { root: true })
      throw error
    }
  },
  async createQrCode ({ commit, dispatch }: ActionContext<State, any>, qrCode: IQrCode) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: '/apis/qrcode/create',
          method: 'POST',
          data: qrCode
        },
        { root: true }
      )
      commit('PUSH_QR_CODES', data)
      return data
    } catch (error) {
      console.error('Error creating QR code:', error)
      throw error
    }
  },
  async updateQrCode ({ commit, dispatch }: ActionContext<State, any>, qrCode: IQrCode) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: `/apis/qrcode/update/${qrCode.id}`,
          method: 'PUT',
          data: qrCode
        },
        { root: true }
      )
      commit('UPDATE_QR_CODE', data)
      return data
    } catch (error) {
      console.error('Error updating QR code:', error)
      throw error
    }
  },
  async deleteQrCode ({ commit, dispatch }: ActionContext<State, any>, qrCode: IQrCode) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: `/apis/qrcode/delete/${qrCode.id}`,
          method: 'DELETE'
        },
        { root: true }
      )
      commit('DELETE_QR_CODE', qrCode)
      return data
    } catch (error) {
      console.error('Error deleting QR code:', error)
      throw error
    }
  },
  async updateStoreInventoryById ({ dispatch }: ActionContext<State, any>, payload: { id: number; data: any }) {
    const { id, data } = payload
    try {
      const response = await dispatch(
        'app/axios',
        {
          url: `/apis/store/update/inventory/${id}`,
          method: 'PUT',
          data: data
        },
        { root: true }
      )
      await dispatch('loadQrCodes')
      dispatch(
        'app/notificationToasted',
        {
          content: 'QR asignado correctamente',
          position: 'top-center',
          theme: 'bubble',
          duration: false,
          type: 'success',
          icon: 'check',
          clear: true
        },
        { root: true }
      )
      return response.data
    } catch (error) {
      console.error('Error al actualizar el inventario:', error)
      dispatch(
        'app/notificationToasted',
        {
          content: 'Se produjo un error al asignar el código QR',
          position: 'top-center',
          theme: 'bubble',
          duration: false,
          type: 'error',
          clear: true
        },
        { root: true }
      )
      throw error
    }
  },
  async generateCustomPDF ({ dispatch, rootState, commit }: ActionContext<State, string>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: 'apis/qrcode/generate/pdf',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    return data
  }
}

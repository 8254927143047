import { DateTime } from 'luxon'
import isDev from '@/utils/isDev'
import getUrlOrigin from '@/utils/getUrlOrigin'

const state = () => ({
  drawer: true,
  view: 2,
  logs: [],
  config: {
    dialog: false
  },
  loader: {
    loader: false,
    message: ''
  },
  overlay: false,
  socket: null,
  reload: false,
  drawerNotify: false,
  allow_image: (process.env.VUE_APP_ALLOW_IMAGE === 'true'),
  socket_travel: null,
  socket_system: null,
  notifications: [],
  openManager: false,
  BASE_URL: process.env.VUE_APP_BASE_URL || getUrlOrigin(),
  muted: false,
  timeoffset: DateTime.now().setZone(DateTime.local().zoneName).offset / 60,
  IS_DEV: isDev(),
  hideBeforeCloseDialog: false
})

export type State = ReturnType<typeof state>;

export default state


export interface DashboardState {
    any: any[]
}

const state = (): DashboardState => ({
  any: []
})
export type State = ReturnType<typeof state>;

export default state

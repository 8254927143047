/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import ReportItemData from './ReportItemData'
// import { ICommand } from '@/store/interfaces/Command'
import { UnitConsole } from '@/store/interfaces/ReportModules'
import { PLATAFORM } from '@/store/resources/RESOURCES'
import AUnit from '@/submodules/maponjssdk/dist/classes/actions/AUnit.action'
import { Unit } from '@/submodules/maponjssdk/dist/interfaces/unit.interface'

export default class RIMapon
  extends ReportItemData<AUnit, Unit>
  implements UnitConsole {
  public bateryFlag = 'V';
  public keyUnitMessage = ''
  public pType = PLATAFORM.MAPON

  async getIgnitionState (): Promise<number> {
    if (this.unit_item.ignition) {
      if (this.unit_item.ignition.value === 'off') {
        return 0
      } else if (this.unit_item.ignition.value === 'on') {
        return 1
      } else {
        return 3
      }
    }
    return 3
  }

  speed (): number {
    return this.unit_item?.speed || 0
  }

  unsusbscribeToUnitMessages (): void {
    this.unit_item.removeEventListener('MESSAGE_CHANGED', data => {
      this.last_message = { ...data, pos: { x: data.lat, y: data.lng, c: data.direction } }
    })
  }

  susbscribeToUnitMessages () {
    // @ts-ignore
    this.last_message = { ...this.unit_item?.lastData, pos: { x: this.unit_item?.lastData?.lng, y: this.unit_item?.lastData?.lat, c: this.unit_item?.lastData?.direction } }
    try {
      this.unit_item.addEventListener('MESSAGE_CHANGED', data => {
        this.last_message = { ...data, pos: { x: data.lng, y: data.lat, c: data.direction } }
      })
    } catch (error) {
      console.error(error)
    }
  }

  getName () {
    // return this.unit_item ? this.unit_item.$$user_name : `${this.unit_name} NO ESTA EN EL TOKEN`

    return this.unit_item ? `${this.unit_item.number} - ${this.unit_item.label}` : `${this.unit_name} NO ESTA EN EL TOKEN`
  }

  getIcon () {
    return '/images/truck.png'
  }

  getDistanceByTime (params: { from: number; to: number; }): Promise<number> {
    throw new Error('Method not implemented.')
  }
}

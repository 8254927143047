import { InventoryState } from '../../state'
import { IPartsMaterials } from '../../../../classes/Inventory/interfaces/IPartsMaterials'

export default {
  SET_PARTS_MATERIALS (state: InventoryState, parts_materials: IPartsMaterials[]): void {
    state.parts_materials = parts_materials
  },
  PUSH_PARTS_MATERIALS (state: InventoryState, parts_materials: IPartsMaterials): void {
    state.parts_materials.push(parts_materials)
  },
  UPDATE_PARTS_MATERIALS (state: InventoryState, parts_materials: IPartsMaterials): void {
    const index = state.parts_materials.findIndex(p => p.id === parts_materials.id)
    if (index !== -1) {
      state.parts_materials.splice(index, 1, parts_materials)
    }
  },
  DELETE_PARTS_MATERIALS (state: InventoryState, parts_materials: IPartsMaterials): void {
    const index = state.parts_materials.findIndex(p => p.id === parts_materials.id)
    if (index !== -1) {
      state.parts_materials.splice(index, 1)
    }
  }
}

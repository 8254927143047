<template>
  <div>
    <notifications style="margin-top: 20px; margin-right: 2px;" group="app" position="top center" >
      <template #body="props">
        <div :class="'vue-notification ' + props.item.type" >
          <div class="">
            <div class="notification-title"><v-icon dark small v-if="props.item.data.icon">{{ props.item.data.icon }}</v-icon> {{ props.item.title }}</div>
            <div class="notification-content">{{ props.item.text }}</div>
          </div>
        </div>
        </template>
    </notifications>
    <OverlayLoader />
    <LoaderDialog />
    <router-view></router-view>
  </div>
</template>

<script>
import LoaderDialog from './components/LoaderDialog.vue'
import { requestPermission, fetchToken, messaging } from '@/firebase'
// import { onMessage } from 'firebase/messaging'
import OverlayLoader from './components/OverlayLoader.vue'

export default {
  components: {
    LoaderDialog,
    OverlayLoader
  },
  methods: {
    async initializeFirebase () {
      // await requestPermission()
      // onMessage(messaging, (payload) => {
      //   console.log('Message received. ', payload)
      //   console.log('DESDE AQUIIIIIIIIIIIIIIIIIIIII')
      // })
    }
  },
  mounted () {
    this.initializeFirebase()
    const urlParams = new URLSearchParams(window.location.search)
    const sessionId = urlParams.get('sessionId')
    if (sessionId) {
      const storedState = sessionStorage.getItem(`authState_${sessionId}`)

      if (storedState) {
        const parsedState = JSON.parse(storedState)
        this.$store.commit('auth/SET_LOGIN_DATA', parsedState)
      }
    }
  }
}
</script>

import AAlert from './AAlert.action'
import AUnit from './AUnit.actions'
// import AA from './AUnit.actions'

const actions = {
  AUnit,
  AAlert
}

export default actions

export type IActions = typeof actions

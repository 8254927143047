import { State } from './state'

export default {
  SET_STATE (state: State, payload: State) {
    Object.assign(state, payload)
  },
  SET_NOTIFICATION_STATE (state: State, payload: typeof state.notifications_active) {
    Object.assign(state.notifications_active, payload)
  }
}

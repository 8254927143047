
// import { InventoryState } from '../../state'
import { ChangeStoreState } from './state'

export default {
  SET_ALL (state: ChangeStoreState, entities: any[]): void {
    state.change_store = entities
  },
  PUSH (state: ChangeStoreState, entitie: any): void {
    state.change_store.unshift(entitie)
  },
  UPDATE (state: ChangeStoreState, entitie: any): void {
    const index = state.change_store.findIndex(p => p.id === entitie.id)
    if (index !== -1) {
      state.change_store.splice(index, 1, entitie)
    }
  }
}

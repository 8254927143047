import { ActionContext } from 'vuex'
import { State } from './state'
import Area, { IArea } from '@/classes/Area/ITicket'

export default {
  async init ({ dispatch }: ActionContext<State, string>) {
    dispatch('load')
  },
  async loadArea ({ commit, dispatch }: ActionContext<State, any>) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: '/apis/area/get/all',
          method: 'GET'
        },
        { root: true }
      )
      const areas = data.map((area: IArea) => new Area(area))
      commit('SET_AREAS', areas)
      return areas
    } catch (error) {
      dispatch('app/addLogWithError', {
        title: 'Error al cargar las Areas',
        color: 'error',
        message: '',
        error
      }, { root: true })
      throw error
    }
  },
  async create ({ commit, dispatch }: ActionContext<State, any>, area: IArea) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: '/apis/area/create',
          method: 'POST',
          data: area
        },
        { root: true }
      )
      commit('PUSH_AREA', data)
      await dispatch('app/notification', { content: 'Área Creada', type: 'success' }, { root: true })
      return data
    } catch (error) {
      await dispatch('app/notification', { content: 'No se ha podido crear el mantenimiento de activos', type: 'error' }, { root: true })
      throw error
    }
  },
  async update ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/area/update/${payload.id}`,
        method: 'PUT',
        data: payload
      },
      { root: true }
    )
    commit('UPDATE_AREA', data)
    return data
  },
  async loadByUser ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/area/get/${payload}`,
        method: 'GET'
      },
      { root: true }
    )
    return data
  },
  async createByAccount ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { id_user, data } = payload
    const { data: responseData } = await dispatch(
      'app/axios',
      {
        url: `/apis/area/create/${id_user}`,
        method: 'POST',
        data: data
      },
      { root: true }
    )
    return responseData
  },
  async delete ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/area/delete/${payload}`,
        method: 'DELETE'
      },
      { root: true }
    )
    return data
  }
}

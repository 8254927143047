/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */

import { INotification } from '@/interfaces/Wialon.interface'
import getAVLResourceItem from '@/utils/wialonSDK/getAVLResourceItem'
import { ActionContext } from 'vuex'
import { PLATAFORM, PLATAFORM_RESOURCES, RESOURCES } from '../resources/RESOURCES'
import { State } from './state'

export default {
  async init ({ dispatch }: ActionContext<State, any>) {
    // ---
  },
  async suscribeNotificationsResources ({ dispatch, rootState }: ActionContext<State, string>): Promise<void> {
    // @ts-ignore
    const resourcesIds: number[] = rootState.resources[RESOURCES.TRAVEL_RESOURCES_IDS]

    try {
      for (let i = 0; i < resourcesIds.length; i++) {
        const resId = resourcesIds[i]
        await dispatch('suscribeNotificationResource', resId)
      }
    } catch (error) {
      console.error(error)
    }
  },
  desusbcribeUnitNotification ({ commit, rootState }: ActionContext<State, string>, unitId: number) {
    // @ts-ignore
    const index: number = rootState.resources[RESOURCES.TRAVEL_UNITS_IDS].findIndex(u => u === unitId)

    commit(
      'resources/REMOVE_RESOURCE',
      {
        index,
        rType: RESOURCES.TRAVEL_UNITS_IDS
      },
      { root: true }
    )
  },
  async suscribeNotificationResource ({ dispatch }: ActionContext<State, string>, resId: number): Promise<void> {
    // @ts-ignore
    const res = await getAVLResourceItem(resId, wialon.item.Resource.dataFlag.zones | wialon.item.Resource.dataFlag.drivers)
    if (res) {
      res.addListener('messageRegistered', async (event: { getData: () => any }) => await dispatch('addNotification', { pType: PLATAFORM.WIALON, ...event.getData() }))
    }
  },
  async sudscribeUnitNotification ({ dispatch, rootState, commit }: ActionContext<State, string>, travel: any): Promise<void> {
    // @ts-ignore
    const resourcesIds: number[] = rootState.resources[RESOURCES.TRAVEL_RESOURCES_IDS]
    // @ts-ignore
    const unitsIds: number[] = rootState.resources[RESOURCES.TRAVEL_UNITS_IDS]

    if (!resourcesIds.includes(travel.tra_plataform_line_resource_id)) {
      await dispatch('suscribeNotificationResource', travel.tra_plataform_line_resource_id)

      commit(
        'resources/PUSH_RESOURCE',
        {
          item: travel.tra_plataform_line_resource_id,
          rType: RESOURCES.TRAVEL_RESOURCES_IDS
        },
        { root: true }
      )

      commit(
        'resources/PUSH_RESOURCE',
        {
          item: travel.tra_plataform_unit.id,
          rType: RESOURCES.TRAVEL_UNITS_IDS
        },
        { root: true }
      )
    } else if (resourcesIds.includes(travel.tra_plataform_line_resource_id) && !unitsIds.includes(travel.tra_plataform_unit.id)) {
      commit(
        'resources/PUSH_RESOURCE',
        {
          item: travel.tra_plataform_unit.id,
          rType: RESOURCES.TRAVEL_UNITS_IDS
        },
        { root: true }
      )
    }
  },
  async addNotification ({ rootState, commit, dispatch }: ActionContext<State, string>, message: INotification): Promise<void> {
    // @ts-ignore
    const unitsIds: number[] = rootState.resources[RESOURCES.TRAVEL_UNITS_IDS]
    if (unitsIds.includes(message.unit)) {
      // @ts-ignore
      const travel = rootState.resources[RESOURCES.TRAVELS].find(tvl => tvl.tra_plataform_unit.id === message.unit)
      if (travel) {
        if (message.pType === PLATAFORM.WIALON) {
          // @ts-ignore
          const unit = rootState.resources.PLATAFORM[PLATAFORM.WIALON][PLATAFORM_RESOURCES.UNITS].find(u => u._id === message.unit)
          if (unit) {
            message.account_id = unit.getAccountId()
            // @ts-ignore
            for (let i = 0; i < rootState.resources[RESOURCES.TRAVEL_EVENTS].length; i++) {
            // @ts-ignore
              const notification = rootState.resources[RESOURCES.TRAVEL_EVENTS][i]
              const isSameResource = notification.message.account_id === message.account_id
              const isSameNotification = notification.message.nid === message.nid
              const isSameUnit = notification.message.unit === message.unit
              if (isSameResource && isSameNotification && isSameUnit) {
                notification.notificationChild.push(message)
                return
              }
            }
            commit(
              'resources/UNSHIFT_RESOURCE',
              {
                item: { travel, message, notificationChild: [], id: new Date().getTime(), icon: unit.getIconUrl() ? unit.getIconUrl() : require('@/assets/images/s_a_35.svg') },
                rType: RESOURCES.TRAVEL_EVENTS
              },
              { root: true }
            )
            dispatch('app/playSound', { url: message.url }, { root: true })
          }
        }
        if (message.pType === PLATAFORM.MAPON) {
          // @ts-ignore
          const unit = rootState.resources.PLATAFORM[PLATAFORM.MAPON][PLATAFORM_RESOURCES.UNITS].find(u => u.unit_id === message.unit)
          if (unit) {
            // @ts-ignore
            for (let i = 0; i < rootState.resources[RESOURCES.TRAVEL_EVENTS].length; i++) {
            // @ts-ignore
              const notification = rootState.resources[RESOURCES.TRAVEL_EVENTS][i]
              const isSameNotification = notification.message.nid === message.nid
              const isSameUnit = notification.message.unit === message.unit
              if (isSameNotification && isSameUnit) {
                notification.notificationChild.push(message)
                return
              }
            }
            commit(
              'resources/UNSHIFT_RESOURCE',
              {
                item: { travel, message, notificationChild: [], id: new Date().getTime(), icon: '/images/truck.png' },
                rType: RESOURCES.TRAVEL_EVENTS
              },
              { root: true }
            )
            await dispatch('app/playSound', {}, { root: true })
          }
        }
      }
    }
  },
  async addNotificationConsole ({ rootState, commit, dispatch }: ActionContext<State, string>, options: { pType: PLATAFORM, name:string, txt:string, color:string, unit:number, coord: { x:number, y:number }, nid:number }): Promise<void> {
    const not: INotification = {
      t: Date.now(),
      f: 896,
      tp: 'unm',
      name: options.name,
      txt: options.txt,
      color: options.color,
      url: '',
      unit: options.unit,
      blink: 1,
      x: options.coord.x,
      y: options.coord.y,
      nid: options.nid,
      rt: 0,
      p: {},
      pType: options.pType
    }
    dispatch('addNotification', not)
  }

}

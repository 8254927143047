import { GeofencesState, ICGeofence } from './state'

export default {
  SET_MODAL_STATE (state: GeofencesState, payload:{
    modal: boolean
    id_group: number
  }): void {
    state.geofencesCrud = payload
  },
  SET_GEOFENCES (state: GeofencesState, geofences: Array<ICGeofence>): void {
    state.geofences = geofences
  },
  UPDATE_GEOFENCES (state: GeofencesState, geofenceU: ICGeofence): void {
    const geofenceUpdate = state.geofences.find(geofence => geofence.id === geofenceU.id)
    // @ts-ignore
    Object.assign(geofenceUpdate, { ...geofenceU })
  },
  PUSH_GEOFENCE (state: GeofencesState, geofences: ICGeofence) {
    state.geofences.push(geofences)
  },
  DELETE_GEOFENCE (state: GeofencesState, id_geofence: number) {
    const indexToRemove = state.geofences.findIndex(geofence => geofence.id === id_geofence)
    if (indexToRemove !== -1) {
      state.geofences.splice(indexToRemove, 1)
    }
  },
  PUSH_GEOFENCES (state: GeofencesState, geofences: [ICGeofence]) {
    state.geofences.push(...geofences)
  }
}

export enum TYPE_PLAN {
  MINUTES_5 = 1,
  MINUTES_150 = 2,
  MINUTES_200 = 3,
  MINUTES_250 = 4,
  MINUTES_300 = 5,
}

export const PLAN_CAMERA_TYPE = [
  {
    id: TYPE_PLAN.MINUTES_5,
    name: '5 minutos',
    seconds: 300
  },
  {
    id: TYPE_PLAN.MINUTES_150,
    name: '150 minutos',
    seconds: 9000
  },
  {
    id: TYPE_PLAN.MINUTES_200,
    name: '200 minutos',
    seconds: 12000
  },
  {
    id: TYPE_PLAN.MINUTES_250,
    name: '250 minutos',
    seconds: 15000
  },
  {
    id: TYPE_PLAN.MINUTES_300,
    name: '300 minutos',
    seconds: 18000
  }
]

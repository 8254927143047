import Root from '../Root/Root'
export interface IProvider {
    id: number,
    prov_name: string
}
export default class Provider extends Root implements IProvider {
  id!: number;
  prov_name!: string;
  loco!: string;

  constructor (provider: IProvider) {
    super()
    Object.assign(this, provider)
  }

  getName () {
    return this.prov_name
  }
}

import { InventoryState } from '../../state'

export default {
  SET_BATTERY_INVENTORY (state: InventoryState, entities: any[]): void {
    state.battery_inventory = entities
  },
  PUSH_BATTERY_INVENTORY (state: InventoryState, entitie: any): void {
    state.battery_inventory.unshift(entitie)
  },
  UNMOUNTED_BATTERY (state: InventoryState, entity: any): void {
    const battery = state.battery_inventory.find(ib => ib.id === entity.id_battery)
    if (battery) {
      battery.unit = null
    }
  },
  MOUNTED_BATTERY (state: InventoryState, entity: any): void {
    const battery = state.battery_inventory.find(ib => ib.id === entity.id_battery)
    if (battery) {
      battery.unit = entity.unit
      battery.lifespan_years = entity.lifespan_years
    }
  },
  UPDATE_BATTERY_INVENTORY (state: InventoryState, entity: any): void {
    const index = state.battery_inventory.findIndex(battery => battery.id === entity.id)
    if (index !== -1) {
      state.battery_inventory.splice(index, 1, (entity))
    }
  }
}

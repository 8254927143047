// import { GeofencesState, ICGeofence } from './state'
import { InventoryState } from '../../state'
import { IEntryStore } from '../../../../classes/Inventory/interfaces/IEntryStore'

export default {
  SET_ENTRY_STORE (state: InventoryState, entities: IEntryStore[]): void {
    state.entry_store = entities
  },
  PUSH_ENTRY_STORE (state: InventoryState, entity: IEntryStore): void {
    state.entry_store.push(entity)
  },
  UPDATE_ENTRY_STORE (state: InventoryState, entity: IEntryStore): void {
    const index = state.entry_store.findIndex(p => p.id === entity.id)
    if (index !== -1) {
      state.entry_store.splice(index, 1, entity)
    }
  },
  DELETE_ENTRY_STORE (state: InventoryState, entity: IEntryStore): void {
    const index = state.entry_store.findIndex(p => p.id === entity.id)
    if (index !== -1) {
      state.entry_store.splice(index, 1)
    }
  }
}

// import { GeofencesState, ICGeofence } from './state'
import { WheelState } from '../../state'
import { IWheelMaker } from '@/classes/Wheel/interfaces/IWheelMaker'

export default {
  SET_WHEEL_MAKER (state: WheelState, entities: IWheelMaker[]): void {
    state.wheel_maker = entities
  },
  PUSH_WHEEL_MAKER (state: WheelState, entity: IWheelMaker): void {
    state.wheel_maker.push(entity)
  },
  UPDATE_WHEEL_MAKER (state: WheelState, entity: IWheelMaker): void {
    const index = state.wheel_maker.findIndex(p => p.id === entity.id)
    if (index !== -1) {
      state.wheel_maker.splice(index, 1, entity)
    }
  },
  DELETE_WHEEL_MAKER (state: WheelState, entity: IWheelMaker): void {
    const index = state.wheel_maker.findIndex(p => p.id === entity.id)
    if (index !== -1) {
      state.wheel_maker.splice(index, 1)
    }
  }
}

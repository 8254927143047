import { ActionContext } from 'vuex'
import { State } from './state'

const URL_ACTION = '/apis/crm_companies/'

export default {
  async load ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION + 'get/all',
        method: 'GET'
      },
      { root: true }
    )
    return data
  }
}

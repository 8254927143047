
export interface SatState {
  ubication: {
    estados: []
  },
  transport: {
    config_transport: [],
    perm_sct: [],
    remolque: []
  }
}

const state = (): SatState => ({
  ubication: { estados: [] },
  transport: { config_transport: [], perm_sct: [], remolque: [] }
})
export type State = ReturnType<typeof state>;

export default state

import { State } from '../../state'
import { ActionContext } from 'vuex'
import { IBatteryModel } from '../../../../classes/Battery/interfaces/IBatteryModel'
const actions = {
  async loadBatteryModel ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/battery_model/get/all',
        method: 'GET'
      },
      { root: true }
    )
    commit('SET_BATTERY_MODEL', data)
    return data
  },
  async createBatteryModel ({ commit, dispatch }: ActionContext<State, any>, entity: IBatteryModel) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/battery_model/create',
        method: 'POST',
        data: entity
      },
      { root: true }
    )
    commit('PUSH_BATTERY_MODEL', data)
    return data
  },
  async updateBatteryModel ({ commit, dispatch }: ActionContext<State, any>, entity: IBatteryModel) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/battery_model/update/${entity.id}`,
        method: 'PUT',
        data: entity
      },
      { root: true }
    )
    commit('UPDATE_BATTERY_MODEL', data)
    return data
  },
  async deleteBatteryModel ({ commit, dispatch }: ActionContext<State, any>, entity: IBatteryModel) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/battery_model/delete/${entity.id}`,
        method: 'DELETE'
      },
      { root: true }
    )
    commit('DELETE_BATTERY_MODEL', entity)
    return data
  }
}
export default actions

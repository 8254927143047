
export interface ChangeStoreState {
  change_store: any[]
}

const state = (): ChangeStoreState => ({
  change_store: []
})
export type State = ReturnType<typeof state>;

export default state


export interface ManttoOrderState {
  manttos: any[]
}

const state = (): ManttoOrderState => ({
  manttos: []
})
export type State = ReturnType<typeof state>;

export default state

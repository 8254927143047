import { InventoryState } from '../../state'
import { IDepartment } from '@/classes/Inventory/interfaces/IDepartment'

export default {
  SET_DEPARTMENT (state: InventoryState, entities: IDepartment[]): void {
    state.deparment = entities
  },
  PUSH_DEPARTMENT (state: InventoryState, entitie: IDepartment): void {
    state.deparment.unshift(entitie)
  },
  UPDATE_DEPARTMENT (state: InventoryState, entitie: IDepartment): void {
    const index = state.deparment.findIndex(p => p.id === entitie.id)
    if (index !== -1) {
      state.deparment.splice(index, 1, entitie)
    }
  },
  DELETE_DEPARTMENT (state: InventoryState, entitie: IDepartment): void {
    const index = state.deparment.findIndex(p => p.id === entitie.id)
    if (index !== -1) {
      state.deparment.splice(index, 1)
    }
  }
}


// import { InventoryState } from '../../state'
import { SatState } from './state'

export default {
  SET_ESTADOS (state: SatState, entities: []): void {
    state.ubication.estados = entities
  },
  SET_REMOLQUE (state: SatState, entities: []): void {
    state.transport.remolque = entities
  }
  // SET_PERM_SCT (state: SatState, entities: []): void {
  //   state.ubication.estados = entities
  // }
  // PUSH (state: SatState, entitie: any): void {
  //   state.quotations.unshift(entitie)
  // },
  // UPDATE (state: SatState, entitie: any): void {
  //   const index = state.quotations.findIndex(p => p.id === entitie.id)
  //   if (index !== -1) {
  //     state.quotations.splice(index, 1, entitie)
  //   }
  // }
}

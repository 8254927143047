/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionContext } from 'vuex'
import ActualizerItem from '@/classes/Actualizer/ActualizerItem'
import ResourceItem from '@/classes/Actualizer/ResourceItem'
import { PLATAFORM, PLATAFORM_RESOURCES, RESOURCES } from '../resources/RESOURCES'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ActualizerState {
  componentKey: number
  resourcesActualizer: Array<ResourceItem>
}

const state: ActualizerState = {
  componentKey: 1,
  resourcesActualizer: []
}

const mutations = {
  SET_RESOURCES_ACTUALIZER (state: ActualizerState, resources:Array<ResourceItem>): void {
    state.resourcesActualizer = resources
  }
}

const actions = {
  changeComponentKey (context: ActionContext<ActualizerState, string>): void {
    context.state.componentKey++
  },
  changeActualizerTab (context: ActionContext<ActualizerState, string>): void {
    context.dispatch('sys/changeTab', 'tab-Actualizer', { root: true })
    setTimeout(() => {
      context.dispatch('changeComponentKey')
    }, 2000)
  },
  async getResourcesActualizer (context: ActionContext<ActualizerState, string>): Promise<void> {
    // @ts-ignore
    const units = context.rootState.resources.PLATAFORM[PLATAFORM.WIALON][PLATAFORM_RESOURCES.UNITS]
    const unitsGroups = {}
    for (let i = 0; i < units.length; i++) {
      const unit = units[i]
      // @ts-ignore
      const resourceID = unit.$$user_accountId || 'DESCONOCIDO'
      // @ts-ignore
      if (unitsGroups[`${resourceID}`]) {
        // @ts-ignore
        unitsGroups[`${resourceID}`].addUnit(new ActualizerItem(unit))
      } else {
        // @ts-ignore
        const resource = context.rootState.resources.PLATAFORM[PLATAFORM.WIALON][PLATAFORM_RESOURCES.RESOURCES].find(res => res._id === resourceID)
        if (resource) {
          // @ts-ignore
          unitsGroups[`${resourceID}`] = new ResourceItem(resource, resourceID)
          // @ts-ignore
          unitsGroups[`${resourceID}`].addUnit(new ActualizerItem(unit))
        }
      }
    }
    context.commit('resources/SET_RESOURCES', { resources: Object.values(unitsGroups), rType: RESOURCES.ACTUALIZER }, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

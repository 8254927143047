
// import { InventoryState } from '../../state'
import { DriverState } from './state'

export default {
  SET_ALL (state: DriverState, entities: any[]): void {
    state.drivers = entities
  },
  PUSH (state: DriverState, entitie: any): void {
    state.drivers.unshift(entitie)
  },
  UPDATE (state: DriverState, entitie: any): void {
    const index = state.drivers.findIndex(p => p.id === entitie.id)
    if (index !== -1) {
      state.drivers.splice(index, 1, entitie)
    }
  }
}

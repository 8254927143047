
// import { InventoryState } from '../../state'
import { ProductState } from './state'

export default {
  SET_ALL (state: ProductState, entities: any[]): void {
    state.products = entities
  },
  PUSH (state: ProductState, entitie: any): void {
    state.products.unshift(entitie)
  },
  UPDATE (state: ProductState, entitie: any): void {
    const index = state.products.findIndex(p => p.id === entitie.id)
    if (index !== -1) {
      state.products.splice(index, 1, entitie)
    }
  }
}

// import { InventoryState } from '../../state'

import { QuotationsState } from './state'

// import { ProductState } from './state'

export default {
  getQuotations (state: QuotationsState) {
    return state.quotations
  }
}

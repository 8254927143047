
import { ActionContext } from 'vuex'
// import { State } from './state'
// import { ICustomer } from '@/classes/Facturacion/ICustomer'
const URL_ACTION = '/apis/sat/'
const actions = {
  async loadCodigoPostal ({ commit, dispatch }: ActionContext<unknown, any>, payload = { search: '', code: '', page: 1 }) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}postal_code/`,
        params: {
          ...payload
        },
        method: 'GET'
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async loadEstado ({ commit, dispatch }: ActionContext<unknown, any>, payload = { search: '', code: '', page: 1 }) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}estado/`,
        params: {
          ...payload
        },
        method: 'GET'
      },
      { root: true }
    )
    commit('SET_ESTADOS', data.results)
    return data
  },
  async loadMunicipio ({ commit, dispatch }: ActionContext<unknown, any>, payload = { search: '', code: '', page: 1 }) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}municipio/`,
        params: {
          ...payload
        },
        method: 'GET'
      },
      { root: true }
    )
    return data
  },
  async loadLocalidad ({ commit, dispatch }: ActionContext<unknown, any>, payload = { search: '', code: '', page: 1 }) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}localidad/`,
        params: {
          ...payload
        },
        method: 'GET'
      },
      { root: true }
    )
    return data
  },
  async loadColonia ({ commit, dispatch }: ActionContext<unknown, any>, payload = { search: '', code: '', page: 1 }) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}colonia/`,
        params: {
          ...payload
        },
        method: 'GET'
      },
      { root: true }
    )
    return data
  },
  async loadPermSCT ({ commit, dispatch }: ActionContext<unknown, any>, payload = { search: '', code: '', page: 1 }) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}config_transport/perm_sct`,
        params: {
          ...payload
        },
        method: 'GET'
      },
      { root: true }
    )
    return data
  },
  async loadRemolque ({ commit, dispatch }: ActionContext<unknown, any>, payload = { search: '', code: '', page: 1 }) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}config_transport/remolque`,
        params: {
          ...payload
        },
        method: 'GET'
      },
      { root: true }
    )
    commit('SET_REMOLQUE', data.results)
    return data
  },
  async loadConfigTransport ({ commit, dispatch }: ActionContext<unknown, any>, payload = { search: '', code: '', page: 1 }) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}config_transport/config_transport`,
        params: {
          ...payload
        },
        method: 'GET'
      },
      { root: true }
    )
    return data
  }
  // async create ({ commit, dispatch }: ActionContext<unknown, any>, payload: ICustomer) {
  //   const { data } = await dispatch(
  //     'app/axios',
  //     {
  //       url: URL_ACTION,
  //       method: 'POST',
  //       data: payload
  //     },
  //     { root: true }
  //   )
  //   commit('PUSH', data)
  // },
  // async update ({ commit, dispatch }: ActionContext<State, any>, payload: ICustomer) {
  //   const { data } = await dispatch(
  //     'app/axios',
  //     {
  //       url: `${URL_ACTION}${payload.id}`,
  //       method: 'PUT',
  //       data: payload
  //     },
  //     { root: true }
  //   )
  //   commit('UPDATE', data)
  // }
}
export default actions

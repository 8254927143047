
export interface NoteSaleState {
  note_sales: any[]
}

const state = (): NoteSaleState => ({
  note_sales: []
})
export type State = ReturnType<typeof state>;

export default state


export interface QuotationsState {
  quotations: any[]
}

const state = (): QuotationsState => ({
  quotations: []
})
export type State = ReturnType<typeof state>;

export default state

import mutations from './mutations'
import actions from './actions'
import state from './state'
import getters from './getters'
import { mantto_order } from './modules/mantto_order/index'

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
  modules: {
    mantto_order
  }
}

import { State } from '../../state'
import { ActionContext } from 'vuex'
import { IStore } from '../../../../classes/Inventory/interfaces/IStore'
const actions = {
  async loadStore ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/store/get/all',
        method: 'GET'
      },
      { root: true }
    )
    commit('SET_STORES', data)
    return data
  },
  async loadStoreInventories ({ commit, dispatch }: ActionContext<State, any>, store: number) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/store/get/inventory/all',
        method: 'GET'
      },
      { root: true }
    )
    commit('SET_INVENTORY', data)
    return data
  },
  async loadStoreWithInventories ({ commit, dispatch }: ActionContext<State, any>, id_user: number) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/store/get/inventory/',
        method: 'POST',
        data: { id_user }
      },
      { root: true }
    )
    commit('SET_INVENTORY', data)
    return data
  },
  async loadStoreDetailInventories ({ commit, dispatch }: ActionContext<State, any>, payload: { id_store: number, action?: string }) {
    const { id_store, action } = payload
    let url = `/apis/store/inventory/${id_store}`
    if (action) {
      url += `?action=${action}`
    }
    const { data } = await dispatch(
      'app/axios',
      {
        url,
        method: 'GET'
      },
      { root: true }
    )
    return data
  },
  async saveStoreWithInventories ({ commit, dispatch }: ActionContext<State, any>, payload: { id_user:number, stores_selected: []}) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/store/get/inventory/',
        method: 'PUT',
        data: payload
      },
      { root: true }
    )
    // commit('SET_INVENTORY', data)
    return data
  },
  async loadTotalStoreInventories ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/store/get/store-totals',
        method: 'GET'
      },
      { root: true }
    )
    commit('SET_TOTAL', data)
    return data
  },
  async loadStoreInventoryById ({ commit, dispatch }: ActionContext<State, any>, id: number) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/store/get/inventory/${id}`,
        method: 'GET'
      },
      { root: true }
    )
    commit('SET_INVENTORY', data)
    return data
  },
  async updateStoreInventoryById ({ commit, dispatch }: ActionContext<State, any>, payload: { id: number; data: any }) {
    const { id, data } = payload
    try {
      const response = await dispatch(
        'app/axios',
        {
          url: `/apis/store/update/inventory/${id}`,
          method: 'PUT',
          data: data
        },
        { root: true }
      )
      commit('SET_INVENTORY', response.data)
      return response.data
    } catch (error) {
      // Manejo de errores
      console.error('Error updating inventory:', error)
      throw error
    }
  },
  async updateEntryDetail ({ commit, dispatch }: ActionContext<State, any>, payload: { id: number; data: any }) {
    const { id, data } = payload
    try {
      const response = await dispatch(
        'app/axios',
        {
          url: `/apis/entry_detail/update/${id}`,
          method: 'PUT',
          data: data
        },
        { root: true }
      )
      commit('SET_INVENTORY', response.data)
      return response.data
    } catch (error) {
      // Manejo de errores
      console.error('Error updating inventory:', error)
      throw error
    }
  },
  async createStore ({ commit, dispatch }: ActionContext<State, any>, store: IStore) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/store/create',
        method: 'POST',
        data: store
      },
      { root: true }
    )
    commit('PUSH_STORE', data)
    return data
  },
  async updateStore ({ commit, dispatch }: ActionContext<State, any>, store: IStore) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/store/update/${store.id}`,
        method: 'PUT',
        data: store
      },
      { root: true }
    )
    commit('UPDATE_STORE', data)
    return data
  },
  async deleteStore ({ commit, dispatch }: ActionContext<State, any>, store: IStore) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/store/delete/${store.id}`,
        method: 'DELETE'
      },
      { root: true }
    )
    commit('DELETE_STORE', store)
    return data
  },
  async createInventoryIssue ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/store/inventory_issue/create',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    // commit('SET_STORES', data)
    return data
  },
  async loadInventoryIssue ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/store/inventory_issue/get/all',
        method: 'GET'
      },
      { root: true }
    )
    // commit('SET_STORES', data)
    return data
  }
}
export default actions

// src/utils/notifications.js
import Vue from 'vue'
import VueNotification from '../components/utils/VueNotification.vue'
// import CustomNotification from '@/components/CustomNotification.vue' // Asegúrate de que la ruta sea correcta

// @ts-ignore
export function notifyToast ({ msj, type, title = 'Notificación de operación', icon = 'mdi-bell-cog', duration = 5000 }) {
  // @ts-ignore
  Vue.notify({
    group: 'app',
    type,
    title: title,
    text: msj,
    duration: duration,
    data: {
      date: new Date().toLocaleString(),
      icon: icon
    }
  })
}


// import { InventoryState } from '../../state'
import { NoteSaleState } from './state'

export default {
  SET_ALL (state: NoteSaleState, entities: any[]): void {
    state.note_sales = entities
  },
  PUSH (state: NoteSaleState, entitie: any): void {
    state.note_sales.unshift(entitie)
  },
  UPDATE (state: NoteSaleState, entitie: any): void {
    const index = state.note_sales.findIndex(p => p.id === entitie.id)
    if (index !== -1) {
      state.note_sales.splice(index, 1, entitie)
    }
  }
}

import { State } from '../../state'
import { ActionContext } from 'vuex'
import { IWheelMaker } from '../../../../classes/Wheel/interfaces/IWheelMaker'
const actions = {
  async loadWheelMaker ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/wheel_maker/get/all',
        method: 'GET'
      },
      { root: true }
    )
    commit('SET_WHEEL_MAKER', data)
    return data
  },
  async createWheelMaker ({ commit, dispatch }: ActionContext<State, any>, entity: IWheelMaker) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/wheel_maker/create',
        method: 'POST',
        data: entity
      },
      { root: true }
    )
    commit('PUSH_WHEEL_MAKER', data)
    return data
  },
  async updateWheelMaker ({ commit, dispatch }: ActionContext<State, any>, entity: IWheelMaker) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/wheel_maker/update/${entity.id}`,
        method: 'PUT',
        data: entity
      },
      { root: true }
    )
    commit('UPDATE_WHEEL_MAKER', data)
    return data
  },
  async deleteWheelMaker ({ commit, dispatch }: ActionContext<State, any>, entity: IWheelMaker) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/wheel_maker/delete/${entity.id}`,
        method: 'DELETE'
      },
      { root: true }
    )
    commit('DELETE_WHEEL_MAKER', entity)
    return data
  }
}
export default actions

import { IAuth } from './auth.interface'
import { State } from './state'

export const mutations = {
  SET_LOGIN_DATA (state: State, data: State) {
    Object.assign(state, data)
  },
  SET_USER_DATE_DATA (state: State, data: typeof state.user_date) {
    Object.assign(state.user_date, data)
  },
  SET_COPY_AUTH (state: State, { dataUser, sessionId }: { dataUser: State; sessionId: number }) {
    const sessionData = {
      ...dataUser,
      userBaseAuth: { ...state }
    }
    sessionStorage.setItem(`authState_${sessionId}`, JSON.stringify(sessionData))
  },
  RETURN_USER_PRINCIPAL (state: State) {
    Object.assign(state, state.userBaseAuth)
    state.userBaseAuth = null
  },
  UPDATE_AUTH_DATA (state: State, auth: State) {
    state.plataform_data = auth.plataform_data
    state.developer = auth.developer
    state.user_date = { ...auth.user_date }
  },
  CLEAN_LOGIN_DATA (state: State) {
    const cleanState: IAuth = {
      is_auth: false,
      login_cms: false,
      auth_token: '',
      plataform_data: [],
      auth_tokenWith: null,
      user_date: {
        id: 0,
        id_user: 0,
        service: [],
        us_name: '',
        // data_userType: [],
        distribuitor: false,
        id_user_profile: 0,
        loader_image: '',
        phone: '',
        logo_image: '',
        email: '',
        id_user_mirror: 0,
        account: {
          id: 0,
          name: ''
        },
        thumbnail_file: null
      },
      service: [],
      reload: false,
      developer: false,
      userBaseAuth: null
    }
    Object.assign(state, cleanState)
  }
}

export default mutations


export interface ProductState {
    products: any[]
}

const state = (): ProductState => ({
  products: []
})
export type State = ReturnType<typeof state>;

export default state

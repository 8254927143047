
import { State } from '../state'
import SheduledReport from '../../../classes/Unit/SheduledReport'
export default {
  SET_SHEDULED_REPORTS (state: State, sheduled_reports: SheduledReport[]) {
    state.sheduled_reports = sheduled_reports
  },
  PUSH_SHEDULED_REPORT (state: State, sheduled_report: SheduledReport) {
    state.sheduled_reports.push(sheduled_report)
  },
  UPDATE_SHEDULED_REPORT (state: State, item:any) {
    const sheduled_report = state.sheduled_reports.find(sheduled_report => sheduled_report.id === item.id)
    // @ts-ignore
    Object.assign(sheduled_report, { ...item })
  },
  DELETE_SHEDULED_REPORT (state: State, id_sheduled_report: number) {
    const indexToRemove = state.sheduled_reports.findIndex(sheduled_report => sheduled_report.id === id_sheduled_report)
    if (indexToRemove !== -1) {
      state.sheduled_reports.splice(indexToRemove, 1)
    }
  }
}

import mutations from './mutations'
import actions from './actions'
import state from './state'
import getters from './getters'
import { notifications } from './modules/notifications/index'
import { email } from './modules/email/index'
// import { email } from 'vee-validate/dist/rules'

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
  modules: {
    notifications,
    email
  }
}

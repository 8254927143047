import { State } from '../../state'
import { ActionContext } from 'vuex'
import { IEntryConcept } from '../../../../classes/Inventory/interfaces/IEntryConcept'
const actions = {
  async loadEntryConcept ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/entry_concept/get/all',
        method: 'GET'
      },
      { root: true }
    )
    commit('SET_ENTRY_CONCEPT', data)
    return data
  },
  async createEntryConcept ({ commit, dispatch }: ActionContext<State, any>, entry_concept: IEntryConcept) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/entry_concept/create',
        method: 'POST',
        data: entry_concept
      },
      { root: true }
    )
    commit('PUSH_ENTRY_CONCEPT', data)
    return data
  },
  async updateEntryConcept ({ commit, dispatch }: ActionContext<State, any>, entry_concept: IEntryConcept) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/entry_concept/update/${entry_concept.id}`,
        method: 'PUT',
        data: entry_concept
      },
      { root: true }
    )
    commit('UPDATE_ENTRY_CONCEPT', data)
    return data
  },
  async deleteEntryConcept ({ commit, dispatch }: ActionContext<State, any>, entry_concept: IEntryConcept) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/entry_concept/delete/${entry_concept.id}`,
        method: 'DELETE'
      },
      { root: true }
    )
    commit('DELETE_ENTRY_CONCEPT', entry_concept)
    return data
  }
}
export default actions

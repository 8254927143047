
export interface TagsState {
  tags: any[]
}

const state = (): TagsState => ({
  tags: []
})

export type State = ReturnType<typeof state>

export default state

import { IMessageFile } from '@/store/interfaces/Chat'
import BaseClass from '../BaseClass'
import { IFile } from '@/interfaces/IFIle.interface'
import store from '@/store'

export default class MessageFile extends BaseClass<IMessageFile> implements IMessageFile {
  file!: IFile;
  id_chat_message!: number;
  id_chat_message_file!: number;
  id_file!: number;

  public onInitInstance (): void {
    // ---
  }

  /**
   * Returns a boolean value indicating whether the file associated with this message is a multimedia file.
   * A file is considered multimedia if its mimetype starts with 'image/' or 'video/'.
   *
   * @returns {boolean} True if the file is a multimedia file, false otherwise.
   */
  public getIsMultimediaFile () {
    return this.file.fl_mimetype.startsWith('image/') || this.file.fl_mimetype.startsWith('video/')
  }

  public get filename () {
    return this.file.fl_tagname || this.file.fl_name
  }

  public get fileUrl () {
    // @ts-ignore
    return `${store.state.app.BASE_URL}${this.file.fl_url}`
  }
}

import { Module } from 'vuex'
// @ts-ignore
import { RootState } from '@/store/types'
// import { productos } from './modules/product'
// import { customer } from './modules/customer'
// import { quotation } from './modules/quotations'
import { driver } from './modules/driver'
// import { productos } from './productos'
// import { clientes } from './clientes'
// import { cotizaciones } from './cotizaciones'
import { unit } from './modules/unit/index'

export const unit_console: Module<any, RootState> = {
  namespaced: true,
  modules: {
    // productos,
    // customer,
    // quotation,
    driver,
    unit
    // clientes,
    // cotizaciones
  }
}

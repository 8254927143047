import { IArea } from '@/classes/Area/ITicket'

export interface AreaState {
  areas: Array<IArea>;
}

const state = (): AreaState => ({
  areas: []
})

export type State = ReturnType<typeof state>

export default state

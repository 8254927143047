/* eslint-disable @typescript-eslint/no-explicit-any */
// @ts-nocheck
// eslint-disable-next-line @typescript-eslint/no-explicit-any
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default (sess: any, token: string, operateAs?: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    sess.loginToken(token, operateAs | '',
      (code: string) => {
        if (code) {
          reject(wialon.core.Errors.getErrorText(code))
        } else {
          resolve()
        }
      }
    )
  })
}

// import { InventoryState } from '../../state'

import { RouteDestinationState } from './state'

// import { ProductState } from './state'

export default {
  getRouteDestination (state: RouteDestinationState) {
    return state.route_destinations
  }
}

import axios, { Axios, AxiosRequestConfig, AxiosResponse } from "axios"
import JimiIothubSDK from ".."
import getMethodNameArgs from "../utils/getMethodNameArgs"
import objToURLSerachParams from "../utils/objToURLSerachParams"
import { IGetChannelsLinksOptions } from "./drv.interface";

/**
 * Clase que proporciona una interfaz para interactuar con la API de DVR de Jimi IoT Hub.
 *
 * La clase utiliza el patron de proxy para llamar los metodos de la API correspondiente.
 *
 * Luego, se puede llamar los metodos de la API utilizando el nombre del m todo y los
 * par metros necesarios.
  *
 * @example
 * const result = await sdk.drv.get_dvr_upload_jc450_assets(["860222070000574"],{}, { headers: { 'Authorization': 'Basic dXNlcjpwYXNz' } })
 * console.log(result.data)
 */
export default class DVR {
    // Para evitar errores de tipado
    [key: string]: any;
    private sdk: JimiIothubSDK

    constructor(sdk: JimiIothubSDK) {
        this.sdk = sdk
        return this.initHandler()
    }

    private initHandler() {
        const self = this;
        return new Proxy<typeof this>(this, {
            get(target: any, prop: string) {
                if (prop in target) {
                    return (target as any)[prop];
                }
                return function (...args: any[]) {
                    const pathParams: string[] = args.length > 0 ? args[0] : []
                    const params: { [key: string]: any } = args.length > 1 ? args[1] : {}
                    const requestConfig: AxiosRequestConfig = args.length > 2 ? args[2] : {}
                    return self.caller(prop, pathParams, params, requestConfig);
                };
            }
        });
    }

    private async caller(
        method: string,
        pathArgs: string[],
        args: { [key: string]: any },
        { headers = {}, ...requestConfig }: AxiosRequestConfig = {}
    ): Promise<any> {
        const protocol = this.sdk.config.useHttps ? 'https' : 'http'
        const [httpMethodUnderScore, methodPathUnderScore] = getMethodNameArgs(method)
        const httpMethod = httpMethodUnderScore.replace(/_/g, '')
        const methodPath = methodPathUnderScore.replace(/_/g, '/')
        const isGet = httpMethod === 'get'

        let url = `${protocol}://${this.sdk.config.iothubCustomerServerInterfaceHost}/${methodPath}/${pathArgs.join('/')}`
        if (url.endsWith('/')) {
          url = url.slice(0, -1);
        }

        if (this.sdk.IS_DEV) {
            console.log("Method name: ", method)
            console.log("HttpMethod: ", httpMethod)
            console.log("Path: ", methodPath)
            console.log("Args: ", args)
            console.log('URL: ', url)
            console.log('Request Config: ', { ...requestConfig, headers })
        }

        const data = axios({
            method: httpMethod,
            url,
            data: isGet ? undefined : args,
            params: isGet ? objToURLSerachParams(args) : {},
            headers: {
                ...(isGet ? {} : { 'Content-Type': 'application/json' }),
                ...headers
            },
            ...requestConfig
        })

        return data
    }

    /**
     * Gets the links to the video streams of the given channels.
     *
     * The links can be in HTTP or RTMP protocol.
     *
     * For HTTP protocol, the link can be a FLV or an HLS (m3u8) link.
     *
     * @param channels The IDs of the channels to get the links for.
     * @param options Options for the links.
     * @param options.asHttps If true, the links will be in HTTPS protocol. If false, the links will be in HTTP protocol. Defaults to the value of `useHttps` in the SDK configuration.
     * @param options.protocol The protocol of the links. Can be 'http' or 'rtmp'. Defaults to 'http'.
     * @param options.useHLS If true, the links will be in HLS (m3u8) format. If false, the links will be in FLV format. Defaults to false.
     * @returns An array of links to the video streams of the given channels.
     */
    public getChannelsLinks(deviceImei: string, channels: number[], {
        asHttps = this.sdk.config.useHttps,
        protocol = 'http' as 'http' | 'rtmp',
        useHLS = false,
        asHistory = false,
        usePortsAsUrl = this.sdk.config.usePortsAsUrl
    }: IGetChannelsLinksOptions = {}): { link: string; channelId: number }[] {
        // http://{server_name}:8881/{Channel ID}/{Device IMEI}.flv
        // http://{server_name}:8881/{Channel ID}/{Device IMEI}/hls.m3u8
        // rtmp://{server_name}:1936/{Channel ID}/{Device IMEI}
        const links: { link: string; channelId: number }[] = [];
        const serverName = this.sdk.config.iothubServerIp; // Ignoramos el server_name como indicaste

        channels.forEach((channelId) => {
            let link = '';
            if (protocol === 'http') {
                const scheme = asHttps ? 'https' : 'http';
                const port = 8881;

                if (useHLS) {
                    // Link para HLS
                    // http://{server_name}:8881/{Channel ID}/{Device IMEI}/hls.m3u8
                    link = `${scheme}://${serverName}${usePortsAsUrl ? '/' + port : ':'}/${channelId}/${deviceImei}${asHistory ? '.history': ''}/hls.m3u8`;
                } else {
                    // Link para FLV
                    // http://{server_name}:8881/{Channel ID}/{Device IMEI}.flv
                    link = `${scheme}://${serverName}${usePortsAsUrl ? '/' + port : ':'}/${channelId}/${deviceImei}${asHistory ? '.history': ''}.flv`;
                }
            } else if (protocol === 'rtmp') {
                const scheme = 'rtmp';
                const port = 1936;

                // Link para RTMP
                // rtmp://{server_name}:1936/{Channel ID}/{Device IMEI}
                link = `${scheme}://${serverName}${usePortsAsUrl ? '/' + port : ':'}/${channelId}/${deviceImei}${asHistory ? '.history': ''}`;
            }

            links.push({ link, channelId });
        });

        return links;
    }
}

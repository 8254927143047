import mutationsSheduledReport from './sheduled_report/mutations'
import { State } from './state'
export default {
  CLEAN_NOTIFICATIONS (state: State) {
    state.notifications = []
  },
  REMOVE_NOTIFICATION (state: State, payload : {index:number, parentIndex:number}) {
    console.log(payload)
    if (payload.parentIndex !== undefined) {
      // @ts-ignore
      state.notifications[payload.parentIndex].notification_child.splice(payload.index, 1)
    } else {
      state.notifications.splice(payload.index, 1)
    }
  },
  ...mutationsSheduledReport
}

/* eslint-disable */

export interface Comment {
  ticketId: number | string
  comment: string
  images: File[]
  [key: string]: any
}

export default function createCommentFormData (comment: Comment): FormData {
  const formData = new FormData()

  for (const key in comment) {
    if (key === 'images') {
      comment.images.forEach((image) => {
        if (image instanceof File) {
          formData.append('images', image)
        } else {
          console.warn(`Expected image to be an instance of File but got ${typeof image}`)
        }
      })
    } else {
      formData.append(key, comment[key])
    }
  }

  return formData
}

import { ITicket } from '@/classes/Ticket/ITicket'

export interface TicketState {
  tickets: Array<ITicket>;
}

const state = (): TicketState => ({
  tickets: []
})

export type State = ReturnType<typeof state>

export default state


export interface ReportTaskState {
  report_templates: any[]
}

const state = (): ReportTaskState => ({
  report_templates: []
})
export type State = ReturnType<typeof state>

export default state

import { State } from '../../state'
import { ActionContext } from 'vuex'
import { IWheelSize } from '../../../../classes/Wheel/interfaces/IWheelSize'
const actions = {
  async loadWheelSize ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/wheel_size/get/all',
        method: 'GET'
      },
      { root: true }
    )
    commit('SET_WHEEL_SIZE', data)
    return data
  },
  async createWheelSize ({ commit, dispatch }: ActionContext<State, any>, entity: IWheelSize) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/wheel_size/create',
        method: 'POST',
        data: entity
      },
      { root: true }
    )
    commit('PUSH_WHEEL_SIZE', data)
    return data
  },
  async updateWheelSize ({ commit, dispatch }: ActionContext<State, any>, entity: IWheelSize) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/wheel_size/update/${entity.id}`,
        method: 'PUT',
        data: entity
      },
      { root: true }
    )
    commit('UPDATE_WHEEL_SIZE', data)
    return data
  },
  async deleteWheelSize ({ commit, dispatch }: ActionContext<State, any>, entity: IWheelSize) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/wheel_size/delete/${entity.id}`,
        method: 'DELETE'
      },
      { root: true }
    )
    commit('DELETE_WHEEL_SIZE', entity)
    return data
  }
}
export default actions

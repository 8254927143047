
// import { InventoryState } from '../../state'
import { TechnicalState } from './state'

export default {
  SET_ALL (state: TechnicalState, entities: any[]): void {
    state.technicals = entities
  },
  PUSH (state: TechnicalState, entitie: any): void {
    state.technicals.unshift(entitie)
  },
  UPDATE (state: TechnicalState, entitie: any): void {
    const index = state.technicals.findIndex(p => p.id === entitie.id)
    if (index !== -1) {
      state.technicals.splice(index, 1, entitie)
    }
  }
}

import { InventoryState } from '../../state'
import { PURCHASE_ORDER_STATUS, PURCHASE_ORDER_STATUS_ARRAY } from '../../../catalogs/PURCHASE_ORDER_STATUS'

export default {
  getPurchaseOrder (state: InventoryState) {
    return state.purchase_order
  },
  getPurchaseOrderConfirmed (state: InventoryState) {
    // @ts-ignore
    return state.purchase_order.filter(purch => purch.por_added_inventory === false)
  },
  getTextStatusPurchase () {
    return (status: PURCHASE_ORDER_STATUS) => {
      return PURCHASE_ORDER_STATUS_ARRAY.find(s => s.id === status)
    }
  }
}

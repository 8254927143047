// import { GeofencesState, ICGeofence } from './state'
import { WheelState } from '../../state'
import { IUnitType } from '../../../../classes/Unit/IUnitType'

export default {
  SET_UNIT_TYPE (state: WheelState, entities: IUnitType[]): void {
    state.unit_type = entities
  },
  PUSH_UNIT_TYPE (state: WheelState, entity: IUnitType): void {
    state.unit_type.push(entity)
  },
  UPDATE_UNIT_TYPE (state: WheelState, entity: IUnitType): void {
    const index = state.unit_type.findIndex(p => p.id === entity.id)
    if (index !== -1) {
      state.unit_type.splice(index, 1, entity)
    }
  },
  DELETE_UNIT_TYPE (state: WheelState, entity: IUnitType): void {
    const index = state.unit_type.findIndex(p => p.id === entity.id)
    if (index !== -1) {
      state.unit_type.splice(index, 1)
    }
  }
}

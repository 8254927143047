// import { GeofencesState, ICGeofence } from './state'
import { InventoryState } from '../../state'
import { IStore } from '../../../../classes/Inventory/interfaces/IStore'
import { IEntryConcept } from '../../../../classes/Inventory/interfaces/IEntryConcept'

export default {
  SET_ENTRY_CONCEPT (state: InventoryState, entities: IEntryConcept[]): void {
    state.entry_concept = entities
  },
  PUSH_ENTRY_CONCEPT (state: InventoryState, entity: IEntryConcept): void {
    state.entry_concept.push(entity)
  },
  UPDATE_ENTRY_CONCEPT (state: InventoryState, entity: IEntryConcept): void {
    const index = state.entry_concept.findIndex(p => p.id === entity.id)
    if (index !== -1) {
      state.entry_concept.splice(index, 1, entity)
    }
  },
  DELETE_ENTRY_CONCEPT (state: InventoryState, entity: IEntryConcept): void {
    const index = state.entry_concept.findIndex(p => p.id === entity.id)
    if (index !== -1) {
      state.entry_concept.splice(index, 1)
    }
  }
}


// import { InventoryState } from '../../state'
import { NotificationState } from './state'

export default {
  SET_ALL (state: NotificationState, entities: any[]): void {
    state.notifications = entities
  },
  PUSH (state: NotificationState, entitie: any): void {
    state.notifications.unshift(entitie)
  },
  UPDATE (state: NotificationState, entitie: any): void {
    const index = state.notifications.findIndex(p => p.id === entitie.id)
    if (index !== -1) {
      state.notifications.splice(index, 1, entitie)
    }
  }
}

export enum CAMERA_TYPE {
  RTSP = 1,
  // RTSP_AUTH = 2,
  HV_RTSP = 3,
  FLV = 4
}

export const CAMERA_TYPE_ARR = [
  {
    id: 1,
    name: 'RTSP'
  },
  // {
  //   id: 2,
  //   name: 'RTSP - AUTH'
  // },
  {
    id: 3,
    name: 'HV - RTSP'
  }
]

import Root from '../Root/Root'

export interface IIssue {
  id: number;
  issue: string;
  area: number;
  created_by: number;
}
export default class Issue extends Root implements IIssue {
  id!: number;
  issue!: string;
  area!: number;
  created_by!: number;

  constructor (issue: IIssue) {
    super()
    Object.assign(this, issue)
  }
}

// import { InventoryState } from '../../state'

import { TollState } from './state'

// import { ProductState } from './state'

export default {
  getTolls (state: TollState) {
    return state.tolls
  }
}

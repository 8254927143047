// import { GeofencesState, ICGeofence } from './state'
import { InventoryState } from '../../state'
import { IEntryType } from '../../../../classes/Inventory/interfaces/IEntryType'

export default {
  SET_ENTRY_TYPE (state: InventoryState, entities: IEntryType[]): void {
    state.entry_type = entities
  },
  PUSH_ENTRY_TYPE (state: InventoryState, entity: IEntryType): void {
    state.entry_type.push(entity)
  },
  UPDATE_ENTRY_TYPE (state: InventoryState, entity: IEntryType): void {
    const index = state.entry_type.findIndex(p => p.id === entity.id)
    if (index !== -1) {
      state.entry_type.splice(index, 1, entity)
    }
  },
  DELETE_ENTRY_TYPE (state: InventoryState, entity: IEntryType): void {
    const index = state.entry_type.findIndex(p => p.id === entity.id)
    if (index !== -1) {
      state.entry_type.splice(index, 1)
    }
  }
}

// import { InventoryState } from '../../state'

import { State } from './state'

// import { ProductState } from './state'

export default {
  getNotifications (state: State) {
    return state.emails.filter(notification => !notification.deleted_at)
  }
}

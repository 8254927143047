const quotationRoutes = {
  meta: {
    title: 'Cotizaciones'
  },
  path: 'cotizaciones',
  component: () => import('@/components/Facturacion/modules/quotation/Quotation.vue'),
  children: [
    {
      meta: {
        title: 'Cotizaciones'
      },
      path: '',
      name: 'cotizaciones',
      component: () => import('@/components/Facturacion/modules/quotation/QuotationList.vue')
    },
    {
      path: 'nueva-cotizacion',
      name: 'nueva-cotizacion',
      meta: {
        title: 'Cotización'
      },
      component: () => import('@/components/Facturacion/modules/quotation/QuotationForm.vue')
    },
    {
      path: 'pdf-settings',
      name: 'pdf-settings',
      meta: {
        title: 'PDF Personalizado'
      },
      component: () => import('@/components/Facturacion/modules/quotation/Settings_customized/MembretesList.vue')
    }
  ]
}

export default quotationRoutes

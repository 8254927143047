/* eslint-disable no-throw-literal */
/* eslint-disable no-new */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */

import { ActionContext } from 'vuex'
import { State } from './state'
import MaponSDK from '@/submodules/maponjssdk'
import { PLATAFORM, PLATAFORM_RESOURCES } from '../resources/RESOURCES'
import axios, { AxiosResponse } from 'axios'
import { Alert } from '@/submodules/maponjssdk/dist/interfaces/Alert.interface'
import { INotification } from '@/interfaces/Wialon.interface'
import formatGeofenceMapon from '@/utils/formatGeofenceMapon'
import { ICGeofence } from '../geofences/state'

export default {
  async init ({ rootGetters, rootState, commit, dispatch }: ActionContext<State, any>) {
    const mapon = rootGetters['auth/maponData']
    if (!mapon) return
    if (mapon.token === '') return
    try {
      await dispatch('checkLogin', mapon.token)
      const sdk = new MaponSDK({
        token: mapon.token,
        disableInterval: false
      })
      commit('SET_SDK', sdk)
      dispatch('loadUnits')
      dispatch('loadGeofences')
      dispatch('loadDrivers')
      // dispatch('initNotifications', true)
    } catch (err) {
      await dispatch('app/notification', {
        content: `${err}`,
        type: 'error',
        timeout: 2000
      }, { root: true })
    }
  },
  async checkLogin ({ rootGetters, rootState, commit, dispatch }: ActionContext<State, any>, apiKeyMapon:string) {
    const response :AxiosResponse = await axios.get(`https://mapon.com/api/v1/unit.json?key=${apiKeyMapon}`)
    if (response.status !== 200) throw 'Error al cargar API MAPON'
    if (response.data.error) {
      throw `MAPON: ${response.data.error.msg}`
    }
  },
  initNotifications ({ dispatch, rootState }: ActionContext<State, any>, state: boolean) {
    const callback = async (alert: Alert) => {
      const unitRegister = (rootState.travel.unitsRegisters.find((unit: { plataform: string, unit_resource_id: number }) => unit.plataform === 'MAPON' && Number(unit.unit_resource_id) === alert.unit_id))
      if (!unitRegister) return
      const unit = await MaponSDK.actions.AUnit.getById(alert.unit_id)
      if (unit) {
        const notification: INotification = {
          color: '#28affc',
          pType: PLATAFORM.MAPON,
          name: alert.alert_type,
          // @ts-ignore
          nid: alert.alert_type,
          p: {
            x: unit.lng,
            y: unit.lat
          },
          rt: 0,
          t: Date.now(),
          x: unit.lng,
          y: unit.lat,
          unitData: unit,
          unit: unit.unit_id,
          tp: '',
          txt: `${alert.msg}. El ${new Date(alert.time).toLocaleString()}`,
          url: ''

        }
        await dispatch('wialon/addNotification', notification, { root: true })
      }
    }
    if (state) {
      MaponSDK.actions.AAlert.addEventListener('ALERT_MESSAGE_CHANGED', callback)
      // dispatch('sys/showNotificationMessage', {
      //   title: 'Notificaciones de mapon activadas',
      //   color: 'success'
      // }, { root: true })
    } else {
      MaponSDK.actions.AAlert.removeEventListener('ALERT_MESSAGE_CHANGED', callback)
      // dispatch('sys/showNotificationMessage', {
      //   title: 'Notificaciones de mapon desactivadas',
      //   color: 'success'
      // }, { root: true })
    }
  },
  async loadUnits ({ rootGetters, rootState, commit }: ActionContext<State, any>) {
    // @ts-ignore
    // const objects = await state.sdk
    const pUnits = await MaponSDK.actions.AUnit.list()
    commit(
      'resources/SET_RESOURCES',
      {
        resources: pUnits,
        plataform: PLATAFORM.MAPON,
        rPlatafrom: PLATAFORM_RESOURCES.UNITS
      },
      { root: true }
    )
  },
  async loadGeofences ({ rootGetters, rootState, commit }: ActionContext<State, any>) {
    const geofences = await MaponSDK.actions.AObjects.list()
    // const b:ICGeofence =
    const pGeofences: ICGeofence[] = geofences.map(geo => {
      const coordinates = formatGeofenceMapon(geo.wkt)
      const b = { cen_y: coordinates[0].lat, cen_x: coordinates[0].lng }
      return { geo_color: '#004CFFFF', geo_description: '', geo_name: geo.name, id: geo.id, n: geo.name, w: 30, b, geo_payload: { type: 2, coordinates } }
    })
    return pGeofences
    // commit(
    //   'resources/SET_RESOURCES',
    //   {
    //     resources: pGeofences,
    //     plataform: PLATAFORM.MAPON,
    //     rPlatafrom: PLATAFORM_RESOURCES.GEOFENCES
    //   },
    //   { root: true }
    // )
  },
  async loadDrivers ({ rootGetters, rootState, commit }: ActionContext<State, any>) {
    const pDrivers = await MaponSDK.actions.ADriver.list()
    commit(
      'resources/SET_RESOURCES',
      {
        resources: pDrivers,
        plataform: PLATAFORM.MAPON,
        rPlatafrom: PLATAFORM_RESOURCES.DRIVERS
      },
      { root: true }
    )
    // commit(
    //   'resources/SET_RESOURCES',
    //   {
    //     resources: pUnits,
    //     plataform: PLATAFORM.MAPON,
    //     rPlatafrom: PLATAFORM_RESOURCES.GEOFENCES
    //   },
    //   { root: true }
    // )
  }
}

import { InventoryState } from '../../state'

export default {
  getStores (state: InventoryState) {
    return state.stores
  },
  getInventories (state: InventoryState) {
    return state.inventories
  },
  getTotalInventories (state: InventoryState) {
    return state.total
  }
}

// import { GeofencesState, ICGeofence } from './state'
import { InventoryState } from '../../state'
import { IStore } from '../../../../classes/Inventory/interfaces/IStore'

export default {
  SET_STORES (state: InventoryState, stores: IStore[]): void {
    state.stores = stores
  },
  PUSH_STORE (state: InventoryState, store: IStore): void {
    state.stores.push(store)
  },
  UPDATE_STORE (state: InventoryState, store: IStore): void {
    const index = state.stores.findIndex(p => p.id === store.id)
    if (index !== -1) {
      state.stores.splice(index, 1, store)
    }
  },
  DELETE_STORE (state: InventoryState, store: IStore): void {
    const index = state.stores.findIndex(p => p.id === store.id)
    if (index !== -1) {
      state.stores.splice(index, 1)
    }
  },
  SET_INVENTORY (state: InventoryState, inventories: any): void {
    state.inventories = inventories
  },
  SET_TOTAL (state: InventoryState, total: any): void {
    state.total = total
  }
}

import { CHAT_MESSAGE_CODE, CHAT_MESSAGE_TYPES, IChat, IChatMessage, UserSchemaSimple } from '@/store/interfaces/Chat'
import BaseClass from '../BaseClass'
import ChatItem from './ChatItem'
import store from '@/store'
import objToFormData from '@/utils/objToFormDataDeep'
import { CHAT_MESSAGE_STATE } from '@/store/catalogs/CHAT_MESSAGE_STATE'
import { DateTime } from 'luxon'
import MessageFile from './MessageFile'
import { downloadAsync } from '@/utils/download'
import { IFile } from '@/interfaces/IFIle.interface'

export default class ChatMessage extends BaseClass<IChatMessage> implements IChatMessage {
  chm_createdAt!: string;
  chm_message!: string;
  chm_user_name!: string;
  chm_is_edited!: boolean;
  id_chat_item!: number;
  id_chat_message!: number;
  id_chat_message_to_reply!: number | null;
  // eslint-disable-next-line no-use-before-define
  chat_message_to_reply!: ChatMessage | null;
  chm_user_name_deleted!: string;
  chm_color!: string;
  chm_is_resend!: boolean;
  id_user!: number;
  message_files!: MessageFile[];
  user?: UserSchemaSimple | null;
  chm_code_payload!: { [key: string]: any; };
  id_chat_message_type!: CHAT_MESSAGE_TYPES;
  id_chat_message_code!: CHAT_MESSAGE_CODE;
  id_chat_message_audio!: number | null;
  chat_message_audio!: IFile | null;
  chm_is_deleted!: boolean;

  /** Cuando el objeto audio se alla subido, se creara este objeto audio para su reproduccion */
  audio: {
    _currTime: number
    _duration: number
    _paused: boolean
    _state: number
    _isError: boolean
    play: () => Promise<void>
    pause: () => void
    audio_html_element: HTMLAudioElement | null
    /**
     * A getter function to calculate the total time of the audio.
     * @return {string} the total time of the audio in the format 'mm:ss'
     */
    audioTotalTime: string
    /**
     * This function calculates the current time of the audio and returns it as a string in the format "mm:ss".
     * @return {string} the current time of the audio in "mm:ss" format
     */
    audioCurrTime: string
    /**
     * Getter for checking if the audio is paused.
     * @return {boolean} The paused status of the audio element.
     */
    isPaused: boolean
    /**
     * Getter for checking if the audio is not playable.
     * @return {boolean} true if the audio is not playable, false otherwise
     */
    isNotPlayable: boolean
    setCurrTime: (time: number) => void
    setAudioCurrTime: (time: number) => void
    isAvalable: boolean
    isLoaded: boolean
    audioUrl: string
    donwloadFile: () => Promise<void>
    /**
     * Calculate the percentage of audio played.
     * @return {number} the percentage of audio played
     */
    percentPlayed: number
  }

  /**
   * Archivo de audio cuando aun no se a enviado al servidor cuando aun no se han enviado al servidor, una vez enviados, este array debe estar vacio
   */
  raw_chat_message_audio: File | null
  /**
   * Array de files del mensaje cuando aun no se han enviado al servidor, una vez enviados, este array debe estar vacio
   */
  raw_message_files: File[]
  /**
   * Indica si el mensaje fue enviado por un bot
   */
  omit_bot_message: boolean
  /**
   * Objeto de referencia al objeto ChatItem al que le pertenece este mensaje
   */
  chatItem: ChatItem
  /** Indica el estado del mensaje actual */
  messageState: CHAT_MESSAGE_STATE

  constructor (params: { data: IChatMessage, chatItem: ChatItem, state?: CHAT_MESSAGE_STATE, raw_message_files?: File[], raw_chat_message_audio?: File | null, omit_bot_message?: boolean }) {
    super(params)
    this.chatItem = params.chatItem
    this.messageState = params.state ?? CHAT_MESSAGE_STATE.SENDED
    this.raw_message_files = params.raw_message_files ?? []
    this.raw_chat_message_audio = params.raw_chat_message_audio ?? null
    this.omit_bot_message = params.omit_bot_message ?? false
    this.chat_message_to_reply = null
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this

    // Objeto que maneja el audio del mensaje (solo cuando el mensaje es un audio)
    this.audio = {
      audio_html_element: null,
      isLoaded: true,
      _currTime: 0,
      _duration: 0,
      _paused: true,
      _state: 1,
      _isError: false,
      async donwloadFile () {
        try {
          await downloadAsync(this.audioUrl, `${self.chm_user_name}-${self.chm_createdAt}${self.chat_message_audio?.fl_extencion || '.mp3'}`)
        } catch (error) {
          console.error(error)
          store.dispatch('app/addLogWithError', { title: 'CHAT_MESSAGE_DONWLOAD_AUDIO', color: 'error', message: '', error })
          throw error
        }
      },
      get audioUrl () {
        // @ts-ignore
        return `${store.state.app.BASE_URL}${self.chat_message_audio?.fl_url}`
      },
      async play () {
        if (!this.audio_html_element) return
        this._paused = false
        await this.audio_html_element.play()
      },
      pause () {
        if (!this.audio_html_element) return
        this._paused = true
        this.audio_html_element.pause()
      },
      get audioCurrTime () {
        if (!this.audio_html_element) return ''
        if (isNaN(this._currTime) || !isFinite(this._currTime)) return ''
        // @ts-ignore
        const milliseconds = this._currTime * 1000
        return new Date(milliseconds).toISOString().substr(14, 5)
      },
      get audioTotalTime () {
        if (!this.audio_html_element) return ''
        if (isNaN(this._duration) || !isFinite(this._duration)) return ''
        // @ts-ignore
        const milliseconds = this._duration * 1000
        return new Date(milliseconds).toISOString().substr(14, 5)
      },
      get isPaused () {
        if (!this.audio_html_element) return true
        return this._paused
      },
      get isNotPlayable () {
        return this._state === 0 || this._isError
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setCurrTime (time: number) {
        if (!this.audio_html_element) return
        if (isNaN(time) || Infinity === time) return
        this._currTime = time
        // this.audio_html_element.currentTime = time
      },
      setAudioCurrTime (time: number) {
        if (!this.audio_html_element) return
        if (isNaN(time) || Infinity === time) return
        this.audio_html_element.currentTime = time
      },
      get isAvalable () {
        return Boolean(this.audio_html_element)
      },
      get percentPlayed () {
        if (!this.audio_html_element) return 0
        if (isNaN(this.audio_html_element.duration) || !isFinite(this.audio_html_element.duration)) return 0

        return (this._currTime * 100) / this._duration
      }
    }
  }

  public onInitInstance (): void {
    // ---
  }

  private cleanRawFiles () {
    this.raw_message_files = []
    this.raw_chat_message_audio = null
  }

  public rehidratateData ({ chat_message_to_reply, ...data }: IChatMessage): void {
    super.rehidratateDataPartial(data)
    // Creando objetos de tipo MessageFile de los IMessageFile del mensaje
    Object.assign(this.message_files, data.message_files.map(messFile => new MessageFile({ data: messFile })))
    setTimeout(() => {
      this.initMessAudioElement()

      if (!this.chat_message_to_reply && chat_message_to_reply) {
        // Si el chat messasge no tiene un chat_message_to_reply, pero llega por data, entonces se crea
        this.chat_message_to_reply = new ChatMessage({ data: chat_message_to_reply, chatItem: this.chatItem })
      } else if (this.chat_message_to_reply && chat_message_to_reply) {
        // SI ya tenia uno pero llega data denuevo, se actualiza
        this.chat_message_to_reply.rehidratateData(chat_message_to_reply)
      }
    }, 50)
  }

  public get chatUser () {
    return this.chatItem.chatUsersIndexed.get(this.id_user)
  }

  public get chatUserName () {
    return this.chatUser?.user?.username ?? this.chatUser?.chu_user_saved_data.username ?? this.chm_user_name
  }

  public get chatUserThumbnail () {
    return ''
    // return `${store.state.app.BASE_URL}${this.chatUser?.user?.thumbnail_file?.fl_url}`
  }

  public get isBotMessage () {
    return this.user?.id === -1
  }

  /**
   * Retrieves chat message data.
   *
   * @param payload - The payload containing the message content and optional message files.
   * @returns A Promise that resolves to the chat message data.
   * @throws An error if there is an issue retrieving the chat message data.
   */
  public async rehidratateChatMessage () {
    try {
      this.setMessageState(CHAT_MESSAGE_STATE.SENDING)
      const data: IChatMessage = await store.dispatch('chat/createChatMessage', objToFormData({
        id_chat_item: this.chatItem.id_chat,
        chm_message: this.chm_message,
        message_files: this.raw_message_files,
        chat_message_audio: this.raw_chat_message_audio,
        id_chat_message_to_reply: this.id_chat_message_to_reply,
        omit_bot_message: this.omit_bot_message,
        id_attached_filed_selected: this.chatItem?.id_attached_filed_selected ?? null
      }, null, ''))
      this.setMessageState(CHAT_MESSAGE_STATE.SENDED)
      this.rehidratateData(data)
      this.cleanRawFiles()
      // Actualizo mi propio ultimo chu_last_seen_message_id
      const chatUser = this.chatItem.getOwnChatUser()
      if (chatUser) {
        chatUser.updateProperty('chu_last_seen_message_id', data.id_chat_message)
      }
      this.chatItem.updateUserMessagesStatus()
      this.chatItem.updateUnreadedChatMessages()
    } catch (error) {
      this.setMessageState(CHAT_MESSAGE_STATE.ERROR)
      console.error(error)
      store.dispatch('app/addLogWithError', { title: 'REHIDRATATE_CHAT_MESSAGE', color: 'error', message: '', error })
    }
  }

  /**
   * Rehydrates the chat from a chat message.
   *
   * @param payload - The payload containing the user ID, message content, and optional message files.
   * @returns A Promise that resolves to the rehydrated chat data.
   * @throws An error if there is an issue rehydrating the chat.
   */
  public async rehidratateChatFromChatMessage () {
    try {
      this.setMessageState(CHAT_MESSAGE_STATE.SENDING)
      const data: IChat = await store.dispatch('chat/createChatItemByChatMessage', objToFormData({
        id_user: this.chatItem.userToChat?.id_user,
        chm_message: this.chm_message,
        message_files: this.raw_message_files,
        chat_message_audio: this.raw_chat_message_audio
      }, null, ''))
      this.chatItem.rehidratateData(data)
      this.removeFromChat()
      return this.chatItem
    } catch (error) {
      console.error(error)
      this.setMessageState(CHAT_MESSAGE_STATE.ERROR)
      store.dispatch('app/addLogWithError', { title: 'REHIDRATATE_CHAT_FROM_CHAT_MESSAGE', color: 'error', message: '', error })
    }
  }

  /**
 * Removes the current chat message from the chat item.
  *
  * This method finds the index of the current chat message in the chat item's chat_messages array
  * and removes it from the array using the splice method. If the chat message is not found in the array,
  * the method returns without making any changes.
  *
  * @returns {void}
  */
  public removeFromChat () {
    const index = this.chatItem.chat_messages.findIndex(mess => mess.id_chat_message === this.id_chat_message)
    if (index === -1) return

    this.chatItem.chat_messages.splice(index, 1)
  }

  setMessageState (state: CHAT_MESSAGE_STATE) {
    this.messageState = state
  }

  isState (...states: CHAT_MESSAGE_STATE[]) {
    return new Set(states).has(this.messageState)
  }

  public get isEmpty () {
    return this.id_chat_message === -1
  }

  public get isAudio () {
    return this.raw_chat_message_audio || this.id_chat_message_audio || this.audio.audio_html_element
  }

  public get isFilesOnly () {
    return Boolean(this.message_files.length) && !this.chm_message
  }

  public get lastFile () {
    return this.message_files.at(-1)
  }

  public get timeMessage () {
    // @ts-ignore
    return DateTime.fromISO(this.chm_createdAt, { zone: 'utc' }).setLocale('es').plus({ hours: store.state.app.timeoffset }).toFormat('HH:mm')
  }

  public get stateMessageIcon () {
    return ChatMessage.getMessageStateIcon(this.messageState)
  }

  public get isUserMessage () {
    return this.id_user === store.getters['auth/userId']
  }

  public get audioUrl () {
    // @ts-ignore
    return `${store.state.app.BASE_URL}${this.chat_message_audio?.fl_url}`
  }

  /**
   * Formats the chat message by replacing URLs with clickable links.
   *
   * @returns {string} - The formatted chat message.
   */
  public get chmMessageFormated () {
    // Convierto los links en enlaces clickables
    let text = this.chm_message.replace(ChatMessage.chmMessageREGEXLinkFormater, function (url) {
      return `<a class="link-info-text" href="${url}" target="_blank">${url}</a>`
    })

    // Convierto los strong en negritas
    text = text.replace(ChatMessage.chmMessageStrongTextFormater, '<strong>$1</strong>')

    // Convierto los mentions en enlaces
    text = text.replace(ChatItem.chatUserAllMentionsREGEX, function (mention) {
      return `<a class="link-info-text" target="_blank"><strong>${mention}</strong></a>`
    })

    return text
  }

  public get chmMessageMentionsFormated () {
    // Convierto los strong en negritas
    let text = this.chm_message.replace(ChatMessage.chmMessageStrongTextFormater, '<strong>$1</strong>')

    // Convierto los mentions en enlaces
    text = text.replace(ChatItem.chatUserAllMentionsREGEX, function (mention) {
      return `<a class="link-info-text" target="_blank"><strong>${mention}</strong></a>`
    })

    return text
  }

  /**
   * Checks if the chat message contains any links.
   *
   * @returns {boolean} - True if the chat message contains links, false otherwise.
   */
  public getContainsLinks () {
    const regex = new RegExp(ChatMessage.chmMessageREGEXLinkFormater)
    return regex.test(this.chm_message)
  }

  /**
   * Checks if the chat message contains any files.
   *
   * @returns {boolean} - True if the chat message contains files, false otherwise.
   */
  public getContainsFiles () {
    return Boolean(this.message_files.length)
  }

  /**
   * Checks if the chat message was see it by all users
   *
   * @returns {boolean} - True if the all users see this message, false otherwise.
   */
  public isAllChatUsersSeeThisMessage () {
    const lastSeenChatUsersIds = new Set(this.chatItem.chat_users.reduce((idsUsers, currChatUser) => {
      // Se omite al propio usuario, a los usuarios que ya no estan disponibles y a los usuarios que tengan el chat oculto
      if (currChatUser.id_user === store.getters['auth/userId'] || !currChatUser.user || currChatUser.chu_is_hidden) return idsUsers
      idsUsers.push(currChatUser.chu_last_seen_message_id)
      return idsUsers
    }, [] as (number | null)[]))

    for (const chu_last_seen_message_id of lastSeenChatUsersIds) {
      if (!chu_last_seen_message_id) return false
      if (chu_last_seen_message_id < this.id_chat_message) {
        return false
      }
    }
    return true
  }

  /**
   * Checks if the chat message was recived it by all users
   *
   * @returns {boolean} - True if the all users get this message, false otherwise.
   */
  public isAllChatUsersGetThisMessage () {
    const comunicationsUsersIds = (store.getters['chat/objSetUsersConectedIds'] as Set<number>)
    const messageDatetimeSended = DateTime.fromISO(this.chm_createdAt)

    const lastChatUsersConections = new Set(this.chatItem.avaliableChatUsers.reduce((lastUsersConections, currChatUser) => {
      // Si el usuario que se esta evaluando nunca se a conectado desde la creacion del chat y por ende
      // tiene el chu_last_seen_message_id como null, envio null para que en la posterior iteracion
      // retorne false
      if (!currChatUser.chu_last_seen_message_id) {
        lastUsersConections.push(null)
        return lastUsersConections
      }
      // Se omite al propio usuario, a los usuarios que ya no estan disponibles y a los usuarios que tengan el chat oculto y a los que no tienen el dato de ultima conexion
      if (currChatUser.id_user === store.getters['auth/userId'] || !currChatUser?.user?.last_time_conected) return lastUsersConections
      lastUsersConections.push({ last_time_conected: currChatUser.user.last_time_conected, id_user: currChatUser.curr_id_user })
      return lastUsersConections
    }, [] as ({ last_time_conected: string; id_user: number } | null)[]))

    for (const lastUsersConections of lastChatUsersConections) {
      if (!lastUsersConections) return false
      const lastDatetimeConected = DateTime.fromISO(lastUsersConections.last_time_conected)
      const diff = messageDatetimeSended.diff(lastDatetimeConected).as('minutes')

      // Devuelve el false si algun usuario tiene una fecha inferior a la fecha de creacion del mensaje, en otro caso sigue hasta el true
      if (diff > 0 && !comunicationsUsersIds.has(lastUsersConections.id_user)) return false
    }

    return true
  }

  public static chmMessageREGEXLinkFormater = /(\bhttps?:\/\/[-A-Z0-9+&@#/%=~_|$?!:,.]*[A-Z0-9+&@#/%=~_|$])/ig;
  public static chmMessageStrongTextFormater = /\*(.*?)\*/g;

  /**
   * Checks if the chat associated with this chat message is empty.
   *
   * @returns {boolean} - True if the chat is empty, false otherwise.
   */
  public getIsChatEmpty () {
    return this.chatItem.id_chat === -1
  }

  public get naturalCreatedAtDate () {
    // @ts-ignore
    return BaseClass.getNaturalLabelDate({ isoDate: this.chm_createdAt, offset: store.state.app.timeoffset }).labelDate
  }

  public isType (...types: CHAT_MESSAGE_TYPES[]) {
    return new Set(types).has(this.id_chat_message_type)
  }

  private initMessAudioElement () {
    // @ts-ignore
    const audio_html_element = this.chat_message_audio ? new Audio(`${store.state.app.BASE_URL}${this.chat_message_audio?.fl_url}`) : undefined
    if (!audio_html_element) return

    this.audio.audio_html_element = audio_html_element

    // Inicianlizando eventos para rellenar las propiedades del objeto audio
    // @ts-ignore
    this.audio.audio_html_element.ondurationchange = () => { this.audio._duration = this.audio.audio_html_element.duration }
    // @ts-ignore
    this.audio.audio_html_element.onended = () => { this.audio.setAudioCurrTime(0); this.audio.setCurrTime(0); this.audio._paused = true }
    this.audio.audio_html_element.ontimeupdate = () => {
      // @ts-ignore
      this.audio.setCurrTime(this.audio.audio_html_element.currentTime)
      // @ts-ignore
      this.audio.setCurrTime(this.audio.audio_html_element.currentTime)
      if (this.audio.audio_html_element?.currentTime !== 0) {
        // @ts-ignore
        this.audio._duration = this.audio.audio_html_element?.duration
      }
    }
    this.audio.audio_html_element.onloadeddata = () => {
      setTimeout(() => {
        this.audio.isLoaded = true
        // @ts-ignore
        this.audio._state = this.audio.audio_html_element.readyState
        this.audio.setCurrTime(0)
        // @ts-ignore
        this.audio._duration = this.audio.audio_html_element.duration
      }, 500)
    }
    this.audio.audio_html_element.onerror = () => {
      this.audio._isError = true
    }

    setTimeout(() => {
      this.audio.setCurrTime(0)
      this.audio.setAudioCurrTime(0)
      // @ts-ignore
      this.audio._duration = this.audio.audio_html_element.duration
    }, 2000)
  }

  /**
   * Creates an empty chat message.
   *
   * @param chatItem - The chat item associated with the chat message.
   * @param chm_message - The message content of the chat message.
   * @param user - The user associated with the chat message.
   * @returns A new instance of ChatMessage with the specified data.
   */
  public static createEmptyChatMessage ({
    chatItem,
    chm_message,
    raw_message_files,
    raw_chat_message_audio = null,
    id_chat_message_to_reply = null,
    chat_message_to_reply = null,
    omit_bot_message = false,
    additiona_params = {}
  }: {
    chatItem: ChatItem;
    chm_message: string;
    raw_message_files?: File[];
    raw_chat_message_audio?: File | null,
    id_chat_message_to_reply?: number | null
    chat_message_to_reply?: ChatMessage | null
    omit_bot_message?: boolean
    additiona_params?: Partial<IChatMessage>
  }) {
    return new ChatMessage({
      data: {
        chm_user_name_deleted: '',
        chat_message_to_reply,
        chm_is_edited: false,
        id_chat_message_to_reply,
        chm_is_deleted: false,
        id_chat_message_audio: null,
        chat_message_audio: null,
        id_chat_message_code: CHAT_MESSAGE_CODE.EMPTY,
        chm_code_payload: {},
        id_chat_message_type: CHAT_MESSAGE_TYPES.USER,
        chm_createdAt: DateTime.utc().toISO(),
        chm_message,
        id_chat_item: chatItem.id_chat,
        chm_user_name: store.getters['auth/username'],
        id_user: store.getters['auth/userId'],
        id_chat_message: -1,
        chm_is_resend: false,
        chm_color: '',
        user: {
          id: store.getters['auth/userId'],
          username: store.getters['auth/username'],
          first_name: '',
          last_name: '',
          last_time_conected: null,
          thumbnail_file: null
        },
        message_files: [],
        ...additiona_params
      },
      chatItem,
      state: CHAT_MESSAGE_STATE.SENDING,
      raw_message_files,
      raw_chat_message_audio,
      omit_bot_message
    })
  }

  public static getMessageStateIcon (state: CHAT_MESSAGE_STATE) {
    switch (state) {
      case CHAT_MESSAGE_STATE.ERROR:
        return {
          icon: 'mdi-alert-circle-outline',
          color: 'error'
        }
      case CHAT_MESSAGE_STATE.SENDING:
        return {
          icon: 'mdi-clock',
          color: 'disable'
        }
      case CHAT_MESSAGE_STATE.SENDED:
        return {
          icon: 'mdi-check',
          color: 'disable'
        }
      case CHAT_MESSAGE_STATE.RECEIVED:
        return {
          icon: 'mdi-check-all',
          color: 'disable'
        }
      case CHAT_MESSAGE_STATE.VIEWED:
        return {
          icon: 'mdi-check-all',
          color: 'info'
        }
      default:
        return {
          icon: 'mdi-help-circle-outline',
          color: 'error'
        }
    }
  }
}

import { IFile } from '@/interfaces/IFIle.interface'

/* eslint-disable no-use-before-define */
export interface IChat {
  id_chat: number;
  chat_messages: IChatMessage[];
  chat_users: IChatUser[];
  id_chat_type: number;
  chat_thumbnail_file: IFile | null;
  user_created: UserSchemaSimple;
  id_account: number;
  cht_name: string;
  cht_description: string;
  cht_user_created_name: string | null;
  cht_bot_active: boolean;
  cht_bot_allowed: boolean;
  cht_bot_context_message: string;
  cht_bot_temperature: number;
  cht_createdAt: string;
  chat_type: IChatType;
  cht_additional_config: {
    [key: string]: any;
  };
}
export interface IChatMessage {
  id_chat_message: number;
  id_user?: number | null;
  id_chat_message_code: number;
  id_chat_message_type: number;
  id_chat_item: number;
  id_chat_message_to_reply: number | null;
  chat_message_to_reply: IChatMessage | null;
  id_chat_message_audio: number | null;
  chat_message_audio: IFile | null;
  message_files: IMessageFile[];
  chm_message: string;
  chm_user_name: string;
  chm_user_name_deleted: string;
  chm_is_deleted: boolean;
  chm_is_edited: boolean;
  chm_is_resend: boolean;
  chm_color: string;
  chm_code_payload: { [key: string]: any };
  chm_createdAt: string;
  user?: UserSchemaSimple | null;
}
export interface IMessageFile {
  id_chat_message_file: number;
  file: IFile;
  id_chat_message: number;
  id_file: number;
}

export interface IChatType {
  ctp_name: string;
  id_chat_type: number;
}

export interface IChatUser {
  id_user: number | null;
  chu_is_admin: boolean;
  id_chat_item: number;
  id_chat_user: number;
  chu_createdAt: string;
  chu_is_hidden: boolean;
  chu_silent_chat: boolean;
  chu_user_saved_data: UserSchemaSimple;
  chu_last_seen_message_id: number | null;
}

export interface IChatSchemaSimple {
  id_chat: number
  id_chat_type: number
  id_account: number
  cht_name: string
  cht_description: string
  cht_bot_active: boolean
  cht_bot_allowed: boolean
  cht_bot_context_message: string
  cht_bot_temperature: number
  cht_createdAt: string
  cht_additional_config: {
    [key: string]: any
  }
}

export interface UserSchemaSimple {
  id: number;
  username: string;
  last_name: string;
  first_name: string;
  last_time_conected: string | null;
  thumbnail_file: IFile | null;
  connection_id?: number;
}

export enum CHAT_MESSAGE_CODE {
  EMPTY = 0,
  USERS_REMOVED = 1,
  USERS_ADDED = 2,
  THUMBNAIL_CHANGED = 3
}

export enum CHAT_MESSAGE_TYPES {
  USER = 1,
  SYSTEM = 2
}

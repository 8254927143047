
export interface EmailState {
    emails: any[]
}

const state = (): EmailState => ({
  emails: []
})
export type State = ReturnType<typeof state>;

export default state

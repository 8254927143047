var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "custom-notification"
  }, [_vm._m(0), _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.title))]), _c('div', {
    staticClass: "message"
  }, [_vm._v(_vm._s(_vm.message))]), _c('div', {
    staticClass: "date"
  }, [_vm._v(_vm._s(_vm.date))])]), _c('button', {
    staticClass: "close-btn",
    on: {
      "click": _vm.close
    }
  }, [_vm._v("×")])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "icon"
  }, [_c('i', {
    staticClass: "fas fa-check-circle"
  })]);

}]

export { render, staticRenderFns }
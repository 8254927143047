/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import { serializeError } from 'serialize-error'
import { ActionContext } from 'vuex/types/index'
import {
  RESOURCES,
  PLATAFORM,
  PLATAFORM_RESOURCES
} from '../../resources/RESOURCES'
import { State } from '../state'
import { UnitConsole } from '@/store/interfaces/ReportModules'
import UnitConsoleData from '@/store/packages/ReportItem/ReportItemData'
import RIWialon from '@/store/packages/ReportItem/RIWialon'
import RIMapon from '@/store/packages/ReportItem/RIMapon'
import MaponSDK from '@/submodules/maponjssdk/dist'
import getReportTimeUnit from '@/utils/getReportTimeUnit'
import RIHikvision from '@/store/packages/ReportItem/RIHikvision'
import axios from 'axios'
import RIStreamax from '@/store/packages/ReportItem/RIStreamax'
const actions = {
  async loadUnitsConsole ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/unit/get/all',
        method: 'GET'
      },
      { root: true }
    )
    await dispatch('unitsMonitoring', data)
  },
  // async pushUnitConsole ({ commit, dispatch }: ActionContext<State, any>) {
  //   const { data } = await dispatch(
  //     'app/axios',
  //     {
  //       url: '/apis/unit/get/all',
  //       method: 'GET'
  //     },
  //     { root: true }
  //   )
  //   await dispatch('unitsMonitoring', data)
  // },
  async loadUnitsConsoleUpdate ({ commit, dispatch, rootState }: ActionContext<State, any>, payload : { id_user: number }) {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    rootState.administration.userEdit.cancel = source.cancel
    rootState.administration.userEdit.loaded = true
    rootState.administration.userEdit.units = []
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: `/apis/unit/get/all?update=1&user=${payload.id_user}`,
          method: 'GET',
          cancelToken: source.token
        },
        { root: true }
      )
      rootState.administration.userEdit.units = data
      rootState.administration.userEdit.cancel = null
      rootState.administration.userEdit.loaded = false
      return data
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error('Error en la petición:', error)
      }
    }
    return []
  },
  cancelRequest ({ commit, dispatch, rootState }: ActionContext<State, any>) {
    if (rootState.administration.userEdit.cancel) {
      rootState.administration.userEdit.cancel('Cancelación por destrucción del componente.')
    }
  },
  async createUnitsConsole ({ commit, dispatch }: ActionContext<State, any>, payload: { units: []; plataform: number; }) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/create_units_console',
        method: 'POST',
        data: { ...payload }
      },
      { root: true }
    )
    return data
  },
  async createOnlyUnitsConsole ({ commit, dispatch }: ActionContext<State, any>, payload: { units: []; plataform: number; }) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/unit/create',
        method: 'POST',
        data: { ...payload }
      },
      { root: true }
    )
    return data
  },
  async deleteUnitsConsole ({ commit, dispatch }: ActionContext<State, any>, id_unit_console: number) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: `/apis/unit/delete/${id_unit_console}`,
          method: 'GET'
        },
        { root: true }
      )
    } catch (error) {
      commit('sys/ADD_LOG', { title: 'GEOFENCE_CREATE', color: 'error', message: error, payload: serializeError(error) }, { root: true })
      await dispatch('app/notification', { content: 'Ha ocurrido un error', type: 'error' }, { root: true })
    }
  },
  async unitsMonitoring ({ rootState, commit, dispatch }: ActionContext<State, any>, unitsConsole: UnitConsole[]) {
    let sess = null
    const haveWialon = await dispatch('auth/haveWialon', {}, { root: true })
    if (haveWialon) {
      // @ts-ignore
      sess = wialon.core.Session.getInstance()
      await getReportTimeUnit(sess)
    }
    const ids_streamax = unitsConsole.filter(unit => unit.plataform === 'STREAMAX').map(unit => unit.unit_resource_id).join(',')
    console.log(ids_streamax)
    if (ids_streamax.length > 0) {
      const data = await dispatch('streamax/loadUnitsStreamaxSet', ids_streamax, { root: true })
    }
    const unitsConsoleData: UnitConsoleData<unknown, unknown>[] = []
    for (let index = 0; index < unitsConsole.length; index++) {
      const unitC = unitsConsole[index]
      if (unitC.plataform === 'WIALON') {
        const unitItem = sess.getItem(unitC.unit_resource_id)
        if (unitItem) {
          unitC.unit_km_current = unitItem?.$$user_mileageCounter ?? 0
          unitC.unit_hour_engine_current = parseInt(unitItem?.$$user_engineHoursCounter?.toFixed(1)) ?? 0
          // @ts-ignore
          const unitWialon = new RIWialon(unitC, { unitItem: unitItem })
          unitsConsoleData.push(unitWialon)
        }
      }
      if (unitC.plataform === 'MAPON') {
        const unistMapon = rootState.resources.PLATAFORM[PLATAFORM.MAPON][PLATAFORM_RESOURCES.UNITS]
        // @ts-ignore
        const unitItem = unistMapon.find(unit => String(unit.unit_id) === unitC.unit_resource_id)
        if (unitItem) {
          unitC.unit_km_current = (unitItem?.mileage) ? unitItem?.mileage / 1000 : 0
          // @ts-ignore
          const unitMapon = new RIMapon(unitC, { unitItem: unitItem })
          unitsConsoleData.push(unitMapon)
        }
        // const unitItem = await MaponSDK.actions.AUnit.getById(parseInt(unitC.unit_resource_id))
      }
      if (unitC.plataform === 'HIKVISION') {
        // @ts-ignore
        const unitHikvision = new RIHikvision(unitC, { unitItem: {} })
        unitsConsoleData.push(unitHikvision)
      }
      if (unitC.plataform === 'STREAMAX') {
        const unistStreamax = rootState.streamax.units
        console.log(unistStreamax)
        const unitItem = unistStreamax.find((unit: { uniqueId: any }) => String(unit.uniqueId) === unitC.unit_resource_id)
        if (unitItem) {
          // @ts-ignore
          const unitStreamax = new RIStreamax(unitC, { unitItem: unitItem })
          unitsConsoleData.push(unitStreamax)
        } else {
          // @ts-ignore
          const unitStreamax = new RIStreamax(unitC, { unitItem: {} })
          unitsConsoleData.push(unitStreamax)
        }
      }
    }
    commit(
      'travel/PUSH_UNITS_CONSOLE',
      unitsConsoleData,
      { root: true }
    )
  },
  removeUnitConsole ({ rootState, commit, dispatch }: ActionContext<State, any>, unitsConsole: UnitConsole[]) {
    for (const unit of unitsConsole) {
      const index = rootState.travel.unitsRegisters.findIndex((unit_: { id: any }) => unit_.id === unit)
      if (index !== -1) {
        rootState.travel.unitsRegisters.splice(index, 1)
      }
    }
  },
  async unitsPlataformSelect ({ rootState, rootGetters, dispatch }: ActionContext<State, any>, { typeP, resource_id } : { typeP: number, resource_id?: number}) {
    const unitsPltSelect = {
      unitsPlataform: [],
      unitsRegisters: []
    }
    console.log(typeP)
    if (typeP === PLATAFORM.MAPON) {
      const units = rootState.resources.PLATAFORM[typeP][PLATAFORM_RESOURCES.UNITS]
      unitsPltSelect.unitsPlataform = units.map((unit: { unit_id: any; number: any; label: any }) => ({ value: String(unit.unit_id), label: `${unit.number} - ${unit.label}`, icon: '/images/truck.png' }))
      unitsPltSelect.unitsRegisters = rootState.travel.unitsRegisters.map((unit: { unit_resource_id: any }) => String(unit.unit_resource_id))
    }
    if (typeP === PLATAFORM.WIALON) {
      const units = rootState.resources.PLATAFORM[typeP][PLATAFORM_RESOURCES.UNITS]
      if (!resource_id) return unitsPltSelect
      const resUnits = units.filter((unt: { $$user_accountId: any }) => unt.$$user_accountId === resource_id)
      unitsPltSelect.unitsPlataform = resUnits.map((unit: { _id: any; $$user_name: any; $$user_iconUri: any }) => ({ value: String(unit._id), label: unit.$$user_name, icon: `https://hst-api.wialon.com${unit.$$user_iconUri}` }))
      unitsPltSelect.unitsRegisters = rootState.travel.unitsRegisters.map((unit: { unit_resource_id: any }) => String(unit.unit_resource_id))
    }
    // if (typeP === PLATAFORM.STREAMAX) {
    //   // await dispatch('streamax/init', {}, { root: true })
    //   // const units = rootState.resources.PLATAFORM[typeP][PLATAFORM_RESOURCES.UNITS]
    //   // if (!resource_id) return unitsPltSelect
    //   // const resUnits = units.filter((unt: { $$user_accountId: any }) => unt.$$user_accountId === resource_id)
    //   // unitsPltSelect.unitsPlataform = resUnits.map((unit: { _id: any; $$user_name: any; $$user_iconUri: any }) => ({ value: String(unit._id), label: unit.$$user_name, icon: `https://hst-api.wialon.com${unit.$$user_iconUri}` }))
    //   // unitsPltSelect.unitsRegisters = rootState.travel.unitsRegisters.map((unit: { unit_resource_id: any }) => String(unit.unit_resource_id))
    // }
    return unitsPltSelect
  },
  async assignDriver ({ commit, dispatch, rootState }: ActionContext<State, any>, payload:any) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: '/apis/unit/driver/assignment/',
          method: 'POST',
          data: payload
        },
        { root: true }
      )
      // // @ts-ignore
      // const unitConsoleData: UnitConsoleData<unknown, unknown> = rootState.travel.unitsRegisters.find(obj => obj.id === payload.unit)
      // if (unitConsoleData) unitConsoleData.setDrivers(data)
    } catch (error) {
      commit('sys/ADD_LOG', { title: 'DRIVER - ASSIGNMENT', color: 'error', message: error, payload: serializeError(error) }, { root: true })
      await dispatch('app/notification', { content: 'Ha ocurrido un error', type: 'error' }, { root: true })
    }
  },
  async removeDriver ({ commit, dispatch, rootState }: ActionContext<State, any>, payload:any) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: '/apis/unit/driver/assignment/',
          method: 'DELETE',
          data: payload
        },
        { root: true }
      )
      // @ts-ignore
      const unitConsoleData: UnitConsoleData<unknown, unknown> = rootState.travel.unitsRegisters.find(obj => obj.id === payload.id_unit)
      if (unitConsoleData) unitConsoleData.removeDriver(payload.id_user)
    } catch (error) {
      commit('sys/ADD_LOG', { title: 'DRIVER - ASSIGNMENT', color: 'error', message: error, payload: serializeError(error) }, { root: true })
      await dispatch('app/notification', { content: 'Ha ocurrido un error', type: 'error' }, { root: true })
    }
  },
  async loadOdometerHourEngine ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/unit/metric/get/',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    return data
  },
  async loadManualFuelMetrics ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/unit/metric_manual/get/',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    return data
  }
}
export default actions


export default {
  // SET_REQUISITION (state: RequisitionState, entities: IRequisition[]): void {
  //   state.requisition = entities
  // },
  // PUSH_REQUISITION (state: RequisitionState, entitie: IRequisition): void {
  //   state.requisition.unshift(entitie)
  // },
  // UPDATE_REQUISITION (state: RequisitionState, entitie: IRequisition): void {
  //   const index = state.requisition.findIndex(p => p.id === entitie.id)
  //   if (index !== -1) {
  //     state.requisition.splice(index, 1, entitie)
  //   }
  // },
  // DELETE_REQUISITION (state: RequisitionState, entitie: IRequisition): void {
  //   const index = state.requisition.findIndex(p => p.id === entitie.id)
  //   if (index !== -1) {
  //     state.requisition.splice(index, 1)
  //   }
  // }
}


import { Module } from 'vuex'
// @ts-ignore
import { RootState } from '@/store/types'
import actions from './actions'
// import getters from './getters'
// import mutations from './mutations'
import state, { DriverState } from './state'

export const unit: Module<DriverState, RootState> = {
  namespaced: true,
  state,
  // getters,
  actions
  // mutations
}


export interface NotificationState {
    notifications: any[]
}

const state = (): NotificationState => ({
  notifications: []
})
export type State = ReturnType<typeof state>;

export default state

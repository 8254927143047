import { TaskState } from './state'

export default {
  getTasks (state: TaskState) {
    return state.tasks
  },
  getAssignedTasks (state: TaskState) {
    return state.assignedTasks
  },
  getTemplates (state: TaskState) {
    return state.templates
  }
}

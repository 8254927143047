// import { REQUISITION_STATUS, REQUISITION_STATUS_ARRAY } from '@/store/catalogs/REQUISITION_STATUS'
// import { InventoryState } from '../../state'

import { REQUISITION_STATUS, REQUISITION_STATUS_ARRAY } from '@/store/catalogs/REQUISITION_STATUS'

export default {
  // getRequisition (state: InventoryState) {
  //   return state.requisition
  // },
  getTextStatusRequisition () {
    return (status: REQUISITION_STATUS) => {
      return REQUISITION_STATUS_ARRAY.find(s => s.id === status)
    }
  }
  // getPurchaseOrderConfirmed (state: InventoryState) {
  //   // @ts-ignore
  //   return state.purchase_order.filter(purch => purch.por_added_inventory === false)
  // }
}

import { IBatteryCapacity } from '@/classes/Battery/interfaces/IBatteryCapacity'
import { IBatteryMaker } from '@/classes/Battery/interfaces/IBatteryMaker'
import { IBatteryModel } from '@/classes/Battery/interfaces/IBatteryModel'

export interface BatteryState {
  battery_capacity: IBatteryCapacity[],
  battery_maker: IBatteryMaker[],
  battery_model: IBatteryModel[]
}
const state = (): BatteryState => ({
  battery_capacity: [],
  battery_maker: [],
  battery_model: []
})
export type State = ReturnType<typeof state>

export default state

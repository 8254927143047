import Ticket, { ITicket } from '@/classes/Ticket/ITicket'
import { State } from './state'

export default {
  SET_TICKETS (state: State, tickets: Ticket[]) {
    state.tickets = tickets
  },
  PUSH_TICKET (state: State, ticket: Ticket) {
    state.tickets.push(ticket)
  },
  UPDATE_TICKET (state: { tickets: any[] }, updatedTicket: { id: any }) {
    const ticketIndex = state.tickets.findIndex((ticket: { id: any }) => ticket.id === updatedTicket.id)
    if (ticketIndex !== -1) {
      Object.assign(state.tickets[ticketIndex], updatedTicket)
    } else {
      state.tickets.push(updatedTicket)
    }
  }
}

import { State } from '../../state'
import { ActionContext } from 'vuex'

const actions = {
  async loadBatteryInventory ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/battery_inventory/get/all',
        method: 'GET'
      },
      { root: true }
    )
    commit('SET_BATTERY_INVENTORY', data)
    return data
  },
  async getBatteryByIdInventory ({ commit, dispatch }: ActionContext<State, any>, entity_id: number) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/battery_inventory/get/${entity_id}`,
        method: 'GET'
      },
      { root: true }
    )
    commit('UPDATE_BATTERY_INVENTORY', data)
    return data
  },
  async createBatteryInventory ({ commit, dispatch }: ActionContext<State, any>, entity: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/battery_inventory/create',
        method: 'POST',
        data: entity
      },
      { root: true }
    )
    commit('PUSH_BATTERY_INVENTORY', data)
    return data
  },
  async updateBatteryInventory ({ commit, dispatch }: ActionContext<State, any>, entity: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/battery_inventory/update/${entity.id}`,
        method: 'PUT',
        data: entity
      },
      { root: true }
    )
    commit('UPDATE_BATTERY_INVENTORY', data)
    return data
  },
  async deleteBatteryInventory ({ commit, dispatch }: ActionContext<State, any>, entity: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/battery_inventory/delete/${entity.id}`,
        method: 'DELETE'
      },
      { root: true }
    )
    commit('DELETE_BATTERY_INVENTORY', entity)
    return data
  },
  async mountedBatteryInventory ({ commit, dispatch, rootState }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/battery_inventory/mounted',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    commit('MOUNTED_BATTERY', payload)
    commit('UPDATE_BATTERY_INVENTORY', data)
    const unit = rootState.travel.unitsRegisters.find((unit:any) => unit.id === payload.unit)
    if (unit && !unit.batteries.includes(payload.id_battery)) unit.batteries.push(payload.id_battery)
    return data
  },
  async unmountedBatteryInventory ({ commit, dispatch, rootState }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/battery_inventory/unmounted',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    commit('UNMOUNTED_BATTERY', payload)
    commit('UPDATE_BATTERY_INVENTORY', data)
    // @ts-ignore
    const unit = rootState.travel.unitsRegisters.find((unit:any) => unit.id === payload.unit)
    if (unit) unit.batteries = unit.batteries.filter((id_battery : number) => id_battery !== payload.id_battery)
    return data
  }
}
export default actions

import Checklist, { IChecklist } from '@/classes/Checklist/Checklist'
import { ChecklistState } from './state'

export default {
  // Mutadores para Checklists
  SET_ALL (state: ChecklistState, checklit: Checklist[]) {
    state.checklists = checklit
  },
  PUSH (state: ChecklistState, checklit: Checklist) {
    state.checklists.push(checklit)
  },
  UPDATE (state: ChecklistState, item: any) {
    const checklist = state.checklists.find(checklist => checklist.id === item.id)
    // @ts-ignore
    Object.assign(checklist, { ...item })
  },
  DELETE (state: ChecklistState, item: IChecklist) {
    state.checklists = state.checklists.filter(checklist => checklist.id !== item.id)
  }
}

import { Module } from 'vuex'
import { checklist } from './modules/checklist'
import { sections } from './modules/section'
import { tasks } from './modules/task'
import { report_task } from './modules/report_task'

export const checklists: Module<any, any> = {
  namespaced: true,
  modules: {
    checklist,
    sections,
    tasks,
    report_task
  }
}

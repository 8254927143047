import { State } from './state'
import AssetMaintenance, { IAssetMaintenance } from '@/classes/Inventory/interfaces/IAsset_Maintenance'

export default {
  SET_ASSET_MAINTENANCES (state: State, assetMaintenances: AssetMaintenance[]) {
    state.assetMaintenances = assetMaintenances
  },
  PUSH_ASSET_MAINTENANCES (state: State, assetMaintenance: IAssetMaintenance) {
    state.assetMaintenances.push(assetMaintenance)
  },
  UPDATE_ASSET_MAINTENANCE (state: State, item: any) {
    const checklist = state.assetMaintenances.find(assetMaintenance => assetMaintenance.id === item.id)
    // @ts-ignore
    Object.assign(checklist, { ...item })
  },
  DELETE_ASSET_MAINTENANCE (state: State, assetMaintenanceId: number) {
    state.assetMaintenances = state.assetMaintenances.filter(maintenance => maintenance.id !== assetMaintenanceId)
  },
  SET_ASSET_MAINTENANCE_PARTS (state: State, assetMaintenanceParts: any) {
    state.assetMaintenanceParts = assetMaintenanceParts
  },
  PUSH_ASSET_MAINTENANCE_PARTS (state: State, assetMaintenanceParts: any) {
    state.assetMaintenanceParts.push(...assetMaintenanceParts)
  }
}

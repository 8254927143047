import Root from '../Root/Root'

export interface IChecklistTask {
  id: number;
  task_name: string;
  assigned_to: number;
  checklist: Record<string, unknown>;
  checklist_is_filled: boolean;
  task_completed: boolean;
  due_date: string | null;
  frequency: number
  end_frecuency: string
}

export default class ChecklistTask extends Root implements IChecklistTask {
  id!: number;
  task_name!: string;
  assigned_to!: number;
  checklist!: Record<string, unknown>;
  checklist_is_filled!: boolean;
  task_completed!: boolean;
  due_date!: string | null;
  frequency!: number
  end_frecuency!: string

  constructor (checklistTask: IChecklistTask) {
    super()
    Object.assign(this, checklistTask)
  }
}


export interface CartaPorteState {
  cartas_porte: any[]
}

const state = (): CartaPorteState => ({
  cartas_porte: []
})
export type State = ReturnType<typeof state>;

export default state

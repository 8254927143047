// import { InventoryState } from '../../state'

import { PagoState } from './state'

// import { ProductState } from './state'

export default {
  getPagos (state: PagoState) {
    return state.pagos
  }
}
